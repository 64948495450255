import * as dataProvider from "../../graphql/DataProvider";
import { useRef, useState } from "react";
import { Job } from "../../API";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import PrimaryButton from "../buttons/primary_button";
import { logEvent } from "../../helpers/Analytics";
import { EmailTemplate, sendEmail } from "../../graphql/EmailHandler";
import { getColledgeURL } from "../../helpers/Utilities";
import { useUserContext } from "../../helpers/UserContext";
interface ApplyModalProps {
  job: Job;
  isOpen: boolean;
  onClose: (applied?: boolean) => void;
}

/// Modal for when a student applies to a job.
export default function ApplyModal({ job, isOpen, onClose }: ApplyModalProps) {
  const { currentUser } = useUserContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const messageRef = useRef<HTMLTextAreaElement>(null);
  /// Submits an application to the job.
  async function submitApplication() {
    if (!job) return;
    if (!messageRef.current?.value) {
      toast.error("Message is required.", { toastId: "createJobApplication" });
      return;
    }
    setIsLoading(true);
    const message = messageRef.current as HTMLTextAreaElement;
    await dataProvider
      .createJobApplication(message.value, job)
      .then(() => {
        toast.success("Application submitted!", { toastId: "createJobApplication" });
        sendMessageNotification();
      })
      .catch((error) => {
        toast.error("Error submitting application.", { toastId: "createJobApplication" });
        console.error(error);
      });
    setIsLoading(false);
    onClose(true);
  }
  const sendMessageNotification = async () => {
    logEvent("notification_send_student_applied");
    const studentInvitationParams: EmailTemplate = {
      toEmail: job.poster?.email,
      fromEmail: "no-reply@colledge.us",
      subject: job?.title,
      // message: `Hi, you've a new Application in the Colledge app from ${currentUser?.firstName} ${currentUser?.lastName} regarding ${job?.title} job. Please login to Colledge and check your messages.`,
      message: `${messageRef.current?.value}`,      
      actionURL: getColledgeURL() + "job_listing/"+job?.id,
      templateId: "d-a722f870936f40218755e94b06c58975",
      userName: `${currentUser?.firstName} ${currentUser?.lastName}`
    };
    console.log(await sendEmail(studentInvitationParams)) ;
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 overflow-auto ">
          <div className="fixed inset-0 bg-gray-900 opacity-25"></div>
          <div
            className="bg-white rounded-lg p-6 z-10 w-10/12 lg:w-5/12 flex flex-col gap-2"
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxHeight: "90vh",
              overflowY: "auto",
            }}
          >
            <div className="w-full m-auto flex items-center">
              <h2 className="text-gray-900 text-3xl font-bold">Send a Message</h2>
              <XMarkIcon
                onClick={() => {
                  onClose(false);
                  logEvent("apply_modal_close_button_clicked");
                }}
                className="w-11 h-11 p-3 bg-gray-100 text-gray-700 rounded-full ml-auto"
              />
            </div>
            <div className="w-full m-auto">
              <div className="mt-4 h-[300px] mb-4">
                <textarea
                  id="message"
                  ref={messageRef}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  placeholder="Tell the poster why you'd be a great fit for this job."
                  required
                  className="w-full h-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 placeholder:text-md focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6"
                />
              </div>
              <PrimaryButton
                btnText="Submit Application"
                showLoading={isLoading}
                disabled={message.length === 0}
                onClick={() => {
                  submitApplication();
                  logEvent("apply_modal_submit_button_clicked");
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
