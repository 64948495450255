import { useEffect, useState } from "react";
import { FlaggedContent } from "../API";
import * as dataProvider from "../graphql/DataProvider";
import FlaggedContentRow from "../components/admin/flagged_content_row";
import { CenterInPageSpinner } from "../components/loading_indicator";
import { currentUserIsAdmin } from "../helpers/AuthStatus";

/// Constructs the admin panel where admins can manage Flagged content.
function FlaggedContentView() {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /// The content that has been flagged.
  const [flaggedContent, setFlaggedContent] = useState<FlaggedContent[]>([]);
  /// An array of tokens to keep track of pagination.
  /// When we click "next", we're adding to the stack, when we click
  /// "previous", we're popping from the stack.
  const [tokenStack, setTokenStack] = useState<string[]>([]);
  /// The next token to use for pagination. If nil, then we can't move forward or
  /// are starting from the beginning.
  const [nextToken, setNextToken] = useState<string | null | undefined>(null);

  /// Load initial data.
  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);

  async function fetchData() {
    setIsLoading(true);
    if ((await currentUserIsAdmin()) === false) {
      return;
    }

    dataProvider.getAllContentFlags(nextToken, 10).then((results) => {
      const nextToken = results?.listFlaggedContents?.nextToken;
      setNextToken(nextToken);
      const items = results?.listFlaggedContents?.items as FlaggedContent[];
      setFlaggedContent(items);
      setIsLoading(false);
    });
  }

  if (isLoading) {
    return <CenterInPageSpinner />;
  }

  return (
    <>
      <div className="container mx-auto md:px-4 px-8">
        <div className="py-8">
          <div className="my-2 flex flex-row md:flex-col justify-between items-center">
            <div>
              <h2 className="text-2xl text-left font-semibold leading-tight text-gray-700">
                Flagged Content
              </h2>
            </div>
          </div>
          <div className="md:-mx-4 -mx-8 md:px-4 px-8 py-4 overflow-visible">
            <div className="inline-block min-w-full shadow rounded-lg">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="px-5 py-3 w-2/12 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Info
                    </th>
                    <th className="px-5 py-3 w-2/12 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      ACCOUNT
                    </th>
                    <th className="px-5 py-3 w-1/12 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      REASON
                    </th>
                    <th className="px-5 py-3 w-2/12 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      CONTENT
                    </th>
                    <th className="px-5 py-3 w-2/12 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      COUNT
                    </th>
                    <th className="px-5 py-3 w-2/12 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      ACTIONS
                    </th>
                  </tr>
                </thead>
                {!flaggedContent && (
                  <tbody>
                    <tr className="px-5 py-5 text-gray-700 whitespace-no-wrap text-center">
                      <td className="px-5 py-5">No flagged content.</td>
                    </tr>
                  </tbody>
                )}
                {flaggedContent?.map((flag: FlaggedContent, index) => (
                  <FlaggedContentRow key={index} flaggedContent={flag} />
                ))}
              </table>
            </div>
          </div>
        </div>
        <button
          className="mr-5 bg-gray-100 border-gray-300 text-gray-700 font-medium disabled:opacity-40"
          disabled={!tokenStack.length}
          onClick={() => {
            let currentStack = tokenStack;
            const nextToken = currentStack.pop();
            setNextToken(nextToken);
            setTokenStack(currentStack);
            fetchData();
          }}
        >
          Previous
        </button>
        <button
          className="mr-5 bg-gray-100 border-gray-300 text-gray-700 font-medium disabled:opacity-40"
          disabled={!nextToken}
          onClick={() => {
            let currentStack = tokenStack;
            if (nextToken) {
              currentStack.push(nextToken);
              setTokenStack(currentStack);
            }
            fetchData();
          }}
        >
          Next
        </button>
      </div>
    </>
  );
}

export default FlaggedContentView;
