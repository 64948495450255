import SkeletonJobCards from "./SkeletonJobCards";

const SkeletonMyJob = () => {
  const sections = Array.from({ length: 3 });
  return (
    <>
      <div className="p-6 lg:p-14">
        <div className="w-full max-w-[300px] h-12  skeleton-block !rounded"></div>
        {sections.map((_, index) => (
          <div key={index} className="w-full mt-6">
            <div className="w-full max-w-[200px] h-8 mb-3 skeleton-block !rounded"></div>
            <SkeletonJobCards />
          </div>
        ))}
      </div>
    </>
  );
};

export default SkeletonMyJob;
