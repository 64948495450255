import React, { ReactNode } from "react";

interface IProps {
  children: ReactNode;
  message: string;
  className?: string;
  showTooltip?: boolean;
  parentClassName?: string;
}

export function TooltipWrapper({
  showTooltip,
  children,
  message,
  className,
  parentClassName = "",
}: IProps) {
  if (!showTooltip) {
    return <>{children}</>;
  }

  return (
    <div className={`relative inline-block group w-full ${parentClassName}`}>
      <div
        className={`absolute ${className} invite-value bg-gray-800 max-w-[250px] !min-w-[250px] ml-[33%] translate-x-0 text-center text-white p-2 bottom-[63px] rounded transform -translate-x-1/2 group-hover:scale-100 origin-center transition-transform scale-0 opacity-0 invisible group-hover:scale-100 group-hover:opacity-100 group-hover:visible `}
      >
        {message}
      </div>
      <div className="w-4 h-4 invite-icon opacity-0 bg-gray-800  group-hover:opacity-100 group-hover:visible absolute left-1/2 transform -translate-x-1/2 -rotate-45 bottom-[55px] "></div>
      {/* <div className="mt-4 flex w-full justify-center items-center">{children}</div> */}
      <div className="mt-4 flex w-full lg:justify-center items-center md:ml-3">
        {children}
      </div>
    </div>
  );
}

export default TooltipWrapper;
