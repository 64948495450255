import * as turf from "@turf/turf";
import geohash from "ngeohash";

/**
 * This file has a set of functions to help with performing geospacial queries
 * on the database.
 */

/// Generates geo hashes for a bounding box around a point. The bounding box is created
/// using a x-mile radius around the point.
export function geoHashBoundingBoxFromCoordinates(
  latitude: number,
  longitude: number,
  mileRadius: number
): string[] {
  const point = turf.point([longitude, latitude]);
  const buffer = turf.buffer(point, mileRadius, { units: "miles" });
  // longitude values are considered the x-coordinate, while latitude values are the y-coordinate.
  // bbox [minX, minY, maxX, maxY] order [minLongitude, minLatitude, maxLongitude, maxLatitude]
  const bbox = turf.bbox(buffer);
  const [minLongitude, minLatitude, maxLongitude, maxLatitude] = bbox;
  const boxes: string[] = geohash.bboxes(minLatitude, minLongitude, maxLatitude, maxLongitude, 4);
  return boxes;
}
