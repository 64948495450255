import SkeletonFooter from "./SkeletonFooter";

function SkeletonJobDetails() {
  return (
    <>
      <div className="md:flex inline flex-col max-w-md md:max-w-4xl   lg:max-w-7xl justify-start mx-auto mr-2 ml-2 gap-10 mt-10">
        <div className="gap-8 flex flex-col md:flex-row justify-center m-auto mt-4 w-full">
          <div className="w-full md:w-8/12 skeleton-pulse !rounded-xl">
            <div className="w-full text-left p-10 shadow-md !rounded-xl">
              <div className="mb-10 relative">
                <h2 className="text-3xl text-gray-800 font-bold mb-10 mr-12">
                  <div className="skeleton-block w-3/4 h-10 mb-2 rounded-md"></div>
                </h2>
                <div className="w-auto md:flex grid  gap-2 mb-4 ">
                  <div className="skeleton-block h-12 max-w-[140px] w-full skeleton-block text-gray-700 p-3 rounded-full border-radius"></div>
                  <div className="skeleton-block h-12 max-w-[140px] w-full skeleton-block text-gray-700 p-3 rounded-full border-radius"></div>
                  <div className="skeleton-block w-1/4 h-4 mt-4 md:flex ml-0 md:ml-auto md:min-w-[120px] min-w-[120px] skeleton-block text-gray-700 p-3 rounded-full border-radius"></div>
                </div>
                {/* <div className="skeleton-block w-1/4 h-4 relative bottom-[84px] md:hidden flex mt-4 md:min-w-[120px] ml-auto skeleton-block text-gray-700 p-3 rounded-full border-radius"></div> */}
                <div className="flex flex-col justify-start align-middle skeleton-block rounded">
                  <div className="w-full flex flex-col gap-4">
                    <div className="skeleton-block w-1/2 h-14 !rounded-full"></div>
                  </div>
                </div>
                <p className="text-gray-600 font-normal mb-10 mt-10">
                  <div className="skeleton-block rounded w-full !h-24"></div>
                </p>
              </div>
            </div>
          </div>
          <div className="w-full skeleton-pulse max-h-[180px] px-2 lg:w-4/12 gap-4  shadow-md flex flex-col !rounded-xl">
            <div className="text-left flex items-start pt-4 flex-col w-full rounded-xl max-h-40 pb-20">
              <h3 className="text-lg text-gray-800 font-bold pb-3 pl-6 pr-6 mb-6 border-b w-full min-h-[40px]  rounded skeleton-block"></h3>
              <div className="flex pr-6 justify-start justify-around w-full">
                <div className="flex flex-row items-center gap-4 w-full pl-6">
                  <div className="w-16 h-16 rounded-full self-center skeleton-block "></div>
                  <div className="flex text-left flex-col gap-1 mr-4">
                    <h3 className="text-gray-700 text-lg font-semibold rounded w-36 h-6 skeleton-block"></h3>
                    <div className="flex items-center gap-1">
                      <div className="w-5 h-5 text-yellow-400 skeleton-block rounded-full"></div>
                      <h3 className="text-gray-700 text-md rounded font-bold pt-1 skeleton-block  w-12 h-6"></h3>
                    </div>
                  </div>
                </div>
                <div className="pr-2 flex flex-row justify-evenly">
                  <div className="w-10 h-10 skeleton-block rounded-full text-gray-600 self-center"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <SkeletonFooter />
      </div>
    </>
  );
}

export default SkeletonJobDetails;
