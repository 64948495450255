import { v4 as uuidv4 } from "uuid";
import { Auth } from "aws-amplify";
import { Dictionary, EventLogData, LogEventQuery, LogEventQueryVariables } from "../API";
import GraphQLAPI, { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql";
import { queries } from "../graphql";

export interface LogEventProps {
  /// Attributes of the event, e.g. { "email": "user@example", "name": "John Doe" }
  /// Note, no UserId or DeviceID is necessary. These are automatically added.
  attributes?: { [key: string]: string | string[] | undefined };
  /// The query parameters, such as "utm_source=google&utm_medium=cpc&utm_campaign=summer_sale"
  /// as a dictionary.
  queryParams?: IterableIterator<[string, string]>;
  /// The URL that referred to our page. This is fetched from the Header.
  referrer?: string | null;
}

/// Provides analytics tracking for a variety of services including:
/// MixPanel: https://docs.mixpanel.com/docs/tracking/reference/javascript
/// Amplitude: https://www.docs.developers.amplitude.com/data/sdks/typescript-browser/
///
/// @param eventName The name of the event to log.
/// @param properties, the properties associated with the event.
async function logEvent(eventName: string, properties?: LogEventProps | undefined) {
  if (!eventName) return;

  // Generate or get a device identifier.
  let deviceId = uuidv4();
  if (typeof Storage !== "undefined") {
    const savedId = localStorage.getItem("logId");
    if (savedId) {
      deviceId = savedId;
    } else {
      localStorage.setItem("logId", deviceId);
    }
  }

  // Determine if a user is signed-in.
  Auth.currentUserCredentials().then((credentials) => {
    let userId = null;
    if (credentials.authenticated) {
      userId = credentials.identityId;
    }
    logToServer({ eventName, deviceId, userId, properties });
  });
}

interface LogServerProps {
  /// The name of the event to log.
  eventName: string;
  /// The device identifier. Required.
  deviceId: string;
  /// The user identifier, null if not signed-in.
  userId?: string | null;
  /// Properties of the event such as query params.
  properties?: LogEventProps | undefined;
}

/// Logs the event to the server.
async function logToServer({
  eventName,
  deviceId,
  userId,
  properties,
}: LogServerProps): Promise<boolean> {
    const signedInUser = await Auth.currentUserInfo();
    try {
      // Convert the attributes and queryParameters to a Dictionary (GraphQL) type.
      let attributeDict = [];
      for (const key in properties?.attributes) {
        attributeDict.push({ key: key, value: properties?.attributes[key] } as Dictionary);
      }
      let queryDict = [];
      for (const params in properties?.queryParams) {
        queryDict.push({ key: params[0], value: params[1] } as Dictionary);
      }
      // Create the log data.
      const logData: EventLogData = {
        deviceId: deviceId,
        userId: userId,
        attributes: attributeDict,
        queryParams: queryDict,
        referrer: properties?.referrer,
      };
      const input: LogEventQueryVariables = {
        name: eventName,
        data: logData,
      };
      const response = (await GraphQLAPI.graphql({
        query: queries.logEvent,
        variables: input,
        authMode: signedInUser
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.API_KEY,
      })) as { data: LogEventQuery };
      return response.data.logEvent ? true : false;
    } catch (err) {
      console.error("Error logging event: ", err);
      return false;
    }
}

export { logEvent };
