import { User } from "../API";

/// Returns a properly formatted name for the user: First L.
export function formattedName(user: User | null | undefined) {
  if (!user) return "";
  return user?.firstName + " " + (user?.lastName?.charAt(0) ?? "") + ".";
}

/// Returns a string that's capitalCase, e.g. WIDGET = Widget.
export function capitalCaseString(text: string | null | undefined) {
  if (!text) return "";
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

/// Returns the average review rating for a user. E.g. 4.5
export function averageRatingForUser(user: User | null | undefined): number {
  if (!user) return 0;
  let rating = 0;
  const ratingCount = user?.receivedReviews?.items?.length || 1;
  for (const review of user?.receivedReviews?.items || []) {
    rating += review!.rating;
  }
  return parseFloat((rating / ratingCount).toFixed(1));
}
//// sort to  sortArrayAlphabeticallyByProperty
export function sortArrayAlphabeticallyByProperty<T>(arr: T[], property: keyof T): T[] {
  return arr.slice().sort((a, b) => {
    const propA = String(a[property]).toLowerCase();
    const propB = String(b[property]).toLowerCase();
    return propA.localeCompare(propB);
  });
}


/// Returns the proper text for the number of reviews a user has.
/// E.g. "1 review" or "10 reviews".
export function formattedReviewText(user: User | null | undefined): string {
  if (user?.receivedReviews?.items?.length === 0) {
    return "No Reviews";
  }

  if (user?.receivedReviews?.items?.length) {
    return "(" + user?.receivedReviews?.items?.length + ")";
  }

  return "No Reviews";
}

/// Returns the URL to Colledge site based on the env.
export function getColledgeURL() {
  switch (process.env.REACT_APP_ENV) {
    case "development":
      return "https://dev.colledge.us/";
    case "production":
      return "https://old.colledge.us/";
    default:
      return "https://old.colledge.us/";
  }
}

export function validatePassword(password: string) {
  // Minimum length of 8 characters
  const minLength = 8;

  // Check for at least one digit
  const hasNumber = /\d/.test(password);

  // Check for at least one special character
  const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password);

  // Check for at least one uppercase letter
  const hasUpperCase = /[A-Z]/.test(password);

  // Check if all conditions are met
  const isValid = password.length >= minLength && hasNumber && hasSpecialChar && hasUpperCase;

  return isValid;
}

export function validateEmail(email: string) {
  // Regular expression to match email domains ending with .edu or .us
  const validDomainRegex = /\.(edu|us)$/;

  // Extract the domain from the email address
  const emailParts = email.split('@');
  const domain = emailParts[1];

  // Check if the domain matches the valid pattern
  const isValid = validDomainRegex.test(domain);

  return isValid;
}
type DateFormatOptions = {
  day?: "numeric" | "2-digit";
  month?: "numeric" | "2-digit" | "short" | "long";
  year?: "numeric" | "2-digit";
  hour?: "numeric" | "2-digit";
  minute?: "numeric" | "2-digit";
};

export const formatDateString = (
  inputDateString: string,
  options?: DateFormatOptions
): string => {
  const inputDate = new Date(inputDateString);
  const defaultOptions: DateFormatOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const mergedOptions = { ...defaultOptions, ...options };
  return inputDate.toLocaleDateString("en-US", mergedOptions);
};
// generate coupon code 
export function generateCouponCode(): string {
  const pattern: RegExp = /^[0-9A-Z]{4}$/;
  const characters: string = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let couponCode: string = "";

  for (let i = 0; i < 4; i++) {
    couponCode += characters.charAt(
      Math.floor(Math.random() * characters.length)
    );
  }

  if (pattern.test(couponCode)) {
    return couponCode;
  } else {
    return generateCouponCode(); // Recursively generate a new code
  }
}

export const parseLocation = (location: string) => {
  const parts = location?.split(',')?.map((part) => part?.trim());

  // Assuming the format is City, State, Country
  if (parts.length >= 2) {
    const city = parts[0];
    const state = parts[1];
    return { city, state };
  }

  // If the format is not as expected, you may need to adjust this function
  return null;
};