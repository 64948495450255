import Hero from "../assets/Hero-image.webp";
import HeroFallback from "../assets/Hero-image.png";
import Heromobile from "../assets/Hero-image-mobile.webp";
import HeromobileFallback from "../assets/Hero-image-mobile.png";

import Ratings from "../assets/Ratings.webp";
import RatingsFallback from "../assets/Ratings.png";
import RatingsMobile from "../assets/RatingsMobile.webp";
import RatingsMobileFallback from "../assets/RatingsMobile.png";

import Searchimg from "../assets/Search.png";

import Search from "../components/jobs/search";
import TwoCol from "../components/layouts/two_col";
import JobCards from "../components/jobs/job_cards";
import Footer from "../components/nav/footer";
import VerticalCards from "../components/layouts/vertical_cards";
import Check from "../assets/Check.png";
import Calendar from "../assets/Calendar.png";
import * as dataProvider from "../graphql/DataProvider";
import { ListJobsObjectType } from "../graphql";
import { useEffect, useState } from "react";
import { CenteredSpinner } from "../components/loading_indicator";
import { ImageWithFallback } from "../helpers/ImageWithFallback";
import { SampleJob } from "../API";
import { useUserContext } from "../helpers/UserContext";
import { logEvent } from "../helpers/Analytics";
import SkeletonJobCards from "../components/Skeleton/SkeletonJobCards";
import PrimaryButton from "../components/buttons/primary_button";
import { useNavigate } from "react-router-dom";
import JobCategoriesSelect from "../components/common/job_categories_select";

function Home() {
  const { currentUser } = useUserContext();
  const navigate = useNavigate();
  /// Signed in user jobs.
  const [jobs, setJobs] = useState<ListJobsObjectType | undefined | null>();
  /// Signed out user jobs.
  const [sampleJobs, setSampleJobs] = useState<SampleJob[] | undefined | null>(
    []
  );
  const [isLoadingJobs, setLoadingJobs] = useState<boolean>(true);

  useEffect(() => {
    setLoadingJobs(true);
    let categoryId = null;
    let nextToken = null;
    let limit = null;
    if (currentUser) {
      dataProvider
        .getAvailableJobs(categoryId, nextToken, limit)
        .then((jobsQuery) => {
          setJobs(jobsQuery);
          setLoadingJobs(false);
        })
        .catch(() => {
          setLoadingJobs(false);
        });
    } else {
      dataProvider
        .getSampleJobs()
        .then((sampleJobs) => {
          setSampleJobs(sampleJobs);
          setLoadingJobs(false);
        })
        .catch(() => {
          setLoadingJobs(false);
        });
    }
  }, [currentUser?.userID]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    logEvent("page_view_home", {
      queryParams: queryParams.entries(),
      referrer: document.referrer,
    });
  }, []);

  return (
    <>
      <div className="Hero flex flex-wrap flex-row bg-gray-50 h-auto lg:h-[800px] mx-1/4 container w-full max-w-7xl mx-auto my-0">
        <div className="basis-full relative lg:basis-6/12 h-1/2 flex-row mt-8 justify-center">
          <div className="text-left p-6 m-0 lg:m-8 md:py-2 sm:py-0 sm:my-[-15px]">
            <div className="basis-full lg:basis-6/12">
              {/* <ImageWithFallback
                src={Heromobile}
                fallback={HeromobileFallback}
                alt="Colledge Hero"
                // className="block lg:hidden object-contain items-center h-[250px] mb-4"
                className="block lg:hidden md:hidden sm:hidden object-contain mx-auto mb-4 md:h-[1055px] sm:h-[600px]"
              /> */}
              <h1 className="py-4 colledge !text-teal !capitalize">
                The gig economy for students{" "}
              </h1>
              <p className="text-slate-600 pb-8 text-[17px]">
                Colledge connects students with gig economy jobs. We make
                student life easier for students, and adulting easier for
                adults.
              </p>
            </div>
            {/* <Search onSearch={() => {}} /> */}
            <JobCategoriesSelect />
          </div>
        </div>
        {/* <div className="basis-full lg:basis-6/12 w-full h-full md:flex hidden lg:block"> */}
        <div className="basis-full object-contain lg:basis-6/12 w-full h-full md:flex hidden lg:block] ">
          <ImageWithFallback
            src={Hero}
            fallback={HeroFallback}
            alt="Colledge Hero"
            className="object-contain ml-auto items-center"
          />
        </div>
        <div className="md:hidden lg:hidden sm:mt-8">
          <ImageWithFallback
            src={Hero}
            fallback={HeroFallback}
            alt="Colledge Hero"
            className="object-contain ml-auto items-center"
          />
        </div>
      </div>
      <h2 className="text-gray-900 pb-4 text-3xl font-black mb-0 mt-16 ">
        How it Works
      </h2>

      <div className="flex flex-col md:flex-row justify-center md:px-10 px-0 gap-6 ml-2 relative pt-28 pb-28 bg-[#138086] bg-opacity-20">
        <div className="custom-shape-divider-top-1700474728">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>

        <VerticalCards
          header="Students"
          body="Post your skills and search for jobs."
          imgSrc={Searchimg}
        />
        <VerticalCards
          header="Clients"
          body="Post your jobs and search for talent."
          imgSrc={Calendar}
        />
        <VerticalCards
          header="Colledge"
          body="It's a match! Students get better jobs, clients get better help."
          imgSrc={Check}
        />

        <div className="custom-shape-divider-bottom-17004776156">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </div>

      <div className="w-full ml-0 mx-auto my-0 p-10">
        <h2 className="text-xl text-gray-800 font-bold flex mx-1/4 pb-4">
          Latest job postings
        </h2>
        {/* {isLoadingJobs && <CenteredSpinner />} */}
        {isLoadingJobs && (
          <div className="my-8">
            {Array(5)
              .fill(null)
              .map((_, index) => (
                <SkeletonJobCards key={index} />
              ))}
          </div>
        )}
        {currentUser?.userID &&
          jobs?.items.slice(0, 1).map((job: any, index: number) => {
            return <JobCards key={index} job={job} />;
          })}
        {sampleJobs?.map((sampleJob: any, index: number) => {
          return (
            <JobCards key={index} job={sampleJob} showApplicants={false} />
          );
        })}
        {((currentUser?.userID && jobs && !isLoadingJobs) ||
          (sampleJobs && !isLoadingJobs)) && (
          <div className="flex justify-center items-center px-10 py-10">
            <PrimaryButton
              btnText="View All"
              className="bg-[teal] !hover:bg-[hoverTeal] flex flex-row justify-center text-white font-bold py-3 px-8 rounded-full w-auto h-auto"
              onClick={() => {
                logEvent("home_load_more_button_pressed");
                navigate("/browse");
              }}
            />
          </div>
        )}
        {jobs?.items?.length === 0 && sampleJobs?.length === 0 && (
          <div className="text-gray-800 font-bold flex mx-1/4 mt-10 GothamBold">
            No Jobs Found.
          </div>
        )}
      </div>
      <span>&nbsp;&nbsp;</span>
      {/* <TwoCol
        header="SKILLED & TRUSTED JOB SEEKERS"
        body="Colledge is the only platform that is specifically designed to connect students to the booming gig economy and adults to its most underutilized pool of talent - students. Colledge is also the only platform to require two-way background checks to maximize safety."
        imgSrc={Ratings}
        imgSrcFallback={RatingsFallback}
        imgSrcMobile={RatingsMobile}
        imgSrcMobileFallback={RatingsMobileFallback}
        style={{
          background: "linear-gradient(180deg, #F9FEFE 0%, #FFFFFF 61.69%)",
        }}
      /> */}
      <Footer />
    </>
  );
}

export default Home;
