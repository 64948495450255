import { Fragment, useState } from "react";
import { Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
export interface CategoryOption {
  id: string;
  name: string;
  unavailable: boolean;
}
interface SpecialitiesSelectProps {
  categories: CategoryOption[];
  count?: number | null | undefined;
  selectedCategories: CategoryOption[];
  setSelectedCategory: (category: CategoryOption[]) => void;
}
function SpecialitiesSelect({
  categories,
  count,
  selectedCategories,
  setSelectedCategory,
}: SpecialitiesSelectProps) {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const selectedCategoriesIds = selectedCategories.map((c) => c.id);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setIsFocused(!isFocused); // Toggle focus state
  };

  const toggleCategories = (category: CategoryOption) => {
    // const mode = selectedCategories.includes(category) ? "remove" : "add";
    const mode = selectedCategoriesIds.includes(category.id) ? "remove" : "add";
    if (mode === "remove") {
      setSelectedCategory(
        selectedCategories.filter((c) => c.id !== category.id)
      );
    } else {
      // if count is passed then restrict the selection to count
      if (count) {
        if (selectedCategories.length < count) {
          setSelectedCategory([...selectedCategories, category]);
        } else {
          toast.error(`You can select maximum of ${count} specialities`, {
            toastId: "selectedCategories",
          });
        }
      } else {
        setSelectedCategory([...selectedCategories, category]);
      }
    }
  };
  const clearSelected = () => {
    setSelectedCategory([]);
  };

  const filteredCategories =
    query === ""
      ? categories
      : categories?.filter((category) =>
          `${category.name}`
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );
  const CategoryDelete = (category: CategoryOption) => {
    setSelectedCategory(selectedCategories.filter((c) => c !== category));
  };

  return (
    <>
      <div className="flex justify-center items-center md:ml-8 lg:ml-10 md:w-[100%] w-full min-w-[-webkit-fill-available] md:min-w-auto md:pr-0 md:pl-0 pl-5 pr-4">
        <div className="w-full">
          <div className="relative mt-1">
            <div
              // code might be used for selected classes
              //  relative w-full min-h-[48px] cursor-pointer overflow-hidden rounded-lg  text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm text-teal border-teal border-[1.7px]  bg-[#138086] bg-opacity-10 items-center h-full flex
              className={` relative w-full min-h-[48px] cursor-pointer overflow-hidden rounded-lg  text-left  sm:text-sm     items-center h-full flex
              g-gray-50  !bg-gray-50 text-gray-900 shadow-sm ring-1 ring-inset  ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-400  ${
                isFocused ? "borderFocus" : "border-2 border-transparent"
              }`}
              onClick={toggleDropdown}
            >
              <div className="w-full border-none py-1 pl-6 text-sm leading-5 text-gray-900 break-words pr-14">
                {selectedCategories.length === 0 ? (
                  <span className="text-gray-400 text-base GothamBold ">
                    Select Category...
                  </span>
                ) : (
                  selectedCategories.map((category) => (
                    <span
                      className="text-gray-900 bg-gray-500 whitespace-nowrap relative bg-opacity-10 text-base px-3 py-2    rounded-sm mr-2 leading-[38px]
                    "
                      key={category.id}
                    >
                      {category.name}
                      <XMarkIcon
                        onClick={() => CategoryDelete(category)}
                        className="h-0 w-0 absolute right-1 top-[6px] pointer-events-none bg-transparent p-0 m-0 opacity-0 text-teal cursor-pointer"
                      />
                    </span>
                  ))
                )}
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 read-only:">
                {isOpen ? (
                  <ChevronUpIcon className="h-5 w-5 text-black" />
                ) : (
                  <ChevronDownIcon className="h-5 w-5 text-black" />
                )}
                <div
                  onClick={clearSelected}
                  className="absolute right-9 top-[14px] bg-transparent p-0 m-0 "
                >
                  <XMarkIcon className="h-5 w-5 bg-transparent p-0 m-0  text-black cursor-pointer" />
                </div>
              </div>
            </div>
            <Transition
              as={Fragment}
              show={isOpen}
              enter="transition ease-in-out duration-100"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition ease-in-out duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery("")}
            >
              <div className="absolute mt-2 z-10 rounded-md w-full bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                <div className="relative ">
                  <input
                    type="search"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    className={` w-full rounded-md p-2  outline-none  text-lg font-bold 
                    !bg-gray-50 text-gray-900 shadow-sm  ring-inset  focus:ring-gray-400  ${
                      isFocused
                        ? "SearchBorderFocus"
                        : "border-1 border-transparent"
                    }`}
                    placeholder="Search Categories..."
                  />
                </div>
                {filteredCategories?.length === 0 && query !== "" ? (
                  <div className="relative cursor-default select-none text-lg px-4 text-gray-800 font-bold py-4">
                    Nothing found.
                  </div>
                ) : (
                  <div className="max-h-[400px] overflow-y-scroll">
                    {filteredCategories?.map((category: CategoryOption) => (
                      <label
                        key={category.id}
                        className="relative border-b border-gray-100  cursor-pointer select-none flex items-center h-full py-4 px-2 gap-3 hover:bg-[#138086] hover:bg-opacity-10"
                        // hover:bg-gray-50
                      >
                        <input
                          type="checkbox"
                          checked={selectedCategoriesIds.includes(category.id)}
                          onChange={() => toggleCategories(category)}
                          style={{
                            backgroundColor: selectedCategoriesIds.includes(
                              category.id
                            )
                              ? "#138086"
                              : "white",
                            border: "1px solid #138086",
                            color: "white",
                          }}
                          className="inset-0 p-2 ml-2 !outline-none !shadow-none"
                        />
                        <span className="block truncate text-black GothamBook text-base font-bold">
                          {category.name}
                        </span>
                        <span className="inset-y-0 flex items-center ml-auto mr-5">
                          {selectedCategories.includes(category) && (
                            <CheckIcon
                              onChange={() => toggleCategories(category)}
                              className="h-5 text-teal w-5"
                            />
                          )}
                        </span>
                      </label>
                    ))}
                  </div>
                )}
              </div>
            </Transition>
          </div>
        </div>
      </div>
    </>
  );
}
export default SpecialitiesSelect;
