import { Spinner } from "flowbite-react";
import { useEffect, useState } from "react";
import "../nav/navbar.css";

interface PrimaryBtnProps {
  btnText: string;
  onClick?: any;
  disabled?: boolean;
  showLoading?: boolean;
  title?: string;
  className?: string;
  primaryColor?: string;
}

function PrimaryButton({
  btnText,
  onClick,
  disabled,
  showLoading,
  title = "",
  className = "",
  primaryColor = "bg-gray-600",
}: PrimaryBtnProps) {
  const [isDisabled, setIsDisabled] = useState<boolean>(disabled ?? false);
  const [isLoading, setIsLoading] = useState<boolean>(showLoading ?? false);

  useEffect(() => {
    setIsDisabled(disabled ?? false);
  }, [disabled]);
  useEffect(() => {
    setIsLoading(showLoading ?? false);
  }, [showLoading]);

  return (
    <button
      // className={`bg-[teal] hover:bg-[hoverTeal] flex flex-row justify-center text-white font-bold py-3 px-8 rounded-full w-auto h-auto ${
      //   disabled ? "disabled-class bg-gray-300" : ""
      // }`}
      // className={`${
      //   className || "bg-[teal] hover:bg-[hoverTeal] flex flex-row justify-center text-white font-bold py-3 px-8 rounded-full w-auto h-auto"
      // } ${disabled ? "disabled-class bg-gray-300" : ""}`}
      className={`sign_up_btn GothamBold flex flex-row justify-center text-white font-bold py-3 px-6 lg:px-8 rounded-full w-auto h-auto ${className} ${
        disabled ? "sign_up_btn disabled-class bg-gray-300" : primaryColor
      }`}
      onClick={onClick}
      disabled={isDisabled || isLoading}
      title={title}
    >
      {isLoading ? (
        <>
          <Spinner aria-label="Loading action..." />
          <span className="pl-3">{btnText}</span>
        </>
      ) : (
        <span className="">{btnText}</span>
      )}
    </button>
  );
}

export default PrimaryButton;
