import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PrimaryButton from "../buttons/primary_button";
import NavLink from "./nav_link";
import {
  AuthenticatedStatus,
  currentUserIsAdmin,
} from "../../helpers/AuthStatus";
import logoBeta from "../../assets/Betalogo.png";
// import logo from "../../assets/logo.svg";
import AccountMenu from "./account_menu";
import { UserType } from "../../API";
import { toast, ToastContainer } from "react-toastify";
import { useUserContext } from "../../helpers/UserContext";
import { logEvent } from "../../helpers/Analytics";
import { useLocation } from "react-router-dom";
// import { TableCellsIcon } from "@heroicons/react/24/outline";
import { PosterCheckerMessage } from "../../helpers/Constants";
import ButtonToolTip from "../../helpers/ButtonToolTip";
import "./navbar.css";

export default function Navbar() {
  const { currentUser, approvedUser } = useUserContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [userIsAdminLevel, setUserIsAdminLevel] = useState<boolean>(false);
  let isAuthenticated = AuthenticatedStatus();
  const currentURL = useLocation().pathname;
  useEffect(() => {
    currentUserIsAdmin().then((result) => {
      setUserIsAdminLevel(result);
    });
  }, [currentUser]);
  useEffect(() => {
    if (currentUser) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentURL === "/signup") {
      localStorage.setItem("signup", "true");
      localStorage.removeItem("signin");
    }
    if (currentURL === "/signin") {
      localStorage.setItem("signin", "true");
      localStorage.removeItem("signup");
    }
  }, [currentURL]);

  function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return null;
  }

  return (
    <>
      <ScrollToTop />
      <header className="bg-white sticky top-0 z-20">
        <ToastContainer position={toast.POSITION.TOP_LEFT} theme="light" />
        <nav
          className="max-w-full flex items-center justify-between border-b p-4 md:p-6 lg:px-8 lg:border-none"
          aria-label="Global"
        >
          <div className="flex">
            <div className="flex lg:flex-1">
              <Link
                to="/home"
                onClick={() => {
                  logEvent("header_logo_home_clicked");
                }}
                className="-m-1.5 p-1.5 items-center flex md:pr-12 pr-2"
              >
                <img
                  className="md:h-8 w-auto md:min-w-auto min-w-[122px] h-[18px]"
                  src={logoBeta}
                  alt="Colledge logo"
                />
                {/* <img className="h-8 w-auto" src={logo} alt="Colledge logo" /> */}
              </Link>
            </div>
            <div className="hidden md:visible lg:flex lg:gap-x-6">
              {userIsAdminLevel === false && !loading && (
                <NavLink
                  to="/student-hire"
                  className="text-sm font-semibold flex items-center p-2 GothamBold leading-6 text-gray-900 hover:text-[teal]"
                >
                  Hire a Student
                </NavLink>
              )}
              <NavLink
                to="/browse"
                className="text-sm font-semibold flex items-center p-2 GothamBold leading-6 text-gray-900 hover:text-[teal]"
              >
                Find Jobs
              </NavLink>
              <NavLink
                to="/about"
                className="text-sm font-semibold flex items-center p-2 GothamBold leading-6 text-gray-900 hover:text-[teal]"
              >
                About Us
              </NavLink>
            </div>
          </div>
          {isAuthenticated && (
            <div
              className=" flex lg:flex lg:flex-1 lg:justify-end gap-2"
              id="signed-in"
            >
              {!userIsAdminLevel ? (
                <>
                  {currentUser?.type === UserType.POSTER && !loading && (
                    <ButtonToolTip
                      message={PosterCheckerMessage}
                      showTooltip={!approvedUser}
                    >
                      <Link
                        to="/create_job"
                        className="text-sm font-semibold whitespace-nowrap leading-6 text-gray-900 "
                      >
                        <PrimaryButton
                          className="bg-[#000000]"
                          btnText="Post a Job"
                          onClick={() => {
                            logEvent("header_post_job_button_clicked");
                          }}
                          disabled={!approvedUser}
                        />
                      </Link>
                    </ButtonToolTip>
                  )}
                  <div className="w-12 h-15">
                    <AccountMenu
                      menuItems={[
                        {
                          name: "Profile",
                          link: "/profile/" + currentUser?.userID,
                          iconName: "UserCircleIcon",
                        },
                        {
                          name: "My jobs",
                          link: "/view_jobs",
                          iconName: "DocumentIcon",
                        },
                        {
                          name: "Payment",
                          link: "/payment",
                          iconName: "Cog6ToothIcon",
                        },
                      ]}
                    />
                  </div>
                </>
              ) : (
                <div className="flex items-center gap-2  pl-4 rounded-full bg-gray-100">
                  <p className="text-gray-700 font-medium text-sm">
                    Logged in as Admin
                  </p>
                  <div className="w-12 h-12">
                    <AccountMenu
                      menuItems={[
                        {
                          name: "User Accounts",
                          link: "/manage_accounts",
                          iconName: "UserCircleIcon",
                        },
                        {
                          name: "Flagged Content",
                          link: "/flagged_content",
                          iconName: "FlagIcon",
                        },
                        {
                          name: "GoogleSheet Jobs",
                          link: "/goolesheet_job",
                          iconName: "DocumentIcon",
                        },
                        // {
                        //   name: "Coupon",
                        //   link: "/create_coupon",
                        //   iconName: "TicketIcon",
                        // },
                        {
                          name: "Coupons",
                          link: "/coupons",
                          iconName: "TicketIcon",
                        },
                        // {
                        //   name: "GoogleSheet User",
                        //   link: "/goolesheet_User",
                        //   iconName: "DocumentIcon",
                        // },
                      ]}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          {/* {!isAuthenticated && (
            <div className="lg:flex lg:flex-1 lg:justify-end">
              <Link
                to="/signup"
                className="text-sm font-semibold leading-6 pl-3 text-gray-900 hidden lg:block"
              >
                <PrimaryButton btnText="Sign up" />
              </Link>
              <Link
                to="/signup"
                className="text-sm font-semibold leading-6 pl-3 text-gray-900 block lg:hidden"
              >
                <PrimaryButton
                  btnText="Sign up"
                  onClick={() => {
                    logEvent("header_signin_button_pressed");
                  }}
                />
              </Link>
            </div>
          )} */}
          {!isAuthenticated && (
            <div className="sign_btn_container lg:flex lg:flex-1 lg:justify-end">
              <Link
                to="/signup"
                className="text-sm font-semibold leading-6 pl-3 text-gray-900 hidden lg:block"
              >
                <PrimaryButton
                  btnText="Sign up"
                  className="bg-[#FB923C] hover:bg-[teal] focus:bg-[teal] "
                  primaryColor="bg-[#FB923C]"
                />
              </Link>
              <Link
                to="/signup"
                className="text-sm font-semibold leading-6 pl-3 text-gray-900 block lg:hidden"
              >
                <PrimaryButton
                  className="text-xs px-2 py-1 bg-[#FB923C] hover:bg-[teal] focus:bg-[teal] "
                  btnText="Sign up"
                  primaryColor="bg-[#FB923C]"
                  onClick={() => {
                    logEvent("header_signup_button_pressed");
                  }}
                />
              </Link>

              <Link
                to="/signin"
                className="text-sm font-semibold leading-6 pl-3 text-gray-900 hidden lg:block"
              >
                <PrimaryButton
                  btnText="Sign in"
                  className="bg-[teal] hover:bg-[#FB923C] focus:bg-[#FB923C]"
                  primaryColor="bg-[teal]"
                />
              </Link>
              <Link
                to="/signin"
                className="text-sm font-semibold leading-6 pl-3 text-gray-900 block lg:hidden"
              >
                <PrimaryButton
                  className="text-xs px-2 py-1 bg-[teal] hover:bg-[#FB923C] focus:bg-[#FB923C]"
                  btnText="Sign in"
                  primaryColor="bg-[teal]"
                  onClick={() => {
                    logEvent("header_signin_button_pressed");
                  }}
                />
              </Link>
            </div>
          )}
        </nav>

        {/* Mobile Navigation */}
        <div className="flex flex-row justify-between lg:hidden gap-6 p-4 w-full border-b">
          <div className="flex">
            {userIsAdminLevel === false && !loading && (
              <NavLink
                to="/student-hire"
                className="text-sm font-semibold flex items-center GothamBold p-2 leading-6 text-gray-900 hover:text-[teal]"
              >
                Hire a Student
              </NavLink>
            )}
            <NavLink
              to="/browse"
              className="text-sm font-semibold flex items-center GothamBold p-2 leading-6 text-gray-900 hover:text-[teal]"
            >
              Find Jobs
            </NavLink>
            <NavLink
              to="/about"
              className="text-sm font-semibold flex items-center GothamBold p-2 leading-6 text-gray-900 hover:text-[teal]"
            >
              About Us
            </NavLink>
          </div>
          <div className="flex items-center">
            {/* <MobileMenu
              menuItems={[
                {
                  name: "Some Page",
                  link: "/link",
                  iconName: "DocumentIcon",
                },
              ]}
            /> */}
          </div>
        </div>
      </header>
    </>
  );
}
