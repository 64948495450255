import {
  FireIcon,
  CheckCircleIcon,
  HandThumbUpIcon,
  RocketLaunchIcon,
} from "@heroicons/react/20/solid";
import { User, UserBadge } from "../../API";

/// This array must match that of badgeHandler function.
const BADGES = [
  { id: 1, name: "Early Admission", description: "First to platform", icon: "Fire" },
  { id: 2, name: "Top Rated", description: "Average 4 Stars", icon: "ThumbsUp" },
  { id: 3, name: "Top Hirer", description: "10 Jobs Completed", icon: "CheckMark" },
  { id: 4, name: "Super Hirer", description: "20 Jobs Completed", icon: "RocketLaunch" },
];

/// Returns the Icon file for the given name.
function iconForName(name: string) {
  switch (name) {
    case "Fire":
      return <FireIcon className="w-10" />;
    case "ThumbsUp":
      return <HandThumbUpIcon className="w-10" />;
    case "CheckMark":
      return <CheckCircleIcon className="w-10" />;
    case "RocketLaunch":
      return <RocketLaunchIcon className="w-10" />;
  }
}

interface BadgesCardProps {
  user: User | null | undefined;
  userBadges: UserBadge[];
}

function BadgesCard({ user, userBadges }: BadgesCardProps) {
  /// Determines if a user has a badge for the given ID.
  function userHasBadge(badgeId: number) {
    if (userBadges?.length) {
      return userBadges.some((badge) => badge?.id === badgeId);
    }
    return false;
  }

  return (
    <>
      <div className="bg-white text-left flex items-start p-6 lg:p-14 flex-col w-11/12 m-auto lg:w-full shadow-md rounded-xl h-auto">
        <h2 className="text-gray-700 text-3xl font-semibold text-left pb-10">Badges</h2>
        <div className="flex-col md:flex-row flex justify-around w-full text-center whitespace-nowrap gap-10 pb-8">
          {BADGES.map((badge, index) => {
            return (
              <div key={index} className="flex flex-col justify-center gap-2">
                <div
                  className={`w-20 h-20 px-2 ${
                    userHasBadge(badge.id)
                      ? "bg-orange-100 text-orange-400"
                      : "bg-gray-100 text-gray-300"
                  } m-auto rounded-lg flex justify-center mb-4`}
                >
                  {iconForName(badge.icon)}
                </div>
                <p className="text-gray-700 text-md font-semibold">{badge.name}</p>
                <p className="text-gray-700 text-sm">{badge.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default BadgesCard;
