import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import CustomDialog from "../modals/custom_dialog";
import { logEvent } from "../../helpers/Analytics";
import logoBeta from "../../assets/Betalogo.png";

interface FooterProps {
  faqRef?: React.RefObject<HTMLDivElement> | null;
  aboutUsRef?: React.RefObject<HTMLDivElement> | null;
  whyColledgeRef?: React.RefObject<HTMLDivElement> | null;
}

function Footer({ faqRef, aboutUsRef, whyColledgeRef }: FooterProps) {
  const { hash } = useLocation();
  const currentDate = new Date();
  let [isOpen, setIsOpen] = useState(false);
  let [isOpenPrivacy, setIsOpenPrivacy] = useState(false);

  const showDialog = () => {
    setIsOpen(true);
  };

  const showDialogPrivacy = () => {
    setIsOpenPrivacy(true);
  };

  useEffect(() => {
    switch (hash) {
      case "#faq":
        faqRef?.current?.scrollIntoView({ behavior: "auto" });
        break;
      case "#about-us":
        aboutUsRef?.current?.scrollIntoView({ behavior: "auto" });
        break;
      case "#why-colledge":
        whyColledgeRef?.current?.scrollIntoView({ behavior: "auto" });
        break;
      default:
        // No-op
        break;
    }
  }, [hash]);

  return (
    <>
      <footer className="bg-white">
        <div className="mx-auto max-w-screen-xl space-y-8 px-4 py-16 lg:space-y-16 lg:px-8 text-left">
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
            <div>
              <div className="text-teal-600">
                <Link
                  to="/home"
                  className="font-bold GothamBold text-3xl tracking-tight"
                  onClick={() => {
                    logEvent("footer_home_link_clicked");
                    window.scrollTo(0, 0);   
                  }}
                >
                  {/* colledge */}
                  <img className="md:h-8 w-auto  md:min-w-auto min-w-[122px] h-[28px]" src={logoBeta} alt="Colledge logo" />
                </Link>
              </div>
            </div>

            <div className="grid md:grid-cols-3 w-full gap-8 grid-cols-2 lg:col-span-2 lg:grid-cols-3 md:flex md:justify-between">
              <div>
                <p className="font-semibold text-gray-900 GothamBold">Company</p>

                <ul className="mt-6 space-y-4 text-sm">
                  <li className="GothamBold ">
                    <Link
                      to="/about#why-colledge"
                      onClick={() => {
                        logEvent("footer_why_link_clicked");
                        if (whyColledgeRef) {
                          whyColledgeRef?.current?.scrollIntoView({
                            behavior: "smooth",
                          });
                        }
                      }}
                      className="text-gray-600 transition font-bold hover:text-[teal]"
                    >
                      Why Colledge?
                    </Link>
                  </li>

                  <li className="GothamBold ">
                    <Link
                      to="/about#about-us"
                      onClick={() => {
                        logEvent("footer_about_link_clicked");
                        if (aboutUsRef) {
                          aboutUsRef?.current?.scrollIntoView({
                            behavior: "smooth",
                          });
                        }
                      }}
                      className="text-gray-600 transition font-bold hover:text-[teal]"
                    >
                      About Us
                    </Link>
                  </li>
                  <li className="GothamBold ">
                    <Link
                      to="/terms"
                      onClick={() => {
                        logEvent("footer_terms_link_clicked");
                      }}
                      className="text-gray-600 transition font-bold hover:text-[teal]"
                    >
                      Terms                    
                  </Link>
                  </li>                  
                </ul>
              </div>

              <div>
                <p className="font-semibold text-gray-900 GothamBold">Resources</p>

                <ul className="mt-6 space-y-4 text-sm">
                  <li className="GothamBold ">
                    <a
                      href="mailto:help@colledge.us"
                      className="text-gray-600 transition font-bold hover:text-[teal]"
                    >
                      Contact Us
                    </a>
                  </li>

                  <li className="GothamBold ">
                    <Link
                      to="/about#faq"
                      onClick={() => {
                        logEvent("footer_faq_link_clicked");
                        if (faqRef) {
                          faqRef?.current?.scrollIntoView({
                            behavior: "smooth",
                          });
                        }
                      }}
                      className="text-gray-600 transition font-bold hover:text-[teal]"
                    >
                      FAQ
                    </Link>
                  </li>

                  <li className="GothamBold ">
                    <Link
                      to="/support"
                      onClick={() => {
                        logEvent("footer_faq_link_clicked");
                        if (faqRef) {
                          faqRef?.current?.scrollIntoView({
                            behavior: "smooth",
                          });
                        }
                      }}
                      className="text-gray-600 transition font-bold hover:text-[teal]"
                    >
                    Support 
                    </Link>
                  </li>
                </ul>
              </div>

              <div>
                <p className="font-semibold text-gray-900 GothamBold">Legal</p>

                <ul className="mt-6 space-y-4 text-sm">
                  <li className="GothamBold ">
                    <button
                      onClick={() => {
                        showDialog();
                        logEvent("footer_refund_policy_link_clicked");
                      }}
                      className="text-gray-600 transition font-bold hover:text-[teal] bg-white p-0"
                    >
                      Refund Policy
                    </button>
                  </li>
                  <li className="GothamBold ">
                    <button
                      onClick={() => {
                        showDialogPrivacy();
                        logEvent("footer_privacy_policy_link_clicked");
                      }}
                      className="text-gray-600 transition font-bold hover:text-[teal] bg-white p-0"
                    >
                      Privacy Policy
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <p className="text-xs text-gray-500 GothamBold font-bold ">
            &copy; {currentDate.getFullYear()} Colledge. All rights reserved.
          </p>
        </div>
        <CustomDialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          title="Refund policy"
          description={
            <>
              <div className="flex flex-col gap-4">
                <p className="text-gray-700">
                  Colledge is a customer-service driven platform and we strive
                  to provide students and clients with a seamless experience.
                  Please refer to the details of our refund policy below.{" "}
                </p>
                <div className="flex flex-col gap-1 mt-4">
                  <h3 className="text-gray-800 font-bold">
                    Background Checks{" "}
                  </h3>
                  <p className="text-gray-700">
                    Background checks are required for all parties to ensure the
                    safety and security of our Colledge community. Fees
                    associated with background checks are not refundable.
                  </p>
                </div>
                <div className="flex flex-col gap-1 mt-4">
                  <h3 className="text-gray-800 font-bold">
                    Service Cancellations
                  </h3>
                  <p className="text-gray-700">
                    Once a job is booked through Colledge, payment is held in
                    escrow. If the job is cancelled or not completed for any
                    reason, the amount in escrow will be fully refunded.{" "}
                  </p>
                </div>
                <div className="flex flex-col gap-1 mt-4">
                  <h3 className="text-gray-800 font-bold">Service Disputes </h3>
                  <p className="text-gray-700">
                    Colledge provides guidance, best practices, and
                    communication tools to support both parties in any service
                    engagement. If a dispute does arise, please contact{" "}
                    <a
                      href="mailto:help@colledge.us"
                      className="text-[teal] hover:opacity-75"
                    >
                      help@colledge.us
                    </a>{" "}
                    for review.
                  </p>
                </div>
                <div className="flex flex-col gap-1 mt-4">
                  <h3 className="text-gray-800 font-bold">
                    Subscription Refunds{" "}
                  </h3>
                  <p className="text-gray-700">
                    Monthly subscriptions can be cancelled anytime, and the
                    prorated portion of the subscription will be refunded. Any
                    completed jobs prior to the cancellation will be subject to
                    payment and not eligible for a refund.
                  </p>
                </div>
              </div>
            </>
          }
        />

        <CustomDialog
          open={isOpenPrivacy}
          onClose={() => setIsOpenPrivacy(false)}
          title="Privacy Policy for Colledge Inc."
          description={
            <>
              <div className="flex flex-col gap-4">
                <h3 className="text-gray-700 font-bold">
                  Effective Date: July 1, 2023
                </h3>
                <p className="text-gray-700">
                  This Privacy Policy outlines the practices and procedures of
                  Colledge Inc. (referred to as "we," "us," or "our"), regarding
                  the collection, use, and disclosure of personal information
                  from users (referred to as "you" or "your") of our website and
                  related services. We are committed to protecting your privacy
                  and ensuring the security of your personal information. By
                  accessing or using our website and services, you consent to
                  the terms and practices described in this Privacy Policy.
                </p>
                <div className="flex flex-col gap-1 mt-4">
                  <h3 className="text-gray-800 font-bold">
                    1. Information We Collect:
                  </h3>
                  <p className="text-gray-700">
                    <span className="underline">a. Personal Information:</span>{" "}
                    We may collect personally identifiable information, such as
                    your name, email address, phone number, postal address, and
                    other relevant details necessary for creating and managing
                    your account, providing services, and facilitating
                    communications and payments between users.
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-gray-700">
                    <span className="underline">
                      b. User-generated Content:
                    </span>{" "}
                    We may collect information you provide when creating or
                    updating your profile, including your skills,
                    qualifications, employment history, education, and other
                    relevant details necessary for showcasing your professional
                    background to potential clients.
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-gray-700">
                    <span className="underline">c. Usage Information:</span> We
                    may collect non-personal information about your interactions
                    with our website, such as your IP address, browser type,
                    operating system, referring URLs, pages visited, and the
                    duration of your visits. We may also use cookies or similar
                    technologies to enhance your experience on our site.
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-gray-700">
                    <span className="underline">d. Communications:</span>We may
                    collect information related to your communications with
                    other users, such as messages, job applications, feedback,
                    and reviews.
                  </p>
                </div>
                <div className="flex flex-col gap-1 mt-4">
                  <h3 className="text-gray-800 font-bold">
                    2. Use of Collected Information:
                  </h3>
                  <p className="text-gray-700">
                    <span className="underline">
                      a. Provisions of Services:
                    </span>{" "}
                    We use the collected information to provide, improve, and
                    personalize our services, including matching job seekers
                    with employers or clients, facilitating communication, and
                    optimizing the user experience.
                  </p>
                </div>
                <div className="flex flex-col gap-1 mt-4">
                  <h3 className="text-gray-800 font-bold">
                    2. Use of Collected Information:
                  </h3>
                  <p className="text-gray-700">
                    <span className="underline">
                      a. Provisions of Services:
                    </span>{" "}
                    We use the collected information to provide, improve, and
                    personalize our services, including matching job seekers
                    with employers or clients, facilitating communication, and
                    optimizing the user experience.
                  </p>
                </div>
                <div className="flex flex-col gap-1 mt-4">
                  <p className="text-gray-700">
                    <span className="underline">b. Communication:</span> We may
                    use your information to send you administrative or
                    promotional messages about additional Colledge services,
                    updates, and newsletters, as well as respond to your
                    inquiries, feedback, or requests for assistance.
                  </p>
                </div>
                <div className="flex flex-col gap-1 mt-4">
                  <p className="text-gray-700">
                    <span className="underline">
                      Analytics and Improvements:
                    </span>{" "}
                    We may analyze the collected information to understand user
                    preferences, trends, and patterns, in order to enhance and
                    optimize our website, services, and user experience.
                  </p>
                </div>
                <div className="flex flex-col gap-1 mt-4">
                  <p className="text-gray-700">
                    <span className="underline">Legal Compliance:</span> We may
                    use your information to comply with applicable laws,
                    regulations, or legal processes, as well as protect our
                    rights, property, or safety, or the rights, property, or
                    safety of our users or others.
                  </p>
                </div>
                <div className="flex flex-col gap-1 mt-4">
                  <h3 className="text-gray-800 font-bold">
                    3. Information Sharing and Disclosure:
                  </h3>
                  <p className="text-gray-700">
                    <span className="underline">a. Service Providers:</span> We
                    may engage third-party service providers to perform
                    functions on our behalf, such as hosting, data storage,
                    payment processing, customer support, or analytics. These
                    service providers will have access to your information
                    solely for the purpose of performing their functions and are
                    obligated to maintain its confidentiality.
                  </p>
                </div>
                <div className="flex flex-col gap-1 mt-4">
                  <p className="text-gray-700">
                    <span className="underline">
                      b. Users and Public Information:
                    </span>
                    Your profile and user-generated content, including
                    information provided in job applications, may be visible to
                    other users or the public, depending on your account
                    settings and the nature of our services.
                  </p>
                </div>
                <div className="flex flex-col gap-1 mt-4">
                  <p className="text-gray-700">
                    <span className="underline">c. Compliance with Law:</span>{" "}
                    We may disclose your information if required by law,
                    subpoena, court order, or other governmental or legal
                    process, or to protect and defend our rights, property, or
                    safety, or those of our users or others.
                  </p>
                </div>
                <div className="flex flex-col gap-1 mt-4">
                  <p className="text-gray-700">
                    <span className="underline">d. Business Transfers: </span>In
                    the event of a merger, acquisition, sale, or transfer of all
                    or a portion of our assets, your information may be
                    transferred as part of the transaction. We will notify you
                    via email and/or prominent notice on our website of any such
                    change in ownership or control.
                  </p>
                </div>
                <div className="flex flex-col gap-1 mt-4">
                  <p className="text-gray-700">
                    <span className="underline">d. Business Transfers: </span>In
                    the event of a merger, acquisition, sale, or transfer of all
                    or a portion of our assets, your information may be
                    transferred as part of the transaction. We will notify you
                    via email and/or prominent notice on our website of any such
                    change in ownership or control.
                  </p>
                </div>
                <div className="flex flex-col gap-1 mt-4">
                  <h3 className="text-gray-800 font-bold">4. Data Security:</h3>
                  <p className="text-gray-700">
                    a. We implement industry-standard security measures to
                    protect your personal information from unauthorized access,
                    disclosure, alteration, or destruction. However, no method
                    of transmission over the internet or electronic storage is
                    100% secure, and we cannot guarantee absolute security.
                  </p>
                </div>
                <div className="flex flex-col gap-1 mt-4">
                  <h3 className="text-gray-800 font-bold">
                    5. Your Privacy Choices:
                  </h3>
                  <p className="text-gray-700">
                    <span className="underline">a. Account Information: </span>
                    You have the right to access, update, correct, or delete
                    your account information. You may review and modify your
                    profile settings and preferences within your account or by
                    contacting us directly.
                  </p>
                  <p className="text-gray-700">
                    <span className="underline">b.Communications: </span>You can
                    manage your communication preferences by opting out of
                    promotional emails or notifications through the account
                    settings or by following the instructions provided in the
                    communication.
                  </p>
                </div>
                <div className="flex flex-col gap-1 mt-4">
                  <h3 className="text-gray-800 font-bold">
                    6. Children’s Privacy:
                  </h3>
                  <p className="text-gray-700">
                    a. Our services are not intended for individuals under the
                    age of 18. We do not knowingly collect or solicit personal
                    information from anyone under 18. If we become aware that we
                    have collected personal information from a child under 18
                    without verification of parental consent, we will delete
                    that information promptly.
                  </p>
                </div>
                <div className="flex flex-col gap-1 mt-4">
                  <h3 className="text-gray-800 font-bold">
                    7. Changes to this Privacy Policy:
                  </h3>
                  <p className="text-gray-700">
                    a. We reserve the right to modify or update this Privacy
                    Policy at any time. We will notify you of significant
                    changes by posting a prominent notice on our website or
                    sending you a notification. Your continued use of our
                    website and services after any modifications to this Privacy
                    Policy will signify your acceptance of the changes.
                  </p>
                </div>
                <div className="flex flex-col gap-1 mt-4">
                  <h3 className="text-gray-800 font-bold">8. Contact Us:</h3>
                  <p className="text-gray-700">
                    a. If you have any questions, concerns, or requests
                    regarding this Privacy Policy or the handling of your
                    personal information, please contact us at{" "}
                    <a
                      href="mailto:help@colledge.us"
                      className="text-[teal] hover:opacity-75"
                    >
                      help@colledge.us
                    </a>
                  </p>
                </div>
              </div>
            </>
          }
        />
      </footer>
    </>
  );
}

export default Footer;
