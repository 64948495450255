import { createContext, useContext, useEffect, useState } from "react";
import * as adminDataProvider from "../graphql/AdminDataProvider";
import { User } from "../API";
import * as dataProvider from "../graphql/DataProvider";
import { AuthenticatedStatus, currentUserIsApproved } from "./AuthStatus";

interface UserGroup {
  id: string;
  name: string;
}
export interface UserProps {
  loading: boolean;
  currentUser: User | null;
  userGroup: UserGroup | null;
  approvedUser: boolean;
  updateUser: () => void;
  updateApprovedStatus : (status:boolean) => void;
}

const UserContext = createContext<UserProps | null>(null);

interface UserContextProps {
  children: React.ReactNode;
}

/// Provider for user state.
const UserProvider = ({ children }: UserContextProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [userGroup, setUserGroup] = useState<UserGroup | null>(null);
  const [approvedUser, setApprovedUser] = useState(false);
  const isAuthenticated = AuthenticatedStatus();

  async function fetchUser() {
    setLoading(true);
    dataProvider.getCurrentUser().then((user) => {
      setCurrentUser(user);
      setLoading(false);

      // fetch user group
      if (user?.name || user?.userID) {
        adminDataProvider
          .listGroupsForUser(user?.name || user?.userID)
          .then((groups) => {
            if (!groups) {
              setUserGroup(null);
              return;
            }
            const group: UserGroup = {
              id: groups[0].GroupName,
              name: groups[0].GroupName.split("_")[0],
            };
            setUserGroup(group);
          });
      }
    });
    currentUserIsApproved().then((isApproved) => {
      setApprovedUser(isApproved);
    });
  }

  useEffect(() => {
    fetchUser();
  }, [isAuthenticated]);

  const updateUser = () => {
    fetchUser();
  };
  const updateApprovedStatus =(status:boolean)=>{
   setApprovedUser(status);
  }
  return (
    <UserContext.Provider
      value={{
        currentUser: currentUser,
        updateUser: updateUser,
        loading,
        approvedUser,
        userGroup,
        updateApprovedStatus:updateApprovedStatus
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

/// Helper to consume the UserContext.
const useUserContext = () => {
  const context = useContext(UserContext);
  return context as UserProps;
};

export { UserProvider, useUserContext };
