import { useEffect, useState } from "react";
import { Listbox } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { CustomSpinner } from "../loading_indicator";
import {
  CreateCouponInput,
  UpdateCouponInput,
  couponCodeType,
} from "../../API";
import * as dataProvider from "../../graphql/DataProvider";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { CenterInPageSpinner } from "../loading_indicator";
import PrimaryButton from "../buttons/primary_button";
import { generateCouponCode } from "../../helpers/Utilities";
import { useNavigate } from "react-router-dom";
interface ISelectedType {
  id: string;
  name: string;
}
interface ICoupon {
  id: string;
  selectedType: ISelectedType;
  amount: string;
  percentage: string;
  isActive: boolean;
  couponCode: string;
  description: string;
  expiryDate: string;
}

interface IFormErrors {
  couponCode: string;
  amount: string;
  percentage: string;
}

const CouponForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const type: ISelectedType[] = [
    { id: "Flat_Off", name: "Flat OFF" },
    { id: "Percentage", name: "Percentage(%)" },
  ];
  const initialState = {
    id: "",
    selectedType: type[0],
    amount: "",
    percentage: "",
    isActive: false,
    couponCode: "",
    description: "",
    expiryDate: "",
  };

  const [formData, setFormData] = useState<ICoupon>(initialState);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<IFormErrors>({
    couponCode: "",
    amount: "",
    percentage: "",
  });

  const toggleIsActive = () => {
    setFormData((prevData) => ({ ...prevData, isActive: !prevData.isActive }));
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const validateForm = () => {
    let errors: IFormErrors = {
      couponCode: "",
      amount: "",
      percentage: "",
    };

    if (formData.couponCode.trim() === "") {
      errors.couponCode = "Coupon Code is required!";
    }

    if (
      formData.selectedType.id === "Flat_Off" &&
      (formData.amount.trim() === "" || Number(formData.amount) <= 0)
    ) {
      errors.amount = "Amount should be a valid number greater than 0!";
    }

    if (
      formData.selectedType.id === "Percentage" &&
      (formData.percentage.trim() === "" || Number(formData.percentage) <= 0)
    ) {
      errors.percentage = "Percentage should be a valid number greater than 0!";
    }

    setFormErrors(errors);

    return errors;
  };
  const handleCouponForm = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    const errors = validateForm();
    const hasErrors = Object.values(errors).some((error) => error !== "");
    if (hasErrors) {
      return;
    } else {
      let couponData = {
        couponCode: formData.couponCode,
        type: formData.selectedType.id as couponCodeType,
        description: formData.description,
        expiryDate: formData.expiryDate,
        ...(formData.selectedType.id === "Flat_Off"
          ? { amount: Number(formData.amount) * 100 }
          : { percentage: Number(formData.percentage) }),
        isActive: formData.isActive,
      } as CreateCouponInput;
      setIsSaving(true);

      // if id is there update the coupon else create the coupon
      if (id) {
        let newCouponData = { ...couponData, id: id } as UpdateCouponInput;
        updateCoupon(newCouponData);
      } else {
        createCoupon(couponData);
      }
    }
  };

  const createCoupon = async (data: CreateCouponInput) => {
    try {
      const res = await dataProvider.createCoupon(data);
      if (res?.data) {
        toast.success("Coupon created successfully", {
          toastId: "coupon_created",
        });
        setFormData(initialState);
        setIsSaving(false);
        navigate("/coupons");
      }
    } catch (err) {
      err &&
        toast.error("Error Creating Coupon", {
          toastId: "coupon_created_error",
        });
    } finally {
      setIsSaving(false);
    }
  };

  const updateCoupon = async (data: UpdateCouponInput) => {
    try {
      const res = await dataProvider.updateCoupon(data);
      if (res) {
        toast.success("Coupon updated successfully", {
          toastId: "coupon_created",
        });
        setFormData(formData);
        setIsSaving(false);
        navigate("/coupons");
      }
    } catch (err) {
      err &&
        toast.error("Error Updating Coupon", {
          toastId: "coupon_update_error",
        });
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (id) {
      setisLoading(true);
      dataProvider.getCoupon(id).then((res) => {
        setFormData({
          id: res?.id ?? "",
          couponCode: res?.couponCode ?? "",
          selectedType: res?.type === "Flat_Off" ? type[0] : type[1],
          description: res?.description ?? "",
          expiryDate: res?.expiryDate ?? "",
          amount: String(Number(res?.amount)/100 ?? ""),
          percentage: String(res?.percentage ?? ""),
          isActive: res?.isActive ?? false,
        });
        setisLoading(false);
      });
    } else {
      setFormData(initialState);
    }
  }, [id]);

  // generate Coupom Code 
  const generateCoupon = (event: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    event.preventDefault();
    event.stopPropagation();
    let coupon: string = generateCouponCode();
    setFormData((prevData) => ({
      ...prevData,
      couponCode: `COUPON_${coupon}`,
    }));
  };

  if (isLoading && id) {
    return <CenterInPageSpinner />;
  }

  return (
    <>
      <form
        className="space-y-6 w-11/12 lg:w-8/12 flex flex-col m-auto gap-4 mx-w-auto mt-4 mb-20"
        onSubmit={handleCouponForm}
      >
        <div className="flex flex-col p-4 pb-10 lg:p-10 rounded-2xl w-full shadow-lg justify-center text-left m-auto gap-8">
          <div className="w-full m-auto">
            <h2 className="text-gray-900 text-4xl font-bold ml-8 lg:ml-10">
              Coupon
            </h2>
            <div className="w-full m-auto">
              <label
                htmlFor="couponcode"
                className="block text-sm font-medium leading-6 text-gray-900 ml-8 lg:ml-10 mt-5"
              >
                Coupon Code
              </label>
              <div className="mt-2 ml-8 lg:ml-10 flex gap-2 items-center">
                <input
                  type="text"
                  id="couponcode"
                  name="couponCode"
                  placeholder="Coupon Code"
                  value={formData.couponCode}
                  className="block w-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-12"
                  onChange={handleInputChange}
                />
                <PrimaryButton btnText="generate coupon code" className="ml-8 lg:ml-10 text-sm capitalize" onClick={generateCoupon} />
              </div>
              
              <div className="text-red-500 text-sm text-start capitalize ml-8 lg:ml-10 ">
                {formErrors?.couponCode}
              </div>
            </div>
            <div className="flex ml-8 lg:ml-10 py-4 pr-10 w-full justify-center text-left ">
              <div className="w-full m-auto">
                <label
                  htmlFor="active"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Active
                </label>
              </div>
              <div className="flex max-w-md flex-col gap-4">
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    id="active"
                    checked={formData.isActive}
                    onChange={toggleIsActive}
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 bg-gray-300 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-200 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primaryTeal"></div>
                </label>
              </div>
            </div>
            <div className="w-full m-auto">
              <label
                htmlFor="type"
                className="block text-sm font-medium leading-6 text-gray-900 ml-8 lg:ml-10"
              >
                Type
              </label>
              <div className="lg:ml-10 flex gap-2 items-center">
                <Listbox
                  value={formData.selectedType}
                  onChange={(value) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      selectedType: value,
                    }))
                  }
                >
                  <div className="flex relative flex-row w-full py-1 selector-main-div ">
                    <Listbox.Button
                      className={`relative items-center GothamBold w-full cursor-default  rounded-md font-semibold py-3 pr-10 text-left focus:outline-none focus-visible:border-gray-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 text-sm
               text-[#138086] border-[#138086] border-[1.7px] bg-[#138086] bg-opacity-10
               `}
                    >
                      {formData.selectedType.name}
                    </Listbox.Button>
                    <Listbox.Options className="absolute mt-1 mb-5 max-h-60 w-full overflow-auto rounded-md z-10 bg-white py-1 md:text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
                      {type.map((types, typesIdx) => (
                        <Listbox.Option
                          key={typesIdx}
                          className={({ active }) =>
                            `relative cursor-default hover:bg-gray-300 hover:bg-opacity-25  select-none py-2 pl-5 pr-4 ${
                              active ? " text-gray-900" : "text-gray-800"
                            }`
                          }
                          value={types}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`flex 
                    text-[#138086] truncate GothamBook  md:!whitespace-nowrap !whitespace-normal  ${
                      selected ? "font-medium" : "font-normal"
                    }`}
                              >
                                {types.name}
                              </span>
                              {selected ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600">
                                  <CheckIcon
                                    // className="h-5 w-5 text-red-500"
                                    className={`h-4 w-4  
                    text-[#138086]
                    `}
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </div>
                </Listbox>
              </div>
            </div>
            {formData.selectedType.name === "Flat OFF" ? (
              <div className="w-full m-auto">
                <label
                  htmlFor="amount"
                  className="block text-sm font-medium leading-6 text-gray-900 ml-8 lg:ml-10 mt-5"
                >
                  Amount
                </label>
                <div className="mt-2 ml-8 lg:ml-10 flex gap-2 items-center">
                  <input
                    type="number"
                    id="amount"
                    placeholder="Amount"
                    value={formData.amount}
                    onChange={handleInputChange}
                    name="amount"
                    className="block w-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-12"
                  />
                </div>
                <div className="text-red-500 text-sm text-start capitalize ml-8 lg:ml-10 ">
                  {formErrors?.amount}
                </div>
              </div>
            ) : (
              <div className="w-full m-auto">
                <label
                  htmlFor="percentage"
                  className="block text-sm font-medium leading-6 text-gray-900 ml-8 lg:ml-10 mt-5"
                >
                  Percentage
                </label>
                <div className="mt-2 ml-8 lg:ml-10 flex gap-2 items-center">
                  <input
                    type="number"
                    id="percentage"
                    placeholder="Percentage"
                    value={formData.percentage}
                    onChange={handleInputChange}
                    name="percentage"
                    className="block w-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-12"
                  />
                </div>
                <div className="text-red-500 text-sm text-start capitalize ml-8 lg:ml-10 ">
                  {formErrors?.percentage}
                </div>
              </div>
            )}
            <div className="w-full m-auto">
              <label
                htmlFor="message"
                className="block text-sm font-medium leading-6 text-gray-900 ml-8 lg:ml-10 mt-5"
              >
                Description
              </label>
              <div className="mt-2 flex gap-2 ml-8 lg:ml-10">
                <textarea
                  placeholder="Enter your description..."
                  value={formData.description}
                  className="block w-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-40"
                  onChange={handleInputChange}
                  name="description"
                ></textarea>
              </div>
            </div>
            <div className="flex lg:pt-5 w-full justify-center text-left">
              <div className="flex lg:pt-5 w-full justify-center text-left">
                <div className="w-full m-auto">
                  <label
                    htmlFor="expiry"
                    className="block text-sm font-medium inline-flex leading-6 text-gray-900 ml-8 lg:ml-10 mt-5"
                  >
                    Expiry Date
                  </label>
                  <div className="inline-flex items-center mt-2">
                    <input
                      type="date"
                      id="expiryDate"
                      value={formData.expiryDate}
                      className="border rounded-md p-2 ml-10 focus:ring-2 focus:ring-inset focus:ring-gray"
                      onChange={handleInputChange}
                      name="expiryDate"
                      min={new Date().toISOString().split('T')[0]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <button
              disabled={isSaving}
              className="w-full mt-8 flex justify-center items-center GothamBold bg-orange-500 font-bold text-white rounded-full disabled:opacity-50"
              type="submit"
            >
              {isSaving && <CustomSpinner className="pr-4" />}Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default CouponForm;
