import { useEffect, useState } from "react";
import { FAQ } from "../API";
import * as dataProvider from "../graphql/DataProvider";
import { CenterInPageSpinner } from "./loading_indicator";

function Faq() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [faqArray, setFAQArray] = useState<FAQ[]>([]);

  useEffect(() => {
    setIsLoading(true);
    dataProvider.getFAQ().then((faq) => {
      setFAQArray(faq);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    // return <CenterInPageSpinner />;
    return <></>;
  }

  return (
    <div id="faq">
      <section className="text-gray-700">
        <div className="container max-w-full px-5 py-12 mx-auto">
          {/* <div className="container max-w-full lg:max-w-5xl px-5 py-12 mx-auto"> */}
          <div className="text-center mb-8 lg:mb-10">
            <h1 className="colledge text-gray-900 pb-4 text-lg lg:text-2xl font-black">
              FAQ
            </h1>
          </div>
          <div className="flex flex-wrap  mx-auto mb-2 md:-mx-2 m-auto">
            <div className="w-full lg:w-full px-4 py-2">
              {faqArray.map((faq, index) => {
                return (
                  <details
                    key={index}
                    className="text-left mb-4 border p-8 rounded-xl border-grey-50 hover:cursor-pointer"
                  >
                    <summary className="font-semibold text-gray-800 GothamBold">
                      {faq.question}
                    </summary>
                    <span className="font-sm text-gray-600 GothamBook flex pt-6 w-full pr-8 text-left">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: faq.answer,
                        }}
                      />
                    </span>
                  </details>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Faq;
