/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateUserInput = {
  userID: string;
  firstName: string;
  lastName: string;
  email: string;
  locationAddress?: string | null;
  locationCity?: string | null;
  locationState?: string | null;
  locationZip?: string | null;
  photoKey?: string | null;
  type: UserType;
  school?: string | null;
  biography?: string | null;
  workLocation?: string | null;
  candidateID?: string | null;
  languages?: string | null;
  skills?: string | null;
  stripeAccountID?: string | null;
  subscription?: SubscriptionStatus | null;
  owner?: string | null;
  name?: string | null;
  graduationYear?: string | null;
  emailNotificationEnabled?: boolean | null;
  fullName?: string | null;
  avgHourlyRate?: number | null;
  status?: UserStatus | null;
  geoHash?: string | null;
  stripeCreatedAt?: string | null;
  userBgCheckPaymentId?: string | null;
};

export enum UserType {
  UNKNOWN = "UNKNOWN",
  STUDENT = "STUDENT",
  POSTER = "POSTER",
}

export enum SubscriptionStatus {
  ACTIVE = "ACTIVE",
  PAST_DUE = "PAST_DUE",
  UNPAID = "UNPAID",
  CANCELED = "CANCELED",
  INCOMPLETE = "INCOMPLETE",
  INCOMPLETE_EXPIRED = "INCOMPLETE_EXPIRED",
  TRIALING = "TRIALING",
  PAUSED = "PAUSED",
}

export enum UserStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  DENIED = "DENIED",
  ADMIN = "ADMIN",
}

export type ModelUserConditionInput = {
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  email?: ModelStringInput | null;
  locationAddress?: ModelStringInput | null;
  locationCity?: ModelStringInput | null;
  locationState?: ModelStringInput | null;
  locationZip?: ModelStringInput | null;
  photoKey?: ModelStringInput | null;
  type?: ModelUserTypeInput | null;
  school?: ModelStringInput | null;
  biography?: ModelStringInput | null;
  workLocation?: ModelStringInput | null;
  candidateID?: ModelStringInput | null;
  languages?: ModelStringInput | null;
  skills?: ModelStringInput | null;
  stripeAccountID?: ModelStringInput | null;
  subscription?: ModelSubscriptionStatusInput | null;
  owner?: ModelStringInput | null;
  name?: ModelStringInput | null;
  graduationYear?: ModelStringInput | null;
  emailNotificationEnabled?: ModelBooleanInput | null;
  fullName?: ModelStringInput | null;
  avgHourlyRate?: ModelFloatInput | null;
  status?: ModelUserStatusInput | null;
  geoHash?: ModelStringInput | null;
  stripeCreatedAt?: ModelStringInput | null;
  and?: Array<ModelUserConditionInput | null> | null;
  or?: Array<ModelUserConditionInput | null> | null;
  not?: ModelUserConditionInput | null;
  userBgCheckPaymentId?: ModelIDInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelUserTypeInput = {
  eq?: UserType | null;
  ne?: UserType | null;
};

export type ModelSubscriptionStatusInput = {
  eq?: SubscriptionStatus | null;
  ne?: SubscriptionStatus | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelUserStatusInput = {
  eq?: UserStatus | null;
  ne?: UserStatus | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type User = {
  __typename: "User";
  userID: string;
  adminCode1: string;
  indexKey?: number | null;
  firstName: string;
  lastName: string;
  email: string;
  locationAddress?: string | null;
  locationCity?: string | null;
  locationState?: string | null;
  locationZip?: string | null;
  photoKey?: string | null;
  type: UserType;
  school?: string | null;
  biography?: string | null;
  workLocation?: string | null;
  candidateID?: string | null;
  languages?: string | null;
  skills?: string | null;
  stripeAccountID?: string | null;
  subscription?: SubscriptionStatus | null;
  receivedReviews?: ModelUserReviewConnection | null;
  sentReviews?: ModelUserReviewConnection | null;
  jobApplications?: ModelJobApplicationConnection | null;
  postedJobs?: ModelJobConnection | null;
  owner?: string | null;
  name?: string | null;
  graduationYear?: string | null;
  emailNotificationEnabled?: boolean | null;
  fullName?: string | null;
  avgHourlyRate?: number | null;
  jobSpecilities?: ModelJobSpecilitiesConnection | null;
  status?: UserStatus | null;
  geoHash?: string | null;
  stripeCreatedAt?: string | null;
  bgCheckPayment?: BackgroundCheckPaymentStatus | null;
  createdAt: string;
  updatedAt: string;
  userBgCheckPaymentId?: string | null;
};

export type ModelUserReviewConnection = {
  __typename: "ModelUserReviewConnection";
  items: Array<UserReview | null>;
  nextToken?: string | null;
};

export type UserReview = {
  __typename: "UserReview";
  id: string;
  forUser: User;
  fromUser: User;
  rating: number;
  message?: string | null;
  createdAt: string;
  updatedAt: string;
  userReceivedReviewsUserID?: string | null;
  userSentReviewsUserID?: string | null;
  userReviewForUserUserID: string;
  userReviewFromUserUserID: string;
  owner?: string | null;
};

export type ModelJobApplicationConnection = {
  __typename: "ModelJobApplicationConnection";
  items: Array<JobApplication | null>;
  nextToken?: string | null;
};

export type JobApplication = {
  __typename: "JobApplication";
  id: string;
  job: Job;
  applicant: User;
  message?: string | null;
  status: ApplicationStatus;
  createdAt: string;
  updatedAt: string;
  userJobApplicationsUserID?: string | null;
  jobApplicantsId?: string | null;
  jobApplicationJobId: string;
  jobApplicationApplicantUserID: string;
  owner?: string | null;
};

export type Job = {
  __typename: "Job";
  id: string;
  poster: User;
  rateType: JobRateType;
  compensation?: number | null;
  title?: string | null;
  description?: string | null;
  indexKey?: number | null;
  locationAddress: string;
  locationCity: string;
  locationState: string;
  locationZip: string;
  locationCountry?: string | null;
  category: JobCategory;
  applicants?: ModelJobApplicationConnection | null;
  jobStatus: JobStatus;
  latitude?: number | null;
  longitude?: number | null;
  geoHash?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  userPostedJobsUserID?: string | null;
  jobPosterUserID: string;
  jobCategoryId: string;
  owner?: string | null;
};

export enum JobRateType {
  FLATRATE = "FLATRATE",
  HOURLY = "HOURLY",
}

export type JobCategory = {
  __typename: "JobCategory";
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};

export enum JobStatus {
  OPEN = "OPEN",
  IN_PROGRESS = "IN_PROGRESS",
  CLOSED = "CLOSED",
}

export enum ApplicationStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  DENIED = "DENIED",
  COMPLETE = "COMPLETE",
}

export type ModelJobConnection = {
  __typename: "ModelJobConnection";
  items: Array<Job | null>;
  nextToken?: string | null;
};

export type ModelJobSpecilitiesConnection = {
  __typename: "ModelJobSpecilitiesConnection";
  items: Array<JobSpecilities | null>;
  nextToken?: string | null;
};

export type JobSpecilities = {
  __typename: "JobSpecilities";
  id: string;
  user: User;
  category: JobCategory;
  createdAt: string;
  updatedAt: string;
  userJobSpecilitiesUserID?: string | null;
  jobSpecilitiesUserUserID: string;
  jobSpecilitiesCategoryId: string;
  owner?: string | null;
};

export type BackgroundCheckPaymentStatus = {
  __typename: "BackgroundCheckPaymentStatus";
  id: string;
  user: User;
  payment: boolean;
  createdAt?: string | null;
  updatedAt: string;
  backgroundCheckPaymentStatusUserUserID: string;
};

export type UpdateUserInput = {
  userID: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  locationAddress?: string | null;
  locationCity?: string | null;
  locationState?: string | null;
  locationZip?: string | null;
  photoKey?: string | null;
  type?: UserType | null;
  school?: string | null;
  biography?: string | null;
  workLocation?: string | null;
  candidateID?: string | null;
  languages?: string | null;
  skills?: string | null;
  stripeAccountID?: string | null;
  subscription?: SubscriptionStatus | null;
  owner?: string | null;
  name?: string | null;
  graduationYear?: string | null;
  emailNotificationEnabled?: boolean | null;
  fullName?: string | null;
  avgHourlyRate?: number | null;
  status?: UserStatus | null;
  geoHash?: string | null;
  stripeCreatedAt?: string | null;
  userBgCheckPaymentId?: string | null;
};

export type DeleteUserInput = {
  userID: string;
};

export type CreateJobSpecilitiesInput = {
  id?: string | null;
  userJobSpecilitiesUserID?: string | null;
  jobSpecilitiesUserUserID: string;
  jobSpecilitiesCategoryId: string;
};

export type ModelJobSpecilitiesConditionInput = {
  and?: Array<ModelJobSpecilitiesConditionInput | null> | null;
  or?: Array<ModelJobSpecilitiesConditionInput | null> | null;
  not?: ModelJobSpecilitiesConditionInput | null;
  userJobSpecilitiesUserID?: ModelIDInput | null;
  jobSpecilitiesUserUserID?: ModelIDInput | null;
  jobSpecilitiesCategoryId?: ModelIDInput | null;
};

export type UpdateJobSpecilitiesInput = {
  id: string;
  userJobSpecilitiesUserID?: string | null;
  jobSpecilitiesUserUserID?: string | null;
  jobSpecilitiesCategoryId?: string | null;
};

export type DeleteJobSpecilitiesInput = {
  id: string;
};

export type CreateJobInput = {
  id?: string | null;
  rateType: JobRateType;
  compensation?: number | null;
  title?: string | null;
  description?: string | null;
  locationAddress: string;
  locationCity: string;
  locationState: string;
  locationZip: string;
  locationCountry?: string | null;
  jobStatus: JobStatus;
  latitude?: number | null;
  longitude?: number | null;
  geoHash?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  userPostedJobsUserID?: string | null;
  jobPosterUserID: string;
  jobCategoryId: string;
};

export type ModelJobConditionInput = {
  rateType?: ModelJobRateTypeInput | null;
  compensation?: ModelIntInput | null;
  title?: ModelStringInput | null;
  description?: ModelStringInput | null;
  locationAddress?: ModelStringInput | null;
  locationCity?: ModelStringInput | null;
  locationState?: ModelStringInput | null;
  locationZip?: ModelStringInput | null;
  locationCountry?: ModelStringInput | null;
  jobStatus?: ModelJobStatusInput | null;
  latitude?: ModelFloatInput | null;
  longitude?: ModelFloatInput | null;
  geoHash?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelJobConditionInput | null> | null;
  or?: Array<ModelJobConditionInput | null> | null;
  not?: ModelJobConditionInput | null;
  userPostedJobsUserID?: ModelIDInput | null;
  jobPosterUserID?: ModelIDInput | null;
  jobCategoryId?: ModelIDInput | null;
};

export type ModelJobRateTypeInput = {
  eq?: JobRateType | null;
  ne?: JobRateType | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelJobStatusInput = {
  eq?: JobStatus | null;
  ne?: JobStatus | null;
};

export type UpdateJobInput = {
  id: string;
  rateType?: JobRateType | null;
  compensation?: number | null;
  title?: string | null;
  description?: string | null;
  locationAddress?: string | null;
  locationCity?: string | null;
  locationState?: string | null;
  locationZip?: string | null;
  locationCountry?: string | null;
  jobStatus?: JobStatus | null;
  latitude?: number | null;
  longitude?: number | null;
  geoHash?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  userPostedJobsUserID?: string | null;
  jobPosterUserID?: string | null;
  jobCategoryId?: string | null;
};

export type DeleteJobInput = {
  id: string;
};

export type CreateJobCategoryInput = {
  id?: string | null;
  name: string;
};

export type ModelJobCategoryConditionInput = {
  name?: ModelStringInput | null;
  and?: Array<ModelJobCategoryConditionInput | null> | null;
  or?: Array<ModelJobCategoryConditionInput | null> | null;
  not?: ModelJobCategoryConditionInput | null;
};

export type UpdateJobCategoryInput = {
  id: string;
  name?: string | null;
};

export type DeleteJobCategoryInput = {
  id: string;
};

export type CreateJobApplicationInput = {
  id?: string | null;
  message?: string | null;
  status: ApplicationStatus;
  userJobApplicationsUserID?: string | null;
  jobApplicantsId?: string | null;
  jobApplicationJobId: string;
  jobApplicationApplicantUserID: string;
};

export type ModelJobApplicationConditionInput = {
  message?: ModelStringInput | null;
  status?: ModelApplicationStatusInput | null;
  and?: Array<ModelJobApplicationConditionInput | null> | null;
  or?: Array<ModelJobApplicationConditionInput | null> | null;
  not?: ModelJobApplicationConditionInput | null;
  userJobApplicationsUserID?: ModelIDInput | null;
  jobApplicantsId?: ModelIDInput | null;
  jobApplicationJobId?: ModelIDInput | null;
  jobApplicationApplicantUserID?: ModelIDInput | null;
};

export type ModelApplicationStatusInput = {
  eq?: ApplicationStatus | null;
  ne?: ApplicationStatus | null;
};

export type UpdateJobApplicationInput = {
  id: string;
  message?: string | null;
  status?: ApplicationStatus | null;
  userJobApplicationsUserID?: string | null;
  jobApplicantsId?: string | null;
  jobApplicationJobId?: string | null;
  jobApplicationApplicantUserID?: string | null;
};

export type DeleteJobApplicationInput = {
  id: string;
};

export type CreateCouponInput = {
  id?: string | null;
  couponCode?: string | null;
  isActive?: boolean | null;
  amount?: number | null;
  percentage?: number | null;
  type: couponCodeType;
  description?: string | null;
  expiryDate?: string | null;
};

export enum couponCodeType {
  Percentage = "Percentage",
  Flat_Off = "Flat_Off",
}

export type ModelCouponConditionInput = {
  couponCode?: ModelStringInput | null;
  isActive?: ModelBooleanInput | null;
  amount?: ModelFloatInput | null;
  percentage?: ModelFloatInput | null;
  type?: ModelcouponCodeTypeInput | null;
  description?: ModelStringInput | null;
  expiryDate?: ModelStringInput | null;
  and?: Array<ModelCouponConditionInput | null> | null;
  or?: Array<ModelCouponConditionInput | null> | null;
  not?: ModelCouponConditionInput | null;
};

export type ModelcouponCodeTypeInput = {
  eq?: couponCodeType | null;
  ne?: couponCodeType | null;
};

export type Coupon = {
  __typename: "Coupon";
  id: string;
  couponCode?: string | null;
  isActive?: boolean | null;
  amount?: number | null;
  percentage?: number | null;
  type: couponCodeType;
  description?: string | null;
  expiryDate?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
};

export type UpdateCouponInput = {
  id: string;
  couponCode?: string | null;
  isActive?: boolean | null;
  amount?: number | null;
  percentage?: number | null;
  type?: couponCodeType | null;
  description?: string | null;
  expiryDate?: string | null;
};

export type DeleteCouponInput = {
  id: string;
};

export type CreateUserReviewInput = {
  id?: string | null;
  rating: number;
  message?: string | null;
  userReceivedReviewsUserID?: string | null;
  userSentReviewsUserID?: string | null;
  userReviewForUserUserID: string;
  userReviewFromUserUserID: string;
};

export type ModelUserReviewConditionInput = {
  rating?: ModelIntInput | null;
  message?: ModelStringInput | null;
  and?: Array<ModelUserReviewConditionInput | null> | null;
  or?: Array<ModelUserReviewConditionInput | null> | null;
  not?: ModelUserReviewConditionInput | null;
  userReceivedReviewsUserID?: ModelIDInput | null;
  userSentReviewsUserID?: ModelIDInput | null;
  userReviewForUserUserID?: ModelIDInput | null;
  userReviewFromUserUserID?: ModelIDInput | null;
};

export type UpdateUserReviewInput = {
  id: string;
  rating?: number | null;
  message?: string | null;
  userReceivedReviewsUserID?: string | null;
  userSentReviewsUserID?: string | null;
  userReviewForUserUserID?: string | null;
  userReviewFromUserUserID?: string | null;
};

export type DeleteUserReviewInput = {
  id: string;
};

export type CreateJobMessageLogInput = {
  logId: string;
  posterHash: string;
  applicantHash: string;
  jobMessageLogJobId: string;
  jobMessageLogApplicantId: string;
};

export type ModelJobMessageLogConditionInput = {
  posterHash?: ModelStringInput | null;
  applicantHash?: ModelStringInput | null;
  and?: Array<ModelJobMessageLogConditionInput | null> | null;
  or?: Array<ModelJobMessageLogConditionInput | null> | null;
  not?: ModelJobMessageLogConditionInput | null;
  jobMessageLogJobId?: ModelIDInput | null;
  jobMessageLogApplicantId?: ModelIDInput | null;
};

export type JobMessageLog = {
  __typename: "JobMessageLog";
  logId: string;
  job: Job;
  applicant: JobApplication;
  posterHash: string;
  applicantHash: string;
  messages?: ModelJobMessageConnection | null;
  createdAt: string;
  updatedAt: string;
  jobMessageLogJobId: string;
  jobMessageLogApplicantId: string;
  owner?: string | null;
};

export type ModelJobMessageConnection = {
  __typename: "ModelJobMessageConnection";
  items: Array<JobMessage | null>;
  nextToken?: string | null;
};

export type JobMessage = {
  __typename: "JobMessage";
  id: string;
  to: string;
  from: string;
  subject: string;
  body: string;
  messageLog: JobMessageLog;
  createdAt: string;
  updatedAt: string;
  jobMessageLogMessagesLogId?: string | null;
  jobMessageMessageLogLogId: string;
};

export type UpdateJobMessageLogInput = {
  logId: string;
  posterHash?: string | null;
  applicantHash?: string | null;
  jobMessageLogJobId?: string | null;
  jobMessageLogApplicantId?: string | null;
};

export type DeleteJobMessageLogInput = {
  logId: string;
};

export type CreateJobMessageInput = {
  id?: string | null;
  to: string;
  from: string;
  subject: string;
  body: string;
  jobMessageLogMessagesLogId?: string | null;
  jobMessageMessageLogLogId: string;
};

export type ModelJobMessageConditionInput = {
  to?: ModelStringInput | null;
  from?: ModelStringInput | null;
  subject?: ModelStringInput | null;
  body?: ModelStringInput | null;
  and?: Array<ModelJobMessageConditionInput | null> | null;
  or?: Array<ModelJobMessageConditionInput | null> | null;
  not?: ModelJobMessageConditionInput | null;
  jobMessageLogMessagesLogId?: ModelIDInput | null;
  jobMessageMessageLogLogId?: ModelIDInput | null;
};

export type UpdateJobMessageInput = {
  id: string;
  to?: string | null;
  from?: string | null;
  subject?: string | null;
  body?: string | null;
  jobMessageLogMessagesLogId?: string | null;
  jobMessageMessageLogLogId?: string | null;
};

export type DeleteJobMessageInput = {
  id: string;
};

export type CreateFlaggedContentInput = {
  contentId: string;
  contentType: FlaggedContentType;
  flagReason: FlaggedReason;
  reportCount: number;
  flaggedContentFlaggedUserUserID: string;
};

export enum FlaggedContentType {
  PROFILE = "PROFILE",
  JOB = "JOB",
  USER_REVIEW = "USER_REVIEW",
}

export enum FlaggedReason {
  INAPPROPRIATE = "INAPPROPRIATE",
  OTHER = "OTHER",
}

export type ModelFlaggedContentConditionInput = {
  contentType?: ModelFlaggedContentTypeInput | null;
  reportCount?: ModelIntInput | null;
  and?: Array<ModelFlaggedContentConditionInput | null> | null;
  or?: Array<ModelFlaggedContentConditionInput | null> | null;
  not?: ModelFlaggedContentConditionInput | null;
  flaggedContentFlaggedUserUserID?: ModelIDInput | null;
};

export type ModelFlaggedContentTypeInput = {
  eq?: FlaggedContentType | null;
  ne?: FlaggedContentType | null;
};

export type FlaggedContent = {
  __typename: "FlaggedContent";
  contentId: string;
  flaggedUser: User;
  contentType: FlaggedContentType;
  flagReason: FlaggedReason;
  reportCount: number;
  createdAt: string;
  updatedAt: string;
  flaggedContentFlaggedUserUserID: string;
};

export type UpdateFlaggedContentInput = {
  contentId: string;
  contentType?: FlaggedContentType | null;
  flagReason: FlaggedReason;
  reportCount?: number | null;
  flaggedContentFlaggedUserUserID?: string | null;
};

export type DeleteFlaggedContentInput = {
  contentId: string;
  flagReason: FlaggedReason;
};

export type CreateSampleJobInput = {
  id?: string | null;
  rateType: JobRateType;
  compensation?: number | null;
  title?: string | null;
  description?: string | null;
  locationCity: string;
  locationState: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelSampleJobConditionInput = {
  rateType?: ModelJobRateTypeInput | null;
  compensation?: ModelIntInput | null;
  title?: ModelStringInput | null;
  description?: ModelStringInput | null;
  locationCity?: ModelStringInput | null;
  locationState?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSampleJobConditionInput | null> | null;
  or?: Array<ModelSampleJobConditionInput | null> | null;
  not?: ModelSampleJobConditionInput | null;
};

export type SampleJob = {
  __typename: "SampleJob";
  id: string;
  rateType: JobRateType;
  compensation?: number | null;
  title?: string | null;
  description?: string | null;
  locationCity: string;
  locationState: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateSampleJobInput = {
  id: string;
  rateType?: JobRateType | null;
  compensation?: number | null;
  title?: string | null;
  description?: string | null;
  locationCity?: string | null;
  locationState?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteSampleJobInput = {
  id: string;
};

export type CreateFAQInput = {
  id?: string | null;
  question: string;
  answer: string;
  order: number;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelFAQConditionInput = {
  question?: ModelStringInput | null;
  answer?: ModelStringInput | null;
  order?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelFAQConditionInput | null> | null;
  or?: Array<ModelFAQConditionInput | null> | null;
  not?: ModelFAQConditionInput | null;
};

export type FAQ = {
  __typename: "FAQ";
  id: string;
  question: string;
  answer: string;
  order: number;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateFAQInput = {
  id: string;
  question?: string | null;
  answer?: string | null;
  order?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteFAQInput = {
  id: string;
};

export type CreateBackgroundCheckPaymentStatusInput = {
  id?: string | null;
  payment: boolean;
  createdAt?: string | null;
  backgroundCheckPaymentStatusUserUserID: string;
};

export type ModelBackgroundCheckPaymentStatusConditionInput = {
  payment?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelBackgroundCheckPaymentStatusConditionInput | null> | null;
  or?: Array<ModelBackgroundCheckPaymentStatusConditionInput | null> | null;
  not?: ModelBackgroundCheckPaymentStatusConditionInput | null;
  backgroundCheckPaymentStatusUserUserID?: ModelIDInput | null;
};

export type UpdateBackgroundCheckPaymentStatusInput = {
  id: string;
  payment?: boolean | null;
  createdAt?: string | null;
  backgroundCheckPaymentStatusUserUserID?: string | null;
};

export type DeleteBackgroundCheckPaymentStatusInput = {
  id: string;
};

export type CreateBackgroundCheckLogInput = {
  candidateID: string;
  userID?: string | null;
  invitationID?: string | null;
  reportID?: string | null;
  inviteStatus?: string | null;
  reportStatus?: string | null;
  reportResult?: string | null;
  reportAdjudication?: string | null;
  backgroundCheckCompletedAt?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelBackgroundCheckLogConditionInput = {
  userID?: ModelStringInput | null;
  invitationID?: ModelStringInput | null;
  reportID?: ModelStringInput | null;
  inviteStatus?: ModelStringInput | null;
  reportStatus?: ModelStringInput | null;
  reportResult?: ModelStringInput | null;
  reportAdjudication?: ModelStringInput | null;
  backgroundCheckCompletedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelBackgroundCheckLogConditionInput | null> | null;
  or?: Array<ModelBackgroundCheckLogConditionInput | null> | null;
  not?: ModelBackgroundCheckLogConditionInput | null;
};

export type BackgroundCheckLog = {
  __typename: "BackgroundCheckLog";
  candidateID: string;
  userID?: string | null;
  invitationID?: string | null;
  reportID?: string | null;
  inviteStatus?: string | null;
  reportStatus?: string | null;
  reportResult?: string | null;
  reportAdjudication?: string | null;
  backgroundCheckCompletedAt?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateBackgroundCheckLogInput = {
  candidateID: string;
  userID?: string | null;
  invitationID?: string | null;
  reportID?: string | null;
  inviteStatus?: string | null;
  reportStatus?: string | null;
  reportResult?: string | null;
  reportAdjudication?: string | null;
  backgroundCheckCompletedAt?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteBackgroundCheckLogInput = {
  candidateID: string;
};

export type CreateSettingInput = {
  id?: string | null;
  feature: string;
  active: boolean;
  createdAt?: string | null;
};

export type ModelSettingConditionInput = {
  feature?: ModelStringInput | null;
  active?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelSettingConditionInput | null> | null;
  or?: Array<ModelSettingConditionInput | null> | null;
  not?: ModelSettingConditionInput | null;
};

export type Setting = {
  __typename: "Setting";
  id: string;
  feature: string;
  active: boolean;
  createdAt?: string | null;
  updatedAt: string;
};

export type UpdateSettingInput = {
  id: string;
  feature?: string | null;
  active?: boolean | null;
  createdAt?: string | null;
};

export type DeleteSettingInput = {
  id: string;
};

export type MutationSendEmailQueryInput = {
  toEmail: string;
  fromEmail: string;
  templateId?: string | null;
  message?: string | null;
  subject?: string | null;
  actionURL?: string | null;
  userName?: string | null;
};

export type ModelUserFilterInput = {
  userID?: ModelIDInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  email?: ModelStringInput | null;
  locationAddress?: ModelStringInput | null;
  locationCity?: ModelStringInput | null;
  locationState?: ModelStringInput | null;
  locationZip?: ModelStringInput | null;
  photoKey?: ModelStringInput | null;
  type?: ModelUserTypeInput | null;
  school?: ModelStringInput | null;
  biography?: ModelStringInput | null;
  workLocation?: ModelStringInput | null;
  candidateID?: ModelStringInput | null;
  languages?: ModelStringInput | null;
  skills?: ModelStringInput | null;
  stripeAccountID?: ModelStringInput | null;
  subscription?: ModelSubscriptionStatusInput | null;
  owner?: ModelStringInput | null;
  name?: ModelStringInput | null;
  graduationYear?: ModelStringInput | null;
  emailNotificationEnabled?: ModelBooleanInput | null;
  fullName?: ModelStringInput | null;
  avgHourlyRate?: ModelFloatInput | null;
  status?: ModelUserStatusInput | null;
  geoHash?: ModelStringInput | null;
  stripeCreatedAt?: ModelStringInput | null;
  and?: Array<ModelUserFilterInput | null> | null;
  or?: Array<ModelUserFilterInput | null> | null;
  not?: ModelUserFilterInput | null;
  userBgCheckPaymentId?: ModelIDInput | null;
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export type ModelUserConnection = {
  __typename: "ModelUserConnection";
  items: Array<User | null>;
  nextToken?: string | null;
};

export type ModelJobSpecilitiesFilterInput = {
  id?: ModelIDInput | null;
  and?: Array<ModelJobSpecilitiesFilterInput | null> | null;
  or?: Array<ModelJobSpecilitiesFilterInput | null> | null;
  not?: ModelJobSpecilitiesFilterInput | null;
  userJobSpecilitiesUserID?: ModelIDInput | null;
  jobSpecilitiesUserUserID?: ModelIDInput | null;
  jobSpecilitiesCategoryId?: ModelIDInput | null;
};

export type ModelJobFilterInput = {
  id?: ModelIDInput | null;
  rateType?: ModelJobRateTypeInput | null;
  compensation?: ModelIntInput | null;
  title?: ModelStringInput | null;
  description?: ModelStringInput | null;
  locationAddress?: ModelStringInput | null;
  locationCity?: ModelStringInput | null;
  locationState?: ModelStringInput | null;
  locationZip?: ModelStringInput | null;
  locationCountry?: ModelStringInput | null;
  jobStatus?: ModelJobStatusInput | null;
  latitude?: ModelFloatInput | null;
  longitude?: ModelFloatInput | null;
  geoHash?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelJobFilterInput | null> | null;
  or?: Array<ModelJobFilterInput | null> | null;
  not?: ModelJobFilterInput | null;
  userPostedJobsUserID?: ModelIDInput | null;
  jobPosterUserID?: ModelIDInput | null;
  jobCategoryId?: ModelIDInput | null;
};

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelJobCategoryFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  and?: Array<ModelJobCategoryFilterInput | null> | null;
  or?: Array<ModelJobCategoryFilterInput | null> | null;
  not?: ModelJobCategoryFilterInput | null;
};

export type ModelJobCategoryConnection = {
  __typename: "ModelJobCategoryConnection";
  items: Array<JobCategory | null>;
  nextToken?: string | null;
};

export type ModelJobApplicationFilterInput = {
  id?: ModelIDInput | null;
  message?: ModelStringInput | null;
  status?: ModelApplicationStatusInput | null;
  and?: Array<ModelJobApplicationFilterInput | null> | null;
  or?: Array<ModelJobApplicationFilterInput | null> | null;
  not?: ModelJobApplicationFilterInput | null;
  userJobApplicationsUserID?: ModelIDInput | null;
  jobApplicantsId?: ModelIDInput | null;
  jobApplicationJobId?: ModelIDInput | null;
  jobApplicationApplicantUserID?: ModelIDInput | null;
};

export type ModelCouponFilterInput = {
  id?: ModelIDInput | null;
  couponCode?: ModelStringInput | null;
  isActive?: ModelBooleanInput | null;
  amount?: ModelFloatInput | null;
  percentage?: ModelFloatInput | null;
  type?: ModelcouponCodeTypeInput | null;
  description?: ModelStringInput | null;
  expiryDate?: ModelStringInput | null;
  and?: Array<ModelCouponFilterInput | null> | null;
  or?: Array<ModelCouponFilterInput | null> | null;
  not?: ModelCouponFilterInput | null;
};

export type ModelCouponConnection = {
  __typename: "ModelCouponConnection";
  items: Array<Coupon | null>;
  nextToken?: string | null;
};

export type ModelUserReviewFilterInput = {
  id?: ModelIDInput | null;
  rating?: ModelIntInput | null;
  message?: ModelStringInput | null;
  and?: Array<ModelUserReviewFilterInput | null> | null;
  or?: Array<ModelUserReviewFilterInput | null> | null;
  not?: ModelUserReviewFilterInput | null;
  userReceivedReviewsUserID?: ModelIDInput | null;
  userSentReviewsUserID?: ModelIDInput | null;
  userReviewForUserUserID?: ModelIDInput | null;
  userReviewFromUserUserID?: ModelIDInput | null;
};

export type ModelJobMessageLogFilterInput = {
  logId?: ModelIDInput | null;
  posterHash?: ModelStringInput | null;
  applicantHash?: ModelStringInput | null;
  and?: Array<ModelJobMessageLogFilterInput | null> | null;
  or?: Array<ModelJobMessageLogFilterInput | null> | null;
  not?: ModelJobMessageLogFilterInput | null;
  jobMessageLogJobId?: ModelIDInput | null;
  jobMessageLogApplicantId?: ModelIDInput | null;
};

export type ModelJobMessageLogConnection = {
  __typename: "ModelJobMessageLogConnection";
  items: Array<JobMessageLog | null>;
  nextToken?: string | null;
};

export type ModelJobMessageFilterInput = {
  id?: ModelIDInput | null;
  to?: ModelStringInput | null;
  from?: ModelStringInput | null;
  subject?: ModelStringInput | null;
  body?: ModelStringInput | null;
  and?: Array<ModelJobMessageFilterInput | null> | null;
  or?: Array<ModelJobMessageFilterInput | null> | null;
  not?: ModelJobMessageFilterInput | null;
  jobMessageLogMessagesLogId?: ModelIDInput | null;
  jobMessageMessageLogLogId?: ModelIDInput | null;
};

export type ModelFlaggedContentFilterInput = {
  contentId?: ModelStringInput | null;
  contentType?: ModelFlaggedContentTypeInput | null;
  flagReason?: ModelFlaggedReasonInput | null;
  reportCount?: ModelIntInput | null;
  and?: Array<ModelFlaggedContentFilterInput | null> | null;
  or?: Array<ModelFlaggedContentFilterInput | null> | null;
  not?: ModelFlaggedContentFilterInput | null;
  flaggedContentFlaggedUserUserID?: ModelIDInput | null;
};

export type ModelFlaggedReasonInput = {
  eq?: FlaggedReason | null;
  ne?: FlaggedReason | null;
};

export type ModelFlaggedContentConnection = {
  __typename: "ModelFlaggedContentConnection";
  items: Array<FlaggedContent | null>;
  nextToken?: string | null;
};

export type ModelSampleJobFilterInput = {
  id?: ModelIDInput | null;
  rateType?: ModelJobRateTypeInput | null;
  compensation?: ModelIntInput | null;
  title?: ModelStringInput | null;
  description?: ModelStringInput | null;
  locationCity?: ModelStringInput | null;
  locationState?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSampleJobFilterInput | null> | null;
  or?: Array<ModelSampleJobFilterInput | null> | null;
  not?: ModelSampleJobFilterInput | null;
};

export type ModelSampleJobConnection = {
  __typename: "ModelSampleJobConnection";
  items: Array<SampleJob | null>;
  nextToken?: string | null;
};

export type ModelFAQFilterInput = {
  id?: ModelIDInput | null;
  question?: ModelStringInput | null;
  answer?: ModelStringInput | null;
  order?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelFAQFilterInput | null> | null;
  or?: Array<ModelFAQFilterInput | null> | null;
  not?: ModelFAQFilterInput | null;
};

export type ModelFAQConnection = {
  __typename: "ModelFAQConnection";
  items: Array<FAQ | null>;
  nextToken?: string | null;
};

export type ModelBackgroundCheckPaymentStatusFilterInput = {
  id?: ModelIDInput | null;
  payment?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelBackgroundCheckPaymentStatusFilterInput | null> | null;
  or?: Array<ModelBackgroundCheckPaymentStatusFilterInput | null> | null;
  not?: ModelBackgroundCheckPaymentStatusFilterInput | null;
  backgroundCheckPaymentStatusUserUserID?: ModelIDInput | null;
};

export type ModelBackgroundCheckPaymentStatusConnection = {
  __typename: "ModelBackgroundCheckPaymentStatusConnection";
  items: Array<BackgroundCheckPaymentStatus | null>;
  nextToken?: string | null;
};

export type ModelBackgroundCheckLogFilterInput = {
  candidateID?: ModelIDInput | null;
  userID?: ModelStringInput | null;
  invitationID?: ModelStringInput | null;
  reportID?: ModelStringInput | null;
  inviteStatus?: ModelStringInput | null;
  reportStatus?: ModelStringInput | null;
  reportResult?: ModelStringInput | null;
  reportAdjudication?: ModelStringInput | null;
  backgroundCheckCompletedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelBackgroundCheckLogFilterInput | null> | null;
  or?: Array<ModelBackgroundCheckLogFilterInput | null> | null;
  not?: ModelBackgroundCheckLogFilterInput | null;
};

export type ModelBackgroundCheckLogConnection = {
  __typename: "ModelBackgroundCheckLogConnection";
  items: Array<BackgroundCheckLog | null>;
  nextToken?: string | null;
};

export type ModelSettingFilterInput = {
  id?: ModelIDInput | null;
  feature?: ModelStringInput | null;
  active?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelSettingFilterInput | null> | null;
  or?: Array<ModelSettingFilterInput | null> | null;
  not?: ModelSettingFilterInput | null;
};

export type ModelSettingConnection = {
  __typename: "ModelSettingConnection";
  items: Array<Setting | null>;
  nextToken?: string | null;
};

export type UserBadge = {
  __typename: "UserBadge";
  id: number;
  name: string;
  description: string;
  icon: string;
};

export type EventLogData = {
  deviceId: string;
  userId?: string | null;
  attributes?: Array<Dictionary | null> | null;
  queryParams?: Array<Dictionary | null> | null;
  referrer?: string | null;
};

export type Dictionary = {
  key: string;
  value: string;
};

export type ModelSubscriptionUserFilterInput = {
  userID?: ModelSubscriptionIDInput | null;
  firstName?: ModelSubscriptionStringInput | null;
  lastName?: ModelSubscriptionStringInput | null;
  email?: ModelSubscriptionStringInput | null;
  locationAddress?: ModelSubscriptionStringInput | null;
  locationCity?: ModelSubscriptionStringInput | null;
  locationState?: ModelSubscriptionStringInput | null;
  locationZip?: ModelSubscriptionStringInput | null;
  photoKey?: ModelSubscriptionStringInput | null;
  type?: ModelSubscriptionStringInput | null;
  school?: ModelSubscriptionStringInput | null;
  biography?: ModelSubscriptionStringInput | null;
  workLocation?: ModelSubscriptionStringInput | null;
  candidateID?: ModelSubscriptionStringInput | null;
  languages?: ModelSubscriptionStringInput | null;
  skills?: ModelSubscriptionStringInput | null;
  stripeAccountID?: ModelSubscriptionStringInput | null;
  subscription?: ModelSubscriptionStringInput | null;
  name?: ModelSubscriptionStringInput | null;
  graduationYear?: ModelSubscriptionStringInput | null;
  emailNotificationEnabled?: ModelSubscriptionBooleanInput | null;
  fullName?: ModelSubscriptionStringInput | null;
  avgHourlyRate?: ModelSubscriptionFloatInput | null;
  status?: ModelSubscriptionStringInput | null;
  geoHash?: ModelSubscriptionStringInput | null;
  stripeCreatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionUserFilterInput | null> | null;
  or?: Array<ModelSubscriptionUserFilterInput | null> | null;
};

export type ModelSubscriptionIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  in?: Array<string | null> | null;
  notIn?: Array<string | null> | null;
};

export type ModelSubscriptionStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  in?: Array<string | null> | null;
  notIn?: Array<string | null> | null;
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  in?: Array<number | null> | null;
  notIn?: Array<number | null> | null;
};

export type ModelSubscriptionJobSpecilitiesFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  and?: Array<ModelSubscriptionJobSpecilitiesFilterInput | null> | null;
  or?: Array<ModelSubscriptionJobSpecilitiesFilterInput | null> | null;
};

export type ModelSubscriptionJobFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  rateType?: ModelSubscriptionStringInput | null;
  compensation?: ModelSubscriptionIntInput | null;
  title?: ModelSubscriptionStringInput | null;
  description?: ModelSubscriptionStringInput | null;
  locationAddress?: ModelSubscriptionStringInput | null;
  locationCity?: ModelSubscriptionStringInput | null;
  locationState?: ModelSubscriptionStringInput | null;
  locationZip?: ModelSubscriptionStringInput | null;
  locationCountry?: ModelSubscriptionStringInput | null;
  jobStatus?: ModelSubscriptionStringInput | null;
  latitude?: ModelSubscriptionFloatInput | null;
  longitude?: ModelSubscriptionFloatInput | null;
  geoHash?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionJobFilterInput | null> | null;
  or?: Array<ModelSubscriptionJobFilterInput | null> | null;
};

export type ModelSubscriptionIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  in?: Array<number | null> | null;
  notIn?: Array<number | null> | null;
};

export type ModelSubscriptionJobCategoryFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionJobCategoryFilterInput | null> | null;
  or?: Array<ModelSubscriptionJobCategoryFilterInput | null> | null;
};

export type ModelSubscriptionJobApplicationFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  message?: ModelSubscriptionStringInput | null;
  status?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionJobApplicationFilterInput | null> | null;
  or?: Array<ModelSubscriptionJobApplicationFilterInput | null> | null;
};

export type ModelSubscriptionCouponFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  couponCode?: ModelSubscriptionStringInput | null;
  isActive?: ModelSubscriptionBooleanInput | null;
  amount?: ModelSubscriptionFloatInput | null;
  percentage?: ModelSubscriptionFloatInput | null;
  type?: ModelSubscriptionStringInput | null;
  description?: ModelSubscriptionStringInput | null;
  expiryDate?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionCouponFilterInput | null> | null;
  or?: Array<ModelSubscriptionCouponFilterInput | null> | null;
};

export type ModelSubscriptionUserReviewFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  rating?: ModelSubscriptionIntInput | null;
  message?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionUserReviewFilterInput | null> | null;
  or?: Array<ModelSubscriptionUserReviewFilterInput | null> | null;
};

export type ModelSubscriptionJobMessageLogFilterInput = {
  logId?: ModelSubscriptionIDInput | null;
  posterHash?: ModelSubscriptionStringInput | null;
  applicantHash?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionJobMessageLogFilterInput | null> | null;
  or?: Array<ModelSubscriptionJobMessageLogFilterInput | null> | null;
};

export type ModelSubscriptionJobMessageFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  to?: ModelSubscriptionStringInput | null;
  from?: ModelSubscriptionStringInput | null;
  subject?: ModelSubscriptionStringInput | null;
  body?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionJobMessageFilterInput | null> | null;
  or?: Array<ModelSubscriptionJobMessageFilterInput | null> | null;
};

export type ModelSubscriptionFlaggedContentFilterInput = {
  contentId?: ModelSubscriptionStringInput | null;
  contentType?: ModelSubscriptionStringInput | null;
  flagReason?: ModelSubscriptionStringInput | null;
  reportCount?: ModelSubscriptionIntInput | null;
  and?: Array<ModelSubscriptionFlaggedContentFilterInput | null> | null;
  or?: Array<ModelSubscriptionFlaggedContentFilterInput | null> | null;
};

export type ModelSubscriptionSampleJobFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  rateType?: ModelSubscriptionStringInput | null;
  compensation?: ModelSubscriptionIntInput | null;
  title?: ModelSubscriptionStringInput | null;
  description?: ModelSubscriptionStringInput | null;
  locationCity?: ModelSubscriptionStringInput | null;
  locationState?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionSampleJobFilterInput | null> | null;
  or?: Array<ModelSubscriptionSampleJobFilterInput | null> | null;
};

export type ModelSubscriptionFAQFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  question?: ModelSubscriptionStringInput | null;
  answer?: ModelSubscriptionStringInput | null;
  order?: ModelSubscriptionIntInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionFAQFilterInput | null> | null;
  or?: Array<ModelSubscriptionFAQFilterInput | null> | null;
};

export type ModelSubscriptionBackgroundCheckPaymentStatusFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  payment?: ModelSubscriptionBooleanInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionBackgroundCheckPaymentStatusFilterInput | null> | null;
  or?: Array<ModelSubscriptionBackgroundCheckPaymentStatusFilterInput | null> | null;
};

export type ModelSubscriptionBackgroundCheckLogFilterInput = {
  candidateID?: ModelSubscriptionIDInput | null;
  userID?: ModelSubscriptionStringInput | null;
  invitationID?: ModelSubscriptionStringInput | null;
  reportID?: ModelSubscriptionStringInput | null;
  inviteStatus?: ModelSubscriptionStringInput | null;
  reportStatus?: ModelSubscriptionStringInput | null;
  reportResult?: ModelSubscriptionStringInput | null;
  reportAdjudication?: ModelSubscriptionStringInput | null;
  backgroundCheckCompletedAt?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionBackgroundCheckLogFilterInput | null> | null;
  or?: Array<ModelSubscriptionBackgroundCheckLogFilterInput | null> | null;
};

export type ModelSubscriptionSettingFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  feature?: ModelSubscriptionStringInput | null;
  active?: ModelSubscriptionBooleanInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionSettingFilterInput | null> | null;
  or?: Array<ModelSubscriptionSettingFilterInput | null> | null;
};

export type CreateUserMutationVariables = {
  input: CreateUserInput;
  condition?: ModelUserConditionInput | null;
};

export type CreateUserMutation = {
  createUser?: {
    __typename: "User";
    userID: string;
    firstName: string;
    lastName: string;
    email: string;
    locationAddress?: string | null;
    locationCity?: string | null;
    locationState?: string | null;
    locationZip?: string | null;
    photoKey?: string | null;
    type: UserType;
    school?: string | null;
    biography?: string | null;
    workLocation?: string | null;
    candidateID?: string | null;
    languages?: string | null;
    skills?: string | null;
    stripeAccountID?: string | null;
    subscription?: SubscriptionStatus | null;
    receivedReviews?: {
      __typename: "ModelUserReviewConnection";
      items: Array<{
        __typename: "UserReview";
        id: string;
        forUser: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        fromUser: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rating: number;
        message?: string | null;
        createdAt: string;
        updatedAt: string;
        userReceivedReviewsUserID?: string | null;
        userSentReviewsUserID?: string | null;
        userReviewForUserUserID: string;
        userReviewFromUserUserID: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    sentReviews?: {
      __typename: "ModelUserReviewConnection";
      items: Array<{
        __typename: "UserReview";
        id: string;
        forUser: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        fromUser: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rating: number;
        message?: string | null;
        createdAt: string;
        updatedAt: string;
        userReceivedReviewsUserID?: string | null;
        userSentReviewsUserID?: string | null;
        userReviewForUserUserID: string;
        userReviewFromUserUserID: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    jobApplications?: {
      __typename: "ModelJobApplicationConnection";
      items: Array<{
        __typename: "JobApplication";
        id: string;
        job: {
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        };
        applicant: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        message?: string | null;
        status: ApplicationStatus;
        createdAt: string;
        updatedAt: string;
        userJobApplicationsUserID?: string | null;
        jobApplicantsId?: string | null;
        jobApplicationJobId: string;
        jobApplicationApplicantUserID: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    postedJobs?: {
      __typename: "ModelJobConnection";
      items: Array<{
        __typename: "Job";
        id: string;
        poster: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rateType: JobRateType;
        compensation?: number | null;
        title?: string | null;
        description?: string | null;
        locationAddress: string;
        locationCity: string;
        locationState: string;
        locationZip: string;
        locationCountry?: string | null;
        category: {
          __typename: "JobCategory";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        };
        applicants?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        jobStatus: JobStatus;
        latitude?: number | null;
        longitude?: number | null;
        geoHash?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        userPostedJobsUserID?: string | null;
        jobPosterUserID: string;
        jobCategoryId: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    name?: string | null;
    graduationYear?: string | null;
    emailNotificationEnabled?: boolean | null;
    fullName?: string | null;
    avgHourlyRate?: number | null;
    jobSpecilities?: {
      __typename: "ModelJobSpecilitiesConnection";
      items: Array<{
        __typename: "JobSpecilities";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        category: {
          __typename: "JobCategory";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
        userJobSpecilitiesUserID?: string | null;
        jobSpecilitiesUserUserID: string;
        jobSpecilitiesCategoryId: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    status?: UserStatus | null;
    geoHash?: string | null;
    stripeCreatedAt?: string | null;
    bgCheckPayment?: {
      __typename: "BackgroundCheckPaymentStatus";
      id: string;
      user: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      payment: boolean;
      createdAt?: string | null;
      updatedAt: string;
      backgroundCheckPaymentStatusUserUserID: string;
    } | null;
    createdAt: string;
    updatedAt: string;
    userBgCheckPaymentId?: string | null;
  } | null;
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput;
  condition?: ModelUserConditionInput | null;
};

export type UpdateUserMutation = {
  updateUser?: {
    __typename: "User";
    userID: string;
    firstName: string;
    lastName: string;
    email: string;
    locationAddress?: string | null;
    locationCity?: string | null;
    locationState?: string | null;
    locationZip?: string | null;
    photoKey?: string | null;
    type: UserType;
    school?: string | null;
    biography?: string | null;
    workLocation?: string | null;
    candidateID?: string | null;
    languages?: string | null;
    skills?: string | null;
    stripeAccountID?: string | null;
    subscription?: SubscriptionStatus | null;
    receivedReviews?: {
      __typename: "ModelUserReviewConnection";
      items: Array<{
        __typename: "UserReview";
        id: string;
        forUser: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        fromUser: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rating: number;
        message?: string | null;
        createdAt: string;
        updatedAt: string;
        userReceivedReviewsUserID?: string | null;
        userSentReviewsUserID?: string | null;
        userReviewForUserUserID: string;
        userReviewFromUserUserID: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    sentReviews?: {
      __typename: "ModelUserReviewConnection";
      items: Array<{
        __typename: "UserReview";
        id: string;
        forUser: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        fromUser: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rating: number;
        message?: string | null;
        createdAt: string;
        updatedAt: string;
        userReceivedReviewsUserID?: string | null;
        userSentReviewsUserID?: string | null;
        userReviewForUserUserID: string;
        userReviewFromUserUserID: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    jobApplications?: {
      __typename: "ModelJobApplicationConnection";
      items: Array<{
        __typename: "JobApplication";
        id: string;
        job: {
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        };
        applicant: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        message?: string | null;
        status: ApplicationStatus;
        createdAt: string;
        updatedAt: string;
        userJobApplicationsUserID?: string | null;
        jobApplicantsId?: string | null;
        jobApplicationJobId: string;
        jobApplicationApplicantUserID: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    postedJobs?: {
      __typename: "ModelJobConnection";
      items: Array<{
        __typename: "Job";
        id: string;
        poster: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rateType: JobRateType;
        compensation?: number | null;
        title?: string | null;
        description?: string | null;
        locationAddress: string;
        locationCity: string;
        locationState: string;
        locationZip: string;
        locationCountry?: string | null;
        category: {
          __typename: "JobCategory";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        };
        applicants?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        jobStatus: JobStatus;
        latitude?: number | null;
        longitude?: number | null;
        geoHash?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        userPostedJobsUserID?: string | null;
        jobPosterUserID: string;
        jobCategoryId: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    name?: string | null;
    graduationYear?: string | null;
    emailNotificationEnabled?: boolean | null;
    fullName?: string | null;
    avgHourlyRate?: number | null;
    jobSpecilities?: {
      __typename: "ModelJobSpecilitiesConnection";
      items: Array<{
        __typename: "JobSpecilities";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        category: {
          __typename: "JobCategory";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
        userJobSpecilitiesUserID?: string | null;
        jobSpecilitiesUserUserID: string;
        jobSpecilitiesCategoryId: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    status?: UserStatus | null;
    geoHash?: string | null;
    stripeCreatedAt?: string | null;
    bgCheckPayment?: {
      __typename: "BackgroundCheckPaymentStatus";
      id: string;
      user: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      payment: boolean;
      createdAt?: string | null;
      updatedAt: string;
      backgroundCheckPaymentStatusUserUserID: string;
    } | null;
    createdAt: string;
    updatedAt: string;
    userBgCheckPaymentId?: string | null;
  } | null;
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput;
  condition?: ModelUserConditionInput | null;
};

export type DeleteUserMutation = {
  deleteUser?: {
    __typename: "User";
    userID: string;
    firstName: string;
    lastName: string;
    email: string;
    locationAddress?: string | null;
    locationCity?: string | null;
    locationState?: string | null;
    locationZip?: string | null;
    photoKey?: string | null;
    type: UserType;
    school?: string | null;
    biography?: string | null;
    workLocation?: string | null;
    candidateID?: string | null;
    languages?: string | null;
    skills?: string | null;
    stripeAccountID?: string | null;
    subscription?: SubscriptionStatus | null;
    receivedReviews?: {
      __typename: "ModelUserReviewConnection";
      items: Array<{
        __typename: "UserReview";
        id: string;
        forUser: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        fromUser: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rating: number;
        message?: string | null;
        createdAt: string;
        updatedAt: string;
        userReceivedReviewsUserID?: string | null;
        userSentReviewsUserID?: string | null;
        userReviewForUserUserID: string;
        userReviewFromUserUserID: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    sentReviews?: {
      __typename: "ModelUserReviewConnection";
      items: Array<{
        __typename: "UserReview";
        id: string;
        forUser: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        fromUser: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rating: number;
        message?: string | null;
        createdAt: string;
        updatedAt: string;
        userReceivedReviewsUserID?: string | null;
        userSentReviewsUserID?: string | null;
        userReviewForUserUserID: string;
        userReviewFromUserUserID: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    jobApplications?: {
      __typename: "ModelJobApplicationConnection";
      items: Array<{
        __typename: "JobApplication";
        id: string;
        job: {
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        };
        applicant: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        message?: string | null;
        status: ApplicationStatus;
        createdAt: string;
        updatedAt: string;
        userJobApplicationsUserID?: string | null;
        jobApplicantsId?: string | null;
        jobApplicationJobId: string;
        jobApplicationApplicantUserID: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    postedJobs?: {
      __typename: "ModelJobConnection";
      items: Array<{
        __typename: "Job";
        id: string;
        poster: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rateType: JobRateType;
        compensation?: number | null;
        title?: string | null;
        description?: string | null;
        locationAddress: string;
        locationCity: string;
        locationState: string;
        locationZip: string;
        locationCountry?: string | null;
        category: {
          __typename: "JobCategory";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        };
        applicants?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        jobStatus: JobStatus;
        latitude?: number | null;
        longitude?: number | null;
        geoHash?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        userPostedJobsUserID?: string | null;
        jobPosterUserID: string;
        jobCategoryId: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    name?: string | null;
    graduationYear?: string | null;
    emailNotificationEnabled?: boolean | null;
    fullName?: string | null;
    avgHourlyRate?: number | null;
    jobSpecilities?: {
      __typename: "ModelJobSpecilitiesConnection";
      items: Array<{
        __typename: "JobSpecilities";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        category: {
          __typename: "JobCategory";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
        userJobSpecilitiesUserID?: string | null;
        jobSpecilitiesUserUserID: string;
        jobSpecilitiesCategoryId: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    status?: UserStatus | null;
    geoHash?: string | null;
    stripeCreatedAt?: string | null;
    bgCheckPayment?: {
      __typename: "BackgroundCheckPaymentStatus";
      id: string;
      user: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      payment: boolean;
      createdAt?: string | null;
      updatedAt: string;
      backgroundCheckPaymentStatusUserUserID: string;
    } | null;
    createdAt: string;
    updatedAt: string;
    userBgCheckPaymentId?: string | null;
  } | null;
};

export type CreateJobSpecilitiesMutationVariables = {
  input: CreateJobSpecilitiesInput;
  condition?: ModelJobSpecilitiesConditionInput | null;
};

export type CreateJobSpecilitiesMutation = {
  createJobSpecilities?: {
    __typename: "JobSpecilities";
    id: string;
    user: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    category: {
      __typename: "JobCategory";
      id: string;
      name: string;
      createdAt: string;
      updatedAt: string;
    };
    createdAt: string;
    updatedAt: string;
    userJobSpecilitiesUserID?: string | null;
    jobSpecilitiesUserUserID: string;
    jobSpecilitiesCategoryId: string;
    owner?: string | null;
  } | null;
};

export type UpdateJobSpecilitiesMutationVariables = {
  input: UpdateJobSpecilitiesInput;
  condition?: ModelJobSpecilitiesConditionInput | null;
};

export type UpdateJobSpecilitiesMutation = {
  updateJobSpecilities?: {
    __typename: "JobSpecilities";
    id: string;
    user: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    category: {
      __typename: "JobCategory";
      id: string;
      name: string;
      createdAt: string;
      updatedAt: string;
    };
    createdAt: string;
    updatedAt: string;
    userJobSpecilitiesUserID?: string | null;
    jobSpecilitiesUserUserID: string;
    jobSpecilitiesCategoryId: string;
    owner?: string | null;
  } | null;
};

export type DeleteJobSpecilitiesMutationVariables = {
  input: DeleteJobSpecilitiesInput;
  condition?: ModelJobSpecilitiesConditionInput | null;
};

export type DeleteJobSpecilitiesMutation = {
  deleteJobSpecilities?: {
    __typename: "JobSpecilities";
    id: string;
    user: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    category: {
      __typename: "JobCategory";
      id: string;
      name: string;
      createdAt: string;
      updatedAt: string;
    };
    createdAt: string;
    updatedAt: string;
    userJobSpecilitiesUserID?: string | null;
    jobSpecilitiesUserUserID: string;
    jobSpecilitiesCategoryId: string;
    owner?: string | null;
  } | null;
};

export type CreateJobMutationVariables = {
  input: CreateJobInput;
  condition?: ModelJobConditionInput | null;
};

export type CreateJobMutation = {
  createJob?: {
    __typename: "Job";
    id: string;
    poster: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    rateType: JobRateType;
    compensation?: number | null;
    title?: string | null;
    description?: string | null;
    locationAddress: string;
    locationCity: string;
    locationState: string;
    locationZip: string;
    locationCountry?: string | null;
    category: {
      __typename: "JobCategory";
      id: string;
      name: string;
      createdAt: string;
      updatedAt: string;
    };
    applicants?: {
      __typename: "ModelJobApplicationConnection";
      items: Array<{
        __typename: "JobApplication";
        id: string;
        job: {
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        };
        applicant: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        message?: string | null;
        status: ApplicationStatus;
        createdAt: string;
        updatedAt: string;
        userJobApplicationsUserID?: string | null;
        jobApplicantsId?: string | null;
        jobApplicationJobId: string;
        jobApplicationApplicantUserID: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    jobStatus: JobStatus;
    latitude?: number | null;
    longitude?: number | null;
    geoHash?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    userPostedJobsUserID?: string | null;
    jobPosterUserID: string;
    jobCategoryId: string;
    owner?: string | null;
  } | null;
};

export type UpdateJobMutationVariables = {
  input: UpdateJobInput;
  condition?: ModelJobConditionInput | null;
};

export type UpdateJobMutation = {
  updateJob?: {
    __typename: "Job";
    id: string;
    poster: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    rateType: JobRateType;
    compensation?: number | null;
    title?: string | null;
    description?: string | null;
    locationAddress: string;
    locationCity: string;
    locationState: string;
    locationZip: string;
    locationCountry?: string | null;
    category: {
      __typename: "JobCategory";
      id: string;
      name: string;
      createdAt: string;
      updatedAt: string;
    };
    applicants?: {
      __typename: "ModelJobApplicationConnection";
      items: Array<{
        __typename: "JobApplication";
        id: string;
        job: {
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        };
        applicant: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        message?: string | null;
        status: ApplicationStatus;
        createdAt: string;
        updatedAt: string;
        userJobApplicationsUserID?: string | null;
        jobApplicantsId?: string | null;
        jobApplicationJobId: string;
        jobApplicationApplicantUserID: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    jobStatus: JobStatus;
    latitude?: number | null;
    longitude?: number | null;
    geoHash?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    userPostedJobsUserID?: string | null;
    jobPosterUserID: string;
    jobCategoryId: string;
    owner?: string | null;
  } | null;
};

export type DeleteJobMutationVariables = {
  input: DeleteJobInput;
  condition?: ModelJobConditionInput | null;
};

export type DeleteJobMutation = {
  deleteJob?: {
    __typename: "Job";
    id: string;
    poster: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    rateType: JobRateType;
    compensation?: number | null;
    title?: string | null;
    description?: string | null;
    locationAddress: string;
    locationCity: string;
    locationState: string;
    locationZip: string;
    locationCountry?: string | null;
    category: {
      __typename: "JobCategory";
      id: string;
      name: string;
      createdAt: string;
      updatedAt: string;
    };
    applicants?: {
      __typename: "ModelJobApplicationConnection";
      items: Array<{
        __typename: "JobApplication";
        id: string;
        job: {
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        };
        applicant: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        message?: string | null;
        status: ApplicationStatus;
        createdAt: string;
        updatedAt: string;
        userJobApplicationsUserID?: string | null;
        jobApplicantsId?: string | null;
        jobApplicationJobId: string;
        jobApplicationApplicantUserID: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    jobStatus: JobStatus;
    latitude?: number | null;
    longitude?: number | null;
    geoHash?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    userPostedJobsUserID?: string | null;
    jobPosterUserID: string;
    jobCategoryId: string;
    owner?: string | null;
  } | null;
};

export type CreateJobCategoryMutationVariables = {
  input: CreateJobCategoryInput;
  condition?: ModelJobCategoryConditionInput | null;
};

export type CreateJobCategoryMutation = {
  createJobCategory?: {
    __typename: "JobCategory";
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateJobCategoryMutationVariables = {
  input: UpdateJobCategoryInput;
  condition?: ModelJobCategoryConditionInput | null;
};

export type UpdateJobCategoryMutation = {
  updateJobCategory?: {
    __typename: "JobCategory";
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteJobCategoryMutationVariables = {
  input: DeleteJobCategoryInput;
  condition?: ModelJobCategoryConditionInput | null;
};

export type DeleteJobCategoryMutation = {
  deleteJobCategory?: {
    __typename: "JobCategory";
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateJobApplicationMutationVariables = {
  input: CreateJobApplicationInput;
  condition?: ModelJobApplicationConditionInput | null;
};

export type CreateJobApplicationMutation = {
  createJobApplication?: {
    __typename: "JobApplication";
    id: string;
    job: {
      __typename: "Job";
      id: string;
      poster: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      rateType: JobRateType;
      compensation?: number | null;
      title?: string | null;
      description?: string | null;
      locationAddress: string;
      locationCity: string;
      locationState: string;
      locationZip: string;
      locationCountry?: string | null;
      category: {
        __typename: "JobCategory";
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
      };
      applicants?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobStatus: JobStatus;
      latitude?: number | null;
      longitude?: number | null;
      geoHash?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      userPostedJobsUserID?: string | null;
      jobPosterUserID: string;
      jobCategoryId: string;
      owner?: string | null;
    };
    applicant: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    message?: string | null;
    status: ApplicationStatus;
    createdAt: string;
    updatedAt: string;
    userJobApplicationsUserID?: string | null;
    jobApplicantsId?: string | null;
    jobApplicationJobId: string;
    jobApplicationApplicantUserID: string;
    owner?: string | null;
  } | null;
};

export type UpdateJobApplicationMutationVariables = {
  input: UpdateJobApplicationInput;
  condition?: ModelJobApplicationConditionInput | null;
};

export type UpdateJobApplicationMutation = {
  updateJobApplication?: {
    __typename: "JobApplication";
    id: string;
    job: {
      __typename: "Job";
      id: string;
      poster: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      rateType: JobRateType;
      compensation?: number | null;
      title?: string | null;
      description?: string | null;
      locationAddress: string;
      locationCity: string;
      locationState: string;
      locationZip: string;
      locationCountry?: string | null;
      category: {
        __typename: "JobCategory";
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
      };
      applicants?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobStatus: JobStatus;
      latitude?: number | null;
      longitude?: number | null;
      geoHash?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      userPostedJobsUserID?: string | null;
      jobPosterUserID: string;
      jobCategoryId: string;
      owner?: string | null;
    };
    applicant: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    message?: string | null;
    status: ApplicationStatus;
    createdAt: string;
    updatedAt: string;
    userJobApplicationsUserID?: string | null;
    jobApplicantsId?: string | null;
    jobApplicationJobId: string;
    jobApplicationApplicantUserID: string;
    owner?: string | null;
  } | null;
};

export type DeleteJobApplicationMutationVariables = {
  input: DeleteJobApplicationInput;
  condition?: ModelJobApplicationConditionInput | null;
};

export type DeleteJobApplicationMutation = {
  deleteJobApplication?: {
    __typename: "JobApplication";
    id: string;
    job: {
      __typename: "Job";
      id: string;
      poster: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      rateType: JobRateType;
      compensation?: number | null;
      title?: string | null;
      description?: string | null;
      locationAddress: string;
      locationCity: string;
      locationState: string;
      locationZip: string;
      locationCountry?: string | null;
      category: {
        __typename: "JobCategory";
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
      };
      applicants?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobStatus: JobStatus;
      latitude?: number | null;
      longitude?: number | null;
      geoHash?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      userPostedJobsUserID?: string | null;
      jobPosterUserID: string;
      jobCategoryId: string;
      owner?: string | null;
    };
    applicant: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    message?: string | null;
    status: ApplicationStatus;
    createdAt: string;
    updatedAt: string;
    userJobApplicationsUserID?: string | null;
    jobApplicantsId?: string | null;
    jobApplicationJobId: string;
    jobApplicationApplicantUserID: string;
    owner?: string | null;
  } | null;
};

export type CreateCouponMutationVariables = {
  input: CreateCouponInput;
  condition?: ModelCouponConditionInput | null;
};

export type CreateCouponMutation = {
  createCoupon?: {
    __typename: "Coupon";
    id: string;
    couponCode?: string | null;
    isActive?: boolean | null;
    amount?: number | null;
    percentage?: number | null;
    type: couponCodeType;
    description?: string | null;
    expiryDate?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type UpdateCouponMutationVariables = {
  input: UpdateCouponInput;
  condition?: ModelCouponConditionInput | null;
};

export type UpdateCouponMutation = {
  updateCoupon?: {
    __typename: "Coupon";
    id: string;
    couponCode?: string | null;
    isActive?: boolean | null;
    amount?: number | null;
    percentage?: number | null;
    type: couponCodeType;
    description?: string | null;
    expiryDate?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type DeleteCouponMutationVariables = {
  input: DeleteCouponInput;
  condition?: ModelCouponConditionInput | null;
};

export type DeleteCouponMutation = {
  deleteCoupon?: {
    __typename: "Coupon";
    id: string;
    couponCode?: string | null;
    isActive?: boolean | null;
    amount?: number | null;
    percentage?: number | null;
    type: couponCodeType;
    description?: string | null;
    expiryDate?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type CreateUserReviewMutationVariables = {
  input: CreateUserReviewInput;
  condition?: ModelUserReviewConditionInput | null;
};

export type CreateUserReviewMutation = {
  createUserReview?: {
    __typename: "UserReview";
    id: string;
    forUser: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    fromUser: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    rating: number;
    message?: string | null;
    createdAt: string;
    updatedAt: string;
    userReceivedReviewsUserID?: string | null;
    userSentReviewsUserID?: string | null;
    userReviewForUserUserID: string;
    userReviewFromUserUserID: string;
    owner?: string | null;
  } | null;
};

export type UpdateUserReviewMutationVariables = {
  input: UpdateUserReviewInput;
  condition?: ModelUserReviewConditionInput | null;
};

export type UpdateUserReviewMutation = {
  updateUserReview?: {
    __typename: "UserReview";
    id: string;
    forUser: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    fromUser: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    rating: number;
    message?: string | null;
    createdAt: string;
    updatedAt: string;
    userReceivedReviewsUserID?: string | null;
    userSentReviewsUserID?: string | null;
    userReviewForUserUserID: string;
    userReviewFromUserUserID: string;
    owner?: string | null;
  } | null;
};

export type DeleteUserReviewMutationVariables = {
  input: DeleteUserReviewInput;
  condition?: ModelUserReviewConditionInput | null;
};

export type DeleteUserReviewMutation = {
  deleteUserReview?: {
    __typename: "UserReview";
    id: string;
    forUser: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    fromUser: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    rating: number;
    message?: string | null;
    createdAt: string;
    updatedAt: string;
    userReceivedReviewsUserID?: string | null;
    userSentReviewsUserID?: string | null;
    userReviewForUserUserID: string;
    userReviewFromUserUserID: string;
    owner?: string | null;
  } | null;
};

export type CreateJobMessageLogMutationVariables = {
  input: CreateJobMessageLogInput;
  condition?: ModelJobMessageLogConditionInput | null;
};

export type CreateJobMessageLogMutation = {
  createJobMessageLog?: {
    __typename: "JobMessageLog";
    logId: string;
    job: {
      __typename: "Job";
      id: string;
      poster: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      rateType: JobRateType;
      compensation?: number | null;
      title?: string | null;
      description?: string | null;
      locationAddress: string;
      locationCity: string;
      locationState: string;
      locationZip: string;
      locationCountry?: string | null;
      category: {
        __typename: "JobCategory";
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
      };
      applicants?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobStatus: JobStatus;
      latitude?: number | null;
      longitude?: number | null;
      geoHash?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      userPostedJobsUserID?: string | null;
      jobPosterUserID: string;
      jobCategoryId: string;
      owner?: string | null;
    };
    applicant: {
      __typename: "JobApplication";
      id: string;
      job: {
        __typename: "Job";
        id: string;
        poster: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rateType: JobRateType;
        compensation?: number | null;
        title?: string | null;
        description?: string | null;
        locationAddress: string;
        locationCity: string;
        locationState: string;
        locationZip: string;
        locationCountry?: string | null;
        category: {
          __typename: "JobCategory";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        };
        applicants?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        jobStatus: JobStatus;
        latitude?: number | null;
        longitude?: number | null;
        geoHash?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        userPostedJobsUserID?: string | null;
        jobPosterUserID: string;
        jobCategoryId: string;
        owner?: string | null;
      };
      applicant: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      message?: string | null;
      status: ApplicationStatus;
      createdAt: string;
      updatedAt: string;
      userJobApplicationsUserID?: string | null;
      jobApplicantsId?: string | null;
      jobApplicationJobId: string;
      jobApplicationApplicantUserID: string;
      owner?: string | null;
    };
    posterHash: string;
    applicantHash: string;
    messages?: {
      __typename: "ModelJobMessageConnection";
      items: Array<{
        __typename: "JobMessage";
        id: string;
        to: string;
        from: string;
        subject: string;
        body: string;
        messageLog: {
          __typename: "JobMessageLog";
          logId: string;
          posterHash: string;
          applicantHash: string;
          createdAt: string;
          updatedAt: string;
          jobMessageLogJobId: string;
          jobMessageLogApplicantId: string;
          owner?: string | null;
        };
        createdAt: string;
        updatedAt: string;
        jobMessageLogMessagesLogId?: string | null;
        jobMessageMessageLogLogId: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    jobMessageLogJobId: string;
    jobMessageLogApplicantId: string;
    owner?: string | null;
  } | null;
};

export type UpdateJobMessageLogMutationVariables = {
  input: UpdateJobMessageLogInput;
  condition?: ModelJobMessageLogConditionInput | null;
};

export type UpdateJobMessageLogMutation = {
  updateJobMessageLog?: {
    __typename: "JobMessageLog";
    logId: string;
    job: {
      __typename: "Job";
      id: string;
      poster: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      rateType: JobRateType;
      compensation?: number | null;
      title?: string | null;
      description?: string | null;
      locationAddress: string;
      locationCity: string;
      locationState: string;
      locationZip: string;
      locationCountry?: string | null;
      category: {
        __typename: "JobCategory";
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
      };
      applicants?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobStatus: JobStatus;
      latitude?: number | null;
      longitude?: number | null;
      geoHash?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      userPostedJobsUserID?: string | null;
      jobPosterUserID: string;
      jobCategoryId: string;
      owner?: string | null;
    };
    applicant: {
      __typename: "JobApplication";
      id: string;
      job: {
        __typename: "Job";
        id: string;
        poster: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rateType: JobRateType;
        compensation?: number | null;
        title?: string | null;
        description?: string | null;
        locationAddress: string;
        locationCity: string;
        locationState: string;
        locationZip: string;
        locationCountry?: string | null;
        category: {
          __typename: "JobCategory";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        };
        applicants?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        jobStatus: JobStatus;
        latitude?: number | null;
        longitude?: number | null;
        geoHash?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        userPostedJobsUserID?: string | null;
        jobPosterUserID: string;
        jobCategoryId: string;
        owner?: string | null;
      };
      applicant: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      message?: string | null;
      status: ApplicationStatus;
      createdAt: string;
      updatedAt: string;
      userJobApplicationsUserID?: string | null;
      jobApplicantsId?: string | null;
      jobApplicationJobId: string;
      jobApplicationApplicantUserID: string;
      owner?: string | null;
    };
    posterHash: string;
    applicantHash: string;
    messages?: {
      __typename: "ModelJobMessageConnection";
      items: Array<{
        __typename: "JobMessage";
        id: string;
        to: string;
        from: string;
        subject: string;
        body: string;
        messageLog: {
          __typename: "JobMessageLog";
          logId: string;
          posterHash: string;
          applicantHash: string;
          createdAt: string;
          updatedAt: string;
          jobMessageLogJobId: string;
          jobMessageLogApplicantId: string;
          owner?: string | null;
        };
        createdAt: string;
        updatedAt: string;
        jobMessageLogMessagesLogId?: string | null;
        jobMessageMessageLogLogId: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    jobMessageLogJobId: string;
    jobMessageLogApplicantId: string;
    owner?: string | null;
  } | null;
};

export type DeleteJobMessageLogMutationVariables = {
  input: DeleteJobMessageLogInput;
  condition?: ModelJobMessageLogConditionInput | null;
};

export type DeleteJobMessageLogMutation = {
  deleteJobMessageLog?: {
    __typename: "JobMessageLog";
    logId: string;
    job: {
      __typename: "Job";
      id: string;
      poster: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      rateType: JobRateType;
      compensation?: number | null;
      title?: string | null;
      description?: string | null;
      locationAddress: string;
      locationCity: string;
      locationState: string;
      locationZip: string;
      locationCountry?: string | null;
      category: {
        __typename: "JobCategory";
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
      };
      applicants?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobStatus: JobStatus;
      latitude?: number | null;
      longitude?: number | null;
      geoHash?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      userPostedJobsUserID?: string | null;
      jobPosterUserID: string;
      jobCategoryId: string;
      owner?: string | null;
    };
    applicant: {
      __typename: "JobApplication";
      id: string;
      job: {
        __typename: "Job";
        id: string;
        poster: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rateType: JobRateType;
        compensation?: number | null;
        title?: string | null;
        description?: string | null;
        locationAddress: string;
        locationCity: string;
        locationState: string;
        locationZip: string;
        locationCountry?: string | null;
        category: {
          __typename: "JobCategory";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        };
        applicants?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        jobStatus: JobStatus;
        latitude?: number | null;
        longitude?: number | null;
        geoHash?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        userPostedJobsUserID?: string | null;
        jobPosterUserID: string;
        jobCategoryId: string;
        owner?: string | null;
      };
      applicant: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      message?: string | null;
      status: ApplicationStatus;
      createdAt: string;
      updatedAt: string;
      userJobApplicationsUserID?: string | null;
      jobApplicantsId?: string | null;
      jobApplicationJobId: string;
      jobApplicationApplicantUserID: string;
      owner?: string | null;
    };
    posterHash: string;
    applicantHash: string;
    messages?: {
      __typename: "ModelJobMessageConnection";
      items: Array<{
        __typename: "JobMessage";
        id: string;
        to: string;
        from: string;
        subject: string;
        body: string;
        messageLog: {
          __typename: "JobMessageLog";
          logId: string;
          posterHash: string;
          applicantHash: string;
          createdAt: string;
          updatedAt: string;
          jobMessageLogJobId: string;
          jobMessageLogApplicantId: string;
          owner?: string | null;
        };
        createdAt: string;
        updatedAt: string;
        jobMessageLogMessagesLogId?: string | null;
        jobMessageMessageLogLogId: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    jobMessageLogJobId: string;
    jobMessageLogApplicantId: string;
    owner?: string | null;
  } | null;
};

export type CreateJobMessageMutationVariables = {
  input: CreateJobMessageInput;
  condition?: ModelJobMessageConditionInput | null;
};

export type CreateJobMessageMutation = {
  createJobMessage?: {
    __typename: "JobMessage";
    id: string;
    to: string;
    from: string;
    subject: string;
    body: string;
    messageLog: {
      __typename: "JobMessageLog";
      logId: string;
      job: {
        __typename: "Job";
        id: string;
        poster: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rateType: JobRateType;
        compensation?: number | null;
        title?: string | null;
        description?: string | null;
        locationAddress: string;
        locationCity: string;
        locationState: string;
        locationZip: string;
        locationCountry?: string | null;
        category: {
          __typename: "JobCategory";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        };
        applicants?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        jobStatus: JobStatus;
        latitude?: number | null;
        longitude?: number | null;
        geoHash?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        userPostedJobsUserID?: string | null;
        jobPosterUserID: string;
        jobCategoryId: string;
        owner?: string | null;
      };
      applicant: {
        __typename: "JobApplication";
        id: string;
        job: {
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        };
        applicant: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        message?: string | null;
        status: ApplicationStatus;
        createdAt: string;
        updatedAt: string;
        userJobApplicationsUserID?: string | null;
        jobApplicantsId?: string | null;
        jobApplicationJobId: string;
        jobApplicationApplicantUserID: string;
        owner?: string | null;
      };
      posterHash: string;
      applicantHash: string;
      messages?: {
        __typename: "ModelJobMessageConnection";
        items: Array<{
          __typename: "JobMessage";
          id: string;
          to: string;
          from: string;
          subject: string;
          body: string;
          createdAt: string;
          updatedAt: string;
          jobMessageLogMessagesLogId?: string | null;
          jobMessageMessageLogLogId: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
      jobMessageLogJobId: string;
      jobMessageLogApplicantId: string;
      owner?: string | null;
    };
    createdAt: string;
    updatedAt: string;
    jobMessageLogMessagesLogId?: string | null;
    jobMessageMessageLogLogId: string;
  } | null;
};

export type UpdateJobMessageMutationVariables = {
  input: UpdateJobMessageInput;
  condition?: ModelJobMessageConditionInput | null;
};

export type UpdateJobMessageMutation = {
  updateJobMessage?: {
    __typename: "JobMessage";
    id: string;
    to: string;
    from: string;
    subject: string;
    body: string;
    messageLog: {
      __typename: "JobMessageLog";
      logId: string;
      job: {
        __typename: "Job";
        id: string;
        poster: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rateType: JobRateType;
        compensation?: number | null;
        title?: string | null;
        description?: string | null;
        locationAddress: string;
        locationCity: string;
        locationState: string;
        locationZip: string;
        locationCountry?: string | null;
        category: {
          __typename: "JobCategory";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        };
        applicants?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        jobStatus: JobStatus;
        latitude?: number | null;
        longitude?: number | null;
        geoHash?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        userPostedJobsUserID?: string | null;
        jobPosterUserID: string;
        jobCategoryId: string;
        owner?: string | null;
      };
      applicant: {
        __typename: "JobApplication";
        id: string;
        job: {
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        };
        applicant: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        message?: string | null;
        status: ApplicationStatus;
        createdAt: string;
        updatedAt: string;
        userJobApplicationsUserID?: string | null;
        jobApplicantsId?: string | null;
        jobApplicationJobId: string;
        jobApplicationApplicantUserID: string;
        owner?: string | null;
      };
      posterHash: string;
      applicantHash: string;
      messages?: {
        __typename: "ModelJobMessageConnection";
        items: Array<{
          __typename: "JobMessage";
          id: string;
          to: string;
          from: string;
          subject: string;
          body: string;
          createdAt: string;
          updatedAt: string;
          jobMessageLogMessagesLogId?: string | null;
          jobMessageMessageLogLogId: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
      jobMessageLogJobId: string;
      jobMessageLogApplicantId: string;
      owner?: string | null;
    };
    createdAt: string;
    updatedAt: string;
    jobMessageLogMessagesLogId?: string | null;
    jobMessageMessageLogLogId: string;
  } | null;
};

export type DeleteJobMessageMutationVariables = {
  input: DeleteJobMessageInput;
  condition?: ModelJobMessageConditionInput | null;
};

export type DeleteJobMessageMutation = {
  deleteJobMessage?: {
    __typename: "JobMessage";
    id: string;
    to: string;
    from: string;
    subject: string;
    body: string;
    messageLog: {
      __typename: "JobMessageLog";
      logId: string;
      job: {
        __typename: "Job";
        id: string;
        poster: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rateType: JobRateType;
        compensation?: number | null;
        title?: string | null;
        description?: string | null;
        locationAddress: string;
        locationCity: string;
        locationState: string;
        locationZip: string;
        locationCountry?: string | null;
        category: {
          __typename: "JobCategory";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        };
        applicants?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        jobStatus: JobStatus;
        latitude?: number | null;
        longitude?: number | null;
        geoHash?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        userPostedJobsUserID?: string | null;
        jobPosterUserID: string;
        jobCategoryId: string;
        owner?: string | null;
      };
      applicant: {
        __typename: "JobApplication";
        id: string;
        job: {
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        };
        applicant: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        message?: string | null;
        status: ApplicationStatus;
        createdAt: string;
        updatedAt: string;
        userJobApplicationsUserID?: string | null;
        jobApplicantsId?: string | null;
        jobApplicationJobId: string;
        jobApplicationApplicantUserID: string;
        owner?: string | null;
      };
      posterHash: string;
      applicantHash: string;
      messages?: {
        __typename: "ModelJobMessageConnection";
        items: Array<{
          __typename: "JobMessage";
          id: string;
          to: string;
          from: string;
          subject: string;
          body: string;
          createdAt: string;
          updatedAt: string;
          jobMessageLogMessagesLogId?: string | null;
          jobMessageMessageLogLogId: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
      jobMessageLogJobId: string;
      jobMessageLogApplicantId: string;
      owner?: string | null;
    };
    createdAt: string;
    updatedAt: string;
    jobMessageLogMessagesLogId?: string | null;
    jobMessageMessageLogLogId: string;
  } | null;
};

export type CreateFlaggedContentMutationVariables = {
  input: CreateFlaggedContentInput;
  condition?: ModelFlaggedContentConditionInput | null;
};

export type CreateFlaggedContentMutation = {
  createFlaggedContent?: {
    __typename: "FlaggedContent";
    contentId: string;
    flaggedUser: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    contentType: FlaggedContentType;
    flagReason: FlaggedReason;
    reportCount: number;
    createdAt: string;
    updatedAt: string;
    flaggedContentFlaggedUserUserID: string;
  } | null;
};

export type UpdateFlaggedContentMutationVariables = {
  input: UpdateFlaggedContentInput;
  condition?: ModelFlaggedContentConditionInput | null;
};

export type UpdateFlaggedContentMutation = {
  updateFlaggedContent?: {
    __typename: "FlaggedContent";
    contentId: string;
    flaggedUser: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    contentType: FlaggedContentType;
    flagReason: FlaggedReason;
    reportCount: number;
    createdAt: string;
    updatedAt: string;
    flaggedContentFlaggedUserUserID: string;
  } | null;
};

export type DeleteFlaggedContentMutationVariables = {
  input: DeleteFlaggedContentInput;
  condition?: ModelFlaggedContentConditionInput | null;
};

export type DeleteFlaggedContentMutation = {
  deleteFlaggedContent?: {
    __typename: "FlaggedContent";
    contentId: string;
    flaggedUser: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    contentType: FlaggedContentType;
    flagReason: FlaggedReason;
    reportCount: number;
    createdAt: string;
    updatedAt: string;
    flaggedContentFlaggedUserUserID: string;
  } | null;
};

export type CreateSampleJobMutationVariables = {
  input: CreateSampleJobInput;
  condition?: ModelSampleJobConditionInput | null;
};

export type CreateSampleJobMutation = {
  createSampleJob?: {
    __typename: "SampleJob";
    id: string;
    rateType: JobRateType;
    compensation?: number | null;
    title?: string | null;
    description?: string | null;
    locationCity: string;
    locationState: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type UpdateSampleJobMutationVariables = {
  input: UpdateSampleJobInput;
  condition?: ModelSampleJobConditionInput | null;
};

export type UpdateSampleJobMutation = {
  updateSampleJob?: {
    __typename: "SampleJob";
    id: string;
    rateType: JobRateType;
    compensation?: number | null;
    title?: string | null;
    description?: string | null;
    locationCity: string;
    locationState: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type DeleteSampleJobMutationVariables = {
  input: DeleteSampleJobInput;
  condition?: ModelSampleJobConditionInput | null;
};

export type DeleteSampleJobMutation = {
  deleteSampleJob?: {
    __typename: "SampleJob";
    id: string;
    rateType: JobRateType;
    compensation?: number | null;
    title?: string | null;
    description?: string | null;
    locationCity: string;
    locationState: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type CreateFAQMutationVariables = {
  input: CreateFAQInput;
  condition?: ModelFAQConditionInput | null;
};

export type CreateFAQMutation = {
  createFAQ?: {
    __typename: "FAQ";
    id: string;
    question: string;
    answer: string;
    order: number;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type UpdateFAQMutationVariables = {
  input: UpdateFAQInput;
  condition?: ModelFAQConditionInput | null;
};

export type UpdateFAQMutation = {
  updateFAQ?: {
    __typename: "FAQ";
    id: string;
    question: string;
    answer: string;
    order: number;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type DeleteFAQMutationVariables = {
  input: DeleteFAQInput;
  condition?: ModelFAQConditionInput | null;
};

export type DeleteFAQMutation = {
  deleteFAQ?: {
    __typename: "FAQ";
    id: string;
    question: string;
    answer: string;
    order: number;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type CreateBackgroundCheckPaymentStatusMutationVariables = {
  input: CreateBackgroundCheckPaymentStatusInput;
  condition?: ModelBackgroundCheckPaymentStatusConditionInput | null;
};

export type CreateBackgroundCheckPaymentStatusMutation = {
  createBackgroundCheckPaymentStatus?: {
    __typename: "BackgroundCheckPaymentStatus";
    id: string;
    user: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    payment: boolean;
    createdAt?: string | null;
    updatedAt: string;
    backgroundCheckPaymentStatusUserUserID: string;
  } | null;
};

export type UpdateBackgroundCheckPaymentStatusMutationVariables = {
  input: UpdateBackgroundCheckPaymentStatusInput;
  condition?: ModelBackgroundCheckPaymentStatusConditionInput | null;
};

export type UpdateBackgroundCheckPaymentStatusMutation = {
  updateBackgroundCheckPaymentStatus?: {
    __typename: "BackgroundCheckPaymentStatus";
    id: string;
    user: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    payment: boolean;
    createdAt?: string | null;
    updatedAt: string;
    backgroundCheckPaymentStatusUserUserID: string;
  } | null;
};

export type DeleteBackgroundCheckPaymentStatusMutationVariables = {
  input: DeleteBackgroundCheckPaymentStatusInput;
  condition?: ModelBackgroundCheckPaymentStatusConditionInput | null;
};

export type DeleteBackgroundCheckPaymentStatusMutation = {
  deleteBackgroundCheckPaymentStatus?: {
    __typename: "BackgroundCheckPaymentStatus";
    id: string;
    user: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    payment: boolean;
    createdAt?: string | null;
    updatedAt: string;
    backgroundCheckPaymentStatusUserUserID: string;
  } | null;
};

export type CreateBackgroundCheckLogMutationVariables = {
  input: CreateBackgroundCheckLogInput;
  condition?: ModelBackgroundCheckLogConditionInput | null;
};

export type CreateBackgroundCheckLogMutation = {
  createBackgroundCheckLog?: {
    __typename: "BackgroundCheckLog";
    candidateID: string;
    userID?: string | null;
    invitationID?: string | null;
    reportID?: string | null;
    inviteStatus?: string | null;
    reportStatus?: string | null;
    reportResult?: string | null;
    reportAdjudication?: string | null;
    backgroundCheckCompletedAt?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type UpdateBackgroundCheckLogMutationVariables = {
  input: UpdateBackgroundCheckLogInput;
  condition?: ModelBackgroundCheckLogConditionInput | null;
};

export type UpdateBackgroundCheckLogMutation = {
  updateBackgroundCheckLog?: {
    __typename: "BackgroundCheckLog";
    candidateID: string;
    userID?: string | null;
    invitationID?: string | null;
    reportID?: string | null;
    inviteStatus?: string | null;
    reportStatus?: string | null;
    reportResult?: string | null;
    reportAdjudication?: string | null;
    backgroundCheckCompletedAt?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type DeleteBackgroundCheckLogMutationVariables = {
  input: DeleteBackgroundCheckLogInput;
  condition?: ModelBackgroundCheckLogConditionInput | null;
};

export type DeleteBackgroundCheckLogMutation = {
  deleteBackgroundCheckLog?: {
    __typename: "BackgroundCheckLog";
    candidateID: string;
    userID?: string | null;
    invitationID?: string | null;
    reportID?: string | null;
    inviteStatus?: string | null;
    reportStatus?: string | null;
    reportResult?: string | null;
    reportAdjudication?: string | null;
    backgroundCheckCompletedAt?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type CreateSettingMutationVariables = {
  input: CreateSettingInput;
  condition?: ModelSettingConditionInput | null;
};

export type CreateSettingMutation = {
  createSetting?: {
    __typename: "Setting";
    id: string;
    feature: string;
    active: boolean;
    createdAt?: string | null;
    updatedAt: string;
  } | null;
};

export type UpdateSettingMutationVariables = {
  input: UpdateSettingInput;
  condition?: ModelSettingConditionInput | null;
};

export type UpdateSettingMutation = {
  updateSetting?: {
    __typename: "Setting";
    id: string;
    feature: string;
    active: boolean;
    createdAt?: string | null;
    updatedAt: string;
  } | null;
};

export type DeleteSettingMutationVariables = {
  input: DeleteSettingInput;
  condition?: ModelSettingConditionInput | null;
};

export type DeleteSettingMutation = {
  deleteSetting?: {
    __typename: "Setting";
    id: string;
    feature: string;
    active: boolean;
    createdAt?: string | null;
    updatedAt: string;
  } | null;
};

export type StartBackgroundCheckMutationVariables = {
  userId: string;
};

export type StartBackgroundCheckMutation = {
  startBackgroundCheck?: string | null;
};

export type SendEmailMutationVariables = {
  input?: MutationSendEmailQueryInput | null;
};

export type SendEmailMutation = {
  sendEmail?: string | null;
};

export type CreateStripeExpressUserAccountMutationVariables = {
  userId: string;
  userEmail: string;
};

export type CreateStripeExpressUserAccountMutation = {
  createStripeExpressUserAccount?: string | null;
};

export type CreateStripeExpressOnboardingLinkMutationVariables = {
  userId: string;
  stripeAccountID: string;
};

export type CreateStripeExpressOnboardingLinkMutation = {
  createStripeExpressOnboardingLink?: string | null;
};

export type CreateStripeCheckoutSessionForBgCheckPaymentMutationVariables = {
  userId: string;
};

export type CreateStripeCheckoutSessionForBgCheckPaymentMutation = {
  createStripeCheckoutSessionForBgCheckPayment?: string | null;
};

export type CreateStripeSubscriptionCheckoutMutationVariables = {
  userId: string;
  subTerm: string;
};

export type CreateStripeSubscriptionCheckoutMutation = {
  createStripeSubscriptionCheckout?: string | null;
};

export type CreateStripeCheckoutSessionMutationVariables = {
  userID: string;
  amountDueStudent: number;
  studentStripeAccountId: string;
  jobId: string;
  couponCode?: string | null;
};

export type CreateStripeCheckoutSessionMutation = {
  createStripeCheckoutSession?: string | null;
};

export type RemoveStripeAccountMutationVariables = {
  stripeAccountID: string;
};

export type RemoveStripeAccountMutation = {
  removeStripeAccount?: string | null;
};

export type RemoveUserDetailsMutationVariables = {
  userID: string;
};

export type RemoveUserDetailsMutation = {
  removeUserDetails?: string | null;
};

export type GetUserQueryVariables = {
  userID: string;
};

export type GetUserQuery = {
  getUser?: {
    __typename: "User";
    userID: string;
    firstName: string;
    lastName: string;
    email: string;
    locationAddress?: string | null;
    locationCity?: string | null;
    locationState?: string | null;
    locationZip?: string | null;
    photoKey?: string | null;
    type: UserType;
    school?: string | null;
    biography?: string | null;
    workLocation?: string | null;
    candidateID?: string | null;
    languages?: string | null;
    skills?: string | null;
    stripeAccountID?: string | null;
    subscription?: SubscriptionStatus | null;
    receivedReviews?: {
      __typename: "ModelUserReviewConnection";
      items: Array<{
        __typename: "UserReview";
        id: string;
        forUser: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        fromUser: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rating: number;
        message?: string | null;
        createdAt: string;
        updatedAt: string;
        userReceivedReviewsUserID?: string | null;
        userSentReviewsUserID?: string | null;
        userReviewForUserUserID: string;
        userReviewFromUserUserID: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    sentReviews?: {
      __typename: "ModelUserReviewConnection";
      items: Array<{
        __typename: "UserReview";
        id: string;
        forUser: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        fromUser: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rating: number;
        message?: string | null;
        createdAt: string;
        updatedAt: string;
        userReceivedReviewsUserID?: string | null;
        userSentReviewsUserID?: string | null;
        userReviewForUserUserID: string;
        userReviewFromUserUserID: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    jobApplications?: {
      __typename: "ModelJobApplicationConnection";
      items: Array<{
        __typename: "JobApplication";
        id: string;
        job: {
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        };
        applicant: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        message?: string | null;
        status: ApplicationStatus;
        createdAt: string;
        updatedAt: string;
        userJobApplicationsUserID?: string | null;
        jobApplicantsId?: string | null;
        jobApplicationJobId: string;
        jobApplicationApplicantUserID: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    postedJobs?: {
      __typename: "ModelJobConnection";
      items: Array<{
        __typename: "Job";
        id: string;
        poster: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rateType: JobRateType;
        compensation?: number | null;
        title?: string | null;
        description?: string | null;
        locationAddress: string;
        locationCity: string;
        locationState: string;
        locationZip: string;
        locationCountry?: string | null;
        category: {
          __typename: "JobCategory";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        };
        applicants?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        jobStatus: JobStatus;
        latitude?: number | null;
        longitude?: number | null;
        geoHash?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        userPostedJobsUserID?: string | null;
        jobPosterUserID: string;
        jobCategoryId: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    name?: string | null;
    graduationYear?: string | null;
    emailNotificationEnabled?: boolean | null;
    fullName?: string | null;
    avgHourlyRate?: number | null;
    jobSpecilities?: {
      __typename: "ModelJobSpecilitiesConnection";
      items: Array<{
        __typename: "JobSpecilities";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        category: {
          __typename: "JobCategory";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
        userJobSpecilitiesUserID?: string | null;
        jobSpecilitiesUserUserID: string;
        jobSpecilitiesCategoryId: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    status?: UserStatus | null;
    geoHash?: string | null;
    stripeCreatedAt?: string | null;
    bgCheckPayment?: {
      __typename: "BackgroundCheckPaymentStatus";
      id: string;
      user: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      payment: boolean;
      createdAt?: string | null;
      updatedAt: string;
      backgroundCheckPaymentStatusUserUserID: string;
    } | null;
    createdAt: string;
    updatedAt: string;
    userBgCheckPaymentId?: string | null;
  } | null;
};

export type ListUsersQueryVariables = {
  userID?: string | null;
  filter?: ModelUserFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
  sortDirection?: ModelSortDirection | null;
};

export type ListUsersQuery = {
  listUsers?: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetJobSpecilitiesQueryVariables = {
  id: string;
};

export type GetJobSpecilitiesQuery = {
  getJobSpecilities?: {
    __typename: "JobSpecilities";
    id: string;
    user: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    category: {
      __typename: "JobCategory";
      id: string;
      name: string;
      createdAt: string;
      updatedAt: string;
    };
    createdAt: string;
    updatedAt: string;
    userJobSpecilitiesUserID?: string | null;
    jobSpecilitiesUserUserID: string;
    jobSpecilitiesCategoryId: string;
    owner?: string | null;
  } | null;
};

export type ListJobSpecilitiesQueryVariables = {
  filter?: ModelJobSpecilitiesFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListJobSpecilitiesQuery = {
  listJobSpecilities?: {
    __typename: "ModelJobSpecilitiesConnection";
    items: Array<{
      __typename: "JobSpecilities";
      id: string;
      user: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      category: {
        __typename: "JobCategory";
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
      userJobSpecilitiesUserID?: string | null;
      jobSpecilitiesUserUserID: string;
      jobSpecilitiesCategoryId: string;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetJobQueryVariables = {
  id: string;
};

export type GetJobQuery = {
  getJob?: {
    __typename: "Job";
    id: string;
    poster: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    rateType: JobRateType;
    compensation?: number | null;
    title?: string | null;
    description?: string | null;
    locationAddress: string;
    locationCity: string;
    locationState: string;
    locationZip: string;
    locationCountry?: string | null;
    category: {
      __typename: "JobCategory";
      id: string;
      name: string;
      createdAt: string;
      updatedAt: string;
    };
    applicants?: {
      __typename: "ModelJobApplicationConnection";
      items: Array<{
        __typename: "JobApplication";
        id: string;
        job: {
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        };
        applicant: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        message?: string | null;
        status: ApplicationStatus;
        createdAt: string;
        updatedAt: string;
        userJobApplicationsUserID?: string | null;
        jobApplicantsId?: string | null;
        jobApplicationJobId: string;
        jobApplicationApplicantUserID: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    jobStatus: JobStatus;
    latitude?: number | null;
    longitude?: number | null;
    geoHash?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    userPostedJobsUserID?: string | null;
    jobPosterUserID: string;
    jobCategoryId: string;
    owner?: string | null;
  } | null;
};

export type ListJobsQueryVariables = {
  filter?: ModelJobFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListJobsQuery = {
  listJobs?: {
    __typename: "ModelJobConnection";
    items: Array<{
      __typename: "Job";
      id: string;
      poster: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      rateType: JobRateType;
      compensation?: number | null;
      title?: string | null;
      description?: string | null;
      locationAddress: string;
      locationCity: string;
      locationState: string;
      locationZip: string;
      locationCountry?: string | null;
      category: {
        __typename: "JobCategory";
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
      };
      applicants?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobStatus: JobStatus;
      latitude?: number | null;
      longitude?: number | null;
      geoHash?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      userPostedJobsUserID?: string | null;
      jobPosterUserID: string;
      jobCategoryId: string;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type JobsByLocationCountryAndGeoHashQueryVariables = {
  locationCountry: string;
  geoHash?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelJobFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type JobsByLocationCountryAndGeoHashQuery = {
  jobsByLocationCountryAndGeoHash?: {
    __typename: "ModelJobConnection";
    items: Array<{
      __typename: "Job";
      id: string;
      poster: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      rateType: JobRateType;
      compensation?: number | null;
      title?: string | null;
      description?: string | null;
      locationAddress: string;
      locationCity: string;
      locationState: string;
      locationZip: string;
      locationCountry?: string | null;
      category: {
        __typename: "JobCategory";
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
      };
      applicants?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobStatus: JobStatus;
      latitude?: number | null;
      longitude?: number | null;
      geoHash?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      userPostedJobsUserID?: string | null;
      jobPosterUserID: string;
      jobCategoryId: string;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetJobCategoryQueryVariables = {
  id: string;
};

export type GetJobCategoryQuery = {
  getJobCategory?: {
    __typename: "JobCategory";
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListJobCategoriesQueryVariables = {
  filter?: ModelJobCategoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListJobCategoriesQuery = {
  listJobCategories?: {
    __typename: "ModelJobCategoryConnection";
    items: Array<{
      __typename: "JobCategory";
      id: string;
      name: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type JobCategoriesByNameQueryVariables = {
  name: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelJobCategoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type JobCategoriesByNameQuery = {
  jobCategoriesByName?: {
    __typename: "ModelJobCategoryConnection";
    items: Array<{
      __typename: "JobCategory";
      id: string;
      name: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetJobApplicationQueryVariables = {
  id: string;
};

export type GetJobApplicationQuery = {
  getJobApplication?: {
    __typename: "JobApplication";
    id: string;
    job: {
      __typename: "Job";
      id: string;
      poster: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      rateType: JobRateType;
      compensation?: number | null;
      title?: string | null;
      description?: string | null;
      locationAddress: string;
      locationCity: string;
      locationState: string;
      locationZip: string;
      locationCountry?: string | null;
      category: {
        __typename: "JobCategory";
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
      };
      applicants?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobStatus: JobStatus;
      latitude?: number | null;
      longitude?: number | null;
      geoHash?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      userPostedJobsUserID?: string | null;
      jobPosterUserID: string;
      jobCategoryId: string;
      owner?: string | null;
    };
    applicant: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    message?: string | null;
    status: ApplicationStatus;
    createdAt: string;
    updatedAt: string;
    userJobApplicationsUserID?: string | null;
    jobApplicantsId?: string | null;
    jobApplicationJobId: string;
    jobApplicationApplicantUserID: string;
    owner?: string | null;
  } | null;
};

export type ListJobApplicationsQueryVariables = {
  filter?: ModelJobApplicationFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListJobApplicationsQuery = {
  listJobApplications?: {
    __typename: "ModelJobApplicationConnection";
    items: Array<{
      __typename: "JobApplication";
      id: string;
      job: {
        __typename: "Job";
        id: string;
        poster: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rateType: JobRateType;
        compensation?: number | null;
        title?: string | null;
        description?: string | null;
        locationAddress: string;
        locationCity: string;
        locationState: string;
        locationZip: string;
        locationCountry?: string | null;
        category: {
          __typename: "JobCategory";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        };
        applicants?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        jobStatus: JobStatus;
        latitude?: number | null;
        longitude?: number | null;
        geoHash?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        userPostedJobsUserID?: string | null;
        jobPosterUserID: string;
        jobCategoryId: string;
        owner?: string | null;
      };
      applicant: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      message?: string | null;
      status: ApplicationStatus;
      createdAt: string;
      updatedAt: string;
      userJobApplicationsUserID?: string | null;
      jobApplicantsId?: string | null;
      jobApplicationJobId: string;
      jobApplicationApplicantUserID: string;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetCouponQueryVariables = {
  id: string;
};

export type GetCouponQuery = {
  getCoupon?: {
    __typename: "Coupon";
    id: string;
    couponCode?: string | null;
    isActive?: boolean | null;
    amount?: number | null;
    percentage?: number | null;
    type: couponCodeType;
    description?: string | null;
    expiryDate?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type ListCouponsQueryVariables = {
  filter?: ModelCouponFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListCouponsQuery = {
  listCoupons?: {
    __typename: "ModelCouponConnection";
    items: Array<{
      __typename: "Coupon";
      id: string;
      couponCode?: string | null;
      isActive?: boolean | null;
      amount?: number | null;
      percentage?: number | null;
      type: couponCodeType;
      description?: string | null;
      expiryDate?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetUserReviewQueryVariables = {
  id: string;
};

export type GetUserReviewQuery = {
  getUserReview?: {
    __typename: "UserReview";
    id: string;
    forUser: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    fromUser: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    rating: number;
    message?: string | null;
    createdAt: string;
    updatedAt: string;
    userReceivedReviewsUserID?: string | null;
    userSentReviewsUserID?: string | null;
    userReviewForUserUserID: string;
    userReviewFromUserUserID: string;
    owner?: string | null;
  } | null;
};

export type ListUserReviewsQueryVariables = {
  filter?: ModelUserReviewFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListUserReviewsQuery = {
  listUserReviews?: {
    __typename: "ModelUserReviewConnection";
    items: Array<{
      __typename: "UserReview";
      id: string;
      forUser: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      fromUser: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      rating: number;
      message?: string | null;
      createdAt: string;
      updatedAt: string;
      userReceivedReviewsUserID?: string | null;
      userSentReviewsUserID?: string | null;
      userReviewForUserUserID: string;
      userReviewFromUserUserID: string;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetJobMessageLogQueryVariables = {
  logId: string;
};

export type GetJobMessageLogQuery = {
  getJobMessageLog?: {
    __typename: "JobMessageLog";
    logId: string;
    job: {
      __typename: "Job";
      id: string;
      poster: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      rateType: JobRateType;
      compensation?: number | null;
      title?: string | null;
      description?: string | null;
      locationAddress: string;
      locationCity: string;
      locationState: string;
      locationZip: string;
      locationCountry?: string | null;
      category: {
        __typename: "JobCategory";
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
      };
      applicants?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobStatus: JobStatus;
      latitude?: number | null;
      longitude?: number | null;
      geoHash?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      userPostedJobsUserID?: string | null;
      jobPosterUserID: string;
      jobCategoryId: string;
      owner?: string | null;
    };
    applicant: {
      __typename: "JobApplication";
      id: string;
      job: {
        __typename: "Job";
        id: string;
        poster: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rateType: JobRateType;
        compensation?: number | null;
        title?: string | null;
        description?: string | null;
        locationAddress: string;
        locationCity: string;
        locationState: string;
        locationZip: string;
        locationCountry?: string | null;
        category: {
          __typename: "JobCategory";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        };
        applicants?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        jobStatus: JobStatus;
        latitude?: number | null;
        longitude?: number | null;
        geoHash?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        userPostedJobsUserID?: string | null;
        jobPosterUserID: string;
        jobCategoryId: string;
        owner?: string | null;
      };
      applicant: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      message?: string | null;
      status: ApplicationStatus;
      createdAt: string;
      updatedAt: string;
      userJobApplicationsUserID?: string | null;
      jobApplicantsId?: string | null;
      jobApplicationJobId: string;
      jobApplicationApplicantUserID: string;
      owner?: string | null;
    };
    posterHash: string;
    applicantHash: string;
    messages?: {
      __typename: "ModelJobMessageConnection";
      items: Array<{
        __typename: "JobMessage";
        id: string;
        to: string;
        from: string;
        subject: string;
        body: string;
        messageLog: {
          __typename: "JobMessageLog";
          logId: string;
          posterHash: string;
          applicantHash: string;
          createdAt: string;
          updatedAt: string;
          jobMessageLogJobId: string;
          jobMessageLogApplicantId: string;
          owner?: string | null;
        };
        createdAt: string;
        updatedAt: string;
        jobMessageLogMessagesLogId?: string | null;
        jobMessageMessageLogLogId: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    jobMessageLogJobId: string;
    jobMessageLogApplicantId: string;
    owner?: string | null;
  } | null;
};

export type ListJobMessageLogsQueryVariables = {
  logId?: string | null;
  filter?: ModelJobMessageLogFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
  sortDirection?: ModelSortDirection | null;
};

export type ListJobMessageLogsQuery = {
  listJobMessageLogs?: {
    __typename: "ModelJobMessageLogConnection";
    items: Array<{
      __typename: "JobMessageLog";
      logId: string;
      job: {
        __typename: "Job";
        id: string;
        poster: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rateType: JobRateType;
        compensation?: number | null;
        title?: string | null;
        description?: string | null;
        locationAddress: string;
        locationCity: string;
        locationState: string;
        locationZip: string;
        locationCountry?: string | null;
        category: {
          __typename: "JobCategory";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        };
        applicants?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        jobStatus: JobStatus;
        latitude?: number | null;
        longitude?: number | null;
        geoHash?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        userPostedJobsUserID?: string | null;
        jobPosterUserID: string;
        jobCategoryId: string;
        owner?: string | null;
      };
      applicant: {
        __typename: "JobApplication";
        id: string;
        job: {
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        };
        applicant: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        message?: string | null;
        status: ApplicationStatus;
        createdAt: string;
        updatedAt: string;
        userJobApplicationsUserID?: string | null;
        jobApplicantsId?: string | null;
        jobApplicationJobId: string;
        jobApplicationApplicantUserID: string;
        owner?: string | null;
      };
      posterHash: string;
      applicantHash: string;
      messages?: {
        __typename: "ModelJobMessageConnection";
        items: Array<{
          __typename: "JobMessage";
          id: string;
          to: string;
          from: string;
          subject: string;
          body: string;
          createdAt: string;
          updatedAt: string;
          jobMessageLogMessagesLogId?: string | null;
          jobMessageMessageLogLogId: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
      jobMessageLogJobId: string;
      jobMessageLogApplicantId: string;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetJobMessageQueryVariables = {
  id: string;
};

export type GetJobMessageQuery = {
  getJobMessage?: {
    __typename: "JobMessage";
    id: string;
    to: string;
    from: string;
    subject: string;
    body: string;
    messageLog: {
      __typename: "JobMessageLog";
      logId: string;
      job: {
        __typename: "Job";
        id: string;
        poster: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rateType: JobRateType;
        compensation?: number | null;
        title?: string | null;
        description?: string | null;
        locationAddress: string;
        locationCity: string;
        locationState: string;
        locationZip: string;
        locationCountry?: string | null;
        category: {
          __typename: "JobCategory";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        };
        applicants?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        jobStatus: JobStatus;
        latitude?: number | null;
        longitude?: number | null;
        geoHash?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        userPostedJobsUserID?: string | null;
        jobPosterUserID: string;
        jobCategoryId: string;
        owner?: string | null;
      };
      applicant: {
        __typename: "JobApplication";
        id: string;
        job: {
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        };
        applicant: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        message?: string | null;
        status: ApplicationStatus;
        createdAt: string;
        updatedAt: string;
        userJobApplicationsUserID?: string | null;
        jobApplicantsId?: string | null;
        jobApplicationJobId: string;
        jobApplicationApplicantUserID: string;
        owner?: string | null;
      };
      posterHash: string;
      applicantHash: string;
      messages?: {
        __typename: "ModelJobMessageConnection";
        items: Array<{
          __typename: "JobMessage";
          id: string;
          to: string;
          from: string;
          subject: string;
          body: string;
          createdAt: string;
          updatedAt: string;
          jobMessageLogMessagesLogId?: string | null;
          jobMessageMessageLogLogId: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
      jobMessageLogJobId: string;
      jobMessageLogApplicantId: string;
      owner?: string | null;
    };
    createdAt: string;
    updatedAt: string;
    jobMessageLogMessagesLogId?: string | null;
    jobMessageMessageLogLogId: string;
  } | null;
};

export type ListJobMessagesQueryVariables = {
  filter?: ModelJobMessageFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListJobMessagesQuery = {
  listJobMessages?: {
    __typename: "ModelJobMessageConnection";
    items: Array<{
      __typename: "JobMessage";
      id: string;
      to: string;
      from: string;
      subject: string;
      body: string;
      messageLog: {
        __typename: "JobMessageLog";
        logId: string;
        job: {
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        };
        applicant: {
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        };
        posterHash: string;
        applicantHash: string;
        messages?: {
          __typename: "ModelJobMessageConnection";
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
        jobMessageLogJobId: string;
        jobMessageLogApplicantId: string;
        owner?: string | null;
      };
      createdAt: string;
      updatedAt: string;
      jobMessageLogMessagesLogId?: string | null;
      jobMessageMessageLogLogId: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetFlaggedContentQueryVariables = {
  contentId: string;
  flagReason: FlaggedReason;
};

export type GetFlaggedContentQuery = {
  getFlaggedContent?: {
    __typename: "FlaggedContent";
    contentId: string;
    flaggedUser: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    contentType: FlaggedContentType;
    flagReason: FlaggedReason;
    reportCount: number;
    createdAt: string;
    updatedAt: string;
    flaggedContentFlaggedUserUserID: string;
  } | null;
};

export type ListFlaggedContentsQueryVariables = {
  contentId?: string | null;
  flagReason?: ModelStringKeyConditionInput | null;
  filter?: ModelFlaggedContentFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
  sortDirection?: ModelSortDirection | null;
};

export type ListFlaggedContentsQuery = {
  listFlaggedContents?: {
    __typename: "ModelFlaggedContentConnection";
    items: Array<{
      __typename: "FlaggedContent";
      contentId: string;
      flaggedUser: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      contentType: FlaggedContentType;
      flagReason: FlaggedReason;
      reportCount: number;
      createdAt: string;
      updatedAt: string;
      flaggedContentFlaggedUserUserID: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type FlaggedContentsByContentTypeQueryVariables = {
  contentType: FlaggedContentType;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelFlaggedContentFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type FlaggedContentsByContentTypeQuery = {
  flaggedContentsByContentType?: {
    __typename: "ModelFlaggedContentConnection";
    items: Array<{
      __typename: "FlaggedContent";
      contentId: string;
      flaggedUser: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      contentType: FlaggedContentType;
      flagReason: FlaggedReason;
      reportCount: number;
      createdAt: string;
      updatedAt: string;
      flaggedContentFlaggedUserUserID: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSampleJobQueryVariables = {
  id: string;
};

export type GetSampleJobQuery = {
  getSampleJob?: {
    __typename: "SampleJob";
    id: string;
    rateType: JobRateType;
    compensation?: number | null;
    title?: string | null;
    description?: string | null;
    locationCity: string;
    locationState: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListSampleJobsQueryVariables = {
  filter?: ModelSampleJobFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSampleJobsQuery = {
  listSampleJobs?: {
    __typename: "ModelSampleJobConnection";
    items: Array<{
      __typename: "SampleJob";
      id: string;
      rateType: JobRateType;
      compensation?: number | null;
      title?: string | null;
      description?: string | null;
      locationCity: string;
      locationState: string;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetFAQQueryVariables = {
  id: string;
};

export type GetFAQQuery = {
  getFAQ?: {
    __typename: "FAQ";
    id: string;
    question: string;
    answer: string;
    order: number;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListFAQSQueryVariables = {
  filter?: ModelFAQFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListFAQSQuery = {
  listFAQS?: {
    __typename: "ModelFAQConnection";
    items: Array<{
      __typename: "FAQ";
      id: string;
      question: string;
      answer: string;
      order: number;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetBackgroundCheckPaymentStatusQueryVariables = {
  id: string;
};

export type GetBackgroundCheckPaymentStatusQuery = {
  getBackgroundCheckPaymentStatus?: {
    __typename: "BackgroundCheckPaymentStatus";
    id: string;
    user: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    payment: boolean;
    createdAt?: string | null;
    updatedAt: string;
    backgroundCheckPaymentStatusUserUserID: string;
  } | null;
};

export type ListBackgroundCheckPaymentStatusesQueryVariables = {
  filter?: ModelBackgroundCheckPaymentStatusFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListBackgroundCheckPaymentStatusesQuery = {
  listBackgroundCheckPaymentStatuses?: {
    __typename: "ModelBackgroundCheckPaymentStatusConnection";
    items: Array<{
      __typename: "BackgroundCheckPaymentStatus";
      id: string;
      user: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      payment: boolean;
      createdAt?: string | null;
      updatedAt: string;
      backgroundCheckPaymentStatusUserUserID: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetBackgroundCheckLogQueryVariables = {
  candidateID: string;
};

export type GetBackgroundCheckLogQuery = {
  getBackgroundCheckLog?: {
    __typename: "BackgroundCheckLog";
    candidateID: string;
    userID?: string | null;
    invitationID?: string | null;
    reportID?: string | null;
    inviteStatus?: string | null;
    reportStatus?: string | null;
    reportResult?: string | null;
    reportAdjudication?: string | null;
    backgroundCheckCompletedAt?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListBackgroundCheckLogsQueryVariables = {
  candidateID?: string | null;
  filter?: ModelBackgroundCheckLogFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
  sortDirection?: ModelSortDirection | null;
};

export type ListBackgroundCheckLogsQuery = {
  listBackgroundCheckLogs?: {
    __typename: "ModelBackgroundCheckLogConnection";
    items: Array<{
      __typename: "BackgroundCheckLog";
      candidateID: string;
      userID?: string | null;
      invitationID?: string | null;
      reportID?: string | null;
      inviteStatus?: string | null;
      reportStatus?: string | null;
      reportResult?: string | null;
      reportAdjudication?: string | null;
      backgroundCheckCompletedAt?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSettingQueryVariables = {
  id: string;
};

export type GetSettingQuery = {
  getSetting?: {
    __typename: "Setting";
    id: string;
    feature: string;
    active: boolean;
    createdAt?: string | null;
    updatedAt: string;
  } | null;
};

export type ListSettingsQueryVariables = {
  filter?: ModelSettingFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSettingsQuery = {
  listSettings?: {
    __typename: "ModelSettingConnection";
    items: Array<{
      __typename: "Setting";
      id: string;
      feature: string;
      active: boolean;
      createdAt?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetUserBadgesQueryVariables = {
  userId: string;
};

export type GetUserBadgesQuery = {
  getUserBadges?: Array<{
    __typename: "UserBadge";
    id: number;
    name: string;
    description: string;
    icon: string;
  } | null> | null;
};

export type CheckStripeExpressAccountStatusQueryVariables = {
  stripeAccountID: string;
};

export type CheckStripeExpressAccountStatusQuery = {
  checkStripeExpressAccountStatus?: string | null;
};

export type GetStripeLoginLinkQueryVariables = {
  accountId: string;
};

export type GetStripeLoginLinkQuery = {
  getStripeLoginLink?: string | null;
};

export type LogEventQueryVariables = {
  name?: string | null;
  data?: EventLogData | null;
};

export type LogEventQuery = {
  logEvent?: boolean | null;
};

export type GetUserListQueryVariables = {
  msg?: string | null;
};

export type GetUserListQuery = {
  getUserList?: string | null;
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null;
  owner?: string | null;
};

export type OnCreateUserSubscription = {
  onCreateUser?: {
    __typename: "User";
    userID: string;
    firstName: string;
    lastName: string;
    email: string;
    locationAddress?: string | null;
    locationCity?: string | null;
    locationState?: string | null;
    locationZip?: string | null;
    photoKey?: string | null;
    type: UserType;
    school?: string | null;
    biography?: string | null;
    workLocation?: string | null;
    candidateID?: string | null;
    languages?: string | null;
    skills?: string | null;
    stripeAccountID?: string | null;
    subscription?: SubscriptionStatus | null;
    receivedReviews?: {
      __typename: "ModelUserReviewConnection";
      items: Array<{
        __typename: "UserReview";
        id: string;
        forUser: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        fromUser: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rating: number;
        message?: string | null;
        createdAt: string;
        updatedAt: string;
        userReceivedReviewsUserID?: string | null;
        userSentReviewsUserID?: string | null;
        userReviewForUserUserID: string;
        userReviewFromUserUserID: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    sentReviews?: {
      __typename: "ModelUserReviewConnection";
      items: Array<{
        __typename: "UserReview";
        id: string;
        forUser: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        fromUser: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rating: number;
        message?: string | null;
        createdAt: string;
        updatedAt: string;
        userReceivedReviewsUserID?: string | null;
        userSentReviewsUserID?: string | null;
        userReviewForUserUserID: string;
        userReviewFromUserUserID: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    jobApplications?: {
      __typename: "ModelJobApplicationConnection";
      items: Array<{
        __typename: "JobApplication";
        id: string;
        job: {
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        };
        applicant: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        message?: string | null;
        status: ApplicationStatus;
        createdAt: string;
        updatedAt: string;
        userJobApplicationsUserID?: string | null;
        jobApplicantsId?: string | null;
        jobApplicationJobId: string;
        jobApplicationApplicantUserID: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    postedJobs?: {
      __typename: "ModelJobConnection";
      items: Array<{
        __typename: "Job";
        id: string;
        poster: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rateType: JobRateType;
        compensation?: number | null;
        title?: string | null;
        description?: string | null;
        locationAddress: string;
        locationCity: string;
        locationState: string;
        locationZip: string;
        locationCountry?: string | null;
        category: {
          __typename: "JobCategory";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        };
        applicants?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        jobStatus: JobStatus;
        latitude?: number | null;
        longitude?: number | null;
        geoHash?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        userPostedJobsUserID?: string | null;
        jobPosterUserID: string;
        jobCategoryId: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    name?: string | null;
    graduationYear?: string | null;
    emailNotificationEnabled?: boolean | null;
    fullName?: string | null;
    avgHourlyRate?: number | null;
    jobSpecilities?: {
      __typename: "ModelJobSpecilitiesConnection";
      items: Array<{
        __typename: "JobSpecilities";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        category: {
          __typename: "JobCategory";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
        userJobSpecilitiesUserID?: string | null;
        jobSpecilitiesUserUserID: string;
        jobSpecilitiesCategoryId: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    status?: UserStatus | null;
    geoHash?: string | null;
    stripeCreatedAt?: string | null;
    bgCheckPayment?: {
      __typename: "BackgroundCheckPaymentStatus";
      id: string;
      user: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      payment: boolean;
      createdAt?: string | null;
      updatedAt: string;
      backgroundCheckPaymentStatusUserUserID: string;
    } | null;
    createdAt: string;
    updatedAt: string;
    userBgCheckPaymentId?: string | null;
  } | null;
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null;
  owner?: string | null;
};

export type OnUpdateUserSubscription = {
  onUpdateUser?: {
    __typename: "User";
    userID: string;
    firstName: string;
    lastName: string;
    email: string;
    locationAddress?: string | null;
    locationCity?: string | null;
    locationState?: string | null;
    locationZip?: string | null;
    photoKey?: string | null;
    type: UserType;
    school?: string | null;
    biography?: string | null;
    workLocation?: string | null;
    candidateID?: string | null;
    languages?: string | null;
    skills?: string | null;
    stripeAccountID?: string | null;
    subscription?: SubscriptionStatus | null;
    receivedReviews?: {
      __typename: "ModelUserReviewConnection";
      items: Array<{
        __typename: "UserReview";
        id: string;
        forUser: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        fromUser: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rating: number;
        message?: string | null;
        createdAt: string;
        updatedAt: string;
        userReceivedReviewsUserID?: string | null;
        userSentReviewsUserID?: string | null;
        userReviewForUserUserID: string;
        userReviewFromUserUserID: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    sentReviews?: {
      __typename: "ModelUserReviewConnection";
      items: Array<{
        __typename: "UserReview";
        id: string;
        forUser: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        fromUser: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rating: number;
        message?: string | null;
        createdAt: string;
        updatedAt: string;
        userReceivedReviewsUserID?: string | null;
        userSentReviewsUserID?: string | null;
        userReviewForUserUserID: string;
        userReviewFromUserUserID: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    jobApplications?: {
      __typename: "ModelJobApplicationConnection";
      items: Array<{
        __typename: "JobApplication";
        id: string;
        job: {
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        };
        applicant: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        message?: string | null;
        status: ApplicationStatus;
        createdAt: string;
        updatedAt: string;
        userJobApplicationsUserID?: string | null;
        jobApplicantsId?: string | null;
        jobApplicationJobId: string;
        jobApplicationApplicantUserID: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    postedJobs?: {
      __typename: "ModelJobConnection";
      items: Array<{
        __typename: "Job";
        id: string;
        poster: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rateType: JobRateType;
        compensation?: number | null;
        title?: string | null;
        description?: string | null;
        locationAddress: string;
        locationCity: string;
        locationState: string;
        locationZip: string;
        locationCountry?: string | null;
        category: {
          __typename: "JobCategory";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        };
        applicants?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        jobStatus: JobStatus;
        latitude?: number | null;
        longitude?: number | null;
        geoHash?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        userPostedJobsUserID?: string | null;
        jobPosterUserID: string;
        jobCategoryId: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    name?: string | null;
    graduationYear?: string | null;
    emailNotificationEnabled?: boolean | null;
    fullName?: string | null;
    avgHourlyRate?: number | null;
    jobSpecilities?: {
      __typename: "ModelJobSpecilitiesConnection";
      items: Array<{
        __typename: "JobSpecilities";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        category: {
          __typename: "JobCategory";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
        userJobSpecilitiesUserID?: string | null;
        jobSpecilitiesUserUserID: string;
        jobSpecilitiesCategoryId: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    status?: UserStatus | null;
    geoHash?: string | null;
    stripeCreatedAt?: string | null;
    bgCheckPayment?: {
      __typename: "BackgroundCheckPaymentStatus";
      id: string;
      user: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      payment: boolean;
      createdAt?: string | null;
      updatedAt: string;
      backgroundCheckPaymentStatusUserUserID: string;
    } | null;
    createdAt: string;
    updatedAt: string;
    userBgCheckPaymentId?: string | null;
  } | null;
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null;
  owner?: string | null;
};

export type OnDeleteUserSubscription = {
  onDeleteUser?: {
    __typename: "User";
    userID: string;
    firstName: string;
    lastName: string;
    email: string;
    locationAddress?: string | null;
    locationCity?: string | null;
    locationState?: string | null;
    locationZip?: string | null;
    photoKey?: string | null;
    type: UserType;
    school?: string | null;
    biography?: string | null;
    workLocation?: string | null;
    candidateID?: string | null;
    languages?: string | null;
    skills?: string | null;
    stripeAccountID?: string | null;
    subscription?: SubscriptionStatus | null;
    receivedReviews?: {
      __typename: "ModelUserReviewConnection";
      items: Array<{
        __typename: "UserReview";
        id: string;
        forUser: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        fromUser: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rating: number;
        message?: string | null;
        createdAt: string;
        updatedAt: string;
        userReceivedReviewsUserID?: string | null;
        userSentReviewsUserID?: string | null;
        userReviewForUserUserID: string;
        userReviewFromUserUserID: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    sentReviews?: {
      __typename: "ModelUserReviewConnection";
      items: Array<{
        __typename: "UserReview";
        id: string;
        forUser: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        fromUser: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rating: number;
        message?: string | null;
        createdAt: string;
        updatedAt: string;
        userReceivedReviewsUserID?: string | null;
        userSentReviewsUserID?: string | null;
        userReviewForUserUserID: string;
        userReviewFromUserUserID: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    jobApplications?: {
      __typename: "ModelJobApplicationConnection";
      items: Array<{
        __typename: "JobApplication";
        id: string;
        job: {
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        };
        applicant: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        message?: string | null;
        status: ApplicationStatus;
        createdAt: string;
        updatedAt: string;
        userJobApplicationsUserID?: string | null;
        jobApplicantsId?: string | null;
        jobApplicationJobId: string;
        jobApplicationApplicantUserID: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    postedJobs?: {
      __typename: "ModelJobConnection";
      items: Array<{
        __typename: "Job";
        id: string;
        poster: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rateType: JobRateType;
        compensation?: number | null;
        title?: string | null;
        description?: string | null;
        locationAddress: string;
        locationCity: string;
        locationState: string;
        locationZip: string;
        locationCountry?: string | null;
        category: {
          __typename: "JobCategory";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        };
        applicants?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        jobStatus: JobStatus;
        latitude?: number | null;
        longitude?: number | null;
        geoHash?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        userPostedJobsUserID?: string | null;
        jobPosterUserID: string;
        jobCategoryId: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    name?: string | null;
    graduationYear?: string | null;
    emailNotificationEnabled?: boolean | null;
    fullName?: string | null;
    avgHourlyRate?: number | null;
    jobSpecilities?: {
      __typename: "ModelJobSpecilitiesConnection";
      items: Array<{
        __typename: "JobSpecilities";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        category: {
          __typename: "JobCategory";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
        userJobSpecilitiesUserID?: string | null;
        jobSpecilitiesUserUserID: string;
        jobSpecilitiesCategoryId: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    status?: UserStatus | null;
    geoHash?: string | null;
    stripeCreatedAt?: string | null;
    bgCheckPayment?: {
      __typename: "BackgroundCheckPaymentStatus";
      id: string;
      user: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      payment: boolean;
      createdAt?: string | null;
      updatedAt: string;
      backgroundCheckPaymentStatusUserUserID: string;
    } | null;
    createdAt: string;
    updatedAt: string;
    userBgCheckPaymentId?: string | null;
  } | null;
};

export type OnCreateJobSpecilitiesSubscriptionVariables = {
  filter?: ModelSubscriptionJobSpecilitiesFilterInput | null;
  owner?: string | null;
};

export type OnCreateJobSpecilitiesSubscription = {
  onCreateJobSpecilities?: {
    __typename: "JobSpecilities";
    id: string;
    user: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    category: {
      __typename: "JobCategory";
      id: string;
      name: string;
      createdAt: string;
      updatedAt: string;
    };
    createdAt: string;
    updatedAt: string;
    userJobSpecilitiesUserID?: string | null;
    jobSpecilitiesUserUserID: string;
    jobSpecilitiesCategoryId: string;
    owner?: string | null;
  } | null;
};

export type OnUpdateJobSpecilitiesSubscriptionVariables = {
  filter?: ModelSubscriptionJobSpecilitiesFilterInput | null;
  owner?: string | null;
};

export type OnUpdateJobSpecilitiesSubscription = {
  onUpdateJobSpecilities?: {
    __typename: "JobSpecilities";
    id: string;
    user: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    category: {
      __typename: "JobCategory";
      id: string;
      name: string;
      createdAt: string;
      updatedAt: string;
    };
    createdAt: string;
    updatedAt: string;
    userJobSpecilitiesUserID?: string | null;
    jobSpecilitiesUserUserID: string;
    jobSpecilitiesCategoryId: string;
    owner?: string | null;
  } | null;
};

export type OnDeleteJobSpecilitiesSubscriptionVariables = {
  filter?: ModelSubscriptionJobSpecilitiesFilterInput | null;
  owner?: string | null;
};

export type OnDeleteJobSpecilitiesSubscription = {
  onDeleteJobSpecilities?: {
    __typename: "JobSpecilities";
    id: string;
    user: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    category: {
      __typename: "JobCategory";
      id: string;
      name: string;
      createdAt: string;
      updatedAt: string;
    };
    createdAt: string;
    updatedAt: string;
    userJobSpecilitiesUserID?: string | null;
    jobSpecilitiesUserUserID: string;
    jobSpecilitiesCategoryId: string;
    owner?: string | null;
  } | null;
};

export type OnCreateJobSubscriptionVariables = {
  filter?: ModelSubscriptionJobFilterInput | null;
  owner?: string | null;
};

export type OnCreateJobSubscription = {
  onCreateJob?: {
    __typename: "Job";
    id: string;
    poster: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    rateType: JobRateType;
    compensation?: number | null;
    title?: string | null;
    description?: string | null;
    locationAddress: string;
    locationCity: string;
    locationState: string;
    locationZip: string;
    locationCountry?: string | null;
    category: {
      __typename: "JobCategory";
      id: string;
      name: string;
      createdAt: string;
      updatedAt: string;
    };
    applicants?: {
      __typename: "ModelJobApplicationConnection";
      items: Array<{
        __typename: "JobApplication";
        id: string;
        job: {
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        };
        applicant: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        message?: string | null;
        status: ApplicationStatus;
        createdAt: string;
        updatedAt: string;
        userJobApplicationsUserID?: string | null;
        jobApplicantsId?: string | null;
        jobApplicationJobId: string;
        jobApplicationApplicantUserID: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    jobStatus: JobStatus;
    latitude?: number | null;
    longitude?: number | null;
    geoHash?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    userPostedJobsUserID?: string | null;
    jobPosterUserID: string;
    jobCategoryId: string;
    owner?: string | null;
  } | null;
};

export type OnUpdateJobSubscriptionVariables = {
  filter?: ModelSubscriptionJobFilterInput | null;
  owner?: string | null;
};

export type OnUpdateJobSubscription = {
  onUpdateJob?: {
    __typename: "Job";
    id: string;
    poster: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    rateType: JobRateType;
    compensation?: number | null;
    title?: string | null;
    description?: string | null;
    locationAddress: string;
    locationCity: string;
    locationState: string;
    locationZip: string;
    locationCountry?: string | null;
    category: {
      __typename: "JobCategory";
      id: string;
      name: string;
      createdAt: string;
      updatedAt: string;
    };
    applicants?: {
      __typename: "ModelJobApplicationConnection";
      items: Array<{
        __typename: "JobApplication";
        id: string;
        job: {
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        };
        applicant: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        message?: string | null;
        status: ApplicationStatus;
        createdAt: string;
        updatedAt: string;
        userJobApplicationsUserID?: string | null;
        jobApplicantsId?: string | null;
        jobApplicationJobId: string;
        jobApplicationApplicantUserID: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    jobStatus: JobStatus;
    latitude?: number | null;
    longitude?: number | null;
    geoHash?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    userPostedJobsUserID?: string | null;
    jobPosterUserID: string;
    jobCategoryId: string;
    owner?: string | null;
  } | null;
};

export type OnDeleteJobSubscriptionVariables = {
  filter?: ModelSubscriptionJobFilterInput | null;
  owner?: string | null;
};

export type OnDeleteJobSubscription = {
  onDeleteJob?: {
    __typename: "Job";
    id: string;
    poster: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    rateType: JobRateType;
    compensation?: number | null;
    title?: string | null;
    description?: string | null;
    locationAddress: string;
    locationCity: string;
    locationState: string;
    locationZip: string;
    locationCountry?: string | null;
    category: {
      __typename: "JobCategory";
      id: string;
      name: string;
      createdAt: string;
      updatedAt: string;
    };
    applicants?: {
      __typename: "ModelJobApplicationConnection";
      items: Array<{
        __typename: "JobApplication";
        id: string;
        job: {
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        };
        applicant: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        message?: string | null;
        status: ApplicationStatus;
        createdAt: string;
        updatedAt: string;
        userJobApplicationsUserID?: string | null;
        jobApplicantsId?: string | null;
        jobApplicationJobId: string;
        jobApplicationApplicantUserID: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    jobStatus: JobStatus;
    latitude?: number | null;
    longitude?: number | null;
    geoHash?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    userPostedJobsUserID?: string | null;
    jobPosterUserID: string;
    jobCategoryId: string;
    owner?: string | null;
  } | null;
};

export type OnCreateJobCategorySubscriptionVariables = {
  filter?: ModelSubscriptionJobCategoryFilterInput | null;
};

export type OnCreateJobCategorySubscription = {
  onCreateJobCategory?: {
    __typename: "JobCategory";
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateJobCategorySubscriptionVariables = {
  filter?: ModelSubscriptionJobCategoryFilterInput | null;
};

export type OnUpdateJobCategorySubscription = {
  onUpdateJobCategory?: {
    __typename: "JobCategory";
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteJobCategorySubscriptionVariables = {
  filter?: ModelSubscriptionJobCategoryFilterInput | null;
};

export type OnDeleteJobCategorySubscription = {
  onDeleteJobCategory?: {
    __typename: "JobCategory";
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateJobApplicationSubscriptionVariables = {
  filter?: ModelSubscriptionJobApplicationFilterInput | null;
  owner?: string | null;
};

export type OnCreateJobApplicationSubscription = {
  onCreateJobApplication?: {
    __typename: "JobApplication";
    id: string;
    job: {
      __typename: "Job";
      id: string;
      poster: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      rateType: JobRateType;
      compensation?: number | null;
      title?: string | null;
      description?: string | null;
      locationAddress: string;
      locationCity: string;
      locationState: string;
      locationZip: string;
      locationCountry?: string | null;
      category: {
        __typename: "JobCategory";
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
      };
      applicants?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobStatus: JobStatus;
      latitude?: number | null;
      longitude?: number | null;
      geoHash?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      userPostedJobsUserID?: string | null;
      jobPosterUserID: string;
      jobCategoryId: string;
      owner?: string | null;
    };
    applicant: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    message?: string | null;
    status: ApplicationStatus;
    createdAt: string;
    updatedAt: string;
    userJobApplicationsUserID?: string | null;
    jobApplicantsId?: string | null;
    jobApplicationJobId: string;
    jobApplicationApplicantUserID: string;
    owner?: string | null;
  } | null;
};

export type OnUpdateJobApplicationSubscriptionVariables = {
  filter?: ModelSubscriptionJobApplicationFilterInput | null;
  owner?: string | null;
};

export type OnUpdateJobApplicationSubscription = {
  onUpdateJobApplication?: {
    __typename: "JobApplication";
    id: string;
    job: {
      __typename: "Job";
      id: string;
      poster: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      rateType: JobRateType;
      compensation?: number | null;
      title?: string | null;
      description?: string | null;
      locationAddress: string;
      locationCity: string;
      locationState: string;
      locationZip: string;
      locationCountry?: string | null;
      category: {
        __typename: "JobCategory";
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
      };
      applicants?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobStatus: JobStatus;
      latitude?: number | null;
      longitude?: number | null;
      geoHash?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      userPostedJobsUserID?: string | null;
      jobPosterUserID: string;
      jobCategoryId: string;
      owner?: string | null;
    };
    applicant: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    message?: string | null;
    status: ApplicationStatus;
    createdAt: string;
    updatedAt: string;
    userJobApplicationsUserID?: string | null;
    jobApplicantsId?: string | null;
    jobApplicationJobId: string;
    jobApplicationApplicantUserID: string;
    owner?: string | null;
  } | null;
};

export type OnDeleteJobApplicationSubscriptionVariables = {
  filter?: ModelSubscriptionJobApplicationFilterInput | null;
  owner?: string | null;
};

export type OnDeleteJobApplicationSubscription = {
  onDeleteJobApplication?: {
    __typename: "JobApplication";
    id: string;
    job: {
      __typename: "Job";
      id: string;
      poster: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      rateType: JobRateType;
      compensation?: number | null;
      title?: string | null;
      description?: string | null;
      locationAddress: string;
      locationCity: string;
      locationState: string;
      locationZip: string;
      locationCountry?: string | null;
      category: {
        __typename: "JobCategory";
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
      };
      applicants?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobStatus: JobStatus;
      latitude?: number | null;
      longitude?: number | null;
      geoHash?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      userPostedJobsUserID?: string | null;
      jobPosterUserID: string;
      jobCategoryId: string;
      owner?: string | null;
    };
    applicant: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    message?: string | null;
    status: ApplicationStatus;
    createdAt: string;
    updatedAt: string;
    userJobApplicationsUserID?: string | null;
    jobApplicantsId?: string | null;
    jobApplicationJobId: string;
    jobApplicationApplicantUserID: string;
    owner?: string | null;
  } | null;
};

export type OnCreateCouponSubscriptionVariables = {
  filter?: ModelSubscriptionCouponFilterInput | null;
  owner?: string | null;
};

export type OnCreateCouponSubscription = {
  onCreateCoupon?: {
    __typename: "Coupon";
    id: string;
    couponCode?: string | null;
    isActive?: boolean | null;
    amount?: number | null;
    percentage?: number | null;
    type: couponCodeType;
    description?: string | null;
    expiryDate?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type OnUpdateCouponSubscriptionVariables = {
  filter?: ModelSubscriptionCouponFilterInput | null;
  owner?: string | null;
};

export type OnUpdateCouponSubscription = {
  onUpdateCoupon?: {
    __typename: "Coupon";
    id: string;
    couponCode?: string | null;
    isActive?: boolean | null;
    amount?: number | null;
    percentage?: number | null;
    type: couponCodeType;
    description?: string | null;
    expiryDate?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type OnDeleteCouponSubscriptionVariables = {
  filter?: ModelSubscriptionCouponFilterInput | null;
  owner?: string | null;
};

export type OnDeleteCouponSubscription = {
  onDeleteCoupon?: {
    __typename: "Coupon";
    id: string;
    couponCode?: string | null;
    isActive?: boolean | null;
    amount?: number | null;
    percentage?: number | null;
    type: couponCodeType;
    description?: string | null;
    expiryDate?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type OnCreateUserReviewSubscriptionVariables = {
  filter?: ModelSubscriptionUserReviewFilterInput | null;
  owner?: string | null;
};

export type OnCreateUserReviewSubscription = {
  onCreateUserReview?: {
    __typename: "UserReview";
    id: string;
    forUser: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    fromUser: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    rating: number;
    message?: string | null;
    createdAt: string;
    updatedAt: string;
    userReceivedReviewsUserID?: string | null;
    userSentReviewsUserID?: string | null;
    userReviewForUserUserID: string;
    userReviewFromUserUserID: string;
    owner?: string | null;
  } | null;
};

export type OnUpdateUserReviewSubscriptionVariables = {
  filter?: ModelSubscriptionUserReviewFilterInput | null;
  owner?: string | null;
};

export type OnUpdateUserReviewSubscription = {
  onUpdateUserReview?: {
    __typename: "UserReview";
    id: string;
    forUser: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    fromUser: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    rating: number;
    message?: string | null;
    createdAt: string;
    updatedAt: string;
    userReceivedReviewsUserID?: string | null;
    userSentReviewsUserID?: string | null;
    userReviewForUserUserID: string;
    userReviewFromUserUserID: string;
    owner?: string | null;
  } | null;
};

export type OnDeleteUserReviewSubscriptionVariables = {
  filter?: ModelSubscriptionUserReviewFilterInput | null;
  owner?: string | null;
};

export type OnDeleteUserReviewSubscription = {
  onDeleteUserReview?: {
    __typename: "UserReview";
    id: string;
    forUser: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    fromUser: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    rating: number;
    message?: string | null;
    createdAt: string;
    updatedAt: string;
    userReceivedReviewsUserID?: string | null;
    userSentReviewsUserID?: string | null;
    userReviewForUserUserID: string;
    userReviewFromUserUserID: string;
    owner?: string | null;
  } | null;
};

export type OnCreateJobMessageLogSubscriptionVariables = {
  filter?: ModelSubscriptionJobMessageLogFilterInput | null;
  owner?: string | null;
};

export type OnCreateJobMessageLogSubscription = {
  onCreateJobMessageLog?: {
    __typename: "JobMessageLog";
    logId: string;
    job: {
      __typename: "Job";
      id: string;
      poster: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      rateType: JobRateType;
      compensation?: number | null;
      title?: string | null;
      description?: string | null;
      locationAddress: string;
      locationCity: string;
      locationState: string;
      locationZip: string;
      locationCountry?: string | null;
      category: {
        __typename: "JobCategory";
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
      };
      applicants?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobStatus: JobStatus;
      latitude?: number | null;
      longitude?: number | null;
      geoHash?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      userPostedJobsUserID?: string | null;
      jobPosterUserID: string;
      jobCategoryId: string;
      owner?: string | null;
    };
    applicant: {
      __typename: "JobApplication";
      id: string;
      job: {
        __typename: "Job";
        id: string;
        poster: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rateType: JobRateType;
        compensation?: number | null;
        title?: string | null;
        description?: string | null;
        locationAddress: string;
        locationCity: string;
        locationState: string;
        locationZip: string;
        locationCountry?: string | null;
        category: {
          __typename: "JobCategory";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        };
        applicants?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        jobStatus: JobStatus;
        latitude?: number | null;
        longitude?: number | null;
        geoHash?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        userPostedJobsUserID?: string | null;
        jobPosterUserID: string;
        jobCategoryId: string;
        owner?: string | null;
      };
      applicant: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      message?: string | null;
      status: ApplicationStatus;
      createdAt: string;
      updatedAt: string;
      userJobApplicationsUserID?: string | null;
      jobApplicantsId?: string | null;
      jobApplicationJobId: string;
      jobApplicationApplicantUserID: string;
      owner?: string | null;
    };
    posterHash: string;
    applicantHash: string;
    messages?: {
      __typename: "ModelJobMessageConnection";
      items: Array<{
        __typename: "JobMessage";
        id: string;
        to: string;
        from: string;
        subject: string;
        body: string;
        messageLog: {
          __typename: "JobMessageLog";
          logId: string;
          posterHash: string;
          applicantHash: string;
          createdAt: string;
          updatedAt: string;
          jobMessageLogJobId: string;
          jobMessageLogApplicantId: string;
          owner?: string | null;
        };
        createdAt: string;
        updatedAt: string;
        jobMessageLogMessagesLogId?: string | null;
        jobMessageMessageLogLogId: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    jobMessageLogJobId: string;
    jobMessageLogApplicantId: string;
    owner?: string | null;
  } | null;
};

export type OnUpdateJobMessageLogSubscriptionVariables = {
  filter?: ModelSubscriptionJobMessageLogFilterInput | null;
  owner?: string | null;
};

export type OnUpdateJobMessageLogSubscription = {
  onUpdateJobMessageLog?: {
    __typename: "JobMessageLog";
    logId: string;
    job: {
      __typename: "Job";
      id: string;
      poster: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      rateType: JobRateType;
      compensation?: number | null;
      title?: string | null;
      description?: string | null;
      locationAddress: string;
      locationCity: string;
      locationState: string;
      locationZip: string;
      locationCountry?: string | null;
      category: {
        __typename: "JobCategory";
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
      };
      applicants?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobStatus: JobStatus;
      latitude?: number | null;
      longitude?: number | null;
      geoHash?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      userPostedJobsUserID?: string | null;
      jobPosterUserID: string;
      jobCategoryId: string;
      owner?: string | null;
    };
    applicant: {
      __typename: "JobApplication";
      id: string;
      job: {
        __typename: "Job";
        id: string;
        poster: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rateType: JobRateType;
        compensation?: number | null;
        title?: string | null;
        description?: string | null;
        locationAddress: string;
        locationCity: string;
        locationState: string;
        locationZip: string;
        locationCountry?: string | null;
        category: {
          __typename: "JobCategory";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        };
        applicants?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        jobStatus: JobStatus;
        latitude?: number | null;
        longitude?: number | null;
        geoHash?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        userPostedJobsUserID?: string | null;
        jobPosterUserID: string;
        jobCategoryId: string;
        owner?: string | null;
      };
      applicant: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      message?: string | null;
      status: ApplicationStatus;
      createdAt: string;
      updatedAt: string;
      userJobApplicationsUserID?: string | null;
      jobApplicantsId?: string | null;
      jobApplicationJobId: string;
      jobApplicationApplicantUserID: string;
      owner?: string | null;
    };
    posterHash: string;
    applicantHash: string;
    messages?: {
      __typename: "ModelJobMessageConnection";
      items: Array<{
        __typename: "JobMessage";
        id: string;
        to: string;
        from: string;
        subject: string;
        body: string;
        messageLog: {
          __typename: "JobMessageLog";
          logId: string;
          posterHash: string;
          applicantHash: string;
          createdAt: string;
          updatedAt: string;
          jobMessageLogJobId: string;
          jobMessageLogApplicantId: string;
          owner?: string | null;
        };
        createdAt: string;
        updatedAt: string;
        jobMessageLogMessagesLogId?: string | null;
        jobMessageMessageLogLogId: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    jobMessageLogJobId: string;
    jobMessageLogApplicantId: string;
    owner?: string | null;
  } | null;
};

export type OnDeleteJobMessageLogSubscriptionVariables = {
  filter?: ModelSubscriptionJobMessageLogFilterInput | null;
  owner?: string | null;
};

export type OnDeleteJobMessageLogSubscription = {
  onDeleteJobMessageLog?: {
    __typename: "JobMessageLog";
    logId: string;
    job: {
      __typename: "Job";
      id: string;
      poster: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      rateType: JobRateType;
      compensation?: number | null;
      title?: string | null;
      description?: string | null;
      locationAddress: string;
      locationCity: string;
      locationState: string;
      locationZip: string;
      locationCountry?: string | null;
      category: {
        __typename: "JobCategory";
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
      };
      applicants?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobStatus: JobStatus;
      latitude?: number | null;
      longitude?: number | null;
      geoHash?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      userPostedJobsUserID?: string | null;
      jobPosterUserID: string;
      jobCategoryId: string;
      owner?: string | null;
    };
    applicant: {
      __typename: "JobApplication";
      id: string;
      job: {
        __typename: "Job";
        id: string;
        poster: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rateType: JobRateType;
        compensation?: number | null;
        title?: string | null;
        description?: string | null;
        locationAddress: string;
        locationCity: string;
        locationState: string;
        locationZip: string;
        locationCountry?: string | null;
        category: {
          __typename: "JobCategory";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        };
        applicants?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        jobStatus: JobStatus;
        latitude?: number | null;
        longitude?: number | null;
        geoHash?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        userPostedJobsUserID?: string | null;
        jobPosterUserID: string;
        jobCategoryId: string;
        owner?: string | null;
      };
      applicant: {
        __typename: "User";
        userID: string;
        firstName: string;
        lastName: string;
        email: string;
        locationAddress?: string | null;
        locationCity?: string | null;
        locationState?: string | null;
        locationZip?: string | null;
        photoKey?: string | null;
        type: UserType;
        school?: string | null;
        biography?: string | null;
        workLocation?: string | null;
        candidateID?: string | null;
        languages?: string | null;
        skills?: string | null;
        stripeAccountID?: string | null;
        subscription?: SubscriptionStatus | null;
        receivedReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        sentReviews?: {
          __typename: "ModelUserReviewConnection";
          nextToken?: string | null;
        } | null;
        jobApplications?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        postedJobs?: {
          __typename: "ModelJobConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        name?: string | null;
        graduationYear?: string | null;
        emailNotificationEnabled?: boolean | null;
        fullName?: string | null;
        avgHourlyRate?: number | null;
        jobSpecilities?: {
          __typename: "ModelJobSpecilitiesConnection";
          nextToken?: string | null;
        } | null;
        status?: UserStatus | null;
        geoHash?: string | null;
        stripeCreatedAt?: string | null;
        bgCheckPayment?: {
          __typename: "BackgroundCheckPaymentStatus";
          id: string;
          payment: boolean;
          createdAt?: string | null;
          updatedAt: string;
          backgroundCheckPaymentStatusUserUserID: string;
        } | null;
        createdAt: string;
        updatedAt: string;
        userBgCheckPaymentId?: string | null;
      };
      message?: string | null;
      status: ApplicationStatus;
      createdAt: string;
      updatedAt: string;
      userJobApplicationsUserID?: string | null;
      jobApplicantsId?: string | null;
      jobApplicationJobId: string;
      jobApplicationApplicantUserID: string;
      owner?: string | null;
    };
    posterHash: string;
    applicantHash: string;
    messages?: {
      __typename: "ModelJobMessageConnection";
      items: Array<{
        __typename: "JobMessage";
        id: string;
        to: string;
        from: string;
        subject: string;
        body: string;
        messageLog: {
          __typename: "JobMessageLog";
          logId: string;
          posterHash: string;
          applicantHash: string;
          createdAt: string;
          updatedAt: string;
          jobMessageLogJobId: string;
          jobMessageLogApplicantId: string;
          owner?: string | null;
        };
        createdAt: string;
        updatedAt: string;
        jobMessageLogMessagesLogId?: string | null;
        jobMessageMessageLogLogId: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    jobMessageLogJobId: string;
    jobMessageLogApplicantId: string;
    owner?: string | null;
  } | null;
};

export type OnCreateJobMessageSubscriptionVariables = {
  filter?: ModelSubscriptionJobMessageFilterInput | null;
};

export type OnCreateJobMessageSubscription = {
  onCreateJobMessage?: {
    __typename: "JobMessage";
    id: string;
    to: string;
    from: string;
    subject: string;
    body: string;
    messageLog: {
      __typename: "JobMessageLog";
      logId: string;
      job: {
        __typename: "Job";
        id: string;
        poster: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rateType: JobRateType;
        compensation?: number | null;
        title?: string | null;
        description?: string | null;
        locationAddress: string;
        locationCity: string;
        locationState: string;
        locationZip: string;
        locationCountry?: string | null;
        category: {
          __typename: "JobCategory";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        };
        applicants?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        jobStatus: JobStatus;
        latitude?: number | null;
        longitude?: number | null;
        geoHash?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        userPostedJobsUserID?: string | null;
        jobPosterUserID: string;
        jobCategoryId: string;
        owner?: string | null;
      };
      applicant: {
        __typename: "JobApplication";
        id: string;
        job: {
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        };
        applicant: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        message?: string | null;
        status: ApplicationStatus;
        createdAt: string;
        updatedAt: string;
        userJobApplicationsUserID?: string | null;
        jobApplicantsId?: string | null;
        jobApplicationJobId: string;
        jobApplicationApplicantUserID: string;
        owner?: string | null;
      };
      posterHash: string;
      applicantHash: string;
      messages?: {
        __typename: "ModelJobMessageConnection";
        items: Array<{
          __typename: "JobMessage";
          id: string;
          to: string;
          from: string;
          subject: string;
          body: string;
          createdAt: string;
          updatedAt: string;
          jobMessageLogMessagesLogId?: string | null;
          jobMessageMessageLogLogId: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
      jobMessageLogJobId: string;
      jobMessageLogApplicantId: string;
      owner?: string | null;
    };
    createdAt: string;
    updatedAt: string;
    jobMessageLogMessagesLogId?: string | null;
    jobMessageMessageLogLogId: string;
  } | null;
};

export type OnUpdateJobMessageSubscriptionVariables = {
  filter?: ModelSubscriptionJobMessageFilterInput | null;
};

export type OnUpdateJobMessageSubscription = {
  onUpdateJobMessage?: {
    __typename: "JobMessage";
    id: string;
    to: string;
    from: string;
    subject: string;
    body: string;
    messageLog: {
      __typename: "JobMessageLog";
      logId: string;
      job: {
        __typename: "Job";
        id: string;
        poster: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rateType: JobRateType;
        compensation?: number | null;
        title?: string | null;
        description?: string | null;
        locationAddress: string;
        locationCity: string;
        locationState: string;
        locationZip: string;
        locationCountry?: string | null;
        category: {
          __typename: "JobCategory";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        };
        applicants?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        jobStatus: JobStatus;
        latitude?: number | null;
        longitude?: number | null;
        geoHash?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        userPostedJobsUserID?: string | null;
        jobPosterUserID: string;
        jobCategoryId: string;
        owner?: string | null;
      };
      applicant: {
        __typename: "JobApplication";
        id: string;
        job: {
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        };
        applicant: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        message?: string | null;
        status: ApplicationStatus;
        createdAt: string;
        updatedAt: string;
        userJobApplicationsUserID?: string | null;
        jobApplicantsId?: string | null;
        jobApplicationJobId: string;
        jobApplicationApplicantUserID: string;
        owner?: string | null;
      };
      posterHash: string;
      applicantHash: string;
      messages?: {
        __typename: "ModelJobMessageConnection";
        items: Array<{
          __typename: "JobMessage";
          id: string;
          to: string;
          from: string;
          subject: string;
          body: string;
          createdAt: string;
          updatedAt: string;
          jobMessageLogMessagesLogId?: string | null;
          jobMessageMessageLogLogId: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
      jobMessageLogJobId: string;
      jobMessageLogApplicantId: string;
      owner?: string | null;
    };
    createdAt: string;
    updatedAt: string;
    jobMessageLogMessagesLogId?: string | null;
    jobMessageMessageLogLogId: string;
  } | null;
};

export type OnDeleteJobMessageSubscriptionVariables = {
  filter?: ModelSubscriptionJobMessageFilterInput | null;
};

export type OnDeleteJobMessageSubscription = {
  onDeleteJobMessage?: {
    __typename: "JobMessage";
    id: string;
    to: string;
    from: string;
    subject: string;
    body: string;
    messageLog: {
      __typename: "JobMessageLog";
      logId: string;
      job: {
        __typename: "Job";
        id: string;
        poster: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        rateType: JobRateType;
        compensation?: number | null;
        title?: string | null;
        description?: string | null;
        locationAddress: string;
        locationCity: string;
        locationState: string;
        locationZip: string;
        locationCountry?: string | null;
        category: {
          __typename: "JobCategory";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        };
        applicants?: {
          __typename: "ModelJobApplicationConnection";
          nextToken?: string | null;
        } | null;
        jobStatus: JobStatus;
        latitude?: number | null;
        longitude?: number | null;
        geoHash?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        userPostedJobsUserID?: string | null;
        jobPosterUserID: string;
        jobCategoryId: string;
        owner?: string | null;
      };
      applicant: {
        __typename: "JobApplication";
        id: string;
        job: {
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        };
        applicant: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        message?: string | null;
        status: ApplicationStatus;
        createdAt: string;
        updatedAt: string;
        userJobApplicationsUserID?: string | null;
        jobApplicantsId?: string | null;
        jobApplicationJobId: string;
        jobApplicationApplicantUserID: string;
        owner?: string | null;
      };
      posterHash: string;
      applicantHash: string;
      messages?: {
        __typename: "ModelJobMessageConnection";
        items: Array<{
          __typename: "JobMessage";
          id: string;
          to: string;
          from: string;
          subject: string;
          body: string;
          createdAt: string;
          updatedAt: string;
          jobMessageLogMessagesLogId?: string | null;
          jobMessageMessageLogLogId: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
      jobMessageLogJobId: string;
      jobMessageLogApplicantId: string;
      owner?: string | null;
    };
    createdAt: string;
    updatedAt: string;
    jobMessageLogMessagesLogId?: string | null;
    jobMessageMessageLogLogId: string;
  } | null;
};

export type OnCreateFlaggedContentSubscriptionVariables = {
  filter?: ModelSubscriptionFlaggedContentFilterInput | null;
};

export type OnCreateFlaggedContentSubscription = {
  onCreateFlaggedContent?: {
    __typename: "FlaggedContent";
    contentId: string;
    flaggedUser: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    contentType: FlaggedContentType;
    flagReason: FlaggedReason;
    reportCount: number;
    createdAt: string;
    updatedAt: string;
    flaggedContentFlaggedUserUserID: string;
  } | null;
};

export type OnUpdateFlaggedContentSubscriptionVariables = {
  filter?: ModelSubscriptionFlaggedContentFilterInput | null;
};

export type OnUpdateFlaggedContentSubscription = {
  onUpdateFlaggedContent?: {
    __typename: "FlaggedContent";
    contentId: string;
    flaggedUser: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    contentType: FlaggedContentType;
    flagReason: FlaggedReason;
    reportCount: number;
    createdAt: string;
    updatedAt: string;
    flaggedContentFlaggedUserUserID: string;
  } | null;
};

export type OnDeleteFlaggedContentSubscriptionVariables = {
  filter?: ModelSubscriptionFlaggedContentFilterInput | null;
};

export type OnDeleteFlaggedContentSubscription = {
  onDeleteFlaggedContent?: {
    __typename: "FlaggedContent";
    contentId: string;
    flaggedUser: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    contentType: FlaggedContentType;
    flagReason: FlaggedReason;
    reportCount: number;
    createdAt: string;
    updatedAt: string;
    flaggedContentFlaggedUserUserID: string;
  } | null;
};

export type OnCreateSampleJobSubscriptionVariables = {
  filter?: ModelSubscriptionSampleJobFilterInput | null;
};

export type OnCreateSampleJobSubscription = {
  onCreateSampleJob?: {
    __typename: "SampleJob";
    id: string;
    rateType: JobRateType;
    compensation?: number | null;
    title?: string | null;
    description?: string | null;
    locationCity: string;
    locationState: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnUpdateSampleJobSubscriptionVariables = {
  filter?: ModelSubscriptionSampleJobFilterInput | null;
};

export type OnUpdateSampleJobSubscription = {
  onUpdateSampleJob?: {
    __typename: "SampleJob";
    id: string;
    rateType: JobRateType;
    compensation?: number | null;
    title?: string | null;
    description?: string | null;
    locationCity: string;
    locationState: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnDeleteSampleJobSubscriptionVariables = {
  filter?: ModelSubscriptionSampleJobFilterInput | null;
};

export type OnDeleteSampleJobSubscription = {
  onDeleteSampleJob?: {
    __typename: "SampleJob";
    id: string;
    rateType: JobRateType;
    compensation?: number | null;
    title?: string | null;
    description?: string | null;
    locationCity: string;
    locationState: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnCreateFAQSubscriptionVariables = {
  filter?: ModelSubscriptionFAQFilterInput | null;
};

export type OnCreateFAQSubscription = {
  onCreateFAQ?: {
    __typename: "FAQ";
    id: string;
    question: string;
    answer: string;
    order: number;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnUpdateFAQSubscriptionVariables = {
  filter?: ModelSubscriptionFAQFilterInput | null;
};

export type OnUpdateFAQSubscription = {
  onUpdateFAQ?: {
    __typename: "FAQ";
    id: string;
    question: string;
    answer: string;
    order: number;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnDeleteFAQSubscriptionVariables = {
  filter?: ModelSubscriptionFAQFilterInput | null;
};

export type OnDeleteFAQSubscription = {
  onDeleteFAQ?: {
    __typename: "FAQ";
    id: string;
    question: string;
    answer: string;
    order: number;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnCreateBackgroundCheckPaymentStatusSubscriptionVariables = {
  filter?: ModelSubscriptionBackgroundCheckPaymentStatusFilterInput | null;
};

export type OnCreateBackgroundCheckPaymentStatusSubscription = {
  onCreateBackgroundCheckPaymentStatus?: {
    __typename: "BackgroundCheckPaymentStatus";
    id: string;
    user: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    payment: boolean;
    createdAt?: string | null;
    updatedAt: string;
    backgroundCheckPaymentStatusUserUserID: string;
  } | null;
};

export type OnUpdateBackgroundCheckPaymentStatusSubscriptionVariables = {
  filter?: ModelSubscriptionBackgroundCheckPaymentStatusFilterInput | null;
};

export type OnUpdateBackgroundCheckPaymentStatusSubscription = {
  onUpdateBackgroundCheckPaymentStatus?: {
    __typename: "BackgroundCheckPaymentStatus";
    id: string;
    user: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    payment: boolean;
    createdAt?: string | null;
    updatedAt: string;
    backgroundCheckPaymentStatusUserUserID: string;
  } | null;
};

export type OnDeleteBackgroundCheckPaymentStatusSubscriptionVariables = {
  filter?: ModelSubscriptionBackgroundCheckPaymentStatusFilterInput | null;
};

export type OnDeleteBackgroundCheckPaymentStatusSubscription = {
  onDeleteBackgroundCheckPaymentStatus?: {
    __typename: "BackgroundCheckPaymentStatus";
    id: string;
    user: {
      __typename: "User";
      userID: string;
      firstName: string;
      lastName: string;
      email: string;
      locationAddress?: string | null;
      locationCity?: string | null;
      locationState?: string | null;
      locationZip?: string | null;
      photoKey?: string | null;
      type: UserType;
      school?: string | null;
      biography?: string | null;
      workLocation?: string | null;
      candidateID?: string | null;
      languages?: string | null;
      skills?: string | null;
      stripeAccountID?: string | null;
      subscription?: SubscriptionStatus | null;
      receivedReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sentReviews?: {
        __typename: "ModelUserReviewConnection";
        items: Array<{
          __typename: "UserReview";
          id: string;
          rating: number;
          message?: string | null;
          createdAt: string;
          updatedAt: string;
          userReceivedReviewsUserID?: string | null;
          userSentReviewsUserID?: string | null;
          userReviewForUserUserID: string;
          userReviewFromUserUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      jobApplications?: {
        __typename: "ModelJobApplicationConnection";
        items: Array<{
          __typename: "JobApplication";
          id: string;
          message?: string | null;
          status: ApplicationStatus;
          createdAt: string;
          updatedAt: string;
          userJobApplicationsUserID?: string | null;
          jobApplicantsId?: string | null;
          jobApplicationJobId: string;
          jobApplicationApplicantUserID: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      postedJobs?: {
        __typename: "ModelJobConnection";
        items: Array<{
          __typename: "Job";
          id: string;
          rateType: JobRateType;
          compensation?: number | null;
          title?: string | null;
          description?: string | null;
          locationAddress: string;
          locationCity: string;
          locationState: string;
          locationZip: string;
          locationCountry?: string | null;
          jobStatus: JobStatus;
          latitude?: number | null;
          longitude?: number | null;
          geoHash?: string | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          userPostedJobsUserID?: string | null;
          jobPosterUserID: string;
          jobCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      name?: string | null;
      graduationYear?: string | null;
      emailNotificationEnabled?: boolean | null;
      fullName?: string | null;
      avgHourlyRate?: number | null;
      jobSpecilities?: {
        __typename: "ModelJobSpecilitiesConnection";
        items: Array<{
          __typename: "JobSpecilities";
          id: string;
          createdAt: string;
          updatedAt: string;
          userJobSpecilitiesUserID?: string | null;
          jobSpecilitiesUserUserID: string;
          jobSpecilitiesCategoryId: string;
          owner?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      status?: UserStatus | null;
      geoHash?: string | null;
      stripeCreatedAt?: string | null;
      bgCheckPayment?: {
        __typename: "BackgroundCheckPaymentStatus";
        id: string;
        user: {
          __typename: "User";
          userID: string;
          firstName: string;
          lastName: string;
          email: string;
          locationAddress?: string | null;
          locationCity?: string | null;
          locationState?: string | null;
          locationZip?: string | null;
          photoKey?: string | null;
          type: UserType;
          school?: string | null;
          biography?: string | null;
          workLocation?: string | null;
          candidateID?: string | null;
          languages?: string | null;
          skills?: string | null;
          stripeAccountID?: string | null;
          subscription?: SubscriptionStatus | null;
          owner?: string | null;
          name?: string | null;
          graduationYear?: string | null;
          emailNotificationEnabled?: boolean | null;
          fullName?: string | null;
          avgHourlyRate?: number | null;
          status?: UserStatus | null;
          geoHash?: string | null;
          stripeCreatedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          userBgCheckPaymentId?: string | null;
        };
        payment: boolean;
        createdAt?: string | null;
        updatedAt: string;
        backgroundCheckPaymentStatusUserUserID: string;
      } | null;
      createdAt: string;
      updatedAt: string;
      userBgCheckPaymentId?: string | null;
    };
    payment: boolean;
    createdAt?: string | null;
    updatedAt: string;
    backgroundCheckPaymentStatusUserUserID: string;
  } | null;
};

export type OnCreateBackgroundCheckLogSubscriptionVariables = {
  filter?: ModelSubscriptionBackgroundCheckLogFilterInput | null;
};

export type OnCreateBackgroundCheckLogSubscription = {
  onCreateBackgroundCheckLog?: {
    __typename: "BackgroundCheckLog";
    candidateID: string;
    userID?: string | null;
    invitationID?: string | null;
    reportID?: string | null;
    inviteStatus?: string | null;
    reportStatus?: string | null;
    reportResult?: string | null;
    reportAdjudication?: string | null;
    backgroundCheckCompletedAt?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnUpdateBackgroundCheckLogSubscriptionVariables = {
  filter?: ModelSubscriptionBackgroundCheckLogFilterInput | null;
};

export type OnUpdateBackgroundCheckLogSubscription = {
  onUpdateBackgroundCheckLog?: {
    __typename: "BackgroundCheckLog";
    candidateID: string;
    userID?: string | null;
    invitationID?: string | null;
    reportID?: string | null;
    inviteStatus?: string | null;
    reportStatus?: string | null;
    reportResult?: string | null;
    reportAdjudication?: string | null;
    backgroundCheckCompletedAt?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnDeleteBackgroundCheckLogSubscriptionVariables = {
  filter?: ModelSubscriptionBackgroundCheckLogFilterInput | null;
};

export type OnDeleteBackgroundCheckLogSubscription = {
  onDeleteBackgroundCheckLog?: {
    __typename: "BackgroundCheckLog";
    candidateID: string;
    userID?: string | null;
    invitationID?: string | null;
    reportID?: string | null;
    inviteStatus?: string | null;
    reportStatus?: string | null;
    reportResult?: string | null;
    reportAdjudication?: string | null;
    backgroundCheckCompletedAt?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnCreateSettingSubscriptionVariables = {
  filter?: ModelSubscriptionSettingFilterInput | null;
};

export type OnCreateSettingSubscription = {
  onCreateSetting?: {
    __typename: "Setting";
    id: string;
    feature: string;
    active: boolean;
    createdAt?: string | null;
    updatedAt: string;
  } | null;
};

export type OnUpdateSettingSubscriptionVariables = {
  filter?: ModelSubscriptionSettingFilterInput | null;
};

export type OnUpdateSettingSubscription = {
  onUpdateSetting?: {
    __typename: "Setting";
    id: string;
    feature: string;
    active: boolean;
    createdAt?: string | null;
    updatedAt: string;
  } | null;
};

export type OnDeleteSettingSubscriptionVariables = {
  filter?: ModelSubscriptionSettingFilterInput | null;
};

export type OnDeleteSettingSubscription = {
  onDeleteSetting?: {
    __typename: "Setting";
    id: string;
    feature: string;
    active: boolean;
    createdAt?: string | null;
    updatedAt: string;
  } | null;
};
