import { Auth } from "aws-amplify";
import {
  ApplicationStatus,
  CreateFlaggedContentInput,
  CreateFlaggedContentMutation,
  CreateJobApplicationInput,
  CreateJobApplicationMutation,
  CreateJobCategoryInput,
  CreateJobCategoryMutation,
  CreateJobInput,
  CreateJobMutation,
  CreateUserReviewInput,
  CreateUserReviewMutation,
  DeleteFlaggedContentInput,
  DeleteFlaggedContentMutation,
  DeleteJobApplicationInput,
  DeleteJobApplicationMutation,
  DeleteJobInput,
  DeleteJobMutation,
  DeleteUserInput,
  DeleteUserMutation,
  DeleteUserReviewInput,
  FAQ,
  FlaggedContent,
  FlaggedContentType,
  FlaggedReason,
  GetFlaggedContentQuery,
  GetFlaggedContentQueryVariables,
  GetJobMessageLogQuery,
  GetJobQuery,
  GetUserQuery,
  GetUserReviewQuery,
  GetUserReviewQueryVariables,
  Job,
  JobApplication,
  JobCategory,
  JobMessageLog,
  JobStatus,
  ListFAQSQuery,
  ListFlaggedContentsQuery,
  ListFlaggedContentsQueryVariables,
  ListJobApplicationsQuery,
  ListJobApplicationsQueryVariables,
  ListJobCategoriesQuery,
  ListJobsQuery,
  ListJobsQueryVariables,
  ListSampleJobsQuery,
  ListUserReviewsQuery,
  ListUserReviewsQueryVariables,
  ListUsersQuery,
  ModelJobApplicationFilterInput,
  ModelJobFilterInput,
  ModelUserReviewFilterInput,
  SampleJob,
  UpdateFlaggedContentInput,
  UpdateFlaggedContentMutation,
  UpdateJobApplicationInput,
  UpdateJobApplicationMutation,
  UpdateJobInput,
  UpdateJobMutation,
  UpdateUserInput,
  UpdateUserMutation,
  UpdateUserReviewInput,
  UpdateJobSpecilitiesInput,
  UpdateUserReviewMutation,
  User,
  UserReview,
  UserType,
  ModelUserFilterInput,
  CreateJobSpecilitiesInput,
  CreateJobSpecilitiesMutation,
  GetJobSpecilitiesQuery,
  DeleteJobSpecilitiesMutation,
  DeleteJobSpecilitiesInput,
  JobSpecilities,
  ModelJobSpecilitiesFilterInput,
  ListJobSpecilitiesQuery,
  GetUserListQuery,
  UserStatus,
  CreateCouponInput,
  CreateCouponMutation,
  ListCouponsQuery,
  DeleteCouponInput,
  DeleteCouponMutation,
  UpdateCouponInput,
  UpdateCouponMutation,
  Coupon,
  GetCouponQuery,
  CreateJobMessageInput,
  ListJobMessagesQuery,
  ModelCouponFilterInput,
} from "../API";
import GraphQLAPI, { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql";
import { ListJobsObjectType, mutations, queries } from ".";
import { currentUserIsAdmin } from "../helpers/AuthStatus";
import * as geoSpacial from "../helpers/GeoSpacial";
import * as adminDataProvider from "../graphql/AdminDataProvider";
import * as stripeHandler from "../graphql/StripeHandler";
import { toast } from "react-toastify";

type SetIsLoadingFunction = (loading: boolean) => void;
/// Fetches the current user object for the signed in user.
export async function getCurrentUser(): Promise<User | null> {
  const signedInUser = await Auth.currentUserInfo();
  try {
    if (!signedInUser) {
      return null;
    }
    const userSub = signedInUser.attributes?.sub;
    if (!userSub) return null;

    const foundUser = (await GraphQLAPI.graphql({
      query: queries.getUser,
      variables: { userID: userSub },
    })) as { data: GetUserQuery };
    return foundUser.data?.getUser as User;
  } catch (err) {
    console.error("Error getting Current User:", err);
    return null;
  }
}

/// Fetches a user object for the provided userID string.
export async function getUser(
  userID: string | null | undefined
): Promise<User | null> {
  if (!userID) return null;
  const signedInUser = await Auth.currentUserInfo();
  try {
    if (!signedInUser) {
      return null;
    }

    const foundUser = (await GraphQLAPI.graphql({
      query: queries.getUser,
      variables: { userID: userID },
    })) as { data: GetUserQuery };
    return foundUser.data?.getUser as User;
  } catch (err) {
    console.error("Error getting User:", err);
    return null;
  }
}

/// Gets all users of the system.
/// Note: This can only be performed by an Admin.
/// @param nextToken The next token to use for pagination.
/// @param limit The number of records to return.
export async function getAllUsers(
  nextToken: string | null | undefined,
  limit: number | null,
  filters: {
    fullName?: string | null;
    type?: UserType | null;
    email?: string | null;
    userID?: string | null;
    minimumAvgHourlyRate?: number;
    maximumAvgHourlyRate?: number;
    location?: {
      city?: string | null;
      state?: string | null;
      address?: string | null;
    };
    // Add other filter fields here if needed
  }
): Promise<ListUsersQuery | null> {
  const signedInUser = await Auth.currentUserInfo();
  try {
    const filter: ModelUserFilterInput = {};

    if (filters.fullName) {
      filter.fullName = { contains: filters.fullName.toLowerCase() };
      nextToken = null;
      limit = null;
    }

    if (filters.email) {
      filter.email = { contains: filters.email.toLowerCase() };
      nextToken = null;
      limit = null;
    }

    if (filters.type) {
      filter.type = { eq: filters.type };
      nextToken = null;
      // limit = 100;
    }
    if (
      (typeof filters.minimumAvgHourlyRate === "number" &&
        filters.minimumAvgHourlyRate >= 0) ||
      (typeof filters.maximumAvgHourlyRate === "number" &&
        filters.maximumAvgHourlyRate <= 0)
    ) {
      filter.avgHourlyRate = {
        between: [
          Number(filters.minimumAvgHourlyRate),
          Number(filters.maximumAvgHourlyRate),
        ],
      };
      nextToken = null;
      limit = null;
    }
    if (filters.userID) {
      const userIds = Array.isArray(filters.userID)
        ? filters.userID
        : [filters.userID];
      const userIdConditions = userIds.map((id) => ({ userID: { eq: id } }));
      filter.or = userIdConditions;
      nextToken = null;
      limit = null;
    }
    // status
    filter.status = { ne: UserStatus.ADMIN };

    // Add location filters

    // Add location filters
    if (filters.location) {
      const locationConditions = [];

      if (filters.location.address) {
        locationConditions.push({
          locationAddress: { eq: filters.location.address.toLowerCase() },
        });
        limit = 10000;
      }

      if (filters.location.city) {
        // Update to use exact match (eq) for city
        locationConditions.push({
          locationCity: { eq: filters.location.city.toLowerCase() },
        });
        limit = 10000;
      }

      if (filters.location.state) {
        // Update to use exact match (eq) for state
        locationConditions.push({
          locationState: { eq: filters.location.state.toLowerCase() },
        });
        limit = 10000; // Update limit if needed
      }
      if (locationConditions.length > 0) {
        filter.and = [...(filter.or || []), ...locationConditions];
      }

      nextToken = null;
      limit = null;
    }

    const results = (await GraphQLAPI.graphql({
      query: queries.listUsers,
      authMode: signedInUser
        ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        : GRAPHQL_AUTH_MODE.API_KEY,
      variables: { limit: limit, nextToken: nextToken, filter: filter },
    })) as { data: ListUsersQuery };

    return results.data;
  } catch (err) {
    console.error("Error getting Users:", err);
    return null;
  }
}
export async function getFilterCatergeryAndlocation(
  nextToken: string | null | undefined,
  limit: number | null,
  filters: {
    fullName?: string | null;
    type?: UserType | null;
    email?: string | null;
    userID?: string | null;
    minimumAvgHourlyRate?: number | null;
    maximumAvgHourlyRate?: number | null;
    location?: {
      address?: string | null;
      city?: string | null;
      state?: string | null;
    };
  }
): Promise<ListUsersQuery | null> {
  const signedInUser = await Auth.currentUserInfo();
  try {
    const filter: ModelUserFilterInput = {};

    if (filters.fullName) {
      filter.fullName = { contains: filters.fullName.toLowerCase() };
    }

    if (filters.email) {
      filter.email = { contains: filters.email.toLowerCase() };
    }

    if (filters.type) {
      filter.type = { eq: filters.type };
    }

    if (filters.minimumAvgHourlyRate && filters.maximumAvgHourlyRate) {
      filter.avgHourlyRate = {
        between: [
          Number(filters.minimumAvgHourlyRate),
          Number(filters.maximumAvgHourlyRate),
        ],
      };
    }

    // if (filters.userID) {
    //   const userIds = Array.isArray(filters.userID) ? filters.userID : [filters.userID];
    //   const userIdConditions = userIds.map((id) => ({ userID: { eq: id } }));
    //   filter.or = userIdConditions;
    // }

    // Add location filters
    if (filters.location) {
      const locationConditions = [];

      if (filters.location.address) {
        locationConditions.push({
          locationAddress: { contains: filters.location.address.toLowerCase() },
        });
      }

      if (filters.location.city) {
        locationConditions.push({
          locationCity: { contains: filters.location.city.toLowerCase() },
        });
      }

      if (filters.location.state) {
        locationConditions.push({
          locationState: { contains: filters.location.state.toLowerCase() },
        });
      }

      // Combine location conditions with OR
      // if (locationConditions.length > 0) {
      //   filter.or = [...(filter.or || []), ...locationConditions];
      // }
    }

    // Apply "and" conditions
    const andConditions = [];

    // Check if locationCity is provided
    if (filters.location?.city) {
      andConditions.push({
        or: [
          {
            locationCity: { contains: filters.location.city.toLowerCase() },
          },
        ],
      });
    }
    // Check if locationCity is provided
    if (filters.location?.state) {
      andConditions.push({
        or: [
          {
            locationState: { contains: filters.location.state.toLowerCase() },
          },
        ],
      });
    }
    // Check if userID is provided
    if (filters.userID) {
      andConditions.push({
        or: [
          {
            userID: { eq: filters.userID },
          },
        ],
      });
    }

    // Add "and" conditions to the filter
    if (andConditions.length > 0) {
      filter.and = andConditions;
    }

    const results = (await GraphQLAPI.graphql({
      query: queries.listUsers,
      authMode: signedInUser
        ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        : GRAPHQL_AUTH_MODE.API_KEY,
      variables: { limit: limit, nextToken: nextToken, filter: filter },
    })) as { data: ListUsersQuery };

    return results.data;
  } catch (err) {
    console.error("Error getting Users:", err);
    return null;
  }
}

export async function updateUserProfile(
  userData: UpdateUserInput
): Promise<User | null> {
  if (!userData) return null;
  const currentUser = await Auth.currentUserInfo();
  if (!currentUser) {
    return null;
  }
  try {
    const response = (await GraphQLAPI.graphql({
      query: mutations.updateUser,
      variables: { input: userData },
    })) as { data: UpdateUserMutation };
    return response.data?.updateUser as User;
  } catch (error) {
    console.error("Error Updating User: ", error);
    return null;
  }
}

/// Updates a User's Stripe Account ID. (Students Only)
export async function updateUserStripeAccountId(
  user: User | null | undefined,
  stripeAccountId: string
): Promise<User | null> {
  if (!user || !stripeAccountId) return null;
  try {
    const userData: UpdateUserInput = {
      userID: user.userID,
      stripeAccountID: stripeAccountId,
      stripeCreatedAt: new Date().toISOString(),
    };
    const response = (await GraphQLAPI.graphql({
      query: mutations.updateUser,
      variables: { input: userData },
    })) as { data: UpdateUserMutation };
    return response.data?.updateUser as User;
  } catch (error) {
    console.error("Error Updating Stripe: ", error);
    return null;
  }
}

/// Determines if the Current User has an "OPEN" Job or Application with the User provided.
/// Open means "Pending", "In Progress", etc. It does not mean "Completed" or "Denied".
/// Returns the JobApplication and Job if found, otherwise an empty tuple.
export async function openJobOrApplicationWithUser(
  otherUser: User | null | undefined
): Promise<[JobApplication, Job] | []> {
  if (!otherUser) return [];
  const currentUser = await getCurrentUser();
  // If we don't have a user or the current user is a Poster themselves, return.
  if (!currentUser) {
    return [];
  }

  // If we're the same user, exit.
  if (currentUser.userID === otherUser.userID) return [];

  // Fetch the user again to make sure we have all the fields.
  const fetchedOtherUser = await getUser(otherUser.userID);
  if (!fetchedOtherUser) return [];

  // Get the other user's job postings and applications.
  const [postedJobs, jobApplications] = await getJobsAndApplicationsForUser(
    fetchedOtherUser
  );
  if (fetchedOtherUser.type === UserType.POSTER) {
    // Loop through all of the Poster's jobs.
    if (!postedJobs) return [];
    for (const job of postedJobs as Job[]) {
      if (!job.applicants?.items || job.jobStatus === JobStatus.CLOSED)
        continue;
      for (const application of job.applicants?.items) {
        if (application?.jobApplicationApplicantUserID === currentUser.userID) {
          return [application, job];
        }
      }
    }
  } else if (fetchedOtherUser.type === UserType.STUDENT) {
    // Loop through all of the Student's jobs.
    if (!jobApplications) return [];
    for (const application of jobApplications as JobApplication[]) {
      if (
        application.status === ApplicationStatus.DENIED ||
        application.status === ApplicationStatus.COMPLETE ||
        application.job.jobStatus === JobStatus.CLOSED
      ) {
        continue;
      }
      if (application.job.poster.userID === currentUser.userID) {
        return [application, application.job];
      }
    }
  }
  // Nothing found.
  return [];
}

/// Determines if the current user has an active "In Progress" or "Closed" job
/// with the user provided.
export async function hasActiveJobOrApplicationWithUser(
  otherUser: User | null | undefined
): Promise<[JobApplication, Job] | []> {
  if (!otherUser) return [];
  const currentUser = await getCurrentUser();
  // If we don't have a user or the current user is a Poster themselves, return.
  if (!currentUser || otherUser.userID === currentUser.userID) {
    return [];
  }

  if (otherUser.type === UserType.POSTER) {
    // Loop through all of the Poster's jobs.
    if (!otherUser?.postedJobs?.items) return [];
    for (const job of otherUser?.postedJobs?.items as Job[]) {
      // Fetch job because it's likely missing data due to graphQL depth limits.
      const fetchedJob = await getJob(job.id);
      if (!fetchedJob?.applicants?.items) continue; // No one applied.
      for (const application of fetchedJob?.applicants?.items) {
        if (
          application?.applicant.userID === currentUser.userID &&
          (fetchedJob.jobStatus === JobStatus.IN_PROGRESS ||
            fetchedJob.jobStatus === JobStatus.CLOSED)
        ) {
          return [application, fetchedJob];
        }
      }
    }
  } else if (otherUser.type === UserType.STUDENT) {
    // Loop through all of the Student's jobs.
    if (!otherUser?.jobApplications?.items) return [];
    for (const application of otherUser?.jobApplications
      ?.items as JobApplication[]) {
      if (
        application.status === ApplicationStatus.DENIED ||
        application.status === ApplicationStatus.PENDING
      ) {
        continue;
      }
      if (application.job.jobPosterUserID === currentUser.userID) {
        return [application, application.job];
      }
    }
  }
  // Nothing found.
  return [];
}

/// Returns the MessageLog for the Job and Application provided.
/// If the Job or Application is closed, it will not return a message log.
/// This is to prevent users from communicating outside the platform for future
/// jobs.
export async function getMessageLogForJob(
  job: Job | undefined | null,
  application: any | undefined
): Promise<JobMessageLog | null> {
  if (!job || !application) return null;
  const currentUser = await getCurrentUser();

  // If the job or application is closed, don't return a message log.
  // Note: Remove this if users should communicate for all job statuses.
  if (
    job.jobStatus === JobStatus.CLOSED ||
    application.status === ApplicationStatus.DENIED ||
    application.status === ApplicationStatus.COMPLETE
  ) {
    return null;
  }
  // Create the ID, which is composed with JobID and ApplicationID.
  const messageLogId = job.id + "-" + application.id;
  try {
    const result = (await GraphQLAPI.graphql({
      query: queries.getJobMessageLog,
      variables: { logId: messageLogId },
    })) as { data: GetJobMessageLogQuery };
    return result.data?.getJobMessageLog as JobMessageLog;
  } catch (err) {
    console.error("Error getMessageLogForJob:", err);
    return null;
  }
}
export async function getMessageJob(
  logId: string | null | undefined
): Promise<ListJobMessagesQuery | null> {
  const currentUser = await getCurrentUser();
  if (!currentUser) {
    return null;
  }
  // If the job or application is closed, don't return a message log.
  // Note: Remove this if users should communicate for all job statuses.

  // Create the ID, which is composed with JobID and ApplicationID.
  try {
    const filter = {
      jobMessageLogMessagesLogId: {
        eq: logId, // Replace with the actual value you want to compare against
      },
    };

    const result = (await GraphQLAPI.graphql({
      query: queries.listJobMessages,
      variables: { filter },
    })) as { data: ListJobMessagesQuery };

    return result.data?.listJobMessages?.items as ListJobMessagesQuery;
  } catch (err) {
    console.error("Error getMessageJob:", err);
    return null;
  }
}

export async function createMessageLogForJob(
  job: Job | undefined | null,
  application: JobApplication | undefined | null
): Promise<JobMessageLog | null> {
  if (!job || !application) return null;

  const currentUser = await getCurrentUser();
  if (!currentUser) {
    return null;
  }

  // If the job or application is closed, don't return a message log.
  // Note: Remove this if users should communicate for all job statuses.
  if (
    job.jobStatus === JobStatus.CLOSED ||
    application.status === ApplicationStatus.DENIED ||
    application.status === ApplicationStatus.COMPLETE
  ) {
    return null;
  }

  // Create the ID, which is composed with JobID and ApplicationID.
  const messageLogId = job.id + "-" + application.id;

  try {
    // Create a new message log without checking if it already exists
    const createLogResult = (await GraphQLAPI.graphql({
      query: mutations.createJobMessageLog,
      variables: {
        input: {
          logId: messageLogId,
          posterHash: job.id, // Replace with actual values or logic
          applicantHash: application.id, // Replace with actual values or logic
          jobMessageLogJobId: job.id,
          jobMessageLogApplicantId: application.id,
        },
      },
    })) as { data: { createJobMessageLog: JobMessageLog } };

    return createLogResult.data.createJobMessageLog;
  } catch (err) {
    console.error("Error creating Job Message Log:", err);
    return null;
  }
}
export async function createMessage(
  message: string,
  fromId: string,
  logId: string
): Promise<CreateJobMessageInput | null> {
  const currentUser = await getCurrentUser();
  if (!currentUser) {
    return null;
  }

  // If the job or application is closed, don't return a message log.
  // Note: Remove this if users should communicate for all job statuses.

  // Create the ID, which is composed with JobID and ApplicationID.

  try {
    // Create a new message log without checking if it already exists
    const createLogResult = (await GraphQLAPI.graphql({
      query: mutations.createJobMessage,
      variables: {
        input: {
          to: currentUser?.userID, // Replace with actual values or logic
          from: fromId, // Replace with actual values or logic
          subject: "test test", // Replace with actual values or logic
          body: message, // Replace with actual values or logic
          jobMessageLogMessagesLogId: logId, // Assuming 'logId' is the correct property in your schema
          jobMessageMessageLogLogId: logId, // Assuming 'logId' is the correct property in your schema
        },
      },
    })) as { data: { createJobMessage: CreateJobMessageInput } };

    return createLogResult.data.createJobMessage;
  } catch (err) {
    console.error("Error creating Job Message Log:", err);
    return null;
  }
}

/// Returns the correct contact email for the user and message log provided.
export function userContactEmailForMessageLog(
  user: User | null | undefined,
  messageLog: JobMessageLog | null | undefined
): string | null | undefined {
  if (!user || !messageLog) return null;

  // Build the email address.
  let emailDomain = ""; // Default
  let postfix = ".colledge.us";
  switch (process.env.REACT_APP_ENV) {
    case "development":
      emailDomain = "@relay3" + postfix;
      break;
    case "production":
      emailDomain = "@relay" + postfix;
      break;
  }

  let email = "";
  if (user.type === UserType.POSTER) {
    email = messageLog.posterHash + emailDomain;
  } else if (user.type === UserType.STUDENT) {
    email = messageLog.applicantHash + emailDomain;
  }

  return email;
}

/// Determines if the user has applied to the jobID provided.
export async function userHasAppliedToJob(
  jobId: string | null | undefined
): Promise<boolean> {
  if (!jobId) return false;
  return await userStatusWithJob(jobId, null);
}

/// Determines if the user has applied and completed the job for the jobID provided.
export async function userHasCompletedJob(
  jobId: string | null | undefined
): Promise<boolean> {
  if (!jobId) return false;
  return await userStatusWithJob(jobId, ApplicationStatus.COMPLETE);
}

/// Determines the user's status for the given JobID.
/*
/// Query for the job application.
export type ModelJobApplicationFilterInput = {
  id?: ModelIDInput | null,
  message?: ModelStringInput | null,
  status?: ModelApplicationStatusInput | null,
  and?: Array< ModelJobApplicationFilterInput | null > | null,
  or?: Array< ModelJobApplicationFilterInput | null > | null,
  not?: ModelJobApplicationFilterInput | null,
  userJobApplicationsUserID?: ModelIDInput | null,
  jobApplicantsId?: ModelIDInput | null,
  jobApplicationJobId?: ModelIDInput | null,
  jobApplicationApplicantUserID?: ModelIDInput | null,
};
*/
export async function userStatusWithJob(
  jobId: string | null | undefined,
  status: ApplicationStatus | null | undefined
): Promise<boolean> {
  if (!jobId) return false;
  const currentUser = await getCurrentUser();
  try {
    if (!currentUser) {
      return false;
    }

    // Construct our query input object.
    let queryInput = {
      jobApplicationJobId: { eq: jobId },
      jobApplicationApplicantUserID: { eq: currentUser.userID },
    } as ModelJobApplicationFilterInput;
    if (status) {
      queryInput.and = [{ status: { eq: status } }];
    }
    const variables: ListJobApplicationsQueryVariables = {
      filter: queryInput,
    };
    const results = (await GraphQLAPI.graphql({
      query: queries.listJobApplications,
      variables: variables,
    })) as { data: ListJobApplicationsQuery };
    if (results.data.listJobApplications?.items) {
      return results.data.listJobApplications.items.length > 0;
    }
    return false;
  } catch (err) {
    console.error("Error getting categories:", err);
    return false;
  }
}

/// Gets a list of the FAQ.
export async function getFAQ(): Promise<FAQ[]> {
  const signedInUser = await Auth.currentUserInfo();
  try {
    const results = (await GraphQLAPI.graphql({
      query: queries.listFAQS,
      authMode: signedInUser
        ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        : GRAPHQL_AUTH_MODE.API_KEY,
    })) as { data: ListFAQSQuery };
    let faqArray = (results.data?.listFAQS?.items as FAQ[]) ?? [];
    return faqArray.sort((a: FAQ, b: FAQ) => a.order! - b.order!);
  } catch (err) {
    console.error("Error fetching FAQ:", err);
    return [];
  }
}

/// Fetches a list of all available categories for jobs.
export async function getJobCategories(): Promise<JobCategory[]> {
  const signedInUser = await Auth.currentUserInfo();
  try {
    const results = (await GraphQLAPI.graphql({
      query: queries.listJobCategories,
      authMode: signedInUser
        ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        : GRAPHQL_AUTH_MODE.API_KEY,
    })) as { data: ListJobCategoriesQuery };
    return (results.data?.listJobCategories?.items as JobCategory[]) ?? [];
  } catch (err) {
    console.error("Error fetching categories:", err);
    return [];
  }
}

/// Fetches the jobs and applications a user has.
export async function getJobsAndApplicationsForUser(
  user: User | null | undefined
): Promise<[Job[], JobApplication[]]> {
  if (!user) return [[], []];
  const currentUser = await getCurrentUser();
  try {
    if (!currentUser) {
      return [[], []];
    }

    const workerSearchVariables: ListJobsQueryVariables = {
      filter: {
        jobPosterUserID: { eq: user.userID },
      },
      limit: 1000,
    };
    const jobsResult = (await GraphQLAPI.graphql({
      query: queries.listJobs,
      variables: workerSearchVariables,
    })) as { data: ListJobsQuery };

    const studentSearchVariables: ListJobApplicationsQueryVariables = {
      filter: {
        jobApplicationApplicantUserID: { eq: user.userID },
      },
      limit: 1000,
    };
    const applicationsResult = (await GraphQLAPI.graphql({
      query: queries.listJobApplications,
      variables: studentSearchVariables,
    })) as { data: ListJobApplicationsQuery };

    return [
      (jobsResult.data?.listJobs?.items as any[]) ?? [],
      (applicationsResult.data?.listJobApplications?.items as any[]) ?? [],
    ];
  } catch (err) {
    console.error("Error fetching jobs & applications:", err);
    return [[], []];
  }
}

/// Gets sample jobs for the home page (signed out users).
export async function getSampleJobs(): Promise<SampleJob[] | undefined> {
  const currentUser = await Auth.currentUserInfo();
  try {
    const sampleJobs = (await GraphQLAPI.graphql({
      query: queries.listSampleJobs,
      authMode: currentUser
        ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        : GRAPHQL_AUTH_MODE.API_KEY,
    })) as { data: ListSampleJobsQuery };
    return (sampleJobs.data?.listSampleJobs?.items as SampleJob[]) ?? [];
  } catch (err) {
    console.error("Error fetching sample jobs:", err);
    return undefined;
  }
}

/// Gets available jobs with optional categoryId.
export async function getAvailableJobs(
  categoryId?: string | undefined | null,
  nextToken?: string | undefined | null,
  limit?: number | null
): Promise<ListJobsObjectType | undefined> {
  const currentUser = await getCurrentUser();
  try {
    if (!currentUser) {
      return undefined;
    }

    // Construct our query input object.
    let queryInput = {
      // Ensure only available jobs are returned.
      jobStatus: { eq: JobStatus.OPEN },
    } as ModelJobFilterInput;

    // If searching by Category, append it.
    if (categoryId?.length) {
      queryInput.jobCategoryId = { eq: categoryId };
    }
    const variables: ListJobsQueryVariables = {
      filter: queryInput,
      nextToken: nextToken,
      limit: limit,
    };
    const foundJobs = (await GraphQLAPI.graphql({
      query: queries.listJobs,
      variables: variables,
    })) as { data: ListJobsQuery };
    return foundJobs.data?.listJobs as ListJobsObjectType;
  } catch (err) {
    console.error("Error searching jobs:", err);
    return undefined;
  }
}

/// Creates a job with the given Job Inputs.
export async function createJob(input: CreateJobInput): Promise<Job | null> {
  if (!input) return null;
  const currentUser = await getCurrentUser();
  try {
    if (!currentUser) {
      return null;
    }

    // Save the job to the database.
    const response = (await GraphQLAPI.graphql({
      query: mutations.createJob,
      variables: { input: input },
    })) as { data: CreateJobMutation };
    return response.data.createJob as Job;
  } catch (err) {
    console.error("Error creating Job:", err);
    return null;
  }
}

/// Creates a job with the given Job Inputs.
export async function updateJob(input: UpdateJobInput): Promise<Job | null> {
  if (!input) return null;
  const currentUser = await getCurrentUser();
  try {
    if (!currentUser) {
      return null;
    }

    // Save the job to the database.
    const response = (await GraphQLAPI.graphql({
      query: mutations.updateJob,
      variables: { input: input },
    })) as { data: UpdateJobMutation };
    return response.data.updateJob as Job;
  } catch (err) {
    console.error("Error updating Job:", err);
    return null;
  }
}

/// Deletes a job with the ID provided. Will also delete associated
/// applications, if any.
export async function deleteJob(job: Job): Promise<boolean> {
  if (!job) return false;
  const currentUser = await getCurrentUser();
  try {
    if (!currentUser) {
      return false;
    }

    // Delete all the applications.
    for (const application of job.applicants?.items as JobApplication[]) {
      await deleteJobApplication(application.id);
    }

    // Delete the job.
    const input: DeleteJobInput = {
      id: job.id,
    };
    const response = (await GraphQLAPI.graphql({
      query: mutations.deleteJob,
      variables: input,
    })) as { data: DeleteJobMutation };
    return true;
  } catch (err) {
    console.error("Error deleting Job:", err);
    return false;
  }
}

/// Fetches a job from the ID provided. Null if not found.
export async function getJob(
  jobId: String | null | undefined
): Promise<Job | null> {
  if (!jobId) return null;
  const currentUser = await getCurrentUser();
  try {
    if (!currentUser) {
      return null;
    }
    const result = (await GraphQLAPI.graphql({
      query: queries.getJob,
      variables: { id: jobId },
    })) as { data: GetJobQuery };
    return result.data?.getJob as Job;
  } catch (err) {
    console.error("Error getting job:", err);
    return null;
  }
}

/// Fetches available jobs around a location.
/// @param categoryId The category ID to search for.
/// @param latitude The latitude of the location.
/// @param longitude The longitude of the location.
/// @param mileRadius The radius in miles to search.
/// @param nextToken The next token to use for pagination.
// export async function getAvailableJobsAroundLocation(
//   categoryId: string | undefined | null,
//   latitude: number | null,
//   longitude: number | null,
//   mileRadius: number,
//   nextToken: string | null | undefined,
//   limit?: number | null,
//   location?: {
//     city?: string | null;
//     state?: string | null;
//     address?: string | null;
//   }
// ): Promise<ListJobsObjectType | undefined> {
//   const signedInUser = await Auth.currentUserInfo();
//   try {
//     // Construct our query input object.
//     // Ensure only available jobs are returned.
//     // Show all the jobs and not just opened
//     // let queryInput = {
//     //   jobStatus: { eq: JobStatus.OPEN },
//     // } as ModelJobFilterInput;
//     let queryInput: ModelJobFilterInput = {};
//     let variables: ListJobsQueryVariables = {
//       filter: queryInput,
//       limit: limit,
//       nextToken: nextToken,
//     };

//     // If searching by Category, append it.
//     if (categoryId) {
//       queryInput.jobCategoryId = { eq: categoryId };
//       variables.limit = 1000;
//       variables.nextToken =null
//     }

//     //Or Searches...
//     if (latitude && longitude && mileRadius !== 0) {
//       let orArray = [];
//       // Get our geoHash bounding box.
//       const boxArray = geoSpacial.geoHashBoundingBoxFromCoordinates(
//         latitude,
//         longitude,
//         mileRadius
//       );
//       // Bounding box query.
//       orArray = boxArray.map((box) => ({ geoHash: { beginsWith: box } }));
//       queryInput.or = queryInput.or ? [...queryInput.or, ...orArray] : orArray;
//     }
//     if (location) {
//       const locationConditions = [];

//       if (location.address) {
//         locationConditions.push({ locationAddress: { eq: location.address } });
//       }

//       if (location.city) {
//         // Update to use exact match (eq) for city
//         locationConditions.push({ locationCity: { eq: location.city } });
//       }
//       if (locationConditions.length > 0) {
//         queryInput.or = queryInput.or
//           ? [...queryInput.or, ...locationConditions]
//           : locationConditions;
//       }

//     }
//     const foundJobs = (await GraphQLAPI.graphql({
//       query: queries.listJobs,
//       authMode: signedInUser
//         ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
//         : GRAPHQL_AUTH_MODE.API_KEY,
//       variables: variables,
//     })) as { data: ListJobsQuery };
//     return foundJobs.data?.listJobs as any;
//   } catch (err) {
//     console.error("Error Geo Search:", err);
//     return undefined;
//   }
// }

export async function getAvailableJobsAroundLocation(
  categoryId: string | undefined | null,
  latitude: number | null,
  longitude: number | null,
  mileRadius: number,
  nextToken: string | null | undefined,
  limit?: number | null,
  location?: {
    city?: string | null;
    state?: string | null;
    address?: string | null;
  }
): Promise<ListJobsObjectType | undefined> {
  const signedInUser = await Auth.currentUserInfo();
  try {
    // Construct our query input object.
    // Ensure only available jobs are returned.
    // Show all the jobs and not just opened
    // let queryInput = {
    //   jobStatus: { eq: JobStatus.OPEN },
    // } as ModelJobFilterInput;
    let queryInput: ModelJobFilterInput = {};
    let variables: ListJobsQueryVariables = {
      filter: queryInput,
      limit: limit,
      nextToken: nextToken,
    };

    // If searching by Category, append it.
    if (categoryId) {
      queryInput.jobCategoryId = { eq: categoryId };
      variables.limit = 1000;
      variables.nextToken = null;
    }

    //Or Searches...
    if (location) {
      const locationConditions = [];

      if (location.address) {
        locationConditions.push({ locationAddress: { eq: location.address } });
      }

      if (location.city) {
        // Update to use exact match (eq) for city
        locationConditions.push({ locationCity: { eq: location.city } });
      }
      if (location.state) {
        locationConditions.push({ locationState: { eq: location.state } });
      }

      if (locationConditions.length > 0) {
        queryInput.and = [...(queryInput.or || []), ...locationConditions];
      }
      nextToken = null;
      limit = null;
    } else if (latitude && longitude && mileRadius !== 0) {
      let orArray = [];
      // Get our geoHash bounding box.
      const boxArray = geoSpacial.geoHashBoundingBoxFromCoordinates(
        latitude,
        longitude,
        mileRadius
      );
      // Bounding box query.
      orArray = boxArray.map((box) => ({ geoHash: { beginsWith: box } }));
      queryInput.or = queryInput.or ? [...queryInput.or, ...orArray] : orArray;
    }
    const foundJobs = (await GraphQLAPI.graphql({
      query: queries.listJobs,
      authMode: signedInUser
        ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        : GRAPHQL_AUTH_MODE.API_KEY,
      variables: variables,
    })) as { data: ListJobsQuery };
    return foundJobs.data?.listJobs as any;
  } catch (err) {
    console.error("Error Geo Search:", err);
    return undefined;
  }
}

/// Creates a job application for the current signed in user.
export async function createJobApplication(message: string, job: Job) {
  if (!message || !job) return;
  let user = await getCurrentUser();
  try {
    if (!user) {
      return;
    }
    const application: CreateJobApplicationInput = {
      message: message,
      status: ApplicationStatus.PENDING,
      // Link UserIDs
      userJobApplicationsUserID: user?.userID,
      jobApplicationApplicantUserID: user?.userID,
      // Link JobIDs
      jobApplicantsId: job?.id,
      jobApplicationJobId: job?.id,
    };
    const response = (await GraphQLAPI.graphql({
      query: mutations.createJobApplication,
      variables: { input: application },
    })) as { data: CreateJobApplicationMutation };
  } catch (err) {
    console.error("Error creating Application:", err);
  }
}

/// Deletes a job application for the given ID.
export async function deleteJobApplication(
  applicationId: string
): Promise<boolean> {
  if (!applicationId) return false;
  const currentUser = await getCurrentUser();
  try {
    if (!currentUser) {
      return false;
    }

    const input: DeleteJobApplicationInput = {
      id: applicationId,
    };

    const response = (await GraphQLAPI.graphql({
      query: mutations.deleteJobApplication,
      variables: input,
    })) as { data: DeleteJobApplicationMutation };
    return true;
  } catch (err) {
    console.error("Error deleting Job Application:", err);
    return false;
  }
}

/// Returns a review for the ID provided.
export async function getReview(reviewId: string): Promise<UserReview | null> {
  if (!reviewId) return null;
  const currentUser = await getCurrentUser();
  try {
    if (!currentUser) {
      return null;
    }

    const variables = {
      id: reviewId,
    } as GetUserReviewQueryVariables;

    const result = (await GraphQLAPI.graphql({
      query: queries.getUserReview,
      variables: variables,
    })) as { data: GetUserReviewQuery };
    return result.data?.getUserReview as UserReview;
  } catch (err) {
    console.error("Error getting User Review:", err);
    return null;
  }
}

/// Gets a user review. Users can only leave one review per other User,
/// so this will return the review if they've left one in the past.
export async function getUserReviewForUser(
  user: User
): Promise<UserReview | null> {
  if (!user) return null;
  const currentUser = await getCurrentUser();
  try {
    if (!currentUser) {
      return null;
    }

    const variables = {
      filter: {
        userReceivedReviewsUserID: { eq: user.userID },
        userSentReviewsUserID: { eq: currentUser.userID },
      } as ModelUserReviewFilterInput,
    } as ListUserReviewsQueryVariables;

    const result = (await GraphQLAPI.graphql({
      query: queries.listUserReviews,
      variables: variables,
    })) as { data: ListUserReviewsQuery };
    if (!result.data?.listUserReviews?.items) return null;
    return result.data?.listUserReviews?.items[0] as UserReview;
  } catch (err) {
    console.error("Error getting User Review:", err);
    return null;
  }
}

/// Creates a written review with rating for the user provided.
/// Note: This assumes that the user being reviewed has had an
/// interaction with the reviewer. Users who have not been hired
/// or worked for one another should not be making reviews.
export async function createUserReview(
  rating: number,
  message: string,
  userReviewed: User
) {
  if (!message || !userReviewed) return;
  let user = await getCurrentUser();
  try {
    if (!user) {
      return;
    }
    const input: CreateUserReviewInput = {
      message: message,
      rating: rating,
      // For user.
      userReviewForUserUserID: userReviewed.userID,
      userReceivedReviewsUserID: userReviewed.userID,
      // From user.
      userReviewFromUserUserID: user.userID,
      userSentReviewsUserID: user.userID,
    };

    const response = (await GraphQLAPI.graphql({
      query: mutations.createUserReview,
      variables: { input: input },
    })) as { data: CreateUserReviewMutation };
  } catch (err) {
    console.error("Error creating User Review:", err);
  }
}

/// Updates a written review with rating for the reviewId provided.
export async function updateUserReview(
  reviewId: string,
  rating: number,
  message: string
) {
  if (!message || !reviewId) return;
  let user = await getCurrentUser();
  try {
    if (!user) {
      return;
    }
    const input: Partial<UpdateUserReviewInput> = {
      id: reviewId,
      message: message,
      rating: rating,
    };

    const response = (await GraphQLAPI.graphql({
      query: mutations.updateUserReview,
      variables: { input: input },
    })) as { data: UpdateUserReviewMutation };
  } catch (err) {
    console.error("Error updating User Review:", err);
  }
}

/// Deletes a user review for the ID provided.
export async function deleteUserReview(reviewId: string): Promise<boolean> {
  if (!reviewId) return false;
  let user = await getCurrentUser();
  try {
    if (!user) {
      return false;
    }
    const input: DeleteUserReviewInput = {
      id: reviewId,
    };

    const response = (await GraphQLAPI.graphql({
      query: mutations.deleteUserReview,
      variables: { input: input },
    })) as { data: DeleteUserReviewInput };
    return true;
  } catch (err) {
    console.error("Error deleting User Review:", err);
    return false;
  }
}

/// Updates the status of a job application for the current user.
export async function updateJobApplicationStatus(
  status: ApplicationStatus,
  application: JobApplication
) {
  if (!application || !status) return;
  let user = await getCurrentUser();
  try {
    if (!user) {
      return;
    }
    // If the applicant was selected, mark them as accepted,
    // otherwise mark as denied.
    const updatedApplication: Partial<UpdateJobApplicationInput> = {
      id: application.id,
      status: status,
    };
    const response = (await GraphQLAPI.graphql({
      query: mutations.updateJobApplication,
      variables: { input: updatedApplication },
    })) as { data: UpdateJobApplicationMutation };
  } catch (err) {
    console.error("Error updating Application:", err);
  }
}

/// Updates the job with the provided status for the current user.
export async function updateJobStatus(
  status: JobStatus | null,
  job: Job | null
) {
  if (!job || !status) return;
  let user = await getCurrentUser();
  try {
    if (!user) {
      return;
    }
    const updatedJob: Partial<UpdateJobInput> = {
      id: job.id,
      jobStatus: status,
    };
    const response = (await GraphQLAPI.graphql({
      query: mutations.updateJob,
      variables: { input: updatedJob },
    })) as { data: UpdateJobMutation };
  } catch (err) {
    console.error("Error updating Job:", err);
  }
}

/// Gets all content flags. This function can only be performed by an Admin.
export async function getAllContentFlags(
  nextToken: string | null | undefined,
  limit: number
): Promise<ListFlaggedContentsQuery | null> {
  const adminUser = await currentUserIsAdmin();
  const currentUser = await getCurrentUser();
  try {
    if (!adminUser) {
      return null;
    }

    if (!currentUser) {
      return null;
    }

    const variables = {
      limit: limit,
      nextToken: nextToken,
    } as ListFlaggedContentsQueryVariables;

    const result = (await GraphQLAPI.graphql({
      query: queries.listFlaggedContents,
      variables: variables,
    })) as { data: ListFlaggedContentsQuery };
    return result.data as ListFlaggedContentsQuery;
  } catch (err) {
    console.error("Error fetching Flagged Content:", err);
    return null;
  }
}

/// Sets or increments a content flag on the content provided.
/// This is the function to be called when a user flags content.
/// If the content flag already exists, it will increment it.
export async function setOrIncrementContentFlag(
  contentId: string | null | undefined,
  contentType: FlaggedContentType | null | undefined,
  flagReason: FlaggedReason | null | undefined,
  flaggedUser: User | null | undefined
): Promise<boolean> {
  if (!contentId || !contentType || !flagReason || !flaggedUser) return false;

  // First, try to increment.
  const existingFlagIncremented = await updateContentFlagCount(
    contentId,
    flagReason
  );
  if (existingFlagIncremented) {
    return true;
  }

  // Create a new flag.
  return await createContentFlag(
    contentId,
    contentType,
    flagReason,
    flaggedUser
  );
}

/// Deletes a content flag.
export async function deleteContentFlag(
  contentId: string,
  flagReason: FlaggedReason
): Promise<boolean> {
  if (!contentId || !flagReason) return false;
  const currentUser = await getCurrentUser();
  try {
    if (!currentUser) {
      return false;
    }

    // ContentID and FlagReason are required inputs to get
    // a single flag. This is because flagReason is the sortKey.
    const input = {
      contentId: contentId,
      flagReason: flagReason,
    } as DeleteFlaggedContentInput;

    const result = (await GraphQLAPI.graphql({
      query: mutations.deleteFlaggedContent,
      variables: { input: input },
    })) as { data: DeleteFlaggedContentMutation };
    return true;
  } catch (err) {
    console.error("Error deleting Flag:", err);
    return false;
  }
}

/// Creates a content flag for the inputs provided.
/// @param contentId The ID of the content being flagged.
/// @param flaggedUser The user who was flagged.
async function createContentFlag(
  contentId: string,
  contentType: FlaggedContentType,
  flagReason: FlaggedReason,
  flaggedUser: User
): Promise<boolean> {
  if (!contentId || !contentType || !flagReason || !flaggedUser) return false;
  let user = await getCurrentUser();
  try {
    if (!user) {
      return false;
    }

    const input: CreateFlaggedContentInput = {
      contentId: contentId,
      contentType: contentType,
      flagReason: flagReason,
      flaggedContentFlaggedUserUserID: flaggedUser.userID, // User who was flagged.
      reportCount: 1,
    };

    const response = (await GraphQLAPI.graphql({
      query: mutations.createFlaggedContent,
      variables: { input: input },
    })) as { data: CreateFlaggedContentMutation };
    return true;
  } catch (err) {
    console.error("Error creating Content Flag:", err);
    return false;
  }
}

/// Gets a content flag based on the ID and reason.
/// Note: flagReason is the SortKey, so it's required.
async function getContentFlag(
  contentId: string,
  flagReason: FlaggedReason
): Promise<FlaggedContent | null> {
  if (!contentId || !flagReason) return null;
  const currentUser = await getCurrentUser();
  try {
    if (!currentUser) {
      return null;
    }

    // ContentID and FlagReason are required inputs to get
    // a single flag. This is because flagReason is the sortKey.
    const variables = {
      contentId: contentId,
      flagReason: flagReason,
    } as GetFlaggedContentQueryVariables;

    const result = (await GraphQLAPI.graphql({
      query: queries.getFlaggedContent,
      variables: variables,
    })) as { data: GetFlaggedContentQuery };
    return result.data?.getFlaggedContent as FlaggedContent;
  } catch (err) {
    console.error("Error getting Flagged Content:", err);
    return null;
  }
}

/// Updates the content flag count for the content provided.
async function updateContentFlagCount(
  contentId: string,
  flagReason: FlaggedReason
): Promise<boolean> {
  if (!contentId || !flagReason) return false;
  let user = await getCurrentUser();
  try {
    if (!user) {
      return false;
    }
    // Get existing flag to update.
    const existingFlag = await getContentFlag(contentId, flagReason);
    if (!existingFlag) return false;

    const input: Partial<UpdateFlaggedContentInput> = {
      contentId: contentId,
      flagReason: flagReason,
      reportCount: existingFlag.reportCount + 1,
    };

    const response = (await GraphQLAPI.graphql({
      query: mutations.updateFlaggedContent,
      variables: { input: input },
    })) as { data: UpdateFlaggedContentMutation };
    return true;
  } catch (err) {
    console.error("Error Updating Content Flag:", err);
    return false;
  }
}

/// Inserts job categories into the database from the array provided.
/// Note: This can only be performed by an admin.
export async function createJobCategories(
  categories: { id: string; name: string }[]
) {
  if (!categories) return;
  const adminUser = await currentUserIsAdmin();
  let user = await getCurrentUser();
  try {
    if (!adminUser) {
      return null;
    }
    if (!user) {
      return;
    }
    for (const category of categories) {
      const input: CreateJobCategoryInput = {
        id: category.id,
        name: category.name,
      };
      const response = (await GraphQLAPI.graphql({
        query: mutations.createJobCategory,
        variables: { input: input },
      })) as { data: CreateJobCategoryMutation };
    }
  } catch (err) {
    console.error("Error creating Category:", err);
  }
}
export async function deactivateUserAccount(user: User) {
  if (!user) return;
  const input: DeleteUserInput = {
    userID: user.userID,
  };
  try {
    // removeStripeExpressAccount   remove
    await stripeHandler.removeStripeExpressAccount(user);
    //disable the user
    await adminDataProvider.disableUser(user);
    // delete user from cognito user pool
    await adminDataProvider.deleteCognitoUser(user);

    await adminDataProvider.deactivateUsereview(user);
    // delete the user from the database
    const response = (await GraphQLAPI.graphql({
      query: mutations.deleteUser,
      variables: { input: input },
    })) as { data: DeleteUserMutation };
    // stipe plan deactivation
    return response.data.deleteUser;
  } catch (err) {
    console.error("Error deleting user:", err);
  }
}

export async function createjobGooglesheet(user: User) {
  if (!user) return;

  try {
    const urlApi =
      "https://script.googleusercontent.com/macros/echo?user_content_key=HTBtd13KBtvABuB0A1ZyPPrrcN1RLagiey_CKZUbj2aYzu3BEoOsrMhFzYJpUcSyF3LNvKNLnOTLSOxM57urYzdegJDLW5E1m5_BxDlH2jW0nuo2oDemN9CCS2h10ox_1xSncGQajx_ryfhECjZEnEc_bUjBZovju1tc3rkahLTC7KUagfgvt_ewyP22_0knYP4o6W87kM9sQz1sAcE4qfEwKT7ueKOr4Iczuljh1OeTB70ALEEQTdz9Jw9Md8uu&lib=MqmsprKWbP4e6i0q8FGHXOwGlzfwiIt8c";
    const fetchDataResponse = await fetch(urlApi);

    // Parse the response
    const fetchedData = await fetchDataResponse.json();

    return fetchedData; // Adjust this based on your actual response structure
  } catch (err) {
    console.error("Error fetching data:", err);
  }
}

export const createMultipleJobs = async (
  jobDataArray: any,
  jobCategories: any,
  setIsLoading?: SetIsLoadingFunction
) => {
  const locationCountry = "US";
  let totalJobCreated = 0;
  for (let i = 0; i <= jobDataArray.length; i++) {
    const jobData = jobDataArray[i];
    // logEvent("job_create_button_clicked");
    const jobCategoryId = jobCategories.find(
      (item: JobCategory) => item.name === jobData?.Category
    )?.id;

    if (jobData !== undefined) {
      const job: CreateJobInput = {
        compensation: 1,
        description: jobData?.description,
        geoHash: "7zzzzzzz",
        jobCategoryId: jobCategoryId,
        jobPosterUserID: jobData?.userid,
        jobStatus: JobStatus.OPEN,
        latitude: null,
        locationAddress: jobData?.Address,
        locationCity: jobData?.City,
        locationCountry: locationCountry,
        locationState: jobData?.State,
        locationZip: jobData?.Zipcode,
        longitude: null,
        rateType: jobData?.rateType?.toUpperCase(),
        title: jobData?.JobTitle,
        userPostedJobsUserID: jobData?.userid,
      };
      const workerSearchVariables: ListJobsQueryVariables = {
        filter: {
          jobPosterUserID: { eq: jobData?.userid },
          title: { eq: jobData?.JobTitle },
        },
      };
      const jobsResult = (await GraphQLAPI.graphql({
        query: queries.listJobs,
        variables: workerSearchVariables,
      })) as { data?: ListJobsQuery; errors?: any[] };
      if (
        (jobsResult?.data?.listJobs?.items?.length === 0 &&
          !jobData?.JobTitle) ||
        !jobCategoryId ||
        jobData?.compensation === 0 ||
        !jobData?.userid ||
        !jobData?.description
      ) {
        const missingFields = [];

        if (!jobData?.JobTitle) missingFields.push("Job Title");
        if (!jobCategoryId) missingFields.push("Category");
        if (jobData?.compensation === 0) missingFields.push("Compensation");
        if (!jobData?.userid) missingFields.push("User ID");
        if (!jobData?.description) missingFields.push("Description");

        const missingFieldsMessage = missingFields.join(", ");

        toast.warning(`Missing required fields: ${missingFieldsMessage}`, {
          toastId: "addJobWarning",
        });

        setIsLoading && setIsLoading(false);
        return;
      } else if (jobsResult?.data?.listJobs?.items?.length === 0) {
        totalJobCreated = totalJobCreated + 1;
        await createJob(job);
      }
    }
  }
  if (totalJobCreated === 0) {
    toast.success("No new job to create!", {
      toastId: "addOrUpdateJobSuccess",
    });
    setIsLoading && setIsLoading(false);
  } else {
    toast.success(`${totalJobCreated} new jobs created successfully!`, {
      toastId: "addOrUpdateJobSuccess",
    });
    setIsLoading && setIsLoading(false);
  }
};
export async function listJobsForUser(
  user: CreateJobInput | null | undefined,
  title: string
): Promise<Job[]> {
  if (!user) return [];

  const workerSearchVariables: ListJobsQueryVariables = {
    filter: {
      jobPosterUserID: { eq: user.userPostedJobsUserID },
      title: { eq: title },
    },
  };

  try {
    const jobsResult = (await GraphQLAPI.graphql({
      query: queries.listJobs,
      variables: workerSearchVariables,
    })) as { data?: ListJobsQuery; errors?: any[] };

    if (jobsResult.errors) {
      // Handle GraphQL errors
      console.error("GraphQL errors:", jobsResult.errors);
      throw new Error("GraphQL errors occurred");
    }

    return (jobsResult.data?.listJobs?.items as any[]) ?? [];
  } catch (error) {
    // Handle non-GraphQL errors
    console.error("Error listing jobs:", error);
    return [];
  }
}

export async function FetchUserGooglesheet() {
  try {
    const urlApi =
      "https://script.googleusercontent.com/a/macros/colledge.us/echo?user_content_key=xCF2yii5OgE_GekMSxqREcgjz1Md5uLealEe9VWIwWRqOQomyomPqlaXsXcUZaHoM8OrSFDqD2NtM0yFbccmSiFM0hJvFOtfm5_BxDlH2jW0nuo2oDemN9CCS2h10ox_nRPgeZU6HP91HjL2TKrcilt5E9OXyndq1lggNbgSb0c930in2GFxm6Oknj7JGYvDg97GH4qIqzrQPvD4MZlI33IhfiAUdo7W0FqLDayF8-wVfN5KzSSJtiP4swtDdxHovJsw0wqpxiI&lib=MthhRQaDN_w91cXUEFJMvm56XiB8UFPJ_";
    const fetchDataResponse = await fetch(urlApi);

    // Parse the response
    const fetchedData = await fetchDataResponse.json();
    return fetchedData;
  } catch (err) {
    console.error("Error fetching data:", err);
  }
}

export async function createJobSpecilities(
  jobSpecilities: { categoryId: string; userId: string }[]
) {
  try {
    const results = [];
    for (const jobSpecility of jobSpecilities) {
      const input: CreateJobSpecilitiesInput = {
        jobSpecilitiesUserUserID: jobSpecility.userId,
        jobSpecilitiesCategoryId: jobSpecility.categoryId,
        userJobSpecilitiesUserID: jobSpecility.userId,
      };
      const result = (await GraphQLAPI.graphql({
        query: mutations.createJobSpecilities,
        variables: { input: input },
      })) as { data: CreateJobSpecilitiesMutation };
      results.push(result);
    }
    return results;
  } catch (err) {
    console.log("Error creating Job Specilities", err);
  }
}

export async function getJobSpecilitiesByUserId(userId: string | undefined) {
  if (!userId) return;
  try {
    const result = (await GraphQLAPI.graphql({
      query: queries.getJobSpecilities,
      variables: { id: userId },
    })) as { data: GetJobSpecilitiesQuery[] };
    return result;
  } catch (error) {
    console.log("Error - getJobSpecilitiesByUserId", error);
  }
}

export async function deleteJobSpecilities(
  jobSpecilities: JobSpecilities[] | null
) {
  if (!jobSpecilities) return;
  try {
    const results = [];
    for (const jobSpecility of jobSpecilities) {
      const input: DeleteJobSpecilitiesInput = {
        id: jobSpecility.id,
      };
      const result = (await GraphQLAPI.graphql({
        query: mutations.deleteJobSpecilities,
        variables: { input },
      })) as { data: DeleteJobSpecilitiesMutation };
      results.push(result);
    }
    return results;
  } catch (err) {
    console.log("Error creating Job Specilities", err);
  }
}

export async function listJobSpecilitiesUsers(filter: {
  jobSpecilitiesCategoryId: string | null | undefined;
}) {
  try {
    const filters: ModelJobSpecilitiesFilterInput = {};
    if (filter.jobSpecilitiesCategoryId) {
      filters.jobSpecilitiesCategoryId = {
        eq: filter.jobSpecilitiesCategoryId,
      };
    }
    const results = (await GraphQLAPI.graphql({
      query: queries.listJobSpecilities,
      variables: { limit: null, nextToken: null, filter: filters },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    })) as { data: ListJobSpecilitiesQuery };
    return results?.data?.listJobSpecilities;
  } catch (err) {
    console.log("Error - listJobSpecilitiesUsers");
  }
}

export async function getUserLists(msg: string) {
  try {
    const results = (await GraphQLAPI.graphql({
      query: queries.getUserList,
      variables: { msg: msg },
    })) as { data: GetUserListQuery };
    return results?.data?.getUserList;
  } catch (err) {
    console.log(err, "Error - getUserList");
  }
}

// export const SiginwithGoogleSheet = async (
//   studentDataArray: any,
//   Exituser: any,
//   setIsLoading?: SetIsLoadingFunction
// ) => {
//   let totalJobCreated = 0;

//   for (let i = 0; i < studentDataArray.length; i++) {
//     const studentData = studentDataArray[i];
//     const studentname = extractNames(studentData?.FullName);
//     const StudentHometown = extractLocationFields(studentData?.StudentHometown);

//     const matchemail = MatchisPropertyInArray(
//       Exituser,
//       "email",
//       studentData?.Email
//     );
//     console.log(matchemail, "Exituser");

//     if (studentData !== undefined) {
//       const signUpData = {
//         username: studentData?.Email,
//         password: studentData?.password,
//         attributes: {
//           email: studentData?.Email,
//           given_name: studentname?.firstName,
//           family_name: studentname?.lastName,
//           "custom:userlocation": StudentHometown?.state,
//           "custom:acknowledgement": "yes",
//           "custom:usertype": studentData?.UserType,
//         },
//       };

//       const input: CreateUserInput = {
//         userID: "",
//         firstName: studentData?.firstName,
//         lastName: studentData?.lastName,
//         email: studentData?.Email,
//         locationAddress: StudentHometown?.city,
//         locationCity: StudentHometown?.city,
//         locationState: StudentHometown?.state,
//         locationZip: StudentHometown?.state,

//         type: studentData.UserType,
//       };

//       if (
//         (!matchemail && !studentData?.Email) ||
//         !studentData?.password ||
//         !studentData?.UserType ||
//         !StudentHometown?.state ||
//         !studentData?.FullName
//       ) {
//         const missingFields = [];

//         if (!studentData?.Email) missingFields.push("Email");
//         if (!studentData?.password) missingFields.push("password");
//         if (!studentData?.UserType) missingFields.push("UserType");
//         if (!StudentHometown?.state) missingFields.push("state");
//         if (!studentData?.FullName) missingFields.push("FullName");

//         const missingFieldsMessage = missingFields.join(", ");

//         toast.warning(`Missing required fields: ${missingFieldsMessage}`, {
//           toastId: "addJobWarning",
//         });

//         setIsLoading && setIsLoading(false);
//         return;
//       } else if (matchemail) {
//         toast.error("User has Already Existed!", {
//           toastId: "addOrUpdateJobSuccess",
//         });
//       } else if (!matchemail) {
//         totalJobCreated = totalJobCreated + 1;
//         const siginresponse = await Auth.signUp(signUpData);
//         console.log(siginresponse, "siginresponse");
//         //  updateUserStatusGroup(siginresponse?.userID,)
//         input.userID = siginresponse?.userSub;
//         const response = (await GraphQLAPI.graphql({
//           query: mutations.createUser,
//           variables: { input: input },
//         })) as { data: CreateUserInput };
//       }
//     }
//   }

//   if (totalJobCreated === 0) {
//     toast.success("No new jobs to create!", {
//       toastId: "addOrUpdateJobSuccess",
//     });
//     setIsLoading && setIsLoading(false);
//   } else {
//     toast.success(`${totalJobCreated} User signed up successfully!`, {
//       toastId: "addOrUpdateJobSuccess",
//     });
//     setIsLoading && setIsLoading(false);
//   }
// };
// interface UserGroup {
//   id: string;
//   name: string;
// }
// export const updateUserStatusGroup = async (
//   userId: string,
//   oldGroupId: string,
//   newGroupId: string
// ) => {
//   try {
//     await adminDataProvider.removeUserFromGroup(userId, oldGroupId);
//     await adminDataProvider.addUserToGroup(userId, newGroupId);

//     const group: UserGroup = {
//       id: newGroupId,
//       name: newGroupId.split("_")[0],
//     };

//     console.log("User group updated successfully.", group);

//     return group;
//   } catch (error) {
//     console.error("Error updating user group:", error);
//     throw error;
//   }
// };

// update the userStatus
export async function updateUserStatus(userId: string, group: string) {
  try {
    const input: UpdateUserInput = {
      userID: userId,
      status: UserStatus[group as keyof typeof UserStatus],
    };
    const response = await updateUserProfile(input);
    return response;
  } catch (err) {
    console.log(err, "Error While updating User status");
  }
}

// coupon form

export async function createCoupon(couponData: CreateCouponInput) {
  try {
    const response = (await GraphQLAPI.graphql({
      query: mutations.createCoupon,
      variables: { input: couponData },
    })) as { data: CreateCouponMutation };
    return response;
  } catch (err) {
    console.log(err, "Error While creating Coupon");
  }
}

// list coupons

export async function listCoupons(
  nextToken?: string | null | undefined,
  limit?: number | null,
  filters?: ModelCouponFilterInput | null
): Promise<ListCouponsQuery | null> {
  try {
    let filter: ModelCouponFilterInput = {};
    if (filters?.couponCode) {
      // Assuming couponCode is a string, assign it directly
      filter.couponCode = { eq: filters.couponCode as string };
    }
    const results = (await GraphQLAPI.graphql({
      query: queries.listCoupons,
      variables: { limit: limit, nextToken: nextToken, filter },
    })) as { data: ListCouponsQuery };
    return results?.data;
  } catch (err) {
    console.log(err, "Error getting Coupons");
    return null;
  }
}
// delete Coupon
export async function deleteCoupon(couponId: string): Promise<boolean> {
  try {
    const input = {
      id: couponId,
    } as DeleteCouponInput;

    const result = (await GraphQLAPI.graphql({
      query: mutations.deleteCoupon,
      variables: { input: input },
    })) as { data: DeleteCouponMutation };
    return true;
  } catch (err) {
    console.log(err, "Error Deleting the Coupon");

    return false;
  }
}

// update Coupon
export async function updateCoupon(
  coupondata: UpdateCouponInput
): Promise<boolean> {
  try {
    const response = (await GraphQLAPI.graphql({
      query: mutations.updateCoupon,
      variables: { input: coupondata },
    })) as { data: UpdateCouponMutation };
    return true;
  } catch (err) {
    console.log(err, "Error Updating Coupon");
    return false;
  }
}

// get Coupon

export async function getCoupon(
  couponId: String | null | undefined
): Promise<Coupon | null> {
  if (!couponId) return null;
  try {
    const result = (await GraphQLAPI.graphql({
      query: queries.getCoupon,
      variables: { id: couponId },
    })) as { data: GetCouponQuery };
    return result.data?.getCoupon as Coupon;
  } catch (err) {
    console.error("Error getting job:", err);
    return null;
  }
}
