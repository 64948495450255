import PrimaryButton from "../buttons/primary_button";
import SecondaryButton from "../buttons/secondary_button";
import ReportContentButton from "../buttons/report_content_button";
import { Link, useNavigate } from "react-router-dom";
import PaymentModal from "./payment_modal";
import { useEffect, useState } from "react";
import Chips from "../chips";
import ApplyModal from "./apply_modal";
import * as dataProvider from "../../graphql/DataProvider";
import * as stripeHandler from "../../graphql/StripeHandler";
import {
  ApplicationStatus,
  FlaggedContentType,
  Job,
  JobRateType,
  JobStatus,
  UserType,
} from "../../API";
import { useUserContext } from "../../helpers/UserContext";
import { Dialog } from "@headlessui/react";
import { toast } from "react-toastify";
import { StripeData } from "../../graphql/StripeHandler";
import { logEvent } from "../../helpers/Analytics";
import { checkrVerificationMessage, stripeSetupAccountMessage } from "../../helpers/Constants";
import { CustomSpinner } from "../loading_indicator";
import SkeletonJobDetails from "../Skeleton/SkeletonJobDetails";
import TooltipWrapper from "../../helpers/TooltipWrapper";
import DisplayJobStatus from './job_status';

interface JobDetailProps {
  job: Job;
  isLoading: boolean;
}

function JobDetail({ job, isLoading: loading }: JobDetailProps) {
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { approvedUser, currentUser } = useUserContext();
  const [isStriped, setStripedStatus] = useState<boolean>(false);
  const [userHasAppliedToJob, setUserHasAppliedToJob] =
    useState<boolean>(false);
  const [userWasHiredForJob, setUserWasHiredForJob] = useState<boolean>(false);
  const navigate = useNavigate();
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showFullTitle, setShowFullTitle] = useState(false)

  const showMoreTitle = () => {
    setShowFullTitle(!showFullTitle);
  }

  const showMoreDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const data: StripeData = {
    rows: [
      {
        jobTitle: job.title!,
        jobDescription: job.description!,
        rateType: job.rateType,
        jobCompensation: job.compensation!,
      },
    ],
    finalDetails: {
      organization: "Colledge Holdings, Inc.",
      commissionPercent: 15, // % Commission.
    },
  };

  useEffect(() => {
    fetchData();
  }, [job]);

  async function fetchData() {
    setIsLoadingData(true);
    // Was the user viewing this page an applicant?
    const hasApplied = await dataProvider.userHasAppliedToJob(job?.id);


    setUserHasAppliedToJob(hasApplied);
    // Was the user viewing this page hired?
    const status = await dataProvider.userStatusWithJob(
      job?.id,
      ApplicationStatus.ACCEPTED
    );

    const isLocalhostOrDev =
    window.location.hostname === "localhost" ||
    window.location.hostname === "dev.colledge.us";
    let stripeStatus=true;
    if(!isLocalhostOrDev)
      stripeStatus = await stripeHandler.checkStripeExpressAccountStatus(currentUser);
    console.log("Is User Striped ? "+stripeStatus);    
    setStripedStatus(stripeStatus);
    setUserWasHiredForJob(status);
    setIsLoadingData(false);
  }

  async function processApplication() {
    const isLocalhostOrDev =
    window.location.hostname === "localhost" ||
    window.location.hostname === "dev.colledge.us";
    let isActiveAccount=true;    
    setIsLoading(true);

    if(!isLocalhostOrDev){
      isActiveAccount = await stripeHandler.checkStripeExpressAccountStatus(currentUser);
    }
    if (isActiveAccount) {
      setApplyModalOpen(true);
    } else {
      toast.error("Your Stripe account must be setup before applying.", {
        toastId: "processApplication",
      });
      navigate("/payment/");
      setIsLoading(false);
    }
    setIsLoading(false);
  }

  /// Controls the modals for payment and apply.
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const openPaymentModal = () => {
    setIsLoadingData(true);
    setPaymentModalOpen(true);
    setIsLoadingData(false);
  };
  const closePaymentModal = () => {
    setPaymentModalOpen(false);
    fetchData();
  };
  const [applyModalOpen, setApplyModalOpen] = useState(false);
  const openApplyModal = () => {
    processApplication();
  };
  const closeApplyModal = (applied?: boolean) => {
    setApplyModalOpen(false);
    setUserHasAppliedToJob(applied ? true : false);
    fetchData();
  };
  return (
    <>
      {isLoadingData && !job ? (
        <div className="my-5">
          {/* <CustomSpinner /> */}
          {Array(5).fill(null).map((_, index) => (
            <SkeletonJobDetails key={index} />
          ))}
        </div>
      ) : (
        <div
          key={job.id}
          className="bg-white w-full whitespace-pre-wrap break-words text-left p-10 shadow-md rounded-xl"
        >
          <div className="mb-10 relative">
            <h2 className="text-3xl text-gray-800 font-bold mb-10 mr-8">
              {showFullTitle ? job.title : `${job.title?.slice(0, 120)}`}
              {job?.title && job.title.length > 120 && (
                <span
                  className='text-[teal] cursor-pointer font-semibold hover:underline mr-10'
                  onClick={showMoreTitle}
                >
                  {showFullTitle ? '...' : '...'}
                </span>
              )}
            </h2>
            {job?.poster.userID !== currentUser?.userID &&
              currentUser?.userID ===
              job?.applicants?.items[0]?.applicant.userID && (
                <div className="absolute right-0 top-0">
                  <ReportContentButton
                    contentType={FlaggedContentType.JOB}
                    flaggedUser={job.poster}
                    contentId={job.id}
                  />
                </div>
              )}
            <div className="w-auto xl:flex grid gap-5 xl:gap-2 mb-4 justify-between">
              <Chips job={job} />
              <DisplayJobStatus status={job.jobStatus} />
            </div>
            <div className="flex flex-col justify-start align-middle">
              <div className="w-full flex flex-col gap-4">
                {studentButtons()}
                {currentUser?.userID === job?.poster.userID &&
                  job?.jobStatus !== JobStatus.CLOSED && (
                    <>
                      <TooltipWrapper
                        showTooltip={!approvedUser}
                        message={checkrVerificationMessage}
                      >
                        <PrimaryButton
                          className="bg-[#333333]"
                          btnText="Close out Job"
                          disabled={!approvedUser || job.jobStatus !== JobStatus.IN_PROGRESS}
                          onClick={() => {
                            openPaymentModal();
                            logEvent("job_closeout_button_clicked");
                          }}
                        />
                      </TooltipWrapper>

                      <Link to={"/create_job/" + job.id}>
                        <SecondaryButton
                          onClick={() => {
                            logEvent("job_edit_button_clicked");
                          }}
                          className="hover:text-[teal]"
                          btnText="Edit Job"
                        />
                      </Link>
                      {job?.applicants?.items.length === 0 && (
                        <SecondaryButton
                          onClick={() => {
                            setDeleteDialogOpen(true);
                            logEvent("job_delete_button_clicked");
                          }}
                          btnText="Delete Job"
                        />
                      )}
                    </>
                  )}
              </div>
            </div>
            <p className="text-gray-600 font-normal mb-10 mt-10">
              {showFullDescription ? job.description : `${job.description?.slice(0, 280)}`}
              {job?.description && job.description.length > 280 && (
                <span
                  className='text-[teal] cursor-pointer hover:underline mr-10'
                  onClick={showMoreDescription}
                >
                  {showFullDescription ? ' .Show less' : ' .Show more'}
                </span>
              )}
            </p>
          </div>
        </div>
      )}
      <PaymentModal
        data={data}
        isOpen={paymentModalOpen}
        onClose={closePaymentModal}
        showModal={data.rows[0].rateType === JobRateType.HOURLY}
        job={job}
      />
      <ApplyModal job={job} isOpen={applyModalOpen} onClose={closeApplyModal} />
      <Dialog
        as="div"
        className="fixed inset-0 flex items-center justify-center z-50 overflow-auto"
        open={isDeleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <div className="fixed inset-0 bg-gray-900 opacity-25"></div>
        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
          <Dialog.Title className="text-gray-900 text-3xl font-bold mb-4">
            Delete Job?
          </Dialog.Title>
          <Dialog.Description className="text-gray-900 mb-5">
            Are you sure you wish to permanently delete your Job? This cannot be
            undone.
          </Dialog.Description>
          <div className="flex flex-col justify-center gap-4 mt-4">
            <PrimaryButton
              btnText="Delete"
              onClick={async () => {
                logEvent("deletejob_confirm_button_clicked");
                setDeleteDialogOpen(false);
                const deleted = await dataProvider.deleteJob(job);
                if (deleted) {
                  toast.success("Job deleted successfully");
                } else {
                  toast.error("Error deleting job");
                }
              }}
            />
            <SecondaryButton
              btnText="Cancel"
              onClick={() => {
                logEvent("deletejob_cancel_button_clicked");
                setDeleteDialogOpen(false);
              }}
            />
          </div>
        </Dialog.Panel>
      </Dialog>
    </>
  );

  /// Formats the student buttons.
  function studentButtons() {
    if (currentUser?.type === UserType.STUDENT) {
      if (!userHasAppliedToJob && !userWasHiredForJob) {
        // hide the job if it is Not open, as for now we are showing all the jobs in the browse page - src\graphql\DataProvider.ts -> getAvailableJobsAroundLocation() function
        if (job.jobStatus !== JobStatus.OPEN) return;
        if (!approvedUser) {
          return (
            <TooltipWrapper showTooltip={!approvedUser} message={checkrVerificationMessage}>
              <PrimaryButton
                btnText="Apply Now"
                disabled={!approvedUser}
                onClick={() => {
                  openApplyModal();
                  logEvent("job_apply_now_button_clicked");
                }}
                showLoading={isLoading}
              />
            </TooltipWrapper>
          )
        } else {
          return (
            <TooltipWrapper showTooltip={approvedUser && !isStriped} message={stripeSetupAccountMessage}>
              <PrimaryButton
                btnText="Apply Now"
                onClick={() => {
                  openApplyModal();
                  logEvent("job_apply_now_button_clicked");
                }}
                showLoading={isLoading}
              />
            </TooltipWrapper>
          )
        }
      } else if (userHasAppliedToJob && !userWasHiredForJob) {
        return (
          <TooltipWrapper showTooltip={!approvedUser} message={checkrVerificationMessage}>
            <PrimaryButton
              btnText="Applied"
              onClick={openApplyModal}
              disabled={true}
              showLoading={isLoading}
            />
          </TooltipWrapper>
        )
      } else if (userHasAppliedToJob && userWasHiredForJob) {
        return (
          <TooltipWrapper showTooltip={!approvedUser} message={checkrVerificationMessage}>
            <PrimaryButton
              btnText="Hired"
              onClick={openApplyModal}
              disabled={true}
              showLoading={isLoading}
            />
          </TooltipWrapper>
        )
      }
    }
    return <> </>;
  }
}

export default JobDetail;
