import GraphQLAPI from "@aws-amplify/api-graphql";
import { GetUserBadgesQuery, UserBadge } from "../API";
import { queries } from ".";
import { Auth } from "aws-amplify";

export async function getUserBadges(userId: string | null): Promise<UserBadge[]> {
  if (!userId) {
    return [];
  }

  const currentUser = await Auth.currentUserInfo();
  if (!currentUser) {
    return [];
  }

  try {
    const response = (await GraphQLAPI.graphql({
      query: queries.getUserBadges,
      variables: { userId },
    })) as { data: GetUserBadgesQuery };
    if (response.data?.getUserBadges) {
      return response.data.getUserBadges as UserBadge[];
    }
    return [];
  } catch (err) {
    console.error("Error fetching badges:", err);
    return [];
  }
}
