// add to existing imports
import config from "./aws-exports";
// check if env is localhost or not
// if you're not developing on localhost, you will need to detect this is another way—the docs linked above give some examples.
const isLocalhost = !!(window.location.hostname === "localhost");

// split redirect signin and signout strings into correct URIs
// const defaultSigninUri = config.oauth.redirectSignIn;
const redirectSignIns = config.oauth?.redirectSignIn?.split(",") || [`${window.location.origin}/`];
// const redirectSignIns = config.oauth.redirectSignIn.split(",");
const localRedirectSignIn = redirectSignIns.find((redirect) =>
  redirect.includes("localhost")
);
const productionRedirectSignIn = redirectSignIns.find((redirect) =>
  !redirect.includes("localhost")
);

const redirectSignOuts = config.oauth?.redirectSignOut?.split(",")  || [`${window.location.origin}/}`];
// const redirectSignOuts = config.oauth.redirectSignOut.split(",");
const localRedirectSignOut = redirectSignOuts.find((redirect) =>
  redirect.includes("localhost")
);
const productionRedirectSignOut = redirectSignOuts.find((redirect) =>
  !redirect.includes("localhost")
);

// use correct URI in the right env
export const awsConfig = {
  ...config,
  oauth: {
    ...config.oauth,
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : productionRedirectSignIn,
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : productionRedirectSignOut,
  },
};
