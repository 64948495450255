import { XMarkIcon } from "@heroicons/react/24/outline";
import { useRef, useState, useEffect } from "react";
import { EmailTemplate, sendEmail } from "../../graphql/EmailHandler";
import { useUserContext } from "../../helpers/UserContext";
import PrimaryButton from "../buttons/primary_button";
import { Job, JobMessageLog, UserType } from "../../API";
import * as dataProvider from "../../graphql/DataProvider";
import { toast } from "react-toastify";
import { formattedName } from "../../helpers/Utilities";
import { logEvent } from "../../helpers/Analytics";
// import { User, UserType } from "../../API";
import { getColledgeURL } from "../../helpers/Utilities";

interface JobCommunicationModalProps {
  messageLog?: JobMessageLog | null | any;
  toUser?: any | null | undefined;
  isOpen: boolean;
  onClose: () => void;
  isHired?: string;
  job?: Job;
}

export default function JobCommunicationModal({
  messageLog,
  toUser,
  isHired,
  isOpen,
  job,
  onClose,
}: JobCommunicationModalProps) {
  const [messages, setMessages] = useState<string>("");
  const [getMessageLog, setGetMessageLog] = useState<any>([]);
  const [getMessageChat, setGetMessageChat] = useState<any>([]);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isMessage, setIsMessage] = useState<boolean>(false);
  const { currentUser } = useUserContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const fetchData = async () => {
    try {
      const fetchedMessageLog = await dataProvider.getMessageLogForJob(
        job,
        job?.applicants?.items?.[0]
      );

      if (fetchedMessageLog) {
        setGetMessageLog(fetchedMessageLog);

        // Fetch MessageChat only if getMessageLog is available
        fetchDataMessageChat(fetchedMessageLog?.logId);
      }
      setIsDataFetched(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchDataMessageChat = async (logId: string) => {
    try {
      if (logId) {
        const fetchedMessage = await dataProvider.getMessageJob(logId);
        if (fetchedMessage) {
          setGetMessageChat(fetchedMessage);
        }

        setIsDataFetched(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (toUser && !isDataFetched) {
      fetchData();
    }
  }, [toUser, isDataFetched]);

  async function sendMessage() {
    if (!toUser || !currentUser || !messages) return;

    const currentTimestamp = new Date().toISOString();

    getMessageChat.push({
      body: messages,
      createdAt: currentTimestamp,
    });

    setMessages(""); // Reset the input here

    try {
      // ...

      if (getMessageLog?.length === 0) {
        const messageLogRes = await dataProvider?.createMessageLogForJob(
          job,
          job?.applicants?.items?.[0]
        );

        if (messageLogRes) {
          const messageRes = await dataProvider?.createMessage(
            messages,
            toUser?.userID,
            messageLogRes?.logId
          );

          // Reset the input here
          // ...
        }
      } else {
        const messageRes = await dataProvider?.createMessage(
          messages,
          toUser?.userID,
          getMessageLog?.logId
        );

        // Reset the input here
        // ...
      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      // Reset the input here
    }
  }

  const sortedmesage = getMessageChat
    .sort((jobA: Job, jobB: Job) => {
      return jobA.createdAt! > jobB.createdAt! ? -1 : 1;
    })
    .reverse();

  useEffect(() => {
    // Scroll to the bottom when messages change
    if (isOpen === true && containerRef.current) {
      containerRef.current.scrollIntoView();
    }
  }, [messages, isOpen]);

  // ** App message notification to email
  const sendMessageNotification = async () => {
    logEvent("notification_send");
    const studentInvitationParams: EmailTemplate = {
      toEmail: toUser?.email,
      fromEmail: "no-reply@colledge.us",
      subject: "Message Notification",
      message: `Hi, you've a new message in the Colledge app from ${currentUser?.firstName} ${currentUser?.lastName} regarding ${job?.title} job. Please login to Colledge and check your messages.`,
      actionURL: getColledgeURL() + "browse",
      templateId: "d-f191a158bf5642e0a7ea4b3bffaa095e",
      userName: `${currentUser?.firstName} ${currentUser?.lastName}`,
    };
    await sendEmail(studentInvitationParams);
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 overflow-auto ">
          <div className="fixed inset-0 bg-gray-900 opacity-25"></div>
          <div
            className="bg-white rounded-lg p-6 z-10 w-10/12 lg:w-5/12 flex flex-col gap-2"
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxHeight: "90vh",
              width: "65vw",
              overflowY: "auto",
            }}
          >
            <div className="w-full m-auto flex items-center pb-2 border-b">
              <h2 className="text-gray-900 md:text-3xl text-base font-bold">
                Contact {formattedName(toUser)}
              </h2>
              <XMarkIcon
                onClick={() => {
                  if (isMessage) {
                    sendMessageNotification();
                  }
                  setIsMessage(false);
                  onClose();
                }}
                className="w-11 h-11 p-3 bg-gray-100 text-gray-700 rounded-full ml-auto"
              />
            </div>
            <div className="w-full m-auto">
              <div className="mt-4 md:h-[300px] h-[370px] mb-4">
                <div className="flex lg:flex-row md:flex-row sm:flex-col max-h-[370px] h-full">
                  <div
                    className="flex-grow overflow-y-auto p-4 min-h-[250px] h-full flex-50 bg-green-200"
                    style={{
                      flex: "60%",
                      height: "21rem",
                      marginTop: "-1rem",
                    }}
                  >
                    {Array.isArray(sortedmesage) ? (
                      sortedmesage.map((msg, index) => (
                        <>
                          <div
                            key={index}
                            className={`w-fit my-0 p-2 ${
                              msg?.from !== currentUser?.userID ? "ml-auto" : ""
                            } min-w-[100px] mt-2 md:text-base text-sm max-w-[280px] text-left font-semibold rounded-md ${
                              msg?.from === currentUser?.userID
                                ? "bg-teal text-white"
                                : "bg-gray-100 text-teal"
                            }`}
                            style={{ wordBreak: "break-word" }}
                          >
                            {msg?.body}
                          </div>
                        </>
                      ))
                    ) : (
                      <div className="text-red-500"></div>
                    )}
                    <div ref={containerRef}></div>
                  </div>
                  {/* <div className="p-4 border-t" style={{ flex: "40%" }}> */}
                  <div className="p-4" style={{ flex: "40%" }}>
                    {/* <div className="p-4 flex-50"> */}
                    <div className="md:flex flex flex-col grid items-center gap-3">
                      <textarea
                        id="message"
                        onChange={(e: any) => {
                          setMessages(e.target.value);
                        }}
                        value={messages}
                        rows={2}
                        style={{
                          height: "17rem",
                          marginTop: "-2rem",
                          ...(window.innerWidth <= 768 && {
                            height: "8rem",
                            marginTop: "-0.5rem",
                          }),
                        }}
                        className="w-full h-full sm:h-11rem bg-gray-50 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 placeholder:text-md focus:ring-2 focus:ring-inset focus:ring-teal text-sm !py-3 !font-semibold leading-6"
                        placeholder="Type a message..."
                      />

                      <PrimaryButton
                        btnText="Send"
                        showLoading={isLoading}
                        disabled={messages.length === 0}
                        onClick={() => {
                          sendMessage();
                          logEvent("jobcomms_modal_send_button_clicked");
                          setIsMessage(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
