import { useState, useEffect } from "react";
import { Storage } from "aws-amplify";
import userProfileIcon from "../../assets/userProfileIcon.svg";
import { useUserContext } from "../../helpers/UserContext";
interface IUserProfileImage {
  photoKey: string | null | undefined;
  className?: string;
  poster?: any;
}
const UserProfileImage = ({
  photoKey,
  className,
  poster,
}: IUserProfileImage) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const { currentUser } = useUserContext();
  useEffect(() => {
    const fetchUserProfile = async () => {
      if (photoKey) {
        try {
          const url = await Storage.get(photoKey);
          setImageUrl(url);
        } catch (error) {
          console.error("Error fetching user profile image:", error);
        }
      } else {
        setImageUrl(null);
      }
    };
    fetchUserProfile();
  }, [photoKey]);
  return (
    <>
      {imageUrl ? (
        <img
          alt="user profile pic"
          // className={`w-full h-full object-cover rounded-full`}
          className={`w-full h-full object-cover rounded-full ${className}`}
          src={imageUrl}
          onError={() => setImageUrl(userProfileIcon)}
        />
      ) : (
        <div
          // className={`flex items-center justify-center rounded-full !w-[55px] !h-[55px] m-auto`}
          className={`w-full h-full object-cover rounded-full flex items-center justify-center ${className}`}
        >
          {poster ? (
            <>
              {poster.firstName && (
                <h2 className="capitalize text-2xl font-bold text-gray-700">
                  {poster.firstName.charAt(0).toUpperCase()}
                  {poster.lastName.charAt(0).toUpperCase()}
                </h2>
              )}
            </>
          ) : (
            currentUser && (
              <>
                {currentUser.firstName && (
                  <h2 className="capitalize text-2xl font-bold text-gray-700">
                    {currentUser.firstName.charAt(0).toUpperCase()}
                    {currentUser.lastName.charAt(0).toUpperCase()}
                  </h2>
                )}
              </>
            )
          )}
        </div>
      )}
    </>
  );
};
export default UserProfileImage;
