import React from "react";
import { Modal } from "flowbite-react";
import PrimaryButton from "../buttons/primary_button";
import SecondaryButton from "../buttons/secondary_button";
import { logEvent } from "../../helpers/Analytics";
import DropdownSelector, { DropDownSelectorOption } from "../dropdown_selector";
import { SubscriptionStatus, UserType ,UserStatus } from "../../API";

interface FilterAccountModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  defaultFilters: UserAccountFilters;
  onApplyFilters: (filters: UserAccountFilters) => void;
}
export interface UserAccountFilters {
  fullName: string;
  email: string;
  bgCheck: string;
  accountType: string;
  subscriptionType: string;
  userGroup:string;
}

const initialFilters = {
  fullName: "",
  email: "",
  bgCheck: "",
  accountType: "",
  subscriptionType: "",
  userGroup:"",
};

const userTypeOptions: DropDownSelectorOption[] = [
  { id: "", name: "Select Account Type" },
  { id: UserType.STUDENT, name: "Student" },
  { id: UserType.POSTER, name: "Poster" },
];

const subscriptionTypeOptions: DropDownSelectorOption[] = [
  { id: "", name: "Select Subcription Type" },
  { id: SubscriptionStatus.ACTIVE, name: "Active" },
  { id: "INACTIVE", name: "Inactive" },
  { id: SubscriptionStatus.PAST_DUE, name: "Past Due" },
  { id: SubscriptionStatus.UNPAID, name: "Unpaid" },
  { id: SubscriptionStatus.CANCELED, name: "Canceled" },
  { id: SubscriptionStatus.INCOMPLETE, name: "Incomplete" },
  { id: SubscriptionStatus.INCOMPLETE_EXPIRED, name: "Incomplete Expired" },
  { id: SubscriptionStatus.TRIALING, name: "Trialing" },
  { id: SubscriptionStatus.PAUSED, name: "Paused" },
];

const bgCheckOptions: DropDownSelectorOption[] = [
  { id: "", name: "Select Background Status" },
  { id: "expired", name: "Expired" },
  { id: "clear", name: "Clear" },
  { id: "pending", name: "Pending" },
  { id: "dispute", name: "Dispute" },
];

const accountTypeOptions: DropDownSelectorOption[] = [
  { id: "", name: "Select Status Type" },
  { id: UserStatus.APPROVED, name: "Approved" },
  { id: UserStatus.PENDING, name: "Pending" },
  { id: UserStatus.DENIED, name: "Denied" },
  {id:UserStatus.ADMIN , name:"Admin"},
];


const FilterAccountModal = ({
  open,
  setOpen,
  defaultFilters,
  onApplyFilters,
}: FilterAccountModalProps) => {
  const [filters, setFilters] = React.useState(initialFilters);

  const onClearFilter = () => {
    setFilters(initialFilters);
    setOpen(false);
    onApplyFilters(initialFilters);
  };

  const handleFilterChange = (field: string, value: string) => {
    setFilters({
      ...filters,
      [field]: value,
    });
  };
  const [selectedUserTypeOption, setSelectedUserTypeOption] = React.useState(
    userTypeOptions[0]
  );

  const [selectedSubscription, setSelectedSubscription] = React.useState(
    subscriptionTypeOptions[0]
  );

  const [selectedBgCheckType, setSelectedBgCheckType] = React.useState(
    bgCheckOptions[0]
  );
  const [selectedAccountType, setSelectedAccountType] = React.useState(
    accountTypeOptions[0]
  );

  // sync default filters with localstate
  React.useEffect(() => {
    setFilters(defaultFilters);
    const accountOption = userTypeOptions.find(
      (option) => option.id === defaultFilters.accountType
    );
    const subscriptionOption = subscriptionTypeOptions.find(
      (option) => option.id === defaultFilters.subscriptionType
    );

    const bgCheckOption = bgCheckOptions.find((option)=> option.id === defaultFilters.bgCheck)
    const accountTypeOption = accountTypeOptions.find((option)=>option.id === defaultFilters.userGroup)
    accountOption && setSelectedUserTypeOption(accountOption);
    subscriptionOption && setSelectedSubscription(subscriptionOption);
    bgCheckOption && setSelectedBgCheckType(bgCheckOption)
    accountTypeOption && setSelectedAccountType(accountTypeOption)
  }, [defaultFilters]);

  const handleApplyFilters = () => {
    onApplyFilters(filters);
    setOpen(false);
  };

  const handleClearFilter = () => {
    onClearFilter();
    setSelectedUserTypeOption(userTypeOptions[0]);
    setSelectedSubscription(subscriptionTypeOptions[0]);
    setSelectedBgCheckType(bgCheckOptions[0]);
    setSelectedAccountType(accountTypeOptions[0]);
  };
  return (
    <Modal show={open} onClose={() => setOpen(false)}>
      <Modal.Header>
        <p className="font-semiboldssss">Filter</p>
      </Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          <div className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            <p className="text-base font-semibold mb-1">Full Name:</p>
            <input
              className="block w-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-12"
              type="text"
              value={filters.fullName}
              onChange={(e) => handleFilterChange("fullName", e.target.value)}
            />
          </div>
          <div className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            <p className="text-base font-semibold mb-1">Email:</p>
            <input
              className="block w-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-12"
              type="text"
              value={filters.email}
              onChange={(e) => handleFilterChange("email", e.target.value)}
            />
          </div>
          <div className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            <p className="text-base font-semibold mb-1">Account Type:</p>
            <DropdownSelector
              options={userTypeOptions}
              selectedOption={selectedUserTypeOption}
              onChange={(option) => {
                setSelectedUserTypeOption(option);
                handleFilterChange("accountType", option.id as string);
              }}
              className="block w-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-12"
            />
          </div>
          <div className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            <p className="text-base font-semibold mb-1">Subscription Type:</p>
            <DropdownSelector
              options={subscriptionTypeOptions}
              selectedOption={selectedSubscription}
              onChange={(option) => {
                setSelectedSubscription(option);
                handleFilterChange("subscriptionType", option.id as string);
              }}
              className="block w-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-12"
            />
          </div>
          <div className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            <p className="text-base font-semibold mb-1">Background Check:</p>
            <DropdownSelector
              options={bgCheckOptions}
              selectedOption={selectedBgCheckType}
              onChange={(option) => {
                setSelectedBgCheckType(option);
                handleFilterChange("bgCheck", option.id as string);
              }}
              className="block w-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-12"
            />
          </div>
          <div className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            <p className="text-base font-semibold mb-1">Status:</p>
            <DropdownSelector
              options={accountTypeOptions}
              selectedOption={selectedAccountType}
              onChange={(option) => {
                setSelectedAccountType(option);
                handleFilterChange("userGroup", option.id as string);
              }}
              className="block w-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-12"
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton
          disabled={false}
          btnText="Filter"
          onClick={handleApplyFilters}
        />
        <SecondaryButton
          className="!w-fit"
          btnText="Clear"
          onClick={handleClearFilter}
        />
      </Modal.Footer>
    </Modal>
  );
};

interface filterCardProps {
  defaultFilters: UserAccountFilters;
  onApplyFilters: (filters: UserAccountFilters) => void;
}

const FilterAccountCardFE = ({
  defaultFilters,
  onApplyFilters,
}: filterCardProps) => {
  const [openModal, setOpenModal] = React.useState(false);

  return (
    <>
      <div className="absolute right-0">
        <PrimaryButton
          className="bg-orange-400"
          btnText="Filter By"
          onClick={() => {
            setOpenModal(true);
            logEvent("filter_account_buton_clicked");
          }}
        />
        {openModal && (
          <FilterAccountModal
            open={openModal}
            setOpen={setOpenModal}
            defaultFilters={defaultFilters}
            onApplyFilters={onApplyFilters}
          />
        )}
      </div>
    </>
  );
};

export default FilterAccountCardFE;
