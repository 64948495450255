import {
  ArrowLeftOnRectangleIcon,
  // UserCircleIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useUserContext } from "../../helpers/UserContext";
import { Auth } from "aws-amplify";
import DynamicHeroIcon, { HeroIconType } from "../dynamic_hero_icon";
import { logEvent } from "../../helpers/Analytics";
import UserProfileImage from "../student/userProfileImage";

interface MenuItem {
  name: string;
  link: string;
  iconName: string;
}

interface AccountMenuProps {
  menuItems: MenuItem[];
}

function AccountMenu({ menuItems }: AccountMenuProps) {
  const { currentUser } = useUserContext();
  const [open, setIsOpen] = useState(false);
  const viewRef = useRef<HTMLDivElement>(null);
  const { signOut } = useAuthenticator();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!open);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  }, [viewRef]);

  const handleClickOutside = (e: MouseEvent) => {
    if (!viewRef?.current?.contains(e.target as HTMLDivElement)) {
      setIsOpen(false);
    }
  };

  const signOutUser = async () => {
    try {
      await Auth.signOut();

      signOut();
      navigate("/home");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };
  const OpenmMenuItem = () => {
    if (!currentUser) {
      return;
    }
    toggleMenu();
  };
  return (
    <>
      <div className="relative z-50" ref={viewRef}>
        {currentUser && (
          <div
            onClick={OpenmMenuItem}
            className={`w-[48px] h-[48px] profileImageMain bg-gray-100 relative hover:bg-gray-200 hover:cursor-pointer rounded-full text-gray-700 flex items-center justify-center border-[3.2px] ${
              currentUser?.type === "POSTER"
                ? "border-purple-500"
                : currentUser?.type === "STUDENT"
                ? "border-green-500"
                : "border-unknown"
            }`}
          >
            {/* <div> */}
            <UserProfileImage
              photoKey={currentUser?.photoKey}
              poster={currentUser}
            />
            {/* </div> */}
          </div>
        )}
        {open && (
          <div className="bg-white shadow-md mt-4 right-[20px] w-40 text-left absolute w-[200px]">
            <ul className="text-gray-700 hover:text-[teal]">
              {menuItems.map((menuItem: MenuItem, index: number) => {
                return (
                  <Link
                    key={index}
                    to={menuItem.link}
                    onClick={toggleMenu}
                    className="text-gray-700 hover:text-[teal]"
                  >
                    <li className="p-4 w-full hover:bg-gray-100 hover:cursor-pointer text-sm font-bold">
                      <span className="flex items-center gap-2 GothamBold">
                        <DynamicHeroIcon
                          icon={menuItem.iconName}
                          iconType={HeroIconType.OUTLINE}
                          color="text-gray-700"
                        />{" "}
                        {menuItem.name}
                      </span>
                    </li>
                  </Link>
                );
              })}
              {/* This code may be useful later */}
              {/* <Link
                to=""
                className="text-gray-700 hover:text-teal-600"
              >
                <li className="p-4 w-full hover:bg-gray-100 hover:cursor-pointer text-sm font-bold">
                  <span className="flex items-center gap-2 text-red-600 GothamBold">
                    <ArrowLeftOnRectangleIcon className="w-6 h-6" /> Deactivate account
                  </span>
                </li>
              </Link> */}
              <Link
                to=""
                onClick={() => {
                  signOutUser();

                  toggleMenu();
                  logEvent("sign_out_user_button_clicked");
                }}
                className="text-gray-700 hover:text-teal-600"
              >
                <li className="p-4 w-full hover:bg-gray-100 hover:cursor-pointer text-sm font-bold">
                  <span className="flex items-center gap-2 text-red-600 GothamBold">
                    <ArrowLeftOnRectangleIcon className="w-6 h-6" /> Sign Out
                  </span>
                </li>
              </Link>
            </ul>
          </div>
        )}
      </div>
    </>
  );
}

export default AccountMenu;
