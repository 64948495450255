import { FlaggedContent, FlaggedContentType, FlaggedReason } from "../../API";
import * as dataProvider from "../../graphql/DataProvider";
import MenuButton, { MenuButtonOption } from "../buttons/menu_button";
import * as authStatus from "../../helpers/AuthStatus";
import { GrayColorChip, RedColorChip } from "../color_chips";
import ContentModal from "../modals/content_modal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export interface FlaggedContentProps {
  flaggedContent: FlaggedContent;
}
/// Represents a row in the Flagged Content Page.
export default function FlaggedContentRow({ flaggedContent }: FlaggedContentProps) {
  const [contentModalOpen, setContentModalOpen] = useState<boolean>(false);
  /// If we should hide this row because it was deleted.
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const navigate = useNavigate();

  const menuOptions: MenuButtonOption[] = [
    {
      name: "View Content",
      onClick: async () => {
        setContentModalOpen(true);
      },
    },
    {
      name: "View Profile",
      onClick: async () => {
        navigate(`/profile/${flaggedContent.flaggedUser.userID}`, {
          state: flaggedContent.flaggedUser,
        });
      },
    },
    {
      name: "AWS Cognito Profile",
      onClick: async () => {
        const userPoolID = await authStatus.userCognitoPoolID();
        window.open(
          `https://us-east-2.console.aws.amazon.com/cognito/v2/idp/user-pools/${userPoolID}/users/details/${flaggedContent?.flaggedUser.userID}?region=us-east-2`,
          "_blank",
          "noreferrer"
        );
      },
    },
    {
      name: "Delete Content",
      onClick: async () => {
        // Delete content, then flag.
        switch (flaggedContent.contentType) {
          case FlaggedContentType.JOB:
            const job = await dataProvider.getJob(flaggedContent.contentId);
            if (job) {
              dataProvider.deleteJob(job);
            }
            break;
          case FlaggedContentType.USER_REVIEW:
            dataProvider.deleteUserReview(flaggedContent.contentId);
            break;
        }
        dataProvider.deleteContentFlag(flaggedContent.contentId, flaggedContent.flagReason);
        setIsDeleted(true);
      },
    },
    {
      name: "Ignore Flag",
      onClick: async () => {
        dataProvider.deleteContentFlag(flaggedContent.contentId, flaggedContent.flagReason);
        setIsDeleted(true);
      },
    },
  ];

  /// Returns the colored chip based on the severity of the flag.
  function chipForReason(reason: FlaggedReason) {
    switch (reason) {
      case FlaggedReason.INAPPROPRIATE:
        return <GrayColorChip value="Inappropriate" />;
      case FlaggedReason.OTHER:
        return <GrayColorChip value="Other" />;
    }
  }

  /// Returns the formatted type as a string.
  function formattedType(type: FlaggedContentType) {
    switch (type) {
      case FlaggedContentType.USER_REVIEW:
        return "Review";
      case FlaggedContentType.JOB:
        return "Job";
    }
  }

  return (
    <>
      <tbody hidden={isDeleted}>
        <tr>
          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm w-3/12">
            <div className="flex items-center">
              <div>
                <p className="text-gray-700 font-bold whitespace-no-wrap text-left">
                  {flaggedContent.flaggedUser?.firstName} {flaggedContent.flaggedUser?.lastName}
                </p>
                <p className="text-gray-700 whitespace-no-wrap text-left">
                  <a href={"mailto:" + flaggedContent.flaggedUser?.email}>
                    {flaggedContent.flaggedUser?.email}
                  </a>
                </p>
              </div>
            </div>
          </td>
          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
            <p className="text-gray-900 whitespace-no-wrap">{flaggedContent.flaggedUser.type}</p>
          </td>
          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
            <p className="text-gray-700">{chipForReason(flaggedContent.flagReason)}</p>
          </td>
          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
            <p className="text-gray-700">{formattedType(flaggedContent.contentType)}</p>
          </td>
          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
            <p className="text-gray-700">{flaggedContent.reportCount}</p>
          </td>
          <td className="px-5 text-left py-5 border-b border-gray-200 bg-white text-sm">
            <MenuButton menuName="Options" options={menuOptions} />
          </td>
        </tr>
      </tbody>
      <ContentModal
        isOpen={contentModalOpen}
        closeModal={() => {
          setContentModalOpen(false);
        }}
        contentId={flaggedContent.contentId}
        contentType={flaggedContent.contentType as any}
      />
    </>
  );
}
