import React, { useEffect, useState } from "react";
import PrimaryButton from "../components/buttons/primary_button";
import { useUserContext } from "../helpers/UserContext";
import { v4 as uuidv4 } from "uuid";
import { sendEmail, EmailTemplate } from "../graphql/EmailHandler";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
interface IFormErrors {
  name: string;
  email: string;
  url: string;
  description: string;
  priority: string;
}

interface ISupportForm {
  name: string;
  email: string;
  url: string;
  description: string;
  priority: string;
  emailNotify: boolean;
}
const SupportForm = () => {
  const { currentUser } = useUserContext();
  const navigate = useNavigate();
  const [supportFormData, setSupportForm] = useState<ISupportForm>({
    name: "",
    email: "",
    url: "",
    description: "",
    priority: "Medium",
    emailNotify: false,
  });
  const [formErrors, setFormErrors] = useState<IFormErrors>({
    name: "",
    email: "",
    url: "",
    description: "",
    priority: "",
  });

  const handleSupportFormChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = event.target.name;
    const value = event.target.value;
    setSupportForm({
      ...supportFormData,
      [name]: value,
    });

    if (value.trim() === "" && name !== "emailNotify") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name as keyof IFormErrors]: "This field is required",
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name as keyof IFormErrors]: "",
      }));
    }
  };

  const onSubmitSupportForm = () => {
    let hasErrors = false;
    Object.entries(supportFormData).forEach(([key, value]) => {
      if (
        typeof value === "string" &&
        value.trim() === "" &&
        key !== "emailNotify"
      ) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [key as keyof IFormErrors]: `${key} is required!`,
        }));
        hasErrors = true;
      }
    });
    if (hasErrors) {
      return;
    } else {
      const message = `
    Name: ${supportFormData.name} ,
    Email: ${supportFormData.email} , 
    Description: ${supportFormData.description} ,
    priority: ${supportFormData.priority}
    url: ${supportFormData.url}`;

      const params: EmailTemplate = {
        toEmail: "dev@colledge.us",
        fromEmail: supportFormData?.email,
        subject: `Support Ticket:ID ${uuidv4()}`,
        message: message,
      };
      sendEmail(params).then((success) => {
        if (success) {
          toast.success("Support form submitted successfully", { toastId: "supportMail" });
          setSupportForm({
            name: "",
            email: "",
            url: "",
            description: "",
            priority: "Medium",
            emailNotify: false,
          });
          navigate("/")
        } else {
          toast.error("Error: While sending support mail");
        }
      });
      toast.dismiss();
    }
  };

  useEffect(() => {
    if (currentUser) {
      setSupportForm((prevForm) => ({
        ...prevForm,
        email: currentUser?.email,
      }));
    }
  }, [currentUser]);

  return (
    <>
      <div className="min-h-[300px]  rounded-2xl p-10 shadow-md pb-10 pt-10 mb-8 lg:max-w-[800px] max-w-full w-full m-auto ">
        <div>
          <h2 className="text-gray-900 pb-4 text-3xl font-black  ">
            Support Form
          </h2>

          <div>
            <div className="w-full">
              <div className="mt-4">
                <label
                  htmlFor="firstName"
                  className="block text-sm text-start font-medium leading-6 text-gray-900 "
                >
                  Name
                </label>
                <div className="mt-2  flex gap-2 items-center">
                  <input
                    id="name"
                    type="text"
                    placeholder="Name"
                    name="name"
                    className="block w-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-12"
                    value={supportFormData.name}
                    onChange={handleSupportFormChange}
                  />
                </div>
                <div className="text-red-500 text-sm text-start capitalize">
                  {formErrors?.name}
                </div>
              </div>
              <div className="mt-4">
                <label
                  htmlFor="email"
                  className="block text-sm text-start font-medium leading-6 text-gray-900 "
                >
                  Email
                </label>
                <div className="mt-2  flex gap-2 items-center">
                  <input
                    id="Email"
                    type="email"
                    placeholder="Email"
                    className="block w-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-12"
                    value={supportFormData.email}
                    onChange={handleSupportFormChange}
                    name="email"
                  />
                </div>
                <div className="text-red-500 text-sm text-start capitalize">
                  {formErrors?.email}
                </div>
              </div>

              <div className="mt-4">
                <label
                  htmlFor="url"
                  className="block text-sm text-start font-medium leading-6 text-gray-900 "
                >
                  URL where issue occurred
                </label>
                <div className="mt-2  flex gap-2 items-center">
                  <input
                    id="url"
                    type="text"
                    placeholder="Kindly type or paste URL"
                    name="url"
                    required
                    className="block w-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-12"
                    value={supportFormData.url}
                    onChange={handleSupportFormChange}
                  />
                </div>
                <div className="text-red-500 text-sm text-start capitalize">
                  {formErrors?.url}
                </div>
              </div>

              <div className="mt-4">
                <label
                  htmlFor="description"
                  className="block text-sm text-start font-medium leading-6 text-gray-900"
                >
                 Please describe the issue you encountered in detail
                </label>
                <div className="mt-2 flex gap-2">
                  <textarea
                    id="description"
                    placeholder="Please describe the issue you encountered in detail"
                    required
                    name="description"
                    className="block w-full !min-w-[100px] bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-40"
                    value={supportFormData.description}
                    onChange={handleSupportFormChange}
                  ></textarea>
                </div>
                <div className="text-red-500 text-sm text-start capitalize">
                  {formErrors?.description}
                </div>
              </div>

              <div className="mt-4">
                <label
                  htmlFor="biography"
                  className="block text-sm text-start font-medium leading-6 text-gray-900"
                >
                  Priority
                </label>
                <div className="flex justify-start gap-10 mt-2">
                  <div className="radio">
                    <label className="flex gap-2 items-center text-black">
                      <input
                        type="radio"
                        value="High"
                        checked={supportFormData.priority === "High"}
                        style={{
                          backgroundColor: supportFormData.priority === "High" ? "#138086" : "#fff",
                        }}
                        name="priority"
                        onChange={handleSupportFormChange}
                      />
                      High
                    </label>
                  </div>
                  <div className="radio">
                    <label className="flex gap-2 items-center text-black">
                      <input
                        type="radio"
                        value="Medium"
                        style={{
                          backgroundColor: supportFormData.priority === "Medium" ? "#138086" : "#fff",
                        }}
                        name="priority"
                        checked={supportFormData.priority === "Medium"}
                        onChange={handleSupportFormChange}
                      />
                      Medium
                    </label>
                  </div>
                  <div className="radio">
                    <label className="flex gap-2 items-center text-black">
                      <input
                        type="radio"
                        value="Low"
                        style={{
                          backgroundColor:  supportFormData.priority === "Low" ? "#138086" : "#fff",
                        }}
                        name="priority"
                        checked={supportFormData.priority === "Low"}
                        onChange={handleSupportFormChange}
                      />
                      Low
                    </label>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <label className="relative border-b text-black border-gray-100  cursor-pointer select-none flex items-center h-full py-4 px-2 gap-3 hover:bg-[#138086] hover:bg-opacity-10">
                  <input
                    type="checkbox"
                    name="emailNotify"
                    style={{
                      backgroundColor: `${
                        supportFormData.emailNotify ? "#138086" : "#fff"
                      }`,
                      border: "1px solid #138086",
                      color: "white",
                    }}
                    onChange={(e) =>
                      setSupportForm((supportForm) => ({
                        ...supportForm,
                        emailNotify: e.target.checked,
                      }))
                    }
                    className="inset-0 p-2 ml-2 !outline-none !shadow-none"
                  />
                  Do you want us to follow up with you via e-mail on the status
                  of your ticket?
                </label>
              </div>

              <div className="justify-center flex mt-10">
                <PrimaryButton
                  btnText="Submit"
                  className={`bg-teal  h-fit whitespace-nowrap`}
                  onClick={onSubmitSupportForm}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportForm;
