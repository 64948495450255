/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      userID
      firstName
      lastName
      email
      locationAddress
      locationCity
      locationState
      locationZip
      photoKey
      type
      school
      biography
      workLocation
      candidateID
      languages
      skills
      stripeAccountID
      subscription
      receivedReviews {
        items {
          id
          forUser {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          fromUser {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          rating
          message
          createdAt
          updatedAt
          userReceivedReviewsUserID
          userSentReviewsUserID
          userReviewForUserUserID
          userReviewFromUserUserID
          owner
        }
        nextToken
      }
      sentReviews {
        items {
          id
          forUser {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          fromUser {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          rating
          message
          createdAt
          updatedAt
          userReceivedReviewsUserID
          userSentReviewsUserID
          userReviewForUserUserID
          userReviewFromUserUserID
          owner
        }
        nextToken
      }
      jobApplications {
        items {
          id
          job {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          applicant {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          message
          status
          createdAt
          updatedAt
          userJobApplicationsUserID
          jobApplicantsId
          jobApplicationJobId
          jobApplicationApplicantUserID
          owner
        }
        nextToken
      }
      postedJobs {
        items {
          id
          poster {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          rateType
          compensation
          title
          description
          locationAddress
          locationCity
          locationState
          locationZip
          locationCountry
          category {
            id
            name
            createdAt
            updatedAt
          }
          applicants {
            nextToken
          }
          jobStatus
          latitude
          longitude
          geoHash
          createdAt
          updatedAt
          userPostedJobsUserID
          jobPosterUserID
          jobCategoryId
          owner
        }
        nextToken
      }
      owner
      name
      graduationYear
      emailNotificationEnabled
      fullName
      avgHourlyRate
      jobSpecilities {
        items {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          category {
            id
            name
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          userJobSpecilitiesUserID
          jobSpecilitiesUserUserID
          jobSpecilitiesCategoryId
          owner
        }
        nextToken
      }
      status
      geoHash
      stripeCreatedAt
      bgCheckPayment {
        id
        user {
          userID
          firstName
          lastName
          email
          locationAddress
          locationCity
          locationState
          locationZip
          photoKey
          type
          school
          biography
          workLocation
          candidateID
          languages
          skills
          stripeAccountID
          subscription
          receivedReviews {
            nextToken
          }
          sentReviews {
            nextToken
          }
          jobApplications {
            nextToken
          }
          postedJobs {
            nextToken
          }
          owner
          name
          graduationYear
          emailNotificationEnabled
          fullName
          avgHourlyRate
          jobSpecilities {
            nextToken
          }
          status
          geoHash
          stripeCreatedAt
          bgCheckPayment {
            id
            payment
            createdAt
            updatedAt
            backgroundCheckPaymentStatusUserUserID
          }
          createdAt
          updatedAt
          userBgCheckPaymentId
        }
        payment
        createdAt
        updatedAt
        backgroundCheckPaymentStatusUserUserID
      }
      createdAt
      updatedAt
      userBgCheckPaymentId
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      userID
      firstName
      lastName
      email
      locationAddress
      locationCity
      locationState
      locationZip
      photoKey
      type
      school
      biography
      workLocation
      candidateID
      languages
      skills
      stripeAccountID
      subscription
      receivedReviews {
        items {
          id
          forUser {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          fromUser {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          rating
          message
          createdAt
          updatedAt
          userReceivedReviewsUserID
          userSentReviewsUserID
          userReviewForUserUserID
          userReviewFromUserUserID
          owner
        }
        nextToken
      }
      sentReviews {
        items {
          id
          forUser {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          fromUser {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          rating
          message
          createdAt
          updatedAt
          userReceivedReviewsUserID
          userSentReviewsUserID
          userReviewForUserUserID
          userReviewFromUserUserID
          owner
        }
        nextToken
      }
      jobApplications {
        items {
          id
          job {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          applicant {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          message
          status
          createdAt
          updatedAt
          userJobApplicationsUserID
          jobApplicantsId
          jobApplicationJobId
          jobApplicationApplicantUserID
          owner
        }
        nextToken
      }
      postedJobs {
        items {
          id
          poster {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          rateType
          compensation
          title
          description
          locationAddress
          locationCity
          locationState
          locationZip
          locationCountry
          category {
            id
            name
            createdAt
            updatedAt
          }
          applicants {
            nextToken
          }
          jobStatus
          latitude
          longitude
          geoHash
          createdAt
          updatedAt
          userPostedJobsUserID
          jobPosterUserID
          jobCategoryId
          owner
        }
        nextToken
      }
      owner
      name
      graduationYear
      emailNotificationEnabled
      fullName
      avgHourlyRate
      jobSpecilities {
        items {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          category {
            id
            name
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          userJobSpecilitiesUserID
          jobSpecilitiesUserUserID
          jobSpecilitiesCategoryId
          owner
        }
        nextToken
      }
      status
      geoHash
      stripeCreatedAt
      bgCheckPayment {
        id
        user {
          userID
          firstName
          lastName
          email
          locationAddress
          locationCity
          locationState
          locationZip
          photoKey
          type
          school
          biography
          workLocation
          candidateID
          languages
          skills
          stripeAccountID
          subscription
          receivedReviews {
            nextToken
          }
          sentReviews {
            nextToken
          }
          jobApplications {
            nextToken
          }
          postedJobs {
            nextToken
          }
          owner
          name
          graduationYear
          emailNotificationEnabled
          fullName
          avgHourlyRate
          jobSpecilities {
            nextToken
          }
          status
          geoHash
          stripeCreatedAt
          bgCheckPayment {
            id
            payment
            createdAt
            updatedAt
            backgroundCheckPaymentStatusUserUserID
          }
          createdAt
          updatedAt
          userBgCheckPaymentId
        }
        payment
        createdAt
        updatedAt
        backgroundCheckPaymentStatusUserUserID
      }
      createdAt
      updatedAt
      userBgCheckPaymentId
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      userID
      firstName
      lastName
      email
      locationAddress
      locationCity
      locationState
      locationZip
      photoKey
      type
      school
      biography
      workLocation
      candidateID
      languages
      skills
      stripeAccountID
      subscription
      receivedReviews {
        items {
          id
          forUser {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          fromUser {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          rating
          message
          createdAt
          updatedAt
          userReceivedReviewsUserID
          userSentReviewsUserID
          userReviewForUserUserID
          userReviewFromUserUserID
          owner
        }
        nextToken
      }
      sentReviews {
        items {
          id
          forUser {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          fromUser {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          rating
          message
          createdAt
          updatedAt
          userReceivedReviewsUserID
          userSentReviewsUserID
          userReviewForUserUserID
          userReviewFromUserUserID
          owner
        }
        nextToken
      }
      jobApplications {
        items {
          id
          job {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          applicant {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          message
          status
          createdAt
          updatedAt
          userJobApplicationsUserID
          jobApplicantsId
          jobApplicationJobId
          jobApplicationApplicantUserID
          owner
        }
        nextToken
      }
      postedJobs {
        items {
          id
          poster {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          rateType
          compensation
          title
          description
          locationAddress
          locationCity
          locationState
          locationZip
          locationCountry
          category {
            id
            name
            createdAt
            updatedAt
          }
          applicants {
            nextToken
          }
          jobStatus
          latitude
          longitude
          geoHash
          createdAt
          updatedAt
          userPostedJobsUserID
          jobPosterUserID
          jobCategoryId
          owner
        }
        nextToken
      }
      owner
      name
      graduationYear
      emailNotificationEnabled
      fullName
      avgHourlyRate
      jobSpecilities {
        items {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          category {
            id
            name
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          userJobSpecilitiesUserID
          jobSpecilitiesUserUserID
          jobSpecilitiesCategoryId
          owner
        }
        nextToken
      }
      status
      geoHash
      stripeCreatedAt
      bgCheckPayment {
        id
        user {
          userID
          firstName
          lastName
          email
          locationAddress
          locationCity
          locationState
          locationZip
          photoKey
          type
          school
          biography
          workLocation
          candidateID
          languages
          skills
          stripeAccountID
          subscription
          receivedReviews {
            nextToken
          }
          sentReviews {
            nextToken
          }
          jobApplications {
            nextToken
          }
          postedJobs {
            nextToken
          }
          owner
          name
          graduationYear
          emailNotificationEnabled
          fullName
          avgHourlyRate
          jobSpecilities {
            nextToken
          }
          status
          geoHash
          stripeCreatedAt
          bgCheckPayment {
            id
            payment
            createdAt
            updatedAt
            backgroundCheckPaymentStatusUserUserID
          }
          createdAt
          updatedAt
          userBgCheckPaymentId
        }
        payment
        createdAt
        updatedAt
        backgroundCheckPaymentStatusUserUserID
      }
      createdAt
      updatedAt
      userBgCheckPaymentId
    }
  }
`;
export const createJobSpecilities = /* GraphQL */ `
  mutation CreateJobSpecilities(
    $input: CreateJobSpecilitiesInput!
    $condition: ModelJobSpecilitiesConditionInput
  ) {
    createJobSpecilities(input: $input, condition: $condition) {
      id
      user {
        userID
        firstName
        lastName
        email
        locationAddress
        locationCity
        locationState
        locationZip
        photoKey
        type
        school
        biography
        workLocation
        candidateID
        languages
        skills
        stripeAccountID
        subscription
        receivedReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        sentReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        jobApplications {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        postedJobs {
          items {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          nextToken
        }
        owner
        name
        graduationYear
        emailNotificationEnabled
        fullName
        avgHourlyRate
        jobSpecilities {
          items {
            id
            createdAt
            updatedAt
            userJobSpecilitiesUserID
            jobSpecilitiesUserUserID
            jobSpecilitiesCategoryId
            owner
          }
          nextToken
        }
        status
        geoHash
        stripeCreatedAt
        bgCheckPayment {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          payment
          createdAt
          updatedAt
          backgroundCheckPaymentStatusUserUserID
        }
        createdAt
        updatedAt
        userBgCheckPaymentId
      }
      category {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      userJobSpecilitiesUserID
      jobSpecilitiesUserUserID
      jobSpecilitiesCategoryId
      owner
    }
  }
`;
export const updateJobSpecilities = /* GraphQL */ `
  mutation UpdateJobSpecilities(
    $input: UpdateJobSpecilitiesInput!
    $condition: ModelJobSpecilitiesConditionInput
  ) {
    updateJobSpecilities(input: $input, condition: $condition) {
      id
      user {
        userID
        firstName
        lastName
        email
        locationAddress
        locationCity
        locationState
        locationZip
        photoKey
        type
        school
        biography
        workLocation
        candidateID
        languages
        skills
        stripeAccountID
        subscription
        receivedReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        sentReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        jobApplications {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        postedJobs {
          items {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          nextToken
        }
        owner
        name
        graduationYear
        emailNotificationEnabled
        fullName
        avgHourlyRate
        jobSpecilities {
          items {
            id
            createdAt
            updatedAt
            userJobSpecilitiesUserID
            jobSpecilitiesUserUserID
            jobSpecilitiesCategoryId
            owner
          }
          nextToken
        }
        status
        geoHash
        stripeCreatedAt
        bgCheckPayment {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          payment
          createdAt
          updatedAt
          backgroundCheckPaymentStatusUserUserID
        }
        createdAt
        updatedAt
        userBgCheckPaymentId
      }
      category {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      userJobSpecilitiesUserID
      jobSpecilitiesUserUserID
      jobSpecilitiesCategoryId
      owner
    }
  }
`;
export const deleteJobSpecilities = /* GraphQL */ `
  mutation DeleteJobSpecilities(
    $input: DeleteJobSpecilitiesInput!
    $condition: ModelJobSpecilitiesConditionInput
  ) {
    deleteJobSpecilities(input: $input, condition: $condition) {
      id
      user {
        userID
        firstName
        lastName
        email
        locationAddress
        locationCity
        locationState
        locationZip
        photoKey
        type
        school
        biography
        workLocation
        candidateID
        languages
        skills
        stripeAccountID
        subscription
        receivedReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        sentReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        jobApplications {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        postedJobs {
          items {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          nextToken
        }
        owner
        name
        graduationYear
        emailNotificationEnabled
        fullName
        avgHourlyRate
        jobSpecilities {
          items {
            id
            createdAt
            updatedAt
            userJobSpecilitiesUserID
            jobSpecilitiesUserUserID
            jobSpecilitiesCategoryId
            owner
          }
          nextToken
        }
        status
        geoHash
        stripeCreatedAt
        bgCheckPayment {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          payment
          createdAt
          updatedAt
          backgroundCheckPaymentStatusUserUserID
        }
        createdAt
        updatedAt
        userBgCheckPaymentId
      }
      category {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      userJobSpecilitiesUserID
      jobSpecilitiesUserUserID
      jobSpecilitiesCategoryId
      owner
    }
  }
`;
export const createJob = /* GraphQL */ `
  mutation CreateJob(
    $input: CreateJobInput!
    $condition: ModelJobConditionInput
  ) {
    createJob(input: $input, condition: $condition) {
      id
      poster {
        userID
        firstName
        lastName
        email
        locationAddress
        locationCity
        locationState
        locationZip
        photoKey
        type
        school
        biography
        workLocation
        candidateID
        languages
        skills
        stripeAccountID
        subscription
        receivedReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        sentReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        jobApplications {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        postedJobs {
          items {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          nextToken
        }
        owner
        name
        graduationYear
        emailNotificationEnabled
        fullName
        avgHourlyRate
        jobSpecilities {
          items {
            id
            createdAt
            updatedAt
            userJobSpecilitiesUserID
            jobSpecilitiesUserUserID
            jobSpecilitiesCategoryId
            owner
          }
          nextToken
        }
        status
        geoHash
        stripeCreatedAt
        bgCheckPayment {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          payment
          createdAt
          updatedAt
          backgroundCheckPaymentStatusUserUserID
        }
        createdAt
        updatedAt
        userBgCheckPaymentId
      }
      rateType
      compensation
      title
      description
      locationAddress
      locationCity
      locationState
      locationZip
      locationCountry
      category {
        id
        name
        createdAt
        updatedAt
      }
      applicants {
        items {
          id
          job {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          applicant {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          message
          status
          createdAt
          updatedAt
          userJobApplicationsUserID
          jobApplicantsId
          jobApplicationJobId
          jobApplicationApplicantUserID
          owner
        }
        nextToken
      }
      jobStatus
      latitude
      longitude
      geoHash
      createdAt
      updatedAt
      userPostedJobsUserID
      jobPosterUserID
      jobCategoryId
      owner
    }
  }
`;
export const updateJob = /* GraphQL */ `
  mutation UpdateJob(
    $input: UpdateJobInput!
    $condition: ModelJobConditionInput
  ) {
    updateJob(input: $input, condition: $condition) {
      id
      poster {
        userID
        firstName
        lastName
        email
        locationAddress
        locationCity
        locationState
        locationZip
        photoKey
        type
        school
        biography
        workLocation
        candidateID
        languages
        skills
        stripeAccountID
        subscription
        receivedReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        sentReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        jobApplications {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        postedJobs {
          items {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          nextToken
        }
        owner
        name
        graduationYear
        emailNotificationEnabled
        fullName
        avgHourlyRate
        jobSpecilities {
          items {
            id
            createdAt
            updatedAt
            userJobSpecilitiesUserID
            jobSpecilitiesUserUserID
            jobSpecilitiesCategoryId
            owner
          }
          nextToken
        }
        status
        geoHash
        stripeCreatedAt
        bgCheckPayment {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          payment
          createdAt
          updatedAt
          backgroundCheckPaymentStatusUserUserID
        }
        createdAt
        updatedAt
        userBgCheckPaymentId
      }
      rateType
      compensation
      title
      description
      locationAddress
      locationCity
      locationState
      locationZip
      locationCountry
      category {
        id
        name
        createdAt
        updatedAt
      }
      applicants {
        items {
          id
          job {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          applicant {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          message
          status
          createdAt
          updatedAt
          userJobApplicationsUserID
          jobApplicantsId
          jobApplicationJobId
          jobApplicationApplicantUserID
          owner
        }
        nextToken
      }
      jobStatus
      latitude
      longitude
      geoHash
      createdAt
      updatedAt
      userPostedJobsUserID
      jobPosterUserID
      jobCategoryId
      owner
    }
  }
`;
export const deleteJob = /* GraphQL */ `
  mutation DeleteJob(
    $input: DeleteJobInput!
    $condition: ModelJobConditionInput
  ) {
    deleteJob(input: $input, condition: $condition) {
      id
      poster {
        userID
        firstName
        lastName
        email
        locationAddress
        locationCity
        locationState
        locationZip
        photoKey
        type
        school
        biography
        workLocation
        candidateID
        languages
        skills
        stripeAccountID
        subscription
        receivedReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        sentReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        jobApplications {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        postedJobs {
          items {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          nextToken
        }
        owner
        name
        graduationYear
        emailNotificationEnabled
        fullName
        avgHourlyRate
        jobSpecilities {
          items {
            id
            createdAt
            updatedAt
            userJobSpecilitiesUserID
            jobSpecilitiesUserUserID
            jobSpecilitiesCategoryId
            owner
          }
          nextToken
        }
        status
        geoHash
        stripeCreatedAt
        bgCheckPayment {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          payment
          createdAt
          updatedAt
          backgroundCheckPaymentStatusUserUserID
        }
        createdAt
        updatedAt
        userBgCheckPaymentId
      }
      rateType
      compensation
      title
      description
      locationAddress
      locationCity
      locationState
      locationZip
      locationCountry
      category {
        id
        name
        createdAt
        updatedAt
      }
      applicants {
        items {
          id
          job {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          applicant {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          message
          status
          createdAt
          updatedAt
          userJobApplicationsUserID
          jobApplicantsId
          jobApplicationJobId
          jobApplicationApplicantUserID
          owner
        }
        nextToken
      }
      jobStatus
      latitude
      longitude
      geoHash
      createdAt
      updatedAt
      userPostedJobsUserID
      jobPosterUserID
      jobCategoryId
      owner
    }
  }
`;
export const createJobCategory = /* GraphQL */ `
  mutation CreateJobCategory(
    $input: CreateJobCategoryInput!
    $condition: ModelJobCategoryConditionInput
  ) {
    createJobCategory(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateJobCategory = /* GraphQL */ `
  mutation UpdateJobCategory(
    $input: UpdateJobCategoryInput!
    $condition: ModelJobCategoryConditionInput
  ) {
    updateJobCategory(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteJobCategory = /* GraphQL */ `
  mutation DeleteJobCategory(
    $input: DeleteJobCategoryInput!
    $condition: ModelJobCategoryConditionInput
  ) {
    deleteJobCategory(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createJobApplication = /* GraphQL */ `
  mutation CreateJobApplication(
    $input: CreateJobApplicationInput!
    $condition: ModelJobApplicationConditionInput
  ) {
    createJobApplication(input: $input, condition: $condition) {
      id
      job {
        id
        poster {
          userID
          firstName
          lastName
          email
          locationAddress
          locationCity
          locationState
          locationZip
          photoKey
          type
          school
          biography
          workLocation
          candidateID
          languages
          skills
          stripeAccountID
          subscription
          receivedReviews {
            nextToken
          }
          sentReviews {
            nextToken
          }
          jobApplications {
            nextToken
          }
          postedJobs {
            nextToken
          }
          owner
          name
          graduationYear
          emailNotificationEnabled
          fullName
          avgHourlyRate
          jobSpecilities {
            nextToken
          }
          status
          geoHash
          stripeCreatedAt
          bgCheckPayment {
            id
            payment
            createdAt
            updatedAt
            backgroundCheckPaymentStatusUserUserID
          }
          createdAt
          updatedAt
          userBgCheckPaymentId
        }
        rateType
        compensation
        title
        description
        locationAddress
        locationCity
        locationState
        locationZip
        locationCountry
        category {
          id
          name
          createdAt
          updatedAt
        }
        applicants {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        jobStatus
        latitude
        longitude
        geoHash
        createdAt
        updatedAt
        userPostedJobsUserID
        jobPosterUserID
        jobCategoryId
        owner
      }
      applicant {
        userID
        firstName
        lastName
        email
        locationAddress
        locationCity
        locationState
        locationZip
        photoKey
        type
        school
        biography
        workLocation
        candidateID
        languages
        skills
        stripeAccountID
        subscription
        receivedReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        sentReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        jobApplications {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        postedJobs {
          items {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          nextToken
        }
        owner
        name
        graduationYear
        emailNotificationEnabled
        fullName
        avgHourlyRate
        jobSpecilities {
          items {
            id
            createdAt
            updatedAt
            userJobSpecilitiesUserID
            jobSpecilitiesUserUserID
            jobSpecilitiesCategoryId
            owner
          }
          nextToken
        }
        status
        geoHash
        stripeCreatedAt
        bgCheckPayment {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          payment
          createdAt
          updatedAt
          backgroundCheckPaymentStatusUserUserID
        }
        createdAt
        updatedAt
        userBgCheckPaymentId
      }
      message
      status
      createdAt
      updatedAt
      userJobApplicationsUserID
      jobApplicantsId
      jobApplicationJobId
      jobApplicationApplicantUserID
      owner
    }
  }
`;
export const updateJobApplication = /* GraphQL */ `
  mutation UpdateJobApplication(
    $input: UpdateJobApplicationInput!
    $condition: ModelJobApplicationConditionInput
  ) {
    updateJobApplication(input: $input, condition: $condition) {
      id
      job {
        id
        poster {
          userID
          firstName
          lastName
          email
          locationAddress
          locationCity
          locationState
          locationZip
          photoKey
          type
          school
          biography
          workLocation
          candidateID
          languages
          skills
          stripeAccountID
          subscription
          receivedReviews {
            nextToken
          }
          sentReviews {
            nextToken
          }
          jobApplications {
            nextToken
          }
          postedJobs {
            nextToken
          }
          owner
          name
          graduationYear
          emailNotificationEnabled
          fullName
          avgHourlyRate
          jobSpecilities {
            nextToken
          }
          status
          geoHash
          stripeCreatedAt
          bgCheckPayment {
            id
            payment
            createdAt
            updatedAt
            backgroundCheckPaymentStatusUserUserID
          }
          createdAt
          updatedAt
          userBgCheckPaymentId
        }
        rateType
        compensation
        title
        description
        locationAddress
        locationCity
        locationState
        locationZip
        locationCountry
        category {
          id
          name
          createdAt
          updatedAt
        }
        applicants {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        jobStatus
        latitude
        longitude
        geoHash
        createdAt
        updatedAt
        userPostedJobsUserID
        jobPosterUserID
        jobCategoryId
        owner
      }
      applicant {
        userID
        firstName
        lastName
        email
        locationAddress
        locationCity
        locationState
        locationZip
        photoKey
        type
        school
        biography
        workLocation
        candidateID
        languages
        skills
        stripeAccountID
        subscription
        receivedReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        sentReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        jobApplications {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        postedJobs {
          items {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          nextToken
        }
        owner
        name
        graduationYear
        emailNotificationEnabled
        fullName
        avgHourlyRate
        jobSpecilities {
          items {
            id
            createdAt
            updatedAt
            userJobSpecilitiesUserID
            jobSpecilitiesUserUserID
            jobSpecilitiesCategoryId
            owner
          }
          nextToken
        }
        status
        geoHash
        stripeCreatedAt
        bgCheckPayment {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          payment
          createdAt
          updatedAt
          backgroundCheckPaymentStatusUserUserID
        }
        createdAt
        updatedAt
        userBgCheckPaymentId
      }
      message
      status
      createdAt
      updatedAt
      userJobApplicationsUserID
      jobApplicantsId
      jobApplicationJobId
      jobApplicationApplicantUserID
      owner
    }
  }
`;
export const deleteJobApplication = /* GraphQL */ `
  mutation DeleteJobApplication(
    $input: DeleteJobApplicationInput!
    $condition: ModelJobApplicationConditionInput
  ) {
    deleteJobApplication(input: $input, condition: $condition) {
      id
      job {
        id
        poster {
          userID
          firstName
          lastName
          email
          locationAddress
          locationCity
          locationState
          locationZip
          photoKey
          type
          school
          biography
          workLocation
          candidateID
          languages
          skills
          stripeAccountID
          subscription
          receivedReviews {
            nextToken
          }
          sentReviews {
            nextToken
          }
          jobApplications {
            nextToken
          }
          postedJobs {
            nextToken
          }
          owner
          name
          graduationYear
          emailNotificationEnabled
          fullName
          avgHourlyRate
          jobSpecilities {
            nextToken
          }
          status
          geoHash
          stripeCreatedAt
          bgCheckPayment {
            id
            payment
            createdAt
            updatedAt
            backgroundCheckPaymentStatusUserUserID
          }
          createdAt
          updatedAt
          userBgCheckPaymentId
        }
        rateType
        compensation
        title
        description
        locationAddress
        locationCity
        locationState
        locationZip
        locationCountry
        category {
          id
          name
          createdAt
          updatedAt
        }
        applicants {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        jobStatus
        latitude
        longitude
        geoHash
        createdAt
        updatedAt
        userPostedJobsUserID
        jobPosterUserID
        jobCategoryId
        owner
      }
      applicant {
        userID
        firstName
        lastName
        email
        locationAddress
        locationCity
        locationState
        locationZip
        photoKey
        type
        school
        biography
        workLocation
        candidateID
        languages
        skills
        stripeAccountID
        subscription
        receivedReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        sentReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        jobApplications {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        postedJobs {
          items {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          nextToken
        }
        owner
        name
        graduationYear
        emailNotificationEnabled
        fullName
        avgHourlyRate
        jobSpecilities {
          items {
            id
            createdAt
            updatedAt
            userJobSpecilitiesUserID
            jobSpecilitiesUserUserID
            jobSpecilitiesCategoryId
            owner
          }
          nextToken
        }
        status
        geoHash
        stripeCreatedAt
        bgCheckPayment {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          payment
          createdAt
          updatedAt
          backgroundCheckPaymentStatusUserUserID
        }
        createdAt
        updatedAt
        userBgCheckPaymentId
      }
      message
      status
      createdAt
      updatedAt
      userJobApplicationsUserID
      jobApplicantsId
      jobApplicationJobId
      jobApplicationApplicantUserID
      owner
    }
  }
`;
export const createCoupon = /* GraphQL */ `
  mutation CreateCoupon(
    $input: CreateCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    createCoupon(input: $input, condition: $condition) {
      id
      couponCode
      isActive
      amount
      percentage
      type
      description
      expiryDate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCoupon = /* GraphQL */ `
  mutation UpdateCoupon(
    $input: UpdateCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    updateCoupon(input: $input, condition: $condition) {
      id
      couponCode
      isActive
      amount
      percentage
      type
      description
      expiryDate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCoupon = /* GraphQL */ `
  mutation DeleteCoupon(
    $input: DeleteCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    deleteCoupon(input: $input, condition: $condition) {
      id
      couponCode
      isActive
      amount
      percentage
      type
      description
      expiryDate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createUserReview = /* GraphQL */ `
  mutation CreateUserReview(
    $input: CreateUserReviewInput!
    $condition: ModelUserReviewConditionInput
  ) {
    createUserReview(input: $input, condition: $condition) {
      id
      forUser {
        userID
        firstName
        lastName
        email
        locationAddress
        locationCity
        locationState
        locationZip
        photoKey
        type
        school
        biography
        workLocation
        candidateID
        languages
        skills
        stripeAccountID
        subscription
        receivedReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        sentReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        jobApplications {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        postedJobs {
          items {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          nextToken
        }
        owner
        name
        graduationYear
        emailNotificationEnabled
        fullName
        avgHourlyRate
        jobSpecilities {
          items {
            id
            createdAt
            updatedAt
            userJobSpecilitiesUserID
            jobSpecilitiesUserUserID
            jobSpecilitiesCategoryId
            owner
          }
          nextToken
        }
        status
        geoHash
        stripeCreatedAt
        bgCheckPayment {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          payment
          createdAt
          updatedAt
          backgroundCheckPaymentStatusUserUserID
        }
        createdAt
        updatedAt
        userBgCheckPaymentId
      }
      fromUser {
        userID
        firstName
        lastName
        email
        locationAddress
        locationCity
        locationState
        locationZip
        photoKey
        type
        school
        biography
        workLocation
        candidateID
        languages
        skills
        stripeAccountID
        subscription
        receivedReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        sentReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        jobApplications {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        postedJobs {
          items {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          nextToken
        }
        owner
        name
        graduationYear
        emailNotificationEnabled
        fullName
        avgHourlyRate
        jobSpecilities {
          items {
            id
            createdAt
            updatedAt
            userJobSpecilitiesUserID
            jobSpecilitiesUserUserID
            jobSpecilitiesCategoryId
            owner
          }
          nextToken
        }
        status
        geoHash
        stripeCreatedAt
        bgCheckPayment {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          payment
          createdAt
          updatedAt
          backgroundCheckPaymentStatusUserUserID
        }
        createdAt
        updatedAt
        userBgCheckPaymentId
      }
      rating
      message
      createdAt
      updatedAt
      userReceivedReviewsUserID
      userSentReviewsUserID
      userReviewForUserUserID
      userReviewFromUserUserID
      owner
    }
  }
`;
export const updateUserReview = /* GraphQL */ `
  mutation UpdateUserReview(
    $input: UpdateUserReviewInput!
    $condition: ModelUserReviewConditionInput
  ) {
    updateUserReview(input: $input, condition: $condition) {
      id
      forUser {
        userID
        firstName
        lastName
        email
        locationAddress
        locationCity
        locationState
        locationZip
        photoKey
        type
        school
        biography
        workLocation
        candidateID
        languages
        skills
        stripeAccountID
        subscription
        receivedReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        sentReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        jobApplications {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        postedJobs {
          items {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          nextToken
        }
        owner
        name
        graduationYear
        emailNotificationEnabled
        fullName
        avgHourlyRate
        jobSpecilities {
          items {
            id
            createdAt
            updatedAt
            userJobSpecilitiesUserID
            jobSpecilitiesUserUserID
            jobSpecilitiesCategoryId
            owner
          }
          nextToken
        }
        status
        geoHash
        stripeCreatedAt
        bgCheckPayment {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          payment
          createdAt
          updatedAt
          backgroundCheckPaymentStatusUserUserID
        }
        createdAt
        updatedAt
        userBgCheckPaymentId
      }
      fromUser {
        userID
        firstName
        lastName
        email
        locationAddress
        locationCity
        locationState
        locationZip
        photoKey
        type
        school
        biography
        workLocation
        candidateID
        languages
        skills
        stripeAccountID
        subscription
        receivedReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        sentReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        jobApplications {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        postedJobs {
          items {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          nextToken
        }
        owner
        name
        graduationYear
        emailNotificationEnabled
        fullName
        avgHourlyRate
        jobSpecilities {
          items {
            id
            createdAt
            updatedAt
            userJobSpecilitiesUserID
            jobSpecilitiesUserUserID
            jobSpecilitiesCategoryId
            owner
          }
          nextToken
        }
        status
        geoHash
        stripeCreatedAt
        bgCheckPayment {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          payment
          createdAt
          updatedAt
          backgroundCheckPaymentStatusUserUserID
        }
        createdAt
        updatedAt
        userBgCheckPaymentId
      }
      rating
      message
      createdAt
      updatedAt
      userReceivedReviewsUserID
      userSentReviewsUserID
      userReviewForUserUserID
      userReviewFromUserUserID
      owner
    }
  }
`;
export const deleteUserReview = /* GraphQL */ `
  mutation DeleteUserReview(
    $input: DeleteUserReviewInput!
    $condition: ModelUserReviewConditionInput
  ) {
    deleteUserReview(input: $input, condition: $condition) {
      id
      forUser {
        userID
        firstName
        lastName
        email
        locationAddress
        locationCity
        locationState
        locationZip
        photoKey
        type
        school
        biography
        workLocation
        candidateID
        languages
        skills
        stripeAccountID
        subscription
        receivedReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        sentReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        jobApplications {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        postedJobs {
          items {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          nextToken
        }
        owner
        name
        graduationYear
        emailNotificationEnabled
        fullName
        avgHourlyRate
        jobSpecilities {
          items {
            id
            createdAt
            updatedAt
            userJobSpecilitiesUserID
            jobSpecilitiesUserUserID
            jobSpecilitiesCategoryId
            owner
          }
          nextToken
        }
        status
        geoHash
        stripeCreatedAt
        bgCheckPayment {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          payment
          createdAt
          updatedAt
          backgroundCheckPaymentStatusUserUserID
        }
        createdAt
        updatedAt
        userBgCheckPaymentId
      }
      fromUser {
        userID
        firstName
        lastName
        email
        locationAddress
        locationCity
        locationState
        locationZip
        photoKey
        type
        school
        biography
        workLocation
        candidateID
        languages
        skills
        stripeAccountID
        subscription
        receivedReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        sentReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        jobApplications {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        postedJobs {
          items {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          nextToken
        }
        owner
        name
        graduationYear
        emailNotificationEnabled
        fullName
        avgHourlyRate
        jobSpecilities {
          items {
            id
            createdAt
            updatedAt
            userJobSpecilitiesUserID
            jobSpecilitiesUserUserID
            jobSpecilitiesCategoryId
            owner
          }
          nextToken
        }
        status
        geoHash
        stripeCreatedAt
        bgCheckPayment {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          payment
          createdAt
          updatedAt
          backgroundCheckPaymentStatusUserUserID
        }
        createdAt
        updatedAt
        userBgCheckPaymentId
      }
      rating
      message
      createdAt
      updatedAt
      userReceivedReviewsUserID
      userSentReviewsUserID
      userReviewForUserUserID
      userReviewFromUserUserID
      owner
    }
  }
`;
export const createJobMessageLog = /* GraphQL */ `
  mutation CreateJobMessageLog(
    $input: CreateJobMessageLogInput!
    $condition: ModelJobMessageLogConditionInput
  ) {
    createJobMessageLog(input: $input, condition: $condition) {
      logId
      job {
        id
        poster {
          userID
          firstName
          lastName
          email
          locationAddress
          locationCity
          locationState
          locationZip
          photoKey
          type
          school
          biography
          workLocation
          candidateID
          languages
          skills
          stripeAccountID
          subscription
          receivedReviews {
            nextToken
          }
          sentReviews {
            nextToken
          }
          jobApplications {
            nextToken
          }
          postedJobs {
            nextToken
          }
          owner
          name
          graduationYear
          emailNotificationEnabled
          fullName
          avgHourlyRate
          jobSpecilities {
            nextToken
          }
          status
          geoHash
          stripeCreatedAt
          bgCheckPayment {
            id
            payment
            createdAt
            updatedAt
            backgroundCheckPaymentStatusUserUserID
          }
          createdAt
          updatedAt
          userBgCheckPaymentId
        }
        rateType
        compensation
        title
        description
        locationAddress
        locationCity
        locationState
        locationZip
        locationCountry
        category {
          id
          name
          createdAt
          updatedAt
        }
        applicants {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        jobStatus
        latitude
        longitude
        geoHash
        createdAt
        updatedAt
        userPostedJobsUserID
        jobPosterUserID
        jobCategoryId
        owner
      }
      applicant {
        id
        job {
          id
          poster {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          rateType
          compensation
          title
          description
          locationAddress
          locationCity
          locationState
          locationZip
          locationCountry
          category {
            id
            name
            createdAt
            updatedAt
          }
          applicants {
            nextToken
          }
          jobStatus
          latitude
          longitude
          geoHash
          createdAt
          updatedAt
          userPostedJobsUserID
          jobPosterUserID
          jobCategoryId
          owner
        }
        applicant {
          userID
          firstName
          lastName
          email
          locationAddress
          locationCity
          locationState
          locationZip
          photoKey
          type
          school
          biography
          workLocation
          candidateID
          languages
          skills
          stripeAccountID
          subscription
          receivedReviews {
            nextToken
          }
          sentReviews {
            nextToken
          }
          jobApplications {
            nextToken
          }
          postedJobs {
            nextToken
          }
          owner
          name
          graduationYear
          emailNotificationEnabled
          fullName
          avgHourlyRate
          jobSpecilities {
            nextToken
          }
          status
          geoHash
          stripeCreatedAt
          bgCheckPayment {
            id
            payment
            createdAt
            updatedAt
            backgroundCheckPaymentStatusUserUserID
          }
          createdAt
          updatedAt
          userBgCheckPaymentId
        }
        message
        status
        createdAt
        updatedAt
        userJobApplicationsUserID
        jobApplicantsId
        jobApplicationJobId
        jobApplicationApplicantUserID
        owner
      }
      posterHash
      applicantHash
      messages {
        items {
          id
          to
          from
          subject
          body
          messageLog {
            logId
            posterHash
            applicantHash
            createdAt
            updatedAt
            jobMessageLogJobId
            jobMessageLogApplicantId
            owner
          }
          createdAt
          updatedAt
          jobMessageLogMessagesLogId
          jobMessageMessageLogLogId
        }
        nextToken
      }
      createdAt
      updatedAt
      jobMessageLogJobId
      jobMessageLogApplicantId
      owner
    }
  }
`;
export const updateJobMessageLog = /* GraphQL */ `
  mutation UpdateJobMessageLog(
    $input: UpdateJobMessageLogInput!
    $condition: ModelJobMessageLogConditionInput
  ) {
    updateJobMessageLog(input: $input, condition: $condition) {
      logId
      job {
        id
        poster {
          userID
          firstName
          lastName
          email
          locationAddress
          locationCity
          locationState
          locationZip
          photoKey
          type
          school
          biography
          workLocation
          candidateID
          languages
          skills
          stripeAccountID
          subscription
          receivedReviews {
            nextToken
          }
          sentReviews {
            nextToken
          }
          jobApplications {
            nextToken
          }
          postedJobs {
            nextToken
          }
          owner
          name
          graduationYear
          emailNotificationEnabled
          fullName
          avgHourlyRate
          jobSpecilities {
            nextToken
          }
          status
          geoHash
          stripeCreatedAt
          bgCheckPayment {
            id
            payment
            createdAt
            updatedAt
            backgroundCheckPaymentStatusUserUserID
          }
          createdAt
          updatedAt
          userBgCheckPaymentId
        }
        rateType
        compensation
        title
        description
        locationAddress
        locationCity
        locationState
        locationZip
        locationCountry
        category {
          id
          name
          createdAt
          updatedAt
        }
        applicants {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        jobStatus
        latitude
        longitude
        geoHash
        createdAt
        updatedAt
        userPostedJobsUserID
        jobPosterUserID
        jobCategoryId
        owner
      }
      applicant {
        id
        job {
          id
          poster {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          rateType
          compensation
          title
          description
          locationAddress
          locationCity
          locationState
          locationZip
          locationCountry
          category {
            id
            name
            createdAt
            updatedAt
          }
          applicants {
            nextToken
          }
          jobStatus
          latitude
          longitude
          geoHash
          createdAt
          updatedAt
          userPostedJobsUserID
          jobPosterUserID
          jobCategoryId
          owner
        }
        applicant {
          userID
          firstName
          lastName
          email
          locationAddress
          locationCity
          locationState
          locationZip
          photoKey
          type
          school
          biography
          workLocation
          candidateID
          languages
          skills
          stripeAccountID
          subscription
          receivedReviews {
            nextToken
          }
          sentReviews {
            nextToken
          }
          jobApplications {
            nextToken
          }
          postedJobs {
            nextToken
          }
          owner
          name
          graduationYear
          emailNotificationEnabled
          fullName
          avgHourlyRate
          jobSpecilities {
            nextToken
          }
          status
          geoHash
          stripeCreatedAt
          bgCheckPayment {
            id
            payment
            createdAt
            updatedAt
            backgroundCheckPaymentStatusUserUserID
          }
          createdAt
          updatedAt
          userBgCheckPaymentId
        }
        message
        status
        createdAt
        updatedAt
        userJobApplicationsUserID
        jobApplicantsId
        jobApplicationJobId
        jobApplicationApplicantUserID
        owner
      }
      posterHash
      applicantHash
      messages {
        items {
          id
          to
          from
          subject
          body
          messageLog {
            logId
            posterHash
            applicantHash
            createdAt
            updatedAt
            jobMessageLogJobId
            jobMessageLogApplicantId
            owner
          }
          createdAt
          updatedAt
          jobMessageLogMessagesLogId
          jobMessageMessageLogLogId
        }
        nextToken
      }
      createdAt
      updatedAt
      jobMessageLogJobId
      jobMessageLogApplicantId
      owner
    }
  }
`;
export const deleteJobMessageLog = /* GraphQL */ `
  mutation DeleteJobMessageLog(
    $input: DeleteJobMessageLogInput!
    $condition: ModelJobMessageLogConditionInput
  ) {
    deleteJobMessageLog(input: $input, condition: $condition) {
      logId
      job {
        id
        poster {
          userID
          firstName
          lastName
          email
          locationAddress
          locationCity
          locationState
          locationZip
          photoKey
          type
          school
          biography
          workLocation
          candidateID
          languages
          skills
          stripeAccountID
          subscription
          receivedReviews {
            nextToken
          }
          sentReviews {
            nextToken
          }
          jobApplications {
            nextToken
          }
          postedJobs {
            nextToken
          }
          owner
          name
          graduationYear
          emailNotificationEnabled
          fullName
          avgHourlyRate
          jobSpecilities {
            nextToken
          }
          status
          geoHash
          stripeCreatedAt
          bgCheckPayment {
            id
            payment
            createdAt
            updatedAt
            backgroundCheckPaymentStatusUserUserID
          }
          createdAt
          updatedAt
          userBgCheckPaymentId
        }
        rateType
        compensation
        title
        description
        locationAddress
        locationCity
        locationState
        locationZip
        locationCountry
        category {
          id
          name
          createdAt
          updatedAt
        }
        applicants {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        jobStatus
        latitude
        longitude
        geoHash
        createdAt
        updatedAt
        userPostedJobsUserID
        jobPosterUserID
        jobCategoryId
        owner
      }
      applicant {
        id
        job {
          id
          poster {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          rateType
          compensation
          title
          description
          locationAddress
          locationCity
          locationState
          locationZip
          locationCountry
          category {
            id
            name
            createdAt
            updatedAt
          }
          applicants {
            nextToken
          }
          jobStatus
          latitude
          longitude
          geoHash
          createdAt
          updatedAt
          userPostedJobsUserID
          jobPosterUserID
          jobCategoryId
          owner
        }
        applicant {
          userID
          firstName
          lastName
          email
          locationAddress
          locationCity
          locationState
          locationZip
          photoKey
          type
          school
          biography
          workLocation
          candidateID
          languages
          skills
          stripeAccountID
          subscription
          receivedReviews {
            nextToken
          }
          sentReviews {
            nextToken
          }
          jobApplications {
            nextToken
          }
          postedJobs {
            nextToken
          }
          owner
          name
          graduationYear
          emailNotificationEnabled
          fullName
          avgHourlyRate
          jobSpecilities {
            nextToken
          }
          status
          geoHash
          stripeCreatedAt
          bgCheckPayment {
            id
            payment
            createdAt
            updatedAt
            backgroundCheckPaymentStatusUserUserID
          }
          createdAt
          updatedAt
          userBgCheckPaymentId
        }
        message
        status
        createdAt
        updatedAt
        userJobApplicationsUserID
        jobApplicantsId
        jobApplicationJobId
        jobApplicationApplicantUserID
        owner
      }
      posterHash
      applicantHash
      messages {
        items {
          id
          to
          from
          subject
          body
          messageLog {
            logId
            posterHash
            applicantHash
            createdAt
            updatedAt
            jobMessageLogJobId
            jobMessageLogApplicantId
            owner
          }
          createdAt
          updatedAt
          jobMessageLogMessagesLogId
          jobMessageMessageLogLogId
        }
        nextToken
      }
      createdAt
      updatedAt
      jobMessageLogJobId
      jobMessageLogApplicantId
      owner
    }
  }
`;
export const createJobMessage = /* GraphQL */ `
  mutation CreateJobMessage(
    $input: CreateJobMessageInput!
    $condition: ModelJobMessageConditionInput
  ) {
    createJobMessage(input: $input, condition: $condition) {
      id
      to
      from
      subject
      body
      messageLog {
        logId
        job {
          id
          poster {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          rateType
          compensation
          title
          description
          locationAddress
          locationCity
          locationState
          locationZip
          locationCountry
          category {
            id
            name
            createdAt
            updatedAt
          }
          applicants {
            nextToken
          }
          jobStatus
          latitude
          longitude
          geoHash
          createdAt
          updatedAt
          userPostedJobsUserID
          jobPosterUserID
          jobCategoryId
          owner
        }
        applicant {
          id
          job {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          applicant {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          message
          status
          createdAt
          updatedAt
          userJobApplicationsUserID
          jobApplicantsId
          jobApplicationJobId
          jobApplicationApplicantUserID
          owner
        }
        posterHash
        applicantHash
        messages {
          items {
            id
            to
            from
            subject
            body
            createdAt
            updatedAt
            jobMessageLogMessagesLogId
            jobMessageMessageLogLogId
          }
          nextToken
        }
        createdAt
        updatedAt
        jobMessageLogJobId
        jobMessageLogApplicantId
        owner
      }
      createdAt
      updatedAt
      jobMessageLogMessagesLogId
      jobMessageMessageLogLogId
    }
  }
`;
export const updateJobMessage = /* GraphQL */ `
  mutation UpdateJobMessage(
    $input: UpdateJobMessageInput!
    $condition: ModelJobMessageConditionInput
  ) {
    updateJobMessage(input: $input, condition: $condition) {
      id
      to
      from
      subject
      body
      messageLog {
        logId
        job {
          id
          poster {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          rateType
          compensation
          title
          description
          locationAddress
          locationCity
          locationState
          locationZip
          locationCountry
          category {
            id
            name
            createdAt
            updatedAt
          }
          applicants {
            nextToken
          }
          jobStatus
          latitude
          longitude
          geoHash
          createdAt
          updatedAt
          userPostedJobsUserID
          jobPosterUserID
          jobCategoryId
          owner
        }
        applicant {
          id
          job {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          applicant {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          message
          status
          createdAt
          updatedAt
          userJobApplicationsUserID
          jobApplicantsId
          jobApplicationJobId
          jobApplicationApplicantUserID
          owner
        }
        posterHash
        applicantHash
        messages {
          items {
            id
            to
            from
            subject
            body
            createdAt
            updatedAt
            jobMessageLogMessagesLogId
            jobMessageMessageLogLogId
          }
          nextToken
        }
        createdAt
        updatedAt
        jobMessageLogJobId
        jobMessageLogApplicantId
        owner
      }
      createdAt
      updatedAt
      jobMessageLogMessagesLogId
      jobMessageMessageLogLogId
    }
  }
`;
export const deleteJobMessage = /* GraphQL */ `
  mutation DeleteJobMessage(
    $input: DeleteJobMessageInput!
    $condition: ModelJobMessageConditionInput
  ) {
    deleteJobMessage(input: $input, condition: $condition) {
      id
      to
      from
      subject
      body
      messageLog {
        logId
        job {
          id
          poster {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          rateType
          compensation
          title
          description
          locationAddress
          locationCity
          locationState
          locationZip
          locationCountry
          category {
            id
            name
            createdAt
            updatedAt
          }
          applicants {
            nextToken
          }
          jobStatus
          latitude
          longitude
          geoHash
          createdAt
          updatedAt
          userPostedJobsUserID
          jobPosterUserID
          jobCategoryId
          owner
        }
        applicant {
          id
          job {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          applicant {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          message
          status
          createdAt
          updatedAt
          userJobApplicationsUserID
          jobApplicantsId
          jobApplicationJobId
          jobApplicationApplicantUserID
          owner
        }
        posterHash
        applicantHash
        messages {
          items {
            id
            to
            from
            subject
            body
            createdAt
            updatedAt
            jobMessageLogMessagesLogId
            jobMessageMessageLogLogId
          }
          nextToken
        }
        createdAt
        updatedAt
        jobMessageLogJobId
        jobMessageLogApplicantId
        owner
      }
      createdAt
      updatedAt
      jobMessageLogMessagesLogId
      jobMessageMessageLogLogId
    }
  }
`;
export const createFlaggedContent = /* GraphQL */ `
  mutation CreateFlaggedContent(
    $input: CreateFlaggedContentInput!
    $condition: ModelFlaggedContentConditionInput
  ) {
    createFlaggedContent(input: $input, condition: $condition) {
      contentId
      flaggedUser {
        userID
        firstName
        lastName
        email
        locationAddress
        locationCity
        locationState
        locationZip
        photoKey
        type
        school
        biography
        workLocation
        candidateID
        languages
        skills
        stripeAccountID
        subscription
        receivedReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        sentReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        jobApplications {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        postedJobs {
          items {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          nextToken
        }
        owner
        name
        graduationYear
        emailNotificationEnabled
        fullName
        avgHourlyRate
        jobSpecilities {
          items {
            id
            createdAt
            updatedAt
            userJobSpecilitiesUserID
            jobSpecilitiesUserUserID
            jobSpecilitiesCategoryId
            owner
          }
          nextToken
        }
        status
        geoHash
        stripeCreatedAt
        bgCheckPayment {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          payment
          createdAt
          updatedAt
          backgroundCheckPaymentStatusUserUserID
        }
        createdAt
        updatedAt
        userBgCheckPaymentId
      }
      contentType
      flagReason
      reportCount
      createdAt
      updatedAt
      flaggedContentFlaggedUserUserID
    }
  }
`;
export const updateFlaggedContent = /* GraphQL */ `
  mutation UpdateFlaggedContent(
    $input: UpdateFlaggedContentInput!
    $condition: ModelFlaggedContentConditionInput
  ) {
    updateFlaggedContent(input: $input, condition: $condition) {
      contentId
      flaggedUser {
        userID
        firstName
        lastName
        email
        locationAddress
        locationCity
        locationState
        locationZip
        photoKey
        type
        school
        biography
        workLocation
        candidateID
        languages
        skills
        stripeAccountID
        subscription
        receivedReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        sentReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        jobApplications {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        postedJobs {
          items {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          nextToken
        }
        owner
        name
        graduationYear
        emailNotificationEnabled
        fullName
        avgHourlyRate
        jobSpecilities {
          items {
            id
            createdAt
            updatedAt
            userJobSpecilitiesUserID
            jobSpecilitiesUserUserID
            jobSpecilitiesCategoryId
            owner
          }
          nextToken
        }
        status
        geoHash
        stripeCreatedAt
        bgCheckPayment {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          payment
          createdAt
          updatedAt
          backgroundCheckPaymentStatusUserUserID
        }
        createdAt
        updatedAt
        userBgCheckPaymentId
      }
      contentType
      flagReason
      reportCount
      createdAt
      updatedAt
      flaggedContentFlaggedUserUserID
    }
  }
`;
export const deleteFlaggedContent = /* GraphQL */ `
  mutation DeleteFlaggedContent(
    $input: DeleteFlaggedContentInput!
    $condition: ModelFlaggedContentConditionInput
  ) {
    deleteFlaggedContent(input: $input, condition: $condition) {
      contentId
      flaggedUser {
        userID
        firstName
        lastName
        email
        locationAddress
        locationCity
        locationState
        locationZip
        photoKey
        type
        school
        biography
        workLocation
        candidateID
        languages
        skills
        stripeAccountID
        subscription
        receivedReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        sentReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        jobApplications {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        postedJobs {
          items {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          nextToken
        }
        owner
        name
        graduationYear
        emailNotificationEnabled
        fullName
        avgHourlyRate
        jobSpecilities {
          items {
            id
            createdAt
            updatedAt
            userJobSpecilitiesUserID
            jobSpecilitiesUserUserID
            jobSpecilitiesCategoryId
            owner
          }
          nextToken
        }
        status
        geoHash
        stripeCreatedAt
        bgCheckPayment {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          payment
          createdAt
          updatedAt
          backgroundCheckPaymentStatusUserUserID
        }
        createdAt
        updatedAt
        userBgCheckPaymentId
      }
      contentType
      flagReason
      reportCount
      createdAt
      updatedAt
      flaggedContentFlaggedUserUserID
    }
  }
`;
export const createSampleJob = /* GraphQL */ `
  mutation CreateSampleJob(
    $input: CreateSampleJobInput!
    $condition: ModelSampleJobConditionInput
  ) {
    createSampleJob(input: $input, condition: $condition) {
      id
      rateType
      compensation
      title
      description
      locationCity
      locationState
      createdAt
      updatedAt
    }
  }
`;
export const updateSampleJob = /* GraphQL */ `
  mutation UpdateSampleJob(
    $input: UpdateSampleJobInput!
    $condition: ModelSampleJobConditionInput
  ) {
    updateSampleJob(input: $input, condition: $condition) {
      id
      rateType
      compensation
      title
      description
      locationCity
      locationState
      createdAt
      updatedAt
    }
  }
`;
export const deleteSampleJob = /* GraphQL */ `
  mutation DeleteSampleJob(
    $input: DeleteSampleJobInput!
    $condition: ModelSampleJobConditionInput
  ) {
    deleteSampleJob(input: $input, condition: $condition) {
      id
      rateType
      compensation
      title
      description
      locationCity
      locationState
      createdAt
      updatedAt
    }
  }
`;
export const createFAQ = /* GraphQL */ `
  mutation CreateFAQ(
    $input: CreateFAQInput!
    $condition: ModelFAQConditionInput
  ) {
    createFAQ(input: $input, condition: $condition) {
      id
      question
      answer
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateFAQ = /* GraphQL */ `
  mutation UpdateFAQ(
    $input: UpdateFAQInput!
    $condition: ModelFAQConditionInput
  ) {
    updateFAQ(input: $input, condition: $condition) {
      id
      question
      answer
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteFAQ = /* GraphQL */ `
  mutation DeleteFAQ(
    $input: DeleteFAQInput!
    $condition: ModelFAQConditionInput
  ) {
    deleteFAQ(input: $input, condition: $condition) {
      id
      question
      answer
      order
      createdAt
      updatedAt
    }
  }
`;
export const createBackgroundCheckPaymentStatus = /* GraphQL */ `
  mutation CreateBackgroundCheckPaymentStatus(
    $input: CreateBackgroundCheckPaymentStatusInput!
    $condition: ModelBackgroundCheckPaymentStatusConditionInput
  ) {
    createBackgroundCheckPaymentStatus(input: $input, condition: $condition) {
      id
      user {
        userID
        firstName
        lastName
        email
        locationAddress
        locationCity
        locationState
        locationZip
        photoKey
        type
        school
        biography
        workLocation
        candidateID
        languages
        skills
        stripeAccountID
        subscription
        receivedReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        sentReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        jobApplications {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        postedJobs {
          items {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          nextToken
        }
        owner
        name
        graduationYear
        emailNotificationEnabled
        fullName
        avgHourlyRate
        jobSpecilities {
          items {
            id
            createdAt
            updatedAt
            userJobSpecilitiesUserID
            jobSpecilitiesUserUserID
            jobSpecilitiesCategoryId
            owner
          }
          nextToken
        }
        status
        geoHash
        stripeCreatedAt
        bgCheckPayment {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          payment
          createdAt
          updatedAt
          backgroundCheckPaymentStatusUserUserID
        }
        createdAt
        updatedAt
        userBgCheckPaymentId
      }
      payment
      createdAt
      updatedAt
      backgroundCheckPaymentStatusUserUserID
    }
  }
`;
export const updateBackgroundCheckPaymentStatus = /* GraphQL */ `
  mutation UpdateBackgroundCheckPaymentStatus(
    $input: UpdateBackgroundCheckPaymentStatusInput!
    $condition: ModelBackgroundCheckPaymentStatusConditionInput
  ) {
    updateBackgroundCheckPaymentStatus(input: $input, condition: $condition) {
      id
      user {
        userID
        firstName
        lastName
        email
        locationAddress
        locationCity
        locationState
        locationZip
        photoKey
        type
        school
        biography
        workLocation
        candidateID
        languages
        skills
        stripeAccountID
        subscription
        receivedReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        sentReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        jobApplications {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        postedJobs {
          items {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          nextToken
        }
        owner
        name
        graduationYear
        emailNotificationEnabled
        fullName
        avgHourlyRate
        jobSpecilities {
          items {
            id
            createdAt
            updatedAt
            userJobSpecilitiesUserID
            jobSpecilitiesUserUserID
            jobSpecilitiesCategoryId
            owner
          }
          nextToken
        }
        status
        geoHash
        stripeCreatedAt
        bgCheckPayment {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          payment
          createdAt
          updatedAt
          backgroundCheckPaymentStatusUserUserID
        }
        createdAt
        updatedAt
        userBgCheckPaymentId
      }
      payment
      createdAt
      updatedAt
      backgroundCheckPaymentStatusUserUserID
    }
  }
`;
export const deleteBackgroundCheckPaymentStatus = /* GraphQL */ `
  mutation DeleteBackgroundCheckPaymentStatus(
    $input: DeleteBackgroundCheckPaymentStatusInput!
    $condition: ModelBackgroundCheckPaymentStatusConditionInput
  ) {
    deleteBackgroundCheckPaymentStatus(input: $input, condition: $condition) {
      id
      user {
        userID
        firstName
        lastName
        email
        locationAddress
        locationCity
        locationState
        locationZip
        photoKey
        type
        school
        biography
        workLocation
        candidateID
        languages
        skills
        stripeAccountID
        subscription
        receivedReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        sentReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        jobApplications {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        postedJobs {
          items {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          nextToken
        }
        owner
        name
        graduationYear
        emailNotificationEnabled
        fullName
        avgHourlyRate
        jobSpecilities {
          items {
            id
            createdAt
            updatedAt
            userJobSpecilitiesUserID
            jobSpecilitiesUserUserID
            jobSpecilitiesCategoryId
            owner
          }
          nextToken
        }
        status
        geoHash
        stripeCreatedAt
        bgCheckPayment {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          payment
          createdAt
          updatedAt
          backgroundCheckPaymentStatusUserUserID
        }
        createdAt
        updatedAt
        userBgCheckPaymentId
      }
      payment
      createdAt
      updatedAt
      backgroundCheckPaymentStatusUserUserID
    }
  }
`;
export const createBackgroundCheckLog = /* GraphQL */ `
  mutation CreateBackgroundCheckLog(
    $input: CreateBackgroundCheckLogInput!
    $condition: ModelBackgroundCheckLogConditionInput
  ) {
    createBackgroundCheckLog(input: $input, condition: $condition) {
      candidateID
      userID
      invitationID
      reportID
      inviteStatus
      reportStatus
      reportResult
      reportAdjudication
      backgroundCheckCompletedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateBackgroundCheckLog = /* GraphQL */ `
  mutation UpdateBackgroundCheckLog(
    $input: UpdateBackgroundCheckLogInput!
    $condition: ModelBackgroundCheckLogConditionInput
  ) {
    updateBackgroundCheckLog(input: $input, condition: $condition) {
      candidateID
      userID
      invitationID
      reportID
      inviteStatus
      reportStatus
      reportResult
      reportAdjudication
      backgroundCheckCompletedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteBackgroundCheckLog = /* GraphQL */ `
  mutation DeleteBackgroundCheckLog(
    $input: DeleteBackgroundCheckLogInput!
    $condition: ModelBackgroundCheckLogConditionInput
  ) {
    deleteBackgroundCheckLog(input: $input, condition: $condition) {
      candidateID
      userID
      invitationID
      reportID
      inviteStatus
      reportStatus
      reportResult
      reportAdjudication
      backgroundCheckCompletedAt
      createdAt
      updatedAt
    }
  }
`;
export const createSetting = /* GraphQL */ `
  mutation CreateSetting(
    $input: CreateSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    createSetting(input: $input, condition: $condition) {
      id
      feature
      active
      createdAt
      updatedAt
    }
  }
`;
export const updateSetting = /* GraphQL */ `
  mutation UpdateSetting(
    $input: UpdateSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    updateSetting(input: $input, condition: $condition) {
      id
      feature
      active
      createdAt
      updatedAt
    }
  }
`;
export const deleteSetting = /* GraphQL */ `
  mutation DeleteSetting(
    $input: DeleteSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    deleteSetting(input: $input, condition: $condition) {
      id
      feature
      active
      createdAt
      updatedAt
    }
  }
`;
export const startBackgroundCheck = /* GraphQL */ `
  mutation StartBackgroundCheck($userId: String!) {
    startBackgroundCheck(userId: $userId)
  }
`;
export const sendEmail = /* GraphQL */ `
  mutation SendEmail($input: MutationSendEmailQueryInput) {
    sendEmail(input: $input)
  }
`;
export const createStripeExpressUserAccount = /* GraphQL */ `
  mutation CreateStripeExpressUserAccount(
    $userId: String!
    $userEmail: String!
  ) {
    createStripeExpressUserAccount(userId: $userId, userEmail: $userEmail)
  }
`;
export const createStripeExpressOnboardingLink = /* GraphQL */ `
  mutation CreateStripeExpressOnboardingLink(
    $userId: String!
    $stripeAccountID: String!
  ) {
    createStripeExpressOnboardingLink(
      userId: $userId
      stripeAccountID: $stripeAccountID
    )
  }
`;
export const createStripeCheckoutSessionForBgCheckPayment = /* GraphQL */ `
  mutation CreateStripeCheckoutSessionForBgCheckPayment($userId: String!) {
    createStripeCheckoutSessionForBgCheckPayment(userId: $userId)
  }
`;
export const createStripeSubscriptionCheckout = /* GraphQL */ `
  mutation CreateStripeSubscriptionCheckout(
    $userId: String!
    $subTerm: String!
  ) {
    createStripeSubscriptionCheckout(userId: $userId, subTerm: $subTerm)
  }
`;
export const createStripeCheckoutSession = /* GraphQL */ `
  mutation CreateStripeCheckoutSession(
    $userID: String!
    $amountDueStudent: Int!
    $studentStripeAccountId: String!
    $jobId: String!
    $couponCode: String
  ) {
    createStripeCheckoutSession(
      userID: $userID
      amountDueStudent: $amountDueStudent
      studentStripeAccountId: $studentStripeAccountId
      jobId: $jobId
      couponCode: $couponCode
    )
  }
`;
export const removeStripeAccount = /* GraphQL */ `
  mutation RemoveStripeAccount($stripeAccountID: String!) {
    removeStripeAccount(stripeAccountID: $stripeAccountID)
  }
`;
export const removeUserDetails = /* GraphQL */ `
  mutation RemoveUserDetails($userID: String!) {
    removeUserDetails(userID: $userID)
  }
`;
