import {
  AcademicCapIcon,
  MapPinIcon,
  StarIcon,
} from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { JobMessageLog, User, UserType } from "../../API";
import { useEffect, useState } from "react";
import { Storage } from "aws-amplify";
import {
  averageRatingForUser,
  formattedName,
  formattedReviewText,
} from "../../helpers/Utilities";
import { useUserContext } from "../../helpers/UserContext";
// import { UserCircleIcon } from "@heroicons/react/24/outline";
import JobCommunicationModal from "../jobs/job_communication";
import { logEvent } from "../../helpers/Analytics";
import userProfileIcon from "../../assets/userProfileIcon.svg";
import UserProfileImage from "../student/userProfileImage";

interface ProfileCardProps {
  user: User | null | undefined;
  messageLog: JobMessageLog | null;
}

function ProfileCard({ user, messageLog }: ProfileCardProps) {
  /// Current user that's browsing.
  const { currentUser } = useUserContext();
  const [userAvgRating, setUserAvgRating] = useState(0);
  const fullName = formattedName(user);
  const [showContactModal, setShowContactModal] = useState<boolean>(false);
  return (
    <>
      <div className="bg-white text-left flex items-start p-6 pr-0 lg:p-14 flex-col w-11/12 m-auto lg:w-full shadow-md rounded-xl h-auto md:w-auto">
        <div className="flex-col lg:flex-row flex justify-center w-full gap-4">
          <div
            className={`flex-none w-[100px] h-[100px] rounded-full self-start border-[3.2px] ${
              user?.type === "POSTER"
                ? "border-purple-500"
                : user?.type === "STUDENT"
                ? "border-green-500"
                : "border-unknown"
            } flex items-center justify-center`}
          >
            {user && (
              <UserProfileImage photoKey={user.photoKey} poster={user} />
            )}
          </div>

          <div className="inline ml-0 lg:ml-10 w-full lg:w-11/12 relative flex-col">
            <div className="flex items-center relative w-full flex-row justify-between">
              <h2 className="text-gray-700 text-3xl font-semibold mr-4 max-w-[400px] overflow-clip whitespace-nowrap">
                {fullName}
              </h2>
              <div className="flex gap-2 align-center pr-6">
                {currentUser?.userID === user?.userID && (
                  <Link
                    to="/edit_account"
                    onClick={() => {
                      logEvent("profile_edit_button_clicked");
                    }}
                  >
                    <button className="text-gray-50 bg-orange-400 rounded-full w-auto GothamBold">
                      Edit
                    </button>
                  </Link>
                )}
              </div>
            </div>
            <h3 className="text-gray-700 text-md mt-4 lg:pr-40 pr-8">
              <p className="text-gray-700 break-words">
                {user?.biography ? (
                  user?.biography
                ) : (
                  <span className="text-gray-400">No biography provided.</span>
                )}
              </p>
            </h3>
            <div className="flex flex-col md:flex-row items-center mt-8 md:gap-2 gap-4 whitespace-nowrap pb-4 md:pb-0 ">
              <div className="flex items-center gap-1 bg-gray-100 rounded-full p-4 pr-6">
                <div className="w-6 text-yellow-400">
                  <StarIcon className="h-full m-auto"></StarIcon>
                </div>
                <h3 className="text-gray-700 text-md font-bold pt-1 GothamBook">
                  {userAvgRating > 0 ? userAvgRating : ""}
                </h3>
                <h3 className="text-gray-700 text-md pt-1 GothamBook">
                  {formattedReviewText(user)}
                </h3>
              </div>
              <div className="flex w-auto bg-gray-100 text-gray-700 p-4 rounded-full border-radius pr-6">
                <span className="w-6 mr-2">
                  <MapPinIcon />
                </span>
                <h3 className="text-gray-700 text-md GothamBook">
                  {user?.locationCity ? (
                    (
                      user?.locationCity +
                      ", " +
                      user?.locationState
                    ).toUpperCase()
                  ) : (
                    <p className={"text-gray-400"}>Unknown</p>
                  )}
                </h3>
              </div>
              {user?.type === UserType.STUDENT && user?.school && (
                <div className="flex bg-gray-100 text-gray-700 p-4 rounded-full border-radius pr-6">
                  <span className="w-6 mr-2">
                    <AcademicCapIcon />
                  </span>
                  <h3 className="text-gray-700 text-md md:w-[200px] lg:w-auto">
                    <p className="text-gray-700 truncate">{user?.school}</p>
                  </h3>
                </div>
              )}
            </div>
            <div className="mt-4">
              {user?.jobSpecilities?.items && (
                <div className="flex flex-wrap gap-4">
                  {user.jobSpecilities.items.map((item, index) => (
                    <div
                      key={index}
                      className="flex bg-gray-100 text-gray-700 p-3 rounded-full border-radius pr-6 overflow-wrap"
                    >
                      <div className="flex items-center">
                        <span className="w-6 h-6 mr-2">
                          <StarIcon className="h-full m-auto"></StarIcon>
                        </span>
                        <p className="whitespace-nowrap break-words text-sm md:text-base lg:text-lg">
                          {item?.category.name}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileCard;
