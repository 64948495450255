import TwoCol from "../components/layouts/two_col";
import Footer from "../components/nav/footer";
import Faq from "../components/faq";

import Ratings from "../assets/Ratings.webp";
import RatingsFallback from "../assets/Ratings.png";
import RatingsMobile from "../assets/Ratings.webp";
import RatingsMobileFallback from "../assets/Ratings.png";
import Student from "../assets/Student.webp";
import StudentFallback from "../assets/Student.png";
import Studentmobile from "../assets/Studentmobile.webp";
import StudentmobileFallback from "../assets/Studentmobile.png";

import // AcademicCapIcon,
// CalendarIcon,
// CheckCircleIcon,
// DocumentCheckIcon,
// StarIcon,
"@heroicons/react/24/outline";
import { useEffect, useRef } from "react";
import { logEvent } from "../helpers/Analytics";
import StarIcon from "../assets/StarIcon.svg";
import CalendarIcon from "../assets/CalendarIcon.svg";
import DocumentCheckIcon from "../assets/DocumentCheckIcon.svg";
import AcademicCapIcon from "../assets/AcademicCapIcon.svg";
import CheckCircleIcon from "../assets/CheckCircleIcon.svg";

function About() {
  const faqRef = useRef<HTMLDivElement | null>(null);
  const whyColledgeRef = useRef<HTMLDivElement | null>(null);
  const aboutUsRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    logEvent("page_view_about", {
      queryParams: queryParams.entries(),
      referrer: document.referrer,
    });
  }, []);

  const iconsData = [
    {
      icon: StarIcon,
      title: "Trust & Safety",
      description: "Both students and clients go through background checks",
    },
    {
      icon: CalendarIcon,
      title: "Flexibility",
      description: "Everyone benefits from more flexibility",
    },
    {
      icon: DocumentCheckIcon,
      title: "Simplicity",
      description: "Colledge simplifies the job match process",
    },
    {
      icon: CheckCircleIcon,
      title: "Availability",
      description: "The biggest untapped resource for gig work",
    },
    {
      icon: AcademicCapIcon,
      title: "Community",
      description: "Colledge is more than a job site - it's a community",
    },
  ];

  return (
    <>
      <TwoCol
        header="Welcome to Colledge"
        body="Our mission is to elevate student life and simplify adult life. Colledge is the only platform that connects students to the fast-growing gig economy and adults to its most underutilized pool of talent - students."
        subTitle="The Gig Economy - Elevated"
        subBody={
          <>
            <b>Students:</b> Earn extra money using your skills and talents with
            the ultimate job flexibility. Take finals week off. Work some extra
            hours for your Spring Break fund. Choose remote work or on-site gigs
            or both. You're in control and in charge.
            <br />
          </>
        }
        secondarySubBody={
          <>
            <b>Clients:</b> Reclaim your time and lighten your To-Do list.
            Students are at your service to help you manage your household or
            your business. Hire for ongoing support or one-off jobs when you
            need them. Adulting never seemed so easy.
            <br />
          </>
        }
        imgSrc={Ratings}
        imgSrcFallback={RatingsFallback}
        imgSrcMobile={RatingsMobile}
        imgSrcMobileFallback={RatingsMobileFallback}
        style={{
          background: "linear-gradient(180deg, #F9FEFE 0%, #FFFFFF 61.69%)",
        }}
      />
      <div className="w-full h-20" ref={whyColledgeRef}></div>
      <h2 className="text-gray-900 pb-4 text-2xl font-black mb-12">
        Why Colledge?
      </h2>
      <div className="flex flex-col lg:flex-row justify-center pb-10 gap-8 mjustify-evenly">
        {iconsData.map((item, index) => (
          <div key={index} className="flex flex-col justify-center">
            <img
              alt={`${item.title}Icon`}
              className="w-20 h-20 text-orange-400 p-4 bg-orange-50 rounded-xl m-auto"
              src={item.icon}
            />
            <h3 className="text-gray-900 text-lg font-medium p-2 mt-8">
              {item.title}
            </h3>
            <p className="text-gray-900 GothamBook">{item.description}</p>
          </div>
        ))}
      </div>
      {/* <StarIcon className="w-20 h-20 text-orange-400 p-4 bg-orange-50 rounded-xl m-auto" /> */}
      {/* <CalendarIcon className="w-20 h-20 text-orange-400 p-4 bg-orange-50 rounded-xl m-auto" /> */}
      {/* <DocumentCheckIcon className="w-20 h-20 text-orange-400 p-4 bg-orange-50 rounded-xl m-auto" /> */}
      {/* <CheckCircleIcon className="w-20 h-20 text-orange-400 p-4 bg-orange-50 rounded-xl m-auto" /> */}
      {/* <AcademicCapIcon className="w-20 h-20 text-orange-400 p-4 bg-orange-50 rounded-xl m-auto" /> */}
      <div className="w-full h-10" ref={aboutUsRef}></div>
      <TwoCol
        header="The Founders' Story"
        body="We all have war stories of slogging through dead-end jobs in college. Now as adults, we're juggling seemingly endless responsibilities and managing constantly full plates. A light bulb went off. What if we could connect students with the work opportunities we wish we had, and connect adults with the skilled, trusted, and motivated help we know they need? In that moment, Colledge was born."
        imgSrc={Student}
        imgSrcFallback={StudentFallback}
        imgSrcMobile={Studentmobile}
        imgSrcMobileFallback={StudentmobileFallback}
        direction="reverse"
        style={{
          background: "linear-gradient(180deg, #F9FEFE 0%, #FFFFFF 61.69%)",
        }}
      />
      <div ref={faqRef}>
        <Faq />
      </div>
      <Footer
        faqRef={faqRef}
        aboutUsRef={aboutUsRef}
        whyColledgeRef={whyColledgeRef}
      />
    </>
  );
}

export default About;
