interface SecondaryBtnProps {
  btnText: string;
  onClick: any;
  disabled?: boolean;
  className?: string;
}

function SecondaryButton({ btnText, onClick, disabled = false, className = "" }: SecondaryBtnProps) {
  return (
    <>
      <button
        className={`bg-gray-100 text-gray-700 font-bold py-3 px-8 rounded-full w-full h-auto GothamBold ${className} ${
          disabled ? "disabled-class bg-gray-300" : ""
        }`}
        onClick={onClick}
        disabled={disabled}
      >
        {btnText}
      </button>
    </>
  );
}

export default SecondaryButton;
