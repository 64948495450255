import React, { Children, ReactNode } from "react";
import { checkrVerificationMessage } from "./Constants";
import TooltipWrapper from "./TooltipWrapper";
interface IProps {
  children: ReactNode;
  disabled: boolean;
  message?: string;
  loading?: boolean; 
}

export function BgCheckerWrapper({
  children,
  disabled,
  message = checkrVerificationMessage,
  loading = false
}: IProps) {
  const wrapChildrenWithDisabledProps = (child: ReactNode) => {
    if (typeof child === "string" || typeof child === "number") {
      // If the child is a string or number, return it as is.
      return child;
    } else if (React.isValidElement(child)) {
      if (child.type === "button") {
        return (
          <TooltipWrapper message={message} showTooltip={disabled}>
            <button
              {...child.props}
              disabled={disabled || loading}
              onClick={
                disabled || loading ? (e) => e.preventDefault() : child.props.onClick
              }
            >
              {child.props.children}
            </button>
          </TooltipWrapper>
        );
      } else if (child.type === "a") {
        return (
          <TooltipWrapper message={message} showTooltip={disabled}>
            <a
              {...child.props}
              href={disabled || loading ? undefined : child.props.href}
              onClick={
                disabled || loading ? (e) => e.preventDefault() : child.props.onClick
              }
            >
              {child.props.children}
            </a>
          </TooltipWrapper>
        );
      } else if (child.type === "input") {
        return (
          <TooltipWrapper message={message} showTooltip={disabled}>
            <input
              {...child.props}
              href={disabled || loading ? undefined : child.props.href}
              onClick={
                disabled || loading ? (e) => e.preventDefault() : child.props.onClick
              }
            >
              {child.props.children}
            </input>
          </TooltipWrapper>
        );
      }
    }
    return child;
  };

  const wrappedChildren = Children.map(children, wrapChildrenWithDisabledProps);

  return <div>{wrappedChildren}</div>;
}

export default BgCheckerWrapper;
