import { UserType } from "../API";
import StudentJobsView from "../components/profile/student_jobs_view";
import PosterJobsView from "../components/profile/poster_jobs_view";
import { useUserContext } from "../helpers/UserContext";
import { CenterInPageSpinner } from "../components/loading_indicator";

function ViewJobs() {
  const { currentUser, loading } = useUserContext();

  return (
    <>
      {loading ? (
        <CenterInPageSpinner />
      ) : currentUser?.type === UserType.STUDENT ? (
        <StudentJobsView />
      ) : (
        <PosterJobsView />
      )}
    </>
  );
}

export default ViewJobs;
