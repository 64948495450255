import Chips from "../chips";
import { DocumentIcon } from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";
import { Job } from "../../API";
import DisplayJobStatus from "./job_status";
import { formatDateString } from "../../helpers/Utilities";
import { useState } from "react";
import UserProfileImage from "../student/userProfileImage";

interface JobCardsProps {
  job: Job;
  showApplicants?: boolean;
}

function JobCards({ job, showApplicants }: JobCardsProps) {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showFullTitle, setShowFullTitle] = useState(false);
  const currentURL = useLocation();
  const showMoreTitle = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setShowFullTitle(!showFullTitle);
  };
  const showMoreDescription = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setShowFullDescription(!showFullDescription);
  };
  return (
    <Link
      to={`/job_listing/${job.id}`}
      // className="text-left flex flex-col lg:flex-row gap-8 rounded-2xl md:p-10 p-5 shadow-md  mb-8 overflow-clip"
      className="relative text-left flex flex-col lg:flex-row gap-8 rounded-2xl md:p-10 p-5 shadow-md  mb-8 overflow-clip"
      style={{ position: "relative" }}
    >
      {currentURL?.pathname !== "/home" &&
        currentURL?.pathname !== "/view_jobs" && (
          <h6 className="absolute bottom-0 left-0 pt-[2px] text-white bg-gray-700 text-center h-[28px] w-[52px] rounded-tl-none rounded-tr-[10px] rounded-bl-[10px] rounded-br-none">
            {job?.indexKey}
          </h6>
        )}

      {/* <div className="flex w-[100px] h-[100px] rounded-md bg-orange-50 items-center ">
        <DocumentIcon className="stroke-orange-300 w-[40px] h-[40px] m-auto self-center" />
      </div> */}
      <div
        className={`flex w-[100px] h-[100px] items-center bg-gray-100 relative hover:bg-gray-200 hover:cursor-pointer rounded-full text-gray-700 border-[3.2px] ${
          job?.poster?.type === "POSTER"
            ? "border-purple-500"
            : job?.poster?.type === "STUDENT"
            ? "border-green-500"
            : "border-unknown"
        }`}
      >
        <UserProfileImage
          photoKey={job?.poster?.photoKey}
          poster={job?.poster}
        />
      </div>
      <div className="w-[85%]">
        <h5
          className="mb-2 text-xl w-full font-bold whitespace-pre-wrap break-words leading-tight text-gray-700 hover:text-[teal] truncate"
          style={{ maxWidth: "100%" }}
        >
          {job.title
            ? job.title
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")
            : ""}
        </h5>

        {job?.createdAt && (
          <div className="lg:text-right sm:text-left text-[teal] font-bold hover:text-[teal]">
            Created On :{" "}
            <span className=" text-gray-600">
              {formatDateString(job?.createdAt)}
            </span>
          </div>
        )}
        <p className="mb-4 font-med text-gray-600 pb-4 break-words lg:w-[85%] w-full mr-5">
          {showFullDescription
            ? job.description
            : `${job.description
                ?.charAt(0)
                .toUpperCase()}${job.description?.slice(1, 280)}`}
          {job?.description && job.description.length > 280 && (
            <span
              className="text-[teal] cursor-pointer hover:underline mr-10"
              onClick={showMoreDescription}
            >
              {showFullDescription ? " .Show less" : " .Show more"}
            </span>
          )}
        </p>

        <div className="lg:flex lg:gap-0 gap-5 grid justify-between">
          <Chips job={job} showApplicants={showApplicants} />
          <DisplayJobStatus status={job.jobStatus} />
        </div>
      </div>
    </Link>
  );
}

export default JobCards;
