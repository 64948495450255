/* eslint-disable react/no-unescaped-entities */
import { Amplify, I18n } from "aws-amplify";
import {
  Authenticator,
  useAuthenticator,
  CheckboxField,
  SelectField,
  translations,
  useTheme,
  View,
  Text,
  Input,
  TextField,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Link, useLocation } from "react-router-dom";
import { logEvent } from "../helpers/Analytics";
import { Loader } from "@googlemaps/js-api-loader";
import awsExports from "../aws-exports.js";
import { useEffect, useRef, useState } from "react";
import { GOOGLE_MAPS_API_KEY } from "../utils/constants";
import { parseLocation } from "../helpers/Utilities";

Amplify.configure(awsExports);

interface LocationState {
  latitude: number;
  longitude: number;
  locationName?: string | undefined;
  locationData: any;
  city: string;
  state: string;
}

function SignUpForm() {
  // const currentURL = useLocation().pathname;
  const location = useLocation();
  const [currentURL, setCurrentURL] = useState(location.pathname);
  const [geoLocation, setGeoLocation] = useState<LocationState | null>(null);
  const [geo_location, set_geo_location] = useState<LocationState | null>(null);
  const [geoCity, setGeoCity] = useState<string | undefined>("");
  const [geoState, setGeoState] = useState<string | undefined>("");
  const locationCityStateRef = useRef<HTMLInputElement | null>(null);
  const locationCoordinateLat = useRef<number | null>(null);
  const locationCoordinateLong = useRef<number | null>(null);
  const autoCompleteRef = useRef<google.maps.places.Autocomplete | undefined>();

  const options = {
    componentRestrictions: { country: "us" }, // US Only.
    fields: ["geometry"],
    strictBounds: false,
    types: ["(cities)"], // Only search for cities.
  };

  /// Loads the Google Maps API.
  function loadMapsAPI() {
    new Loader({
      apiKey: GOOGLE_MAPS_API_KEY, // Colledge Key.
      version: "weekly",
      libraries: ["places"],
    })
      .importLibrary("places")
      .then((places) => {
        var input = document.getElementById("cityState") as HTMLInputElement;

        autoCompleteRef.current = new places.Autocomplete(input, options);

        autoCompleteRef.current.addListener(
          "place_changed",
          getCoordinatesFromPlace
        );
      })
      .catch((error) => {
        console.error("Error loading Maps API: ", error);
      });
  }

  useEffect(() => {
    if (!autoCompleteRef.current) {
      loadMapsAPI();
    }
  }, []);

  function getCoordinatesFromPlace() {
    // Get the place details from the autocomplete object.
    const place = autoCompleteRef.current?.getPlace();

    // Get Lat/Long coordinates.
    if (place?.geometry?.location) {
      const lat = place?.geometry?.location.lat();
      const lng = place?.geometry?.location.lng();
      locationCoordinateLat.current = lat;
      locationCoordinateLong.current = lng;
    }
    var input = document.getElementById("cityState") as HTMLInputElement;
    if (input.value && input.value.trim() !== "") {
      let split = input.value.split(",");
      input.value = split[0]?.trim();

      const splitString = localStorage.getItem("split");
      let state_selected = document.getElementById(
        "state_selected"
      ) as HTMLInputElement;
      if (splitString !== null && split.length == 1) {
        const split = JSON.parse(splitString);
        state_selected.value = split[1]?.trim();
      } else {
        state_selected.value = split[1]?.trim();
      }
    }
  }

  /// Handles autocompletion for the City/State field.
  // function getCoordinatesFromPlace() {
  //   // Get the place details from the autocomplete object.
  //   const place = autoCompleteRef.current?.getPlace();
  //   // Get Lat/Long coordinates.
  //   if (place?.geometry?.location) {
  //     const lat = place?.geometry?.location.lat();
  //     const lng = place?.geometry?.location.lng();
  //     locationCoordinateLat.current = lat;
  //     locationCoordinateLong.current = lng;
  //   }
  //   var input = document.getElementById("cityState") as HTMLInputElement;
  //   //
  //   // const inputValue = input.value;
  //   // let address = parseLocation(inputValue);
  //   // let address_location = {
  //   //   city: address?.city || inputValue,
  //   //   state: address?.state || inputValue,
  //   // };
  //   //
  //   let address = parseLocation(locationCityStateRef.current!?.value);
  //   let address_location = {
  //     city: address?.city || locationCityStateRef.current!.value,
  //     state: address?.state || locationCityStateRef.current!.value,
  //   };
  //   input.value = address_location?.city;

  //   let state_selected = document.getElementById(
  //     "state_selected"
  //   ) as HTMLInputElement;
  //   state_selected.value = address_location.state;
  // }

  const handleKeyCapture = (e: any) => {
    if (e.key === "Enter") {
      const inputElement = e.target;
      if (inputElement) {
        let split = inputElement.value.split(",");
        localStorage.setItem("split", JSON.stringify(split));
        var input = document.getElementById("cityState") as HTMLInputElement;
        input.value = "";
      }
    }
  };

  const statesArray = [
    { state_key: "AL", state_value: "Alabama" },
    { state_key: "AK", state_value: "Alaska" },
    { state_key: "AZ", state_value: "Arizona" },
    { state_key: "AR", state_value: "Arkansas" },
    { state_key: "CA", state_value: "California" },
    { state_key: "CO", state_value: "Colorado" },
    { state_key: "CT", state_value: "Connecticut" },
    { state_key: "DE", state_value: "Delaware" },
    { state_key: "DC", state_value: "District Of Columbia" },
    { state_key: "FL", state_value: "Florida" },
    { state_key: "GA", state_value: "Georgia" },
    { state_key: "HI", state_value: "Hawaii" },
    { state_key: "ID", state_value: "Idaho" },
    { state_key: "IL", state_value: "Illinois" },
    { state_key: "IN", state_value: "Indiana" },
    { state_key: "IA", state_value: "Iowa" },
    { state_key: "KS", state_value: "Kansas" },
    { state_key: "KY", state_value: "Kentucky" },
    { state_key: "LA", state_value: "Louisiana" },
    { state_key: "ME", state_value: "Maine" },
    { state_key: "MD", state_value: "Maryland" },
    { state_key: "MA", state_value: "Massachusetts" },
    { state_key: "MI", state_value: "Michigan" },
    { state_key: "MN", state_value: "Minnesota" },
    { state_key: "MS", state_value: "Mississippi" },
    { state_key: "MO", state_value: "Missouri" },
    { state_key: "MT", state_value: "Montana" },
    { state_key: "NE", state_value: "Nebraska" },
    { state_key: "NV", state_value: "Nevada" },
    { state_key: "NH", state_value: "New Hampshire" },
    { state_key: "NJ", state_value: "New Jersey" },
    { state_key: "NM", state_value: "New Mexico" },
    { state_key: "NY", state_value: "New York" },
    { state_key: "NC", state_value: "North Carolina" },
    { state_key: "ND", state_value: "North Dakota" },
    { state_key: "OH", state_value: "Ohio" },
    { state_key: "OK", state_value: "Oklahoma" },
    { state_key: "OR", state_value: "Oregon" },
    { state_key: "PA", state_value: "Pennsylvania" },
    { state_key: "RI", state_value: "Rhode Island" },
    { state_key: "SC", state_value: "South Carolina" },
    { state_key: "SD", state_value: "South Dakota" },
    { state_key: "TN", state_value: "Tennessee" },
    { state_key: "TX", state_value: "Texas" },
    { state_key: "UT", state_value: "Utah" },
    { state_key: "VT", state_value: "Vermont" },
    { state_key: "VA", state_value: "Virginia" },
    { state_key: "WA", state_value: "Washington" },
    { state_key: "WV", state_value: "West Virginia" },
    { state_key: "WI", state_value: "Wisconsin" },
    { state_key: "WY", state_value: "Wyoming" },
  ];

  I18n.putVocabularies(translations);
  I18n.putVocabulariesForLanguage("en", {
    "PreSignUp failed with error EDU.":
      "ERROR: You must register using your school '.edu' email address.",
  });

  const signUpAttributes = [
    "given_name",
    "family_name",
    "email",
    "phone_number",
  ];
  // Customize the default form field labels.
  const formFields = {
    signUp: {
      given_name: {
        label: "First Name",
        placeholder: "Enter your First Name",
        isRequired: true,
        order: 2,
      },
      family_name: {
        label: "Last Name",
        placeholder: "Enter your Last Name",
        isRequired: true,
        order: 3,
      },
      phone_number: {
        label: "Phone Number",
        placeholder: "Enter your Phone Number",
        isRequired: true,
        order: 4,
      },
    },
  };

  useEffect(() => {
    const newURL = location.pathname;

    // Check if the currentURL indicates a change that requires a reload
    if (newURL !== currentURL) {
      setCurrentURL(newURL);
      window.location.reload();
    }
  }, [location.pathname, currentURL]);

  return (
    <>
      <Authenticator
        // hide the social providers as of now
        socialProviders={[]}
        signUpAttributes={signUpAttributes as any}
        formFields={formFields}
        // initialState={"signIn"}
        initialState={currentURL === "/signup" ? "signUp" : "signIn"}
        // Customize `Authenticator.SignUp.FormFields`
        // Note: For a custom field to be sent to server, it must have "custom:" prefix.
        components={{
          Footer() {
            const { tokens } = useTheme();
            return (
              <View textAlign="center" padding={tokens.space.large}>
                <Text color={tokens.colors.neutral[80]}>
                  <Link
                    to={"/terms"}
                    className="hover:text-[teal]"
                    onClick={() => {
                      logEvent("signup_terms_conditions_button_clicked");
                    }}
                  >
                    Terms and conditions
                  </Link>
                </Text>
              </View>
            );
          },
          SignUp: {
            FormFields() {
              const { validationErrors } = useAuthenticator();
              return (
                <>
                  <SelectField
                    name="custom:usertype"
                    label="How would you describe yourself"
                    errorMessage="Please select an option."
                    labelHidden={false}
                  >
                    {/* Values must match UserType.STUDENT / UserType.POSTER */}
                    <option value="STUDENT">
                      I'm a Student looking for a job.
                    </option>
                    <option value="POSTER">I want to hire a Student</option>
                  </SelectField>

                  {/* Re-use default `Authenticator.SignUp.FormFields` */}
                  <Authenticator.SignUp.FormFields />

                  {/* This value is used in the background check only. */}
                  {/* <SelectField
                    name="custom:userlocation"
                    label="Where do you plan to work/post jobs?"
                    errorMessage={
                      validationErrors["custom:userlocation"] as any
                    }
                    hasError={!!validationErrors["custom:userlocation"]}
                    labelHidden={false}
                  >
                    <option value="">Select your state...</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </SelectField> */}
                  {/* Text Field to enter the name of the city */}
                  <TextField
                    ref={locationCityStateRef}
                    id="cityState"
                    type="text"
                    onKeyDownCapture={handleKeyCapture}
                    onKeyDown={(e) => e.stopPropagation()}
                    className="border-0 relative w-full text-gray-900 text-sm leading-6 bg-none GothamBook focus:border-transparent focus:ring-0 bg-gray-50 ring:none shadow:none"
                    name="custom:city"
                    placeholder="Which city do you live in?"
                    label="Enter your city name"
                    errorMessage="Please enter a value for the city."
                    onChange={(e) => {
                      // setGeoCity(e.target.value);
                      loadMapsAPI();
                    }}
                    // onMouseEnter={(e) => {
                    //   const inputElement = e.target as HTMLInputElement;
                    //   inputElement.focus();
                    // }}
                    // onMouseDown={(e) => {
                    //   const inputElement = e.target as HTMLInputElement;
                    // }}
                    // value={geoCity}
                  />
                  <SelectField
                    // onSubmit={(e) => e.preventDefault()}
                    id="state_selected"
                    name="custom:userlocation"
                    label="Where do you plan to work/post jobs?"
                    errorMessage={
                      validationErrors["custom:userlocation"] as any
                    }
                    hasError={!!validationErrors["custom:userlocation"]}
                  >
                    <option value="">Select your state...</option>

                    {statesArray.map((state) => (
                      <option value={state?.state_key}>
                        {state?.state_value}
                      </option>
                    ))}
                  </SelectField>

                  {/* <SelectField
                    name="custom:userlocation"
                    label="Where do you plan to work/post jobs?"
                    errorMessage={
                      validationErrors["custom:userlocation"] as any
                    }
                    hasError={!!validationErrors["custom:userlocation"]}
                    value={geoState}
                  >
                    <option value="">Select your state...</option>

                    {statesArray.map((state) => (
                      <option value={state?.state_key}>
                        {state?.state_value}
                      </option>
                    ))}
                  </SelectField> */}

                  {/* Text Field to enter the name of the ZIP code */}
                  <TextField
                    name="custom:pincode"
                    placeholder="Enter PIN/ZIP code"
                    label="Enter PIN/ZIP code"
                    errorMessage="Please enter a value for the PIN/ZIP code."
                    labelHidden={false}
                    // value={pinCode}
                    // onChange={(e) => setPinCode(e.target.value)}
                    // onMouseEnter={(e) => {
                    //   const inputElement = e.target as HTMLInputElement;
                    //   inputElement.focus();
                    // }}
                  />
                  {/* Append & require Terms & Conditions field to sign up  */}
                  <CheckboxField
                    errorMessage={
                      validationErrors["custom:acknowledgement"] as any
                    }
                    hasError={!!validationErrors["custom:acknowledgement"]}
                    name="custom:acknowledgement"
                    value="yes"
                    label="I agree with the Terms & Conditions"
                  />
                </>
              );
            },
          },
        }}
        services={{
          async validateCustomSignUp(formData: any, touchData: any) {
            let errors = {} as any;
            if (!formData["custom:acknowledgement"]) {
              errors["custom:acknowledgement"] =
                "You must agree to the Terms & Conditions (see below).";
            }

            if (formData["custom:userlocation"] === "") {
              errors["custom:userlocation"] = "You must select a location.";
            }
            return errors;
          },
        }}
        // services={{
        //   async validateCustomSignUp(formData: any, touchData: any) {
        //     let errors = {} as any;
        //     if (!formData["custom:acknowledgement"]) {
        //       errors["custom:acknowledgement"] =
        //         "You must agree to the Terms & Conditions (see below).";
        //     }
        //     if (
        //       !formData["custom:userlocation"] ||
        //       formData["custom:userlocation"] === ""
        //     ) {
        //       errors["custom:userlocation"] = "You must select a location.";
        //     }
        //     return errors;
        //   },
        // }}
      />
    </>
  );
}

export default SignUpForm;
