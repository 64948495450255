import { StarIcon } from "@heroicons/react/20/solid";
import ReportContentButton from "../buttons/report_content_button";
import ReviewsDialog from "./reviews_dialog";
import { useEffect, useState } from "react";
import { FlaggedContentType, User, UserReview } from "../../API";
import * as dataProvider from "../../graphql/DataProvider";
import { formattedName } from "../../helpers/Utilities";
import { logEvent } from "../../helpers/Analytics";

interface ReviewsCardProps {
  /// User for which we want to show reviews for.
  user: User | null | undefined;
  canLeaveReview: boolean;
  onReload: () => void;
}

/// Extended Review object that contains the user object that
/// wrote the review.
interface UserReviewWithUser extends UserReview {
  userReviewFromUser: User;
}

function ReviewsCard({ user, canLeaveReview, onReload }: ReviewsCardProps) {
  const [reviews, setReviews] = useState<UserReviewWithUser[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  /// If the current user can leave a review for the user being viewed.
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    fetchData();
  }, [user]);

  async function fetchData() {
    if (!user) return;
    setIsLoading(true);
    let reviews: UserReviewWithUser[] = [];
    for (const review of user?.receivedReviews?.items as UserReview[]) {
      const reviewedUser = await dataProvider.getUser(
        review?.userReviewFromUserUserID
      );
      if (reviewedUser) {
        reviews.push({ ...review, userReviewFromUser: reviewedUser });
      }
    }
    setReviews(reviews);
    setIsLoading(false);
  }
  const showDialog = () => {
    setIsOpen(true);
  };

  return (
    <>
      <div className="bg-white flex items-start p-6 lg:p-14 flex-col w-11/12 m-auto lg:w-full text-left shadow-md rounded-xl h-auto">
        <div className="flex justify-center w-full">
          <div className="inline w-full relative">
            <div className="flex gap-4 items-center relative w-full flex-row justify-between">
              <div className="flex justify-start gap-4 items-center w-full">
                <h2 className="text-gray-700 text-3xl font-semibold items-center w-full">
                  Reviews
                </h2>
                {canLeaveReview && (
                  <div className="flex gap-2 align-center w-full justify-end">
                    <button
                      onClick={() => {
                        showDialog();
                        logEvent("profile_write_review_buton_clicked");
                      }}
                      className="text-gray-50 bg-orange-400 rounded-full w-auto GothamBold"
                    >
                      Write review
                    </button>
                  </div>
                )}
              </div>
            </div>
            {isLoading ? (
              <div className="flex items-center justify-center ">
                {/* <CustomSpinner /> */}
                <div className="flex justify-center w-full mt-2">
                  <div className="inline w-full relative">
                    <div className="flex items-center justify-center my-4 h-20 rounded skeleton-block"></div>
                    <div className="text-gray-700 pt-2 GothamBook w-[180px] h-6   rounded  skeleton-block"></div>
                    <div className="text-gray-700 pt-2  w-[140px] h-6 mt-4 GothamBook rounded  skeleton-block"></div>
                  </div>
                </div>
              </div>
            ) : reviews?.length === 0 ? (
              <div className="text-gray-700 pt-2 GothamBook">No reviews yet.</div>
            ) : (
              reviews?.map((review: UserReviewWithUser, index) => {
                return (
                  <div className="flex w-full" key={index}>
                    <div className="flex w-full items-center mt-8 gap-16 mr-4">
                      <div className="flex flex-col w-11/12 text-gray-700">
                        <div className="flex items-center gap-2 rounded-full pr-6">
                          {[...Array(5)].map((star, index) => {
                            index += 1;
                            if (index <= review.rating) {
                              return (
                                <div
                                  className="w-6 text-yellow-400"
                                  key={index}
                                >
                                  <StarIcon className="h-full m-auto"></StarIcon>
                                </div>
                              );
                            } else {
                              return (
                                <div className="w-6 text-gray-200" key={index}>
                                  <StarIcon className="h-full m-auto"></StarIcon>
                                </div>
                              );
                            }
                          })}
                          <h3 className="text-gray-700 text-md font-bold pt-1">
                            {review.rating}
                          </h3>
                        </div>
                        <p className="pt-4 text-md w-full lg:w-11/12">
                          {review.message}
                        </p>
                        <p className="pt-4 text-md w-11/12 font-semibold">
                          {formattedName(review.userReviewFromUser)}
                        </p>
                      </div>
                    </div>
                    <div className="hidden lg:block mt-4">
                      <ReportContentButton
                        contentType={FlaggedContentType.USER_REVIEW}
                        flaggedUser={review.userReviewFromUser}
                        contentId={review.id}
                      />
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
        <div className="relative w-full h-full bg-gray-800 opacity-50 top-0 left-0">
          <div className="flex justify-center self-center w-full h-full">
            <ReviewsDialog
              user={user!}
              open={isOpen}
              onClose={({ refetch }) => {
                setIsOpen(false);
                refetch && onReload();
              }}
            ></ReviewsDialog>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReviewsCard;
