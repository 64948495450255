import { useRef } from "react";
import { Listbox } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import * as adminDataProvider from "../../graphql/AdminDataProvider";
import * as stripeHandler from "../../graphql/StripeHandler";
import * as dataProvider from "../../graphql/DataProvider";
import { EmailTemplate, sendEmail } from "../../graphql/EmailHandler";
import { BackgroundCheckLog, User, UserStatus, UserType } from "../../API";
import { useEffect, useState } from "react";
import { RedColorChip, GreenColorChip, GrayColorChip } from "../color_chips";
import MenuButton, { MenuButtonOption } from "../buttons/menu_button";
import * as authStatus from "../../helpers/AuthStatus";
import { capitalCaseString, getColledgeURL } from "../../helpers/Utilities";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { formatDate } from "../../utils/helpers";

/// Red bubble with user group dropdown.
const redUserDropdownValue = (
  user: User,
  selectedGroup: UserGroup,
  onChange: (newGroupID: string) => void
) => {
  return (
    <RedColorChip value={userGroupDropdown(user, selectedGroup, onChange)} />
  );
};
/// Green bubble with user group dropdown.
const greenUserDropdownValue = (
  user: User,
  selectedGroup: UserGroup,
  onChange: (newGroupID: string) => void
) => {
  return (
    <GreenColorChip value={userGroupDropdown(user, selectedGroup, onChange)} />
  );
};

/// DropDown for selecting user group membership.
const userGroupDropdown = (
  user: User,
  selectedGroup: UserGroup,
  onChange: (newGroupID: string) => void
) => {
  return (
    <>
      <Listbox
        key={user.userID + "_dropDown"}
        value={selectedGroup.id}
        onChange={onChange}
      >
        <Listbox.Button className="cursor-default relative bg-transparent text-center text-sm p-0 w-[100px] h-[34px] flex items-center">
          <span className="block truncate">{selectedGroup.name}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-0">
            <ChevronDownIcon
              className="h-5 w-5 text-black-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Listbox.Options className="absolute top-14 right-0 w-[150px] text-left py-2 overflow-visible rounded-md bg-white md:text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm z-10">
          {groupOptions.map((group: UserGroup, index) => (
            <Listbox.Option
              key={index}
              className={({ active }) =>
                `relative cursor-default select-none py-4 p-4 ${
                  active ? "bg-gray-100 text-gray-900" : "text-gray-900"
                }`
              }
              value={group.id}
            >
              {({ selected }) => (
                <>
                  <span
                    className={`block ${
                      selected ? "font-bold" : "font-normal"
                    }`}
                  >
                    {group.name}
                  </span>
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </>
  );
};

/// Available user groups.
const groupOptions: UserGroup[] = [
  { id: "Pending_Users", name: "Pending" },
  { id: "Approved_Users", name: "Approved" },
  { id: "Denied_Users", name: "Denied" },
];

/// Extended user object to include add'l properties.
interface UserGroup {
  id: string;
  name: string;
}

interface AccountRowProps {
  index: number;
  userToDisplay: any;
}

/// A row in the ManageAccounts table.
export default function AccountRowFE({
  index,
  userToDisplay,
}: AccountRowProps) {
  const hasMounted = useRef(false);
  const [userGroup, setUserGroup] = useState<UserGroup | null>(null);
  const [backgroundCheckStatus, setBackgroundCheckStatus] =
    useState<string>("N/A");

  const navigate = useNavigate();

  const posterMenuOptions: MenuButtonOption[] = [
    {
      name: "View Profile",
      onClick: async () => {
        navigate(`/profile/${userToDisplay.userID}`, { state: userToDisplay });
      },
    },
    {
      name: "New Background Check",
      onClick: async () => {
        const result = await adminDataProvider.startBackgroundCheckForUser(
          userToDisplay
        );
        if (result) {
          toast.success("Background Check sent.", { toastId: "bgCheckSent" });
        } else {
          toast.error("Error starting Background Check.", {
            toastId: "bgCheckError",
          });
        }
      },
    },
    {
      name: "Chekr Profile",
      onClick: async () => {
        let chekrURL = "";
        switch (process.env.REACT_APP_ENV) {
          case "development":
            chekrURL = "https://dashboard.checkrhq-staging.net/candidates/";
            break;
          case "production":
            chekrURL = "https://dashboard.checkr.com/candidates/";
            break;
        }
        window.open(
          chekrURL + `${userToDisplay.candidateID}`,
          "_blank",
          "noreferrer"
        );
      },
    },
    {
      name: "AWS Cognito Profile",
      onClick: async () => {
        const userPoolID = await authStatus.userCognitoPoolID();
        window.open(
          `https://us-east-2.console.aws.amazon.com/cognito/v2/idp/user-pools/${userPoolID}/users/details/${userToDisplay.userID}?region=us-east-2`,
          "_blank",
          "noreferrer"
        );
      },
    },
  ];

  const studentMenuOptions: MenuButtonOption[] = [
    ...posterMenuOptions,
    {
      name: "Stripe Connect",
      onClick: () => {
        // Determine if we should open to stripe's test mode or not.
        let useTest = "";
        switch (process.env.REACT_APP_ENV) {
          case "development":
            useTest = "test/";
            break;
          case "production":
            useTest = "";
            break;
        }
        window.open(
          "https://dashboard.stripe.com/" +
            useTest +
            "connect/accounts/" +
            userToDisplay.stripeAccountID,
          "_blank",
          "noreferrer"
        );
      },
    },
  ];

  useEffect(() => {
    if (!hasMounted.current) {
      adminDataProvider
        .listGroupsForUser(userToDisplay.name || userToDisplay.userID)
        .then((groups) => {
          if (!groups) {
            setUserGroup(null);
            return;
          }
          const group: UserGroup = {
            id: groups[0].GroupName,
            name: groups[0].GroupName.split("_")[0],
          };
          setUserGroup(group); 
        });
        processBackgroundCheckStatus(userToDisplay?.bgCheck)
      // adminDataProvider.getUserBackgroundCheckLog(userToDisplay).then((log) => {
      //   processBackgroundCheckStatus(log);
      // });
      // Update the ref to true so that the effect won't run again
      hasMounted.current = true;
    }
    return () => {
      hasMounted.current = false;
    };
  }, [userToDisplay]);
  

  /// Process the background check log to determine the status.
  /// https://docs.google.com/document/d/1z3TEao_fOFWOJOtRtMlcpsQXptimOrnbjE0Z_vHEp_E/edit#bookmark=kix.rry4wnymgm7
  function processBackgroundCheckStatus(log: BackgroundCheckLog | null) {
    if (!log) {
      setBackgroundCheckStatus("N/A");
      return;
    }

    if (log?.reportAdjudication) {
      const formatted = capitalCaseString(log?.reportAdjudication!);
      setBackgroundCheckStatus(formatted);
    } else if (log?.reportResult) {
      const formatted = capitalCaseString(log?.reportResult!);
      setBackgroundCheckStatus(formatted);
    } else if (log?.reportStatus) {
      if (log?.reportStatus === "completed" && log?.reportResult === null) {
        setBackgroundCheckStatus("Cancelled");
      } else {
        const formatted = capitalCaseString(log?.reportStatus!);
        setBackgroundCheckStatus(formatted);
      }
    } else if (log?.inviteStatus) {
      const formatted = capitalCaseString(log?.inviteStatus!);
      setBackgroundCheckStatus(formatted);
    } else {
      setBackgroundCheckStatus("N/A");
    }
  }

  const onDropDownChange = (newGroupID: string) => {
    // If user select the same option for status, just return
    if (newGroupID === userGroup?.id) {
      return;
    }
    toast.loading("Updating Status...");
    adminDataProvider
      .removeUserFromGroup(userToDisplay.name, userGroup!.id)
      .then(() => {
        adminDataProvider
          .addUserToGroup(userToDisplay.name, newGroupID)
          .then(async () => {
            const group: UserGroup = {
              id: newGroupID,
              name: newGroupID.split("_")[0],
            };
            setUserGroup(group);
            await dataProvider.updateUserStatus(
              userToDisplay.name,
              group.name.toUpperCase()
            );
            // If the user is a student, create a stripe express account for them.
            if (
              userToDisplay.type === UserType.STUDENT &&
              newGroupID === "Approved_Users"
            ) {
              // if there is already a stripe account id created, dont create another
              if (!userToDisplay?.stripeAccountID) {
                const response =
                  await stripeHandler.createStripeExpressUserAccount(
                    userToDisplay
                  );
                if (response.id) {
                  await dataProvider.updateUserStripeAccountId(
                    userToDisplay,
                    response.id
                  );
                }
              }
            }
            // Send email with the change.
            let templateId = "";
            let actionURL = getColledgeURL();
            switch (newGroupID) {
              case "Approved_Users":
                templateId = "d-1dc66156102c412d9ddf6c445a68c7d2";
                break;
              case "Pending_Users":
                templateId = "d-2c28f3ab5fda448c8a5d59a7b881888e";
                break;
              case "Denied_Users":
                templateId = "d-4f3754319b7c43269f0bb4351bb5842a";
                break;
              default:
                return; // No email for unknown groups.
            }
            const params: EmailTemplate = {
              toEmail: userToDisplay.email,
              fromEmail: "hello@colledge.us",
              templateId: templateId,
              actionURL: actionURL,
            };
            toast.dismiss();
            sendEmail(params).then((success) => {
              if (success) {
                toast.success("User updated successfully.", {
                  toastId: "sendEmail",
                });
              } else {
                toast.error("Error updating user.", { toastId: "sendEmail" });
              }
            });
          });
      })
      .catch((err) => {
        if (err) {
          toast.dismiss();
        }
      });
  };
  return (
    <tr key={index}>
      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm w-4/12">
        <div className="flex items-center">
          <div>
            <p className="text-gray-700 font-bold whitespace-no-wrap text-left">
              {userToDisplay?.firstName} {userToDisplay?.lastName}
            </p>
            <p className="text-gray-700 whitespace-no-wrap text-left ">
              <a
                href={"mailto:" + userToDisplay?.email}
                className="hover:text-[teal]"
              >
                {userToDisplay?.email}
              </a>
            </p>
          </div>
        </div>
      </td>
      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
        <p className="text-gray-900 whitespace-no-wrap">
          {userToDisplay?.type === UserType.STUDENT ? "Student" : "Poster"}
        </p>
      </td>
      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
        {userGroup?.id === "Pending_Users" &&
          redUserDropdownValue(userToDisplay, userGroup, onDropDownChange)}
        {userGroup?.id === "Denied_Users" &&
          redUserDropdownValue(userToDisplay, userGroup, onDropDownChange)}
        {userGroup?.id === "Approved_Users" &&
          greenUserDropdownValue(userToDisplay, userGroup, onDropDownChange)}
            {userGroup?.id === "us-east-2_Sgo8NPNst_Google" &&
          greenUserDropdownValue(userToDisplay, userGroup, onDropDownChange)}
        {userGroup?.id === "Admins" && <GrayColorChip value={userGroup.name} />}
      </td>
      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
        {userToDisplay.type === UserType.POSTER &&
          (userToDisplay.subscription ? (
            <GreenColorChip value={"Active"} />
          ) : (
            <RedColorChip value={"Inactive"} />
          ))}
        {userToDisplay.type === UserType.STUDENT && (
          <GrayColorChip value={"N/A"} />
        )}
      </td>
      <td className="px-5 text-center py-5 border-b border-gray-200 bg-white text-sm">
        {backgroundCheckStatus === "Clear" ? (
          <p className="text-green-900 whitespace-no-wrap">
            {backgroundCheckStatus}
          </p>
        ) : (
          <p className="text-gray-900 whitespace-no-wrap">
            {backgroundCheckStatus}
          </p>
        )}
      </td>
      <td className="px-5 text-center py-5 border-b border-gray-200 bg-white text-sm">
        {formatDate(userToDisplay.createdAt)}
      </td>
      <td className="px-5 text-left py-5 border-b border-gray-200 bg-white text-sm">
        <MenuButton
          menuName="Options"
          options={
            userToDisplay.type === UserType.STUDENT
              ? studentMenuOptions
              : posterMenuOptions
          }
        />
      </td>
    </tr>
  );
}
