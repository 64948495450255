import { Fragment, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { User } from "../../API";

interface StudentSelectProps {
  students: User[];
  onSelectMultiple: (student: User[]) => void;
  isInviteAllDisabled: boolean;
  setSelectStudent: (student: User[]) => void; 
  selectedStudent: User[]; 
}

export default function StudentSelect({
  students,
  onSelectMultiple,
  isInviteAllDisabled,
  selectedStudent,
  setSelectStudent,
}: StudentSelectProps) {
  // const [selectedPeople, setSelectedPeople] = useState<User[]>([]);
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const togglePerson = (person: User) => {
    if (selectedStudent.includes(person)) {
      setSelectStudent(selectedStudent.filter((p) => p !== person));
    } else {
      setSelectStudent([...selectedStudent, person]);
      onSelectMultiple([...selectedStudent, person]);
    }
  };

  const clearSelected = () => {
    setSelectStudent([]);
    onSelectMultiple([]);
  };

  const StudentDelete = (person: User) => {
    setSelectStudent(selectedStudent.filter((p) => p !== person));
    onSelectMultiple(selectedStudent.filter((p) => p !== person));
  };


  const filteredPeople =
    query === ""
      ? students
      : students.filter((person) =>
          `${person.firstName} ${person.lastName}`
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  return (
    <div className="flex justify-center items-center  md:w-[80%] w-full min-w-[-webkit-fill-available] md:min-w-auto">
      <div className="w-full">
        <div className="relative mt-1">
          <div
            className="relative w-full min-h-[60px] cursor-pointer overflow-hidden rounded-lg  text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm text-teal border-teal border-[1.7px]  bg-[#138086] bg-opacity-10 items-center h-full flex"
            onClick={toggleDropdown}
          >
            <div className="w-full border-none py-2 pl-6 text-sm leading-5 text-gray-900 break-words pr-14">
              {selectedStudent.length === 0 ? (
                <span className="text-teal text-lg GothamBold">
                  Select Students...
                </span>
              ) : (
                selectedStudent.map((person) => (
                  <span
                    className="text-teal bg-[#138086] whitespace-nowrap relative bg-opacity-10 text-base px-3 py-2  font-bold  rounded-sm mr-2 leading-[38px]
                    "
                    key={person.firstName}
                  >
                    {person.firstName}{" "}
                    <XMarkIcon
                      onClick={() => StudentDelete(person)}
                      className="h-0 w-0 absolute right-1 top-[6px] pointer-events-none bg-transparent p-0 m-0 opacity-0 text-black cursor-pointer"
                    />
                  </span>
                ))
              )}
            </div>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 read-only:">
              {isOpen ? (
                <ChevronUpIcon className="h-5 w-5 text-black" />
              ) : (
                <ChevronDownIcon className="h-5 w-5 text-black" />
              )}
              <div
                onClick={clearSelected}
                className="absolute right-9 top-[18px] bg-transparent p-0 m-0 "
              >
                <XMarkIcon className="h-5 w-5 bg-transparent p-0 m-0  text-black cursor-pointer" />
              </div>
            </div>
          </div>
          <Transition
            as={Fragment}
            show={isOpen}
            enter="transition ease-in-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in-out duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <div className="absolute mt-2 z-10 rounded-md w-full bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <div className="relative ">
                <input
                  type="search"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  className="w-full rounded-md p-2 border-2  border-teal outline-none focus:none focus-withi text-black text-lg font-bold "
                  placeholder="Search Students..."
                />
              </div>
              {filteredPeople.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none text-lg px-4 text-gray-800 font-bold py-4">
                  Nothing found.
                </div>
              ) : (
                <div className="max-h-[400px] overflow-y-scroll">
                  {filteredPeople.map((person: any) => (
                    <label
                      key={person.id}
                      className="relative border-b border-gray-100  cursor-pointer select-none flex items-center h-full py-4 px-2 gap-3 hover:bg-[#138086] hover:bg-opacity-10"
                    >
                      <input
                        type="checkbox"
                        checked={selectedStudent.includes(person)}
                        onChange={() => togglePerson(person)}
                        style={{
                          backgroundColor: selectedStudent.includes(person)
                            ? "#138086"
                            : "white",
                          border: "1px solid #138086",
                          color: "white",
                        }}
                        className="inset-0 p-2 ml-2 !outline-none !shadow-none"
                      />
                      <span className="block truncate text-teal GothamBook text-base font-bold">
                        {person.firstName}
                      </span>
                      <span className="inset-y-0 flex items-center ml-auto mr-5">
                        {selectedStudent.includes(person) && (
                          <CheckIcon
                            onChange={() => togglePerson(person)}
                            className="h-5 text-teal w-5"
                          />
                        )}
                      </span>
                    </label>
                  ))}
                </div>
              )}
            </div>
          </Transition>
        </div>
      </div>
    </div>
  );
}
