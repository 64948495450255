import GraphQLAPI from "@aws-amplify/api-graphql";
import { mutations } from ".";
import { Auth } from "aws-amplify";
import { MutationSendEmailQueryInput, SendEmailMutation } from "../API";

/// Sends an email with the given input.
export interface EmailTemplate extends MutationSendEmailQueryInput {}
export async function sendEmail(input: EmailTemplate): Promise<boolean> {
  const signedInUser = await Auth.currentUserInfo();
  try {
    if (!signedInUser) {
      return false;
    }

    // Template or subject must be provided.
    if (!input.templateId && !input.subject) {
      return false;
    }

    const response = (await GraphQLAPI.graphql({
      query: mutations.sendEmail,
      variables: { input: input },
    })) as { data: SendEmailMutation };
    return response.data.sendEmail === "true" ? true : false;
  } catch (err) {
    console.error("Error sending email:", err);
    return false;
  }
}
