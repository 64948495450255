import { StarIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { useEffect, useRef, useState } from "react";
import { Storage } from "aws-amplify";
import {
  formattedName,
  averageRatingForUser,
  formattedReviewText,
} from "../../helpers/Utilities";
import {
  ApplicationStatus,
  Job,
  JobApplication,
  JobStatus,
  User,
} from "../../API";
import userProfileIcon from "../../assets/userProfileIcon.svg";
import UserProfileImage from "../student/userProfileImage";
import JobCommunicationModal from "../jobs/job_communication";
interface JobPosterDetailsProps {
  poster: User | undefined;
  isHired?: any;
  job?: Job; // Change 'any' to the appropriate type
}

function JobPosterDetails({ poster, isHired, job }: JobPosterDetailsProps) {
  const [userAvgRating, setUserAvgRating] = useState(0);
  const [showContactModal, setShowContactModal] = useState<boolean>(false);

  useEffect(() => {
    setUserAvgRating(averageRatingForUser(poster));
  }, [poster?.userID]);

  const hiredStatusArray: boolean[] = [];

  isHired?.items?.forEach((application: JobApplication | null) => {
    if (!application) return null;

    const isHired =
      application.status === ApplicationStatus.ACCEPTED ||
      application.status === ApplicationStatus.COMPLETE;

    // Store the isHired value in the array
    hiredStatusArray.push(isHired);
  });
  return (
    <>
      <div className="bg-white text-left h-full flex items-start pt-4 flex-col w-full text-left shadow-md rounded-xl max-h-60 pb-20">
        <h3 className="text-lg text-gray-800 font-bold pb-3 pl-6 pr-6 mb-6 border-b w-full hover:text-[teal]">
          Job Poster
        </h3>
        <div className="flex pr-6 justify-start justify-around w-full">
          <div className="flex flex-row items-center gap-4 w-full pl-6">
            <div
              className={`w-12 h-12  rounded-full overflow-hidden flex items-center justify-center border-[3.2px] ${
                poster?.type === "POSTER"
                  ? "border-purple-500"
                  : poster?.type === "STUDENT"
                  ? "border-green-500"
                  : "border-unknown"
              }`}
            >
              {/* <div> */}
              <UserProfileImage photoKey={poster?.photoKey} poster={poster} />
              {/* </div> */}
            </div>
            <div className="flex text-left  flex-col gap-1 mr-4">
              <h3 className="text-gray-700 text-lg font-semibold hover:text-[teal]">
                {formattedName(poster)}
              </h3>
              <div className="flex items-center gap-1">
                <div className="w-5 h-5 text-yellow-400">
                  <StarIcon className="h-full m-auto"></StarIcon>
                </div>
                <h3 className="text-gray-700 text-md font-bold pt-1 GothamBook">
                  {userAvgRating > 0 ? userAvgRating : ""}
                </h3>
                <h3 className="text-gray-700 text-md pt-1 GothamBook">
                  {formattedReviewText(poster)}
                </h3>
              </div>

              {hiredStatusArray?.[0] && job?.jobStatus !== JobStatus.CLOSED && (
                <button
                  className="text-gray-50 bg-[teal] p-3  mt-4 pl-3 pr-3 rounded-full"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowContactModal(true);
                  }}
                >
                  Message
                </button>
              )}
            </div>
          </div>
          <div></div>
          <div className="pr-2 flex flex-row justify-evenly">
            <div className="w-10 h-10 bg-gray-100 rounded-full text-gray-600 self-center">
              <ChevronRightIcon className="w-6 h-full m-auto hover:text-[teal]"></ChevronRightIcon>
            </div>
          </div>
        </div>
      </div>
      <JobCommunicationModal
        // messageLog={isHired}
        toUser={poster}
        job={job}
        isHired={isHired?.items}
        isOpen={showContactModal}
        onClose={() => setShowContactModal(false)}
      />
    </>
  );
}

export default JobPosterDetails;
