import { useEffect, useState } from "react";
import Footer from "../components/nav/footer";
import JobDetail from "../components/jobs/job_detail";
import JobPosterDetails from "../components/profile/job_poster_details";
import SecondaryNav from "../components/nav/secondary_nav";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import * as dataProvider from "../graphql/DataProvider";
import ApplicantDetails from "../components/jobs/applicant_details";
import {
  ApplicationStatus,
  Job,
  JobApplication,
  JobStatus,
  UserType,
} from "../API";
import HireModal from "../components/jobs/hire_modal";
import { useUserContext } from "../helpers/UserContext";
import { toast } from "react-toastify";
import { CenterInPageSpinner } from "../components/loading_indicator";
import { getColledgeURL } from "../helpers/Utilities";
import { EmailTemplate, sendEmail } from "../graphql/EmailHandler";
import { logEvent } from "../helpers/Analytics";
import SkeletonJobDetails from "../components/Skeleton/SkeletonJobDetails";

function JobListing() {
  const [applicantLoading, setApplicantLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { id, status } = useParams();
  if (!id) {
    navigate("/home", { replace: true });
  }
  const [job, setJob] = useState<Job | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hireModalOpen, setHireModalOpen] = useState<boolean>(false);

  const [sentReviewEmails, setSentReviewEmails] = useState<boolean>(false);
  /// The application that was hired for the job.
  const [acceptedApplication, setAcceptedApplication] =
    useState<JobApplication | null>(null);
  const { currentUser } = useUserContext();
  useEffect(() => {
    fetchData();
  }, [id]);
  // Handles response from Stripe

  const handlePaymentSuccess = async (status: string) => {
    if (status === "success") {
      dataProvider.getJob(id).then(async (job) => {
        if (job) {
          for (const application of job?.applicants
            ?.items as JobApplication[]) {
            // Send email to student asking for a payment success.
            let templateId = "d-1212b9ee3e434b15abd223b953f85334"; // Completed Job.
            let fromEmail = "hello@colledge.us";

            const studentMessage = `Congratulations ${application.applicant.firstName}, You've been paid for your ${job?.title}. Please check your Stripe account - which you can access through your profile with below Button.`;
            const studentParams: EmailTemplate = {
              toEmail: application.applicant.email,
              fromEmail: fromEmail,
              templateId: templateId,
              message: studentMessage,
              actionURL: getColledgeURL() + "profile/" + job.poster.userID,
            };
            await sendEmail(studentParams);

            //Payments success template for student for stripe account check Please check your Stripe

            templateId = "d-0ab6f18103bb40beb47d514932d229c2";
            const posterName = `Congratulations ${job?.poster.fullName}, Your job ${job?.title} is now closed.  You can leave a review of the student you hired below`;
            const posterEmailParams: EmailTemplate = {
              toEmail: job.poster.email,
              fromEmail: fromEmail,
              templateId: templateId,
              message: posterName,
              actionURL:
                getColledgeURL() + "profile/" + application.applicant.userID,
            };
            await sendEmail(posterEmailParams);
            setSentReviewEmails(true);
          }

          // Display a success toast
          toast.success("Payment successful!", { toastId: "paymentSuccess" });
        }
      });
      // Additional logic if needed, e.g., mark job as complete
    } else if (status === "cancel") {
      // Display an error toast for canceled payment
      toast.error("Payment not completed.", { toastId: "paymentError" });
    }
  };

  useEffect(() => {
    if (status) {
      handlePaymentSuccess(status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);
  /// Closes out job and applicant if payment was successful.
  async function closeOutJob() {
    if (status !== "success") return;
    if (job && acceptedApplication) {
      await dataProvider.updateJobStatus(JobStatus.CLOSED, job);
      await dataProvider.updateJobApplicationStatus(
        ApplicationStatus.COMPLETE,
        acceptedApplication
      );
    }
  }

  /// Sends emails to poster and student requesting reviews of one another.
  async function sendReviewEmails() {
    if (job?.jobStatus !== JobStatus.CLOSED) return;
    if (sentReviewEmails) return;
    // Find the accepted applicant, and email them.
    for (const application of job?.applicants?.items as JobApplication[]) {
      if (application.status === ApplicationStatus.COMPLETE) {
        // Send email to the poster and student asking for a review.
        let templateId = "d-9b1773e45f404b20a43209f536ffe92d"; // Completed Job.
        let fromEmail = "hello@colledge.us";

        const posterName = job.poster.firstName + " " + job.poster.lastName;
        const studentParams: EmailTemplate = {
          toEmail: application.applicant.email,
          fromEmail: fromEmail,
          templateId: templateId,
          message: posterName, // Name of person to review.
          actionURL: getColledgeURL() + "profile/" + job.poster.userID,
        };
        await sendEmail(studentParams);

        const studentName =
          application.applicant.firstName +
          " " +
          application.applicant.lastName;
        const posterEmailParams: EmailTemplate = {
          toEmail: job.poster.email,
          fromEmail: fromEmail,
          templateId: templateId,
          message: studentName, // Name of person to review.
          actionURL:
            getColledgeURL() + "profile/" + application.applicant.userID,
        };
        await sendEmail(posterEmailParams);
        setSentReviewEmails(true);
      }
    }
  }

  function fetchData() {
    setIsLoading(true);
    dataProvider.getJob(id).then(async (job) => {
      if (!job) {
        navigate("/browse", { replace: true });
        return;
      }
      setJob(job);
      if (job?.applicants?.items) {
        for (const application of job?.applicants?.items) {
          if (application?.status === ApplicationStatus.ACCEPTED) {
            setAcceptedApplication(application);
          }
        }
      }
      // Close out the job, if needed.
      await closeOutJob();
      // Send emails to users asking for a review, if needed.
      await sendReviewEmails();
      setIsLoading(false);
    });
  }

  const openHireModal = () => {
    if (
      !currentUser?.stripeAccountID?.length &&
      currentUser?.type === UserType.STUDENT
    ) {
      logEvent("joblisting_student_stripe_setup_error");
      toast.warning(
        "You must activate your payout account to apply for jobs.",
        {
          toastId: "paymentActivation",
        }
      );
      navigate("/payment", { replace: true });
      return;
    }
    setHireModalOpen(true);
  };

  const closeHireModal = (refetch?: boolean) => {
    refetch && fetchData();
    setHireModalOpen(false);
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    logEvent("page_view_about", {
      queryParams: queryParams.entries(),
      referrer: document.referrer,
      attributes: { job_id: id },
    });
  }, []);

  if (isLoading || !job) {
    // return <CenterInPageSpinner />;
    return (
      <div className="my-5">
        {/* <CustomSpinner /> */}
        {Array(1)
          .fill(null)
          .map((_, index) => (
            <SkeletonJobDetails key={index} />
          ))}
      </div>
    );
  } else {
    return (
      <>
        {job?.poster.userID === currentUser?.userID ? (
          <SecondaryNav title={"My Jobs"} toPage="/view_jobs" />
        ) : (
          <SecondaryNav title={"Browse Jobs"} toPage="/browse" />
        )}
        <div className="md:flex inline flex-col max-w-md md:max-w-4xl lg:max-w-7xl justify-start mx-auto mr-2 ml-2 gap-10 mt-10">
          <div className="gap-8 flex flex-col md:flex-row justify-center m-auto mt-4 w-full">
            <div className="w-full md:w-8/12">
              <JobDetail job={job} isLoading={isLoading} />
            </div>
            <div className="w-full lg:w-4/12 gap-4 flex flex-col">
              {currentUser?.type === UserType.STUDENT && (
                <Link
                  to={`/profile/${job.poster?.userID}`}
                  state={location.pathname}
                  className="w-full self-start"
                  onClick={(e) => {
                    e.preventDefault(); // Prevent default link behavior
                    e.stopPropagation(); // Stop the event from propagating to parent components
                  }}
                >
                  <JobPosterDetails
                    poster={job.poster}
                    job={job}
                    isHired={job?.applicants}
                  />
                </Link>
              )}
              {job?.poster.userID === currentUser?.userID && (
                <div className="bg-white text-left flex items-start pt-4 flex-col w-full h-auto shadow-md rounded-xl mr-2">
                  <h3 className="text-lg px-4 text-gray-800 font-bold pb-3 pl-6 pr-6 border-b w-full">
                    Applicants
                  </h3>
                  {!isLoading &&
                    job?.applicants?.items?.map(
                      (application: JobApplication | null, index) => {
                        if (!application) return <> </>;
                        const isHired =
                          application.status === ApplicationStatus.ACCEPTED ||
                          application.status === ApplicationStatus.COMPLETE;
                        // Only show applicants that have been hired.
                        if (job.jobStatus !== JobStatus.OPEN && !isHired) {
                          return <></>;
                        }
                        return (
                          <div className="px-4 w-full" key={index}>
                            <ApplicantDetails
                              key={index}
                              showChevron={true}
                              application={application}
                              isHired={isHired}
                              job={job}
                              state={location.pathname}
                              setLoading={setApplicantLoading}
                              loading={applicantLoading}
                            />
                          </div>
                        );
                      }
                    )}
                  {!isLoading && job?.applicants?.items.length === 0 && (
                    <div className="text-gray-400 text-lg px-8 mt-4 mb-4 GothamBook">
                      No applications yet.
                    </div>
                  )}
                  {!isLoading && job.jobStatus === JobStatus.CLOSED && (
                    <div className="text-gray-800 text-lg px-4 mt-4 mb-4 GothamBook">
                      This Job is closed.
                    </div>
                  )}
                  {/* Only show buttons if there is no selected applicant. */}
                  {!applicantLoading &&
                    !acceptedApplication &&
                    job.jobStatus === JobStatus.OPEN && (
                      <div className="w-full flex flex-col gap-4 px-4 mt-5 pb-8">
                        <button
                          className="w-full mt-4 bg-orange-500 GothamBold font-bold text-white rounded-full disabled:opacity-50"
                          onClick={openHireModal}
                          disabled={
                            job?.applicants?.items.length === 0 ||
                            acceptedApplication !== null
                          }
                        >
                          Make a decision
                        </button>
                      </div>
                    )}
                </div>
              )}
            </div>
          </div>
          <Footer />
        </div>
        <HireModal job={job} isOpen={hireModalOpen} onClose={closeHireModal} />
      </>
    );
  }
}

export default JobListing;
