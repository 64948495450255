import { useRef, useState, useEffect } from "react";
import Slider from "rc-slider";
import Tooltip from "rc-tooltip";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";

interface AverageHourlySearchProps {
  setMinHourlyRate: (value: number) => void;
  setMaxHourlyRate: (value: number) => void;
  minHourlyRate: number;
  maxHourlyRate: number;
}

function AverageHourlySearch({
  setMinHourlyRate,
  setMaxHourlyRate,
  maxHourlyRate,
  minHourlyRate,
}: AverageHourlySearchProps) {
  const avgHourRef = useRef<HTMLInputElement>(null);
  const [avgHours, setAvgHours] = useState<number[]>([
    // minHourlyRate,
    // maxHourlyRate,
    0, 200,
  ]);
  const handleSliderChange = (value: number | number[]) => {
    if (Array.isArray(value)) {
      setAvgHours(value as [number, number]);
      setMinHourlyRate(value[0]);
      setMaxHourlyRate(value[1]);
    } else {
      setAvgHours([value, value]);
      setMinHourlyRate(value);
      setMaxHourlyRate(value);
    }
  };

  useEffect(() => {
    avgHourRef.current!.value = `${avgHours[0]} - ${avgHours[1]}`;
  }, [avgHours]);

  return (
    <div className="relative p-4 flex h-[38px] text-left w-full items-center top-[23px]  min-w-[20px] font-bold bg-gray-50 rounded-lg ring-1 ring-black/5">
      <div className="flex flex-row w-full items-center font-bold relative left-[13px]">
        <Tooltip
          placement="top"
          id="toolTipID"
          overlay={`$${avgHours[0]} /hr - $${avgHours[1]} /hr`}
          visible={true}
        >
          <Slider
            range
            min={0}
            max={200}
            step={1}
            value={avgHours}
            onChange={handleSliderChange}
          />
        </Tooltip>

        <span>
          <input
            ref={avgHourRef}
            id="averageHourlySearch"
            type="text"
            title="slide"
            readOnly
            className="border-0 text-end relative w-full !text-[0px] text-gray-900 font-bold text-sm leading-6 bg-none GothamBook focus:border-transparent focus:ring-0 bg-gray-50 ring:none shadow:none"
          />
        </span>
      </div>
    </div>
  );
}

export default AverageHourlySearch;
