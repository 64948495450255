import { useState, useEffect } from "react";
import { Hub, Auth } from "aws-amplify";

/// Determines if the user is an admin or not.
let cachedGroups: string[] | null = null;
export async function currentUserIsAdmin(): Promise<boolean> {
  try {
    const authenticatedUser = await Auth.currentAuthenticatedUser();
    if (!authenticatedUser) {
      return false;
    }
    const groups = authenticatedUser.signInUserSession.accessToken.payload["cognito:groups"];
    if (!groups) {
      return false;
    }
    return groups.includes("Admins");
  } catch (error) {
    return false;
  }
}

/// Determines if the user is approved and able to use Colledge.
// export async function currentUserIsApproved(): Promise<boolean> {
//   try {
//     const authenticatedUser = await Auth.currentAuthenticatedUser();
//     if (!authenticatedUser) {
//       return false;
//     }
//     const groups = authenticatedUser.signInUserSession.accessToken.payload["cognito:groups"];
//     if (!groups) {
//       return false;
//     }
//     return groups.includes("Approved_Users") || groups.includes("Admins");
//   } catch (error) {
//     return false;
//   }
// }
export async function currentUserIsApproved(): Promise<boolean> {
  try {
    await refreshUserSession(); // Refresh the user session explicitly
    const authenticatedUser = await Auth.currentAuthenticatedUser();
    if (!authenticatedUser) {
      return false;
    }
    const groups = authenticatedUser.signInUserSession.accessToken.payload["cognito:groups"];
    if (!groups) {
      return false;
    }
    return groups.includes("Approved_Users") || groups.includes("Admins");
  } catch (error) {
    return false;
  }
}
async function refreshUserSession(): Promise<void> {
  try {
    await Auth.currentAuthenticatedUser({ bypassCache: true });
    // Session has been refreshed
    cachedGroups = null; // Clear cached data to force a recheck on the next call to currentUserIsApproved()
  } catch (error) {
    console.error("Error refreshing user session:", error);
  }
}


/// Returns the Cognito UserPool ID of the current user.
/*
  Auth.currentUserInfo() response looks like:
 {
  "attributes": {
    "email": "MY EMAIL ADDRESS",
    "email_verified": true,
    "sub": "INTERNAL USERID: XXXX-XXXX-XXXX-XXXX-XXXX"
  },
  "id": "us-east-2:XXXX",
  "username": "MY USERNAME"
}
 */
export async function userCognitoPoolID(): Promise<string | null> {
  const authenticatedUser = await Auth.currentAuthenticatedUser();
  if (!authenticatedUser) {
    return null;
  }
  const userInfo = await Auth.currentUserPoolUser();
  return userInfo.pool.userPoolId;
}

/// Determines if a user is authenticated or not.
export function AuthenticatedStatus(): boolean {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  async function userShouldEnter() {
    try {
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      if (authenticatedUser !== undefined) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } catch {
      setIsAuthenticated(false);
    }
  }

  useEffect(() => {
    userShouldEnter();
  });

  useEffect(() => {
    const listener = (data: any) => {
      switch (data.payload.event) {
        case "signIn" || "autoSignIn" || "tokenRefresh":
          setIsAuthenticated(true);
          break;
        case "signOut" || "signIn_failure" || "tokenRefresh_failure" || "autoSignIn_failure":
          setIsAuthenticated(false);
          break;
      }
    };

    Hub.listen("auth", listener);
  });

  return isAuthenticated;
}
