import React, { Fragment, useEffect, useRef, useState } from "react";
import { Modal } from "flowbite-react";
import PrimaryButton from "../buttons/primary_button";
import SecondaryButton from "../buttons/secondary_button";
import { Listbox, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { useNavigate, useLocation } from "react-router-dom";
import * as dataProvider from "../../graphql/DataProvider";
import { logEvent } from "../../helpers/Analytics";
import { useUserContext } from "../../helpers/UserContext";
import { EmailTemplate, sendEmail } from "../../graphql/EmailHandler";
import { toast } from "react-toastify";
import { User, UserType } from "../../API";
import { getColledgeURL } from "../../helpers/Utilities";
import TooltipWrapper from "../../helpers/TooltipWrapper";
import SkeletonHireModal from "../Skeleton/SkeletonHireModal";
import { HireStudentMessage } from "../../helpers/Constants";

interface StudentHireModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  optionSelected?: CategoryOption | null | undefined | any;
  onSearch?: (selectedCategory: CategoryOption | null | undefined) => void;
  student: User[];
  onSelectMultiple: (student: User[]) => void;
}

export interface CategoryOption {
  id: string;
  name: string;
  unavailable: boolean;
}

const StudentHireModal = ({
  open,
  setOpen,
  optionSelected,
  student,
  onSelectMultiple,
}: StudentHireModalProps) => {
  const { currentUser } = useUserContext();
  const navigate = useNavigate();
  const passedCategoryOption = useLocation().state as CategoryOption | null;
  const [query, setQuery] = useState<string | undefined>("");
  const [selectedOption, setSelectedOption] = useState<
    CategoryOption | null | undefined
  >(optionSelected ?? passedCategoryOption);
  const comboBoxInputRef = useRef<any | null>(null);
  const [jobCategories, setJobCategories] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [emailContent, setEmailContent] = useState<string>("");
  useEffect(() => {
    let categoryOptions: CategoryOption[] = [
      {
        id: "",
        name: "Search for a Job or Service",
        unavailable: false,
      },
    ];
    if (optionSelected) {
      setSelectedOption(optionSelected);
    } else {
      setSelectedOption(categoryOptions[0]);
    }
    if (currentUser) {
      setLoading(true); // Set loading to true before fetching data
      dataProvider
        .getJobsAndApplicationsForUser(currentUser)
        .then((jobs) => {
          let categoryOptions: CategoryOption[] = [];

          const downdropjob = jobs?.[0].filter(
            (item) => item?.jobStatus === "OPEN"
          );
          if (jobs?.[0] && downdropjob) {
            // Check if jobs is not empty or undefined
            downdropjob.forEach((category) => {
              categoryOptions.push({
                id: category?.id as string,
                name: category?.title as string,
                unavailable: false,
              });
            });
          } else {
            // categoryOptions.push({
            //   id: "1" as string,
            //   name: "You first need to create a job before inviting students" as string,
            //   unavailable: false,
            // });
            categoryOptions = [];
            setQuery("");
          }

          setJobCategories(categoryOptions);
        })
        .catch((error) => {
          console.error("Error fetching jobs:", error);
        })
        .finally(() => {
          setLoading(false); // Set loading to false after data is fetched (or an error occurs)
        });
    } else {
      console.error("currentUser is null or undefined");
      setLoading(false); // Set loading to false if currentUser is null or undefined
    }
  }, []);

  const filteredCategories =
    query === "" || query === null
      ? jobCategories
      : jobCategories.filter((category) =>
          category.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query?.toLowerCase().replace(/\s+/g, ""))
        );

  const handleEmailContentChange = (event: any) => {
    setEmailContent(event.target.value);
  };
  console.log(student, "student");
  const sendJobInvitation = async () => {
    if (currentUser?.type !== UserType.STUDENT) {
      logEvent("invitation_send");
      await Promise.all(
        student.map(async (selectedStudent) => {
          let messageSend =
            emailContent ||
            `Hi ${selectedStudent?.firstName} ${selectedStudent?.lastName}, Congratulations! You've been invited to apply for a job on Colledge.us for ${selectedOption?.name} - you can view the job info by clicking the button below`;
          const studentInvitationParams: EmailTemplate = {
            toEmail: selectedStudent?.email as string,
            fromEmail: currentUser?.email as string,
            subject: "Job Invitation",
            message: messageSend,
            actionURL: getColledgeURL() + "browse",
            templateId: "d-3a2b4b2ae93e4a81b236de6025f27d50",
          };
          await sendEmail(studentInvitationParams);
        })
      );
      setOpen(false);
      onSelectMultiple([]);
      toast.success(`Invitation sent to selected students.`, {
        toastId: "sendJobInvitation",
      });
    }
  };
  const CreateJobhandle = () => {
    if (currentUser?.type !== UserType.STUDENT) {
      navigate("/create_job");
    }
  };

  return (
    <Modal show={open} onClose={() => setOpen(false)} className="">
      <Modal.Header>
        <span className="!font-bold GothamBold text-teal">Compose Email</span>
      </Modal.Header>
      {loading ? (
        <div className="text-center p-5">
          <SkeletonHireModal />
        </div>
      ) : (
        <Modal.Body className="min-h-[300px]">
          {filteredCategories.length > 0 ? (
            <>
              <div className="space-y-6">
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  Select Job
                </p>
                <div className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  <div className="w-full absolute pr-[48px]">
                    <Listbox
                      onChange={(selectedOption: CategoryOption) => {
                        setSelectedOption(selectedOption);
                      }}
                      value={selectedOption}
                      as="div"
                      className="relative mx-auto bg-white rounded-xl shadow-md ring-1 ring-black/5 w-auto z-10"
                    >
                      <div className="flex items-center px-4 w-auto">
                        <MagnifyingGlassIcon className="h-6 w-6 text-gray-500" />
                        <Listbox.Button
                          className="w-full bg-transparent border-0 focus:ring-0 text-sm text-gray-800 placeholder-gray-400 h-16"
                          ref={comboBoxInputRef}
                        >
                          <span className="flex flex-row items-center">
                            <span className="block truncate w-full text-left GothamBook">
                              {selectedOption?.name}
                            </span>
                          </span>
                        </Listbox.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery("")}
                      >
                        <Listbox.Options className="max-h-96 overflow-auto text-left">
                          {query !== "" && filteredCategories.length === 0 ? (
                            <p className="p-4 text-sm text-gray-500">
                              No results found. Try again
                            </p>
                          ) : (
                            filteredCategories.map(
                              (category: CategoryOption) => (
                                <Listbox.Option
                                  key={category.id}
                                  value={category}
                                >
                                  {({ active }) => (
                                    <div
                                      className={`px-4 py-2 font-medium GothamBook cursor-pointer ${
                                        active
                                          ? "bg-gray-10 text-[teal]"
                                          : "bg-white-100  text-gray-900"
                                      }`}
                                    >
                                      {category.name}
                                    </div>
                                  )}
                                </Listbox.Option>
                              )
                            )
                          )}
                        </Listbox.Options>
                      </Transition>
                    </Listbox>
                  </div>
                </div>
              </div>
              <div className="space-y-6 mt-28">
                <textarea
                  value={emailContent}
                  onChange={handleEmailContentChange}
                  className="w-full h-32 p-2 border border-gray-300 rounded focus:outline-none focus:border-1 focus:border-gray-400 focus-visible:outline-none focus:ring-1 focus:ring-inset focus:ring-gray-400 dark:bg-gray-700  GothamBold text-black"
                  placeholder="Write your message here..."
                ></textarea>
              </div>
            </>
          ) : (
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              You first need to create a job before inviting students
            </p>
          )}
        </Modal.Body>
      )}
      {!loading && (
        <Modal.Footer>
          <div className="flex flex-row gap-4">
            {filteredCategories.length <= 0 ? (
              <TooltipWrapper
                message={HireStudentMessage}
                showTooltip={currentUser?.type === "STUDENT"}
                className="flex-row"
              >
                <PrimaryButton
                  btnText="Create a job"
                  onClick={CreateJobhandle}
                  className={`bg-orange-400 h-fit ${
                    currentUser?.type === "STUDENT" ? "opacity-50 disabled" : ""
                  }`}
                />
              </TooltipWrapper>
            ) : (
              <TooltipWrapper
                message={HireStudentMessage}
                showTooltip={currentUser?.type === "STUDENT"}
              >
                <PrimaryButton
                  className={`bg-teal h-fit ${
                    currentUser?.type === "STUDENT" || !selectedOption?.id
                      ? "opacity-50 disabled"
                      : ""
                  }`}
                  btnText="Send Email"
                  disabled={
                    currentUser?.type === UserType.STUDENT ||
                    !selectedOption?.id
                  }
                  onClick={sendJobInvitation}
                />
              </TooltipWrapper>
            )}
            <SecondaryButton
              className={`!w-fit h-fit ${
                currentUser?.type === UserType.STUDENT ? "mt-4" : " "
              } `}
              btnText="Cancel"
              onClick={() => setOpen(false)}
            />
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default StudentHireModal;
