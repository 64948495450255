import { Fragment, useEffect, useRef, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useLocation } from "react-router-dom";
import { logEvent } from "../../helpers/Analytics";
import * as dataProvider from "../../graphql/DataProvider";
import { sortArrayAlphabeticallyByProperty } from "../../helpers/Utilities";
import { useNavigate } from "react-router-dom";

export interface CategoryOption {
  id: string;
  name: string;
  unavailable: boolean;
}

interface IProps {
  defaultOption?: CategoryOption | null | undefined;
  onSearch?: (selectedCategory: CategoryOption | null | undefined) => void;
}

const JobCategoriesSelect = ({ defaultOption, onSearch }: IProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const passedCategoryOption = location.state as CategoryOption | null;
  const [query, setQuery] = useState<string>("");
  const [categories, setCategories] = useState<CategoryOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<
    CategoryOption | null | undefined
  >(defaultOption ?? passedCategoryOption);
  // sync default option with selected option
  useEffect(() => {
    const derivedOption = defaultOption ?? passedCategoryOption;
    setSelectedOption(derivedOption);
    setQuery(derivedOption?.name || "");
  }, [defaultOption, passedCategoryOption]);
  useEffect(() => {
    dataProvider.getJobCategories().then((categoryOptions) => {
      setCategories(
        categoryOptions.map((category) => ({
          id: category.id,
          name: category.name,
          unavailable: false,
        }))
      );
    });
  }, []);
  const sortArrayCategory = sortArrayAlphabeticallyByProperty(
    categories,
    "name"
  );
  const filteredCategories =
    query === ""
      ? sortArrayCategory
      : sortArrayCategory.filter((category) =>
          category.name.toLowerCase().includes(query.toLowerCase())
        );
  // search button functionality
  const handleSearch = () => {
    logEvent("search_jobs_search_button_pressed");
    const matchedCategory = categories.find(
      (category) => category.name.toLowerCase() === query.toLowerCase()
    );

    if (matchedCategory) {
      setSelectedOption(matchedCategory);
      onSearch?.(matchedCategory);
      if (location.pathname === "/home" || location.pathname === "/") {
        navigate("/browse", { state: matchedCategory });
      }
    }
  };
  useEffect(() => {
    setQuery(selectedOption?.name || "");
  }, [selectedOption]);
  return (
    <Combobox
      onChange={(selectedOption: CategoryOption) => {
        setSelectedOption(selectedOption);
        onSearch?.(selectedOption);
      }}
      value={selectedOption}
      as="div"
      className="relative mx-auto bg-white rounded-xl shadow-2xl ring-1 ring-black/5 w-auto z-10"
    >
      <div className="flex items-center px-4 w-auto h-16">
        <MagnifyingGlassIcon className="h-6 w-6 !min-w-[16px] !min-h-[16px] text-gray-500" />
        <Combobox.Button as="div" className="flex items-center flex-1">
          <Combobox.Input
            placeholder="Search for a Job or Service"
            // className="flex-1 border-none py-2 pl-3 pr-6 text-sm leading-5 selectInptuField text-gray-900 focus:ring-0"
            className="flex-1 border-none py-2 pl-3 text-sm selectInptuField leading-5 text-gray-900 focus:ring-0"
            value={query}
            onChange={(event) => setQuery(event.target.value)}
          />
        </Combobox.Button>
        {query !== "" && (
          <XMarkIcon
            onClick={() => {
              setQuery("");
              onSearch?.(null);
            }}
            className="h-5 w-5 !min-w-[16px] !min-h-[16px] bg-transparent p-0 m-0 mr-2 text-black cursor-pointer"
          />
        )}
        <button
          className="bg-orange-400 text-white GothamBook md:text-base text-xs"
          onClick={handleSearch}
        >
          Search
        </button>
      </div>
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Combobox.Options className="max-h-96 overflow-auto text-left">
          {query !== "" && filteredCategories.length === 0 ? (
            <p className="p-4  GothamBold text-gray-500 text-base text-center flex justify-center m-auto w-full">
              No results found. Try again
            </p>
          ) : (
            filteredCategories.map((category: CategoryOption) => (
              <Combobox.Option key={category.id} value={category}>
                {({ active }) => (
                  <div
                    className={`px-4 py-2 font-medium GothamBook cursor-pointer ${
                      active
                        ? "bg-gray-10 text-[teal]"
                        : "bg-white-100  text-gray-900"
                    }`}
                    onClick={() => setQuery(category.name)}
                  >
                    {category.name}
                  </div>
                )}
              </Combobox.Option>
            ))
          )}
        </Combobox.Options>
      </Transition>
    </Combobox>
  );
};

export default JobCategoriesSelect;
