import * as HeroOutline from "@heroicons/react/24/outline";
import * as HeroSolid from "@heroicons/react/24/solid";

export enum HeroIconType {
  OUTLINE,
  SOLID,
}

interface DynamicHeroIconProps {
  icon: string;
  iconType: HeroIconType;
  /// e.g. text-gray-500
  color: string;
}

/// Dynamically renders a hero icon: https://heroicons.com/.
export default function DynamicHeroIcon(props: DynamicHeroIconProps) {
  const { ...icons } = props.iconType === HeroIconType.OUTLINE ? HeroOutline : HeroSolid;
  // @ts-ignore
  const TheIcon: JSX.Element = icons[props.icon];

  return (
    <>
      {/* @ts-ignore */}
      <TheIcon className={`h-6 w-6 ${props.color}`} aria-hidden="true" />
    </>
  );
}
