import { ReactNode, useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { Job, JobRateType, SubscriptionStatus, User } from "../../API";
import { useUserContext } from "../../helpers/UserContext";
import * as dataProvider from "../../graphql/DataProvider";
import * as stripeHandler from "../../graphql/StripeHandler";
import { toast } from "react-toastify";
import { StripeData } from "../../graphql/StripeHandler";
import { CenteredSpinner } from "../loading_indicator";
import PrimaryButton from "../buttons/primary_button";
import { logEvent } from "../../helpers/Analytics";
import ApplyCouponModal from "../modals/apply_coupon_modal";

interface ModalProps {
  data: StripeData;
  isOpen: boolean;
  onClose: any;
  children?: ReactNode;
  showModal: boolean;
  job: Job;
}

/// Modal that processes stripe checkout to pay a student.
function PaymentModal({ data, isOpen, onClose, showModal, job }: ModalProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [prices] = useState([-1, -1, -1]);
  const [total, setTotal] = useState(0.0);
  const [workerAccount, setWorkerAccount] = useState<User | null>(null);
  const { currentUser } = useUserContext();
  const [isOpenCouponModal, setIsOpenCouponModal] = useState<boolean>(false);
  const [couponCode, setCouponCode] = useState<string>("");
  const [appliedCoupon, setAppliedCoupon] = useState<string>("");
  const [percentageDiscount, setPercentageDiscount] = useState<number>(0);
  const [flatOffAmount, setFlatOffAmount] = useState<number>(0);

  /// Updates price array with fixed values and handles undefined values
  const updatePriceArray = (row: any, index: number) => {
    if (prices[index] === -1 && row.fixedValue !== undefined) {
      prices[index] = row.fixedValue;
    } else if (row.fixedValue === undefined && prices[index] === -1) {
      prices[index] = 0;
    }
  };

  /// Handles input change and updates the total
  const handleChange = (newValue: number, index: number) => {
    prices[index] = newValue;
    for (let i = 0; i < prices.length; i++) {
      if (prices[i] === -1) {
        prices[i] = 0;
      }
    }
    setTotal(prices[0] + prices[1] + prices[2]);
  };
  
  const handleSwitchToggle = (event: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    event.preventDefault();
    event.stopPropagation();
    setIsOpenCouponModal(true);
  };

  /// Updates the student account for payment.
  async function updateStudentAccount() {
    if (!workerAccount) {
      setIsLoading(true);
      const student = await dataProvider.getUser(
        job?.applicants?.items[0]?.userJobApplicationsUserID
      );
      setWorkerAccount(student);
      setIsLoading(false);
    }
  }

  /// Processes a flat rate job.
  async function processFlatRate() {
    // Check if job is a flat rate job and if the user has an active subscription
    if (isOpen && data.rows[0].rateType === JobRateType.FLATRATE) {
      // Calculate final total and remove decimal for stripe
      const finalTotal =
        Number(
          data.rows[0].jobCompensation.toFixed(2).toString().replace(".", "")
        )
      // Create checkout session
      const checkoutURL = await stripeHandler.createStripeCheckoutSession(
        currentUser?.userID!,
        finalTotal, // As pennies.
        workerAccount!.stripeAccountID!,
        job?.id,
        couponCode
      );
      if (checkoutURL) {
        window.location.href = checkoutURL;
      } else {
        toast.error("Error creating checkout session.", {
          toastId: "createCheckoutSession",
        });
      }
      setTotal(0);
    }
  }

  useEffect(() => {
    updateStudentAccount();
  }, [job]);

  useEffect(() => {
    processFlatRate();
  }, [data.finalDetails.commissionPercent, data.rows, isOpen, total]);

  // handle Close Modal 
  const handleClosePaymentModal=()=>{
    setAppliedCoupon("");
    setFlatOffAmount(0);
    setPercentageDiscount(0);
    setTotal(0);
    onClose();
  }

  if (isLoading) {
    // return <CenteredSpinner />;
    return <div></div>;
  }

  return (
    <>
      {isOpen && showModal && (
        <>
          <div className="fixed inset-0 flex items-center justify-center z-50 overflow-auto ">
            <div className="fixed inset-0 bg-gray-900 opacity-75"></div>
            <div
              className="bg-white rounded-lg p-2 lg:p-4 z-10  max-w-6/12 lg:w-6/12 w-11/12"
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxHeight: "90vh",
                overflowY: "auto",
              }}
            >
              <div className="flex flex-col m-2 text-black p-2 gap-4">
                <div className="flex flex-row align-middle justify-between items-center">
                  <div className="text-2xl font-bold align">Details</div>
                  <div>
                    <button
                      onClick={() => {
                        handleClosePaymentModal();
                        logEvent("payment_modal_close_button_clicked");
                      }}
                      className="p-1 bg-white"
                    >
                      <XMarkIcon className="w-12 h-12 p-3 bg-gray-100 rounded-full" />
                    </button>
                  </div>
                </div>
                <form className="flex flex-col gap-4">
                  {data.rows.map(
                    (row: stripeHandler.StripeDataRow, index: number) => (
                      updatePriceArray(row, index),
                      (
                        <Row
                          key={index}
                          datakey={index}
                          title={row.jobTitle}
                          description={row.jobDescription}
                          type={row.rateType}
                          fixedValue={row.jobCompensation}
                          handleChange={handleChange}
                          handleSwitchToggle={handleSwitchToggle}
                          isSubcribed={currentUser?.subscription}
                          appliedCoupon={appliedCoupon}
                        />
                      )
                    )
                  )}
                  <FinalDetails
                    organization={data.finalDetails.organization}
                    commissionAmount={data.finalDetails.commissionPercent}
                    total={total}
                    stripeAccountID={workerAccount!.stripeAccountID!}
                    jobID={job!.id}
                    couponCode={couponCode}
                    percentageAmount={percentageDiscount}
                    flatOffAmount={flatOffAmount}
                  />
                </form>
              </div>
              {isOpenCouponModal && (
                <ApplyCouponModal
                  open={isOpenCouponModal}
                  setOpen={setIsOpenCouponModal}
                  setCouponCode={setCouponCode}
                  appliedCoupon={appliedCoupon}
                  setPercentageDiscount={setPercentageDiscount}
                  setFlatOffAmount={setFlatOffAmount}
                  setAppliedCoupon={setAppliedCoupon}
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

interface RowProps {
  datakey: number;
  title: string;
  description: string;
  type: string;
  fixedValue: number;
  handleChange: any;
  handleSwitchToggle: any;
  isSubcribed: string | undefined | null;
  appliedCoupon: string;
}

function Row({
  datakey,
  title,
  description,
  type,
  handleChange,
  fixedValue,
  handleSwitchToggle,
  isSubcribed,
  appliedCoupon,
}: RowProps) {
  const handleValueChange = () => {
    const hrs = document.getElementById("hrs" + datakey) as HTMLInputElement;
    const prc = document.getElementById("prc" + datakey) as HTMLInputElement;
    if (Number(prc?.value) > 0 && Number(hrs?.value) > 0)
      handleChange(Number(hrs.value) * Number(prc?.value), datakey);
    else handleChange(Number(0), datakey);
  };

  switch (type) {
    case JobRateType.FLATRATE:
      return (
        <>
          <div className="flex flex-row align-middle p-8 justify-between items-center bg-gray-100 rounded-lg">
            <div className="flex flex-col text-lg text-left">
              <div className="font-bold">{title}</div>
              <div className="text-sm">{description}</div>
            </div>
            <div className="flex flex-row gap-2 align-middle items-center">
              ${" "}
              <input
                id={"prc" + datakey}
                className="bg-white border-2 border-gray-300 rounded-lg w-14"
                type="number"
                onChange={() => handleValueChange()}
              />
            </div>
            {isSubcribed !== SubscriptionStatus.ACTIVE && (
              <>
                <div className="flex flex-row gap-1 justify-between align-middle items-center">
                  <div className="text-base">Do you have any coupon ?.</div>
                  <div className="text-base flex just items-center gap-8">
                    <PrimaryButton
                      btnText="Apply Coupon"
                      onClick={handleSwitchToggle}
                    />
                  </div>
                </div>
                {appliedCoupon && (
                  <div className="text-base">
                    Coupon Applied: {appliedCoupon}
                  </div>
                )}
              </>
            )}
          </div>
        </>
      );
    case JobRateType.HOURLY:
      return (
        <>
          <div className="flex flex-col text-left p-8 bg-gray-100 rounded-lg gap-4">
            <div className="flex flex-col text-lg text-left">
              <div className="font-bold">{title}</div>
              <div className="text-sm">{description}</div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex flex-row gap-1 justify-between align-middle items-center">
                <div className="text-base">Hours Worked</div>
                <div className="text-base justify-end flex items-center gap-4">
                  <input
                    id={"hrs" + datakey}
                    className="bg-white border-2 border-gray-300 rounded-lg w-[140px] text-center [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                    type="number"
                    onChange={() => handleValueChange()}
                  />
                </div>
              </div>
              <div className="flex flex-row gap-1 justify-between align-middle items-center">
                <div className="text-base">Price Per Hour</div>
                <div className="text-base flex just items-center gap-8">
                  ${" "}
                  <input
                    id={"prc" + datakey}
                    placeholder={fixedValue.toString()}
                    disabled
                    value={fixedValue.toString()}
                    className="bg-white border-2 border-gray-300 rounded-lg w-[140px] disabled:bg-gray-300 disabled:text-gray-800 text-center [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                    type="number"
                    onChange={() => handleValueChange()}
                  />
                </div>
              </div>
              {isSubcribed !== SubscriptionStatus.ACTIVE && (
                <>
                  <div className="flex flex-row gap-1 justify-between align-middle items-center">
                    <div className="text-base">Do you have any coupon ?.</div>
                    <div className="text-base flex just items-center gap-8">
                      <PrimaryButton
                        btnText="Apply Coupon"
                        onClick={handleSwitchToggle}
                      />
                    </div>
                  </div>
                  {appliedCoupon && (
                    <div className="text-base">
                      Coupon Applied: {appliedCoupon}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      );
    default:
      return <></>;
  }
}

interface FinalDetailsProps {
  children?: ReactNode;
  organization: string;
  commissionAmount: number;
  total: number;
  stripeAccountID: string;
  jobID: string;
  couponCode: string;
  percentageAmount: number;
  flatOffAmount: number;
}

function FinalDetails({
  organization,
  commissionAmount,
  total,
  stripeAccountID,
  jobID,
  couponCode,
  percentageAmount,
  flatOffAmount,
}: FinalDetailsProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { currentUser } = useUserContext();
  const [disabled, setDisabled] = useState(true);
  const [fee, setFee] = useState(0);
  const [percentagefee,setPercentageFee] = useState(0);
    const finalTotal = Number(
    (total).toFixed(2).toString().replace(".", "")
  );
  const [estimatedTotal, setEstimatedTotal] = useState<number>(0);
  useEffect(() => {
    // Check if subscription is active
    if (currentUser?.subscription === SubscriptionStatus.ACTIVE) {
      setFee(0);
    } else {
      setFee((commissionAmount / 100) * total);
    }
    setDisabled(stripeAccountID === "");
  }, [finalTotal]);

  /// Create checkout session for hourly jobs.
  async function handleSubmit(event: any) {
    setIsLoading(true);
    event.preventDefault();
    const amountDueToStudent = Number(
      (finalTotal/100)
        .toFixed(2)
        .toString()
        .replace(".", "")
    );
    const checkoutURL = await stripeHandler.createStripeCheckoutSession(
      currentUser?.userID!,
      amountDueToStudent,
      stripeAccountID,
      jobID,
      couponCode
    );
    setIsLoading(false);
    if (checkoutURL) {
      window.location.href = checkoutURL;
    } else {
      toast.error("Error creating checkout session.", {
        toastId: "createCheckoutSession",
      });
    }
  }
  useEffect(() => {
    const totalWithDiscounts = total + fee - flatOffAmount - percentagefee;
    if (total + fee <= 0 && couponCode) {
      setEstimatedTotal(0);
    } else {
      setEstimatedTotal(totalWithDiscounts);
    }
  }, [total, fee, flatOffAmount, percentagefee, couponCode]);

  // disabled the button until estimated total is zero
  useEffect(() => {
    setDisabled(estimatedTotal <= 0);
  }, [estimatedTotal]);

  useEffect(() => {
    if (couponCode && fee > 0) {
      let newFee = fee;
      let newPercentageFee = percentageAmount * fee;
  
      if (newPercentageFee > fee) {
        newPercentageFee = fee;
      }
  
      if (flatOffAmount && flatOffAmount >= fee) {
        newFee = 0;
        newPercentageFee = 0;
      } else if (flatOffAmount) {
        if (flatOffAmount > newFee) {
          newFee = 0;
        } else {
          newFee -= flatOffAmount;
        }
      }
      setPercentageFee(newPercentageFee);
    }
  }, [couponCode, percentageAmount, flatOffAmount, fee]);
  
  return (
    <>
      <div className="flex flex-col justify-between p-8 gap-8 bg-gray-100 rounded-lg">
        <div className="flex flex-row justify-between">
          <div className="text-base">
            {organization} Fee {commissionAmount}%
          </div>
          <div className="text-base">${fee.toFixed(2)}</div>
        </div>
        <div className="flex flex-row justify-between">
          <div className="text-base">Taxes</div>
          <div className="text-base">Collected in final step</div>
        </div>
        {couponCode && (
          <div className="flex flex-row justify-between">
            <div className="text-base">Coupon Amount</div>
            <div className="text-base">
              ${(percentagefee || flatOffAmount).toFixed(2)}
            </div>
          </div>
        )}
        <div className="flex flex-row justify-between">
          <div className="text-lg font-bold">Estimated Total</div>
          <div className="text-lg font-bold">${estimatedTotal.toFixed(2)}</div>
        </div>
      </div>
      <PrimaryButton
        btnText="Continue to Payment"
        onClick={(e: any) => {
          handleSubmit(e);
          logEvent("payment_modal_continue_button_clicked");
        }}
        disabled={disabled}
        showLoading={isLoading}
      />
      <div className="flex flex-row text-sm gap-1 items-center justify-center">
        Powered by{" "}
        <span className="text-purple-500 text-md font-bold">Stripe</span>
      </div>
    </>
  );
}

PaymentModal.Row = Row;
PaymentModal.FinalDetails = FinalDetails;

export default PaymentModal;
