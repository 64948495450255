import { useLocation, Navigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { currentUserIsAdmin, currentUserIsApproved } from "./AuthStatus";
import React, { useEffect } from "react";
import { useUserContext } from "./UserContext";
import { UserType } from "../API";
import { CenterInPageSpinner } from "../components/loading_indicator";

export function RequireAuth({ children }: any): any {
  const location = useLocation();
  const { currentUser ,updateApprovedStatus } = useUserContext();
  const { route } = useAuthenticator((context) => [context.route]);
  const [loading, setLoading] = React.useState(true);
  const [isAdminUser, setIsAdminUser] = React.useState(false);

  useEffect(() => {
    setLoading(true);
    currentUserIsAdmin().then((isAdmin) => {
      setIsAdminUser(isAdmin);
      setLoading(false);
    });
    currentUserIsApproved().then((isApproved:boolean)=>{
      updateApprovedStatus(isApproved);
    })
  }, []);

  

  if (loading) {
    return <CenterInPageSpinner />;
  } else {
    // Admin only routes.
    const adminOnlyRoutes = ["/manage_accounts", "/flagged_content"];

    // Poster only routes.
    const posterOnlyRoutes = ["/create_job", ];

    // Ensure that only authenticated and approved users can continue.
    if (route !== "authenticated") {
      return <Navigate to="/signin" state={{ from: location }} replace />;
    } else if (
      posterOnlyRoutes.includes(location.pathname) &&
      currentUser?.type !== UserType.POSTER
    ) {
      // Not a poster, navigate home.
      return <Navigate to="/home" replace />;
    } else if (adminOnlyRoutes.includes(location.pathname) && !isAdminUser) {
      return <Navigate to="/home" replace />;
    } else {
      return children;
    }
  }
}
