import { useNavigate } from "react-router-dom";
import { useUserContext } from "../helpers/UserContext";
import { toast } from "react-toastify";

function VerificationForm() {
  const { currentUser } = useUserContext();
  const navigate = useNavigate();
  const openprofilepage = () => {
    if (currentUser) {
      navigate(`/profile/${currentUser?.userID}`);
    } else {
      toast.error("user not found");
    }
  };
  const openpayment = () => {
    if (currentUser) {
      navigate(`/payment`);
    } else {
      toast.error("user not found");
    }
  };
  const PostJobHandle = () => {
    if (currentUser?.type === "POSTER") {
      return navigate(`/create_job`);
    } else {
      return toast.warning("Only allowed poster post job");
    }
  };
  return (
    <div className="flex flex-col text-black text-center p-5 gap-2">
      <div className="flex flex-col text-black text-center p-3 gap-4">
        <div className="font-bold text-3xl">Additional Verification Needed</div>
        <div className="text-md text-gray-700">
          Thank you for signing-up! Here's what's next:
        </div>
      </div>
      <div className="px-8 pb-8 pt-0">
        <ol className="max-w-lg space-y-4 list-none text-gray-500 list-decimal text-md break-word pl-6 dark:text-gray-400">
          <div className="flex">
            <div className="font-bold text-white w-5 h-5 p-2 rounded-full bg-teal mr-3 mt-2 flex items-center justify-center text-xs">
              1
            </div>
            <li className="text-start  mt-1">
              Everyone who joins Colledge needs to pass a background check to
              keep our community safe. Please check your email for instructions
              from{" "}
              <span>
                <span className="text-[teal] font-bold text-lg">Chekr </span>
              </span>
              <span>
                <a
                  href="https://checkr.com/"
                  className="font-semibold text-black hover:text-[teal] cursor-pointer"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  (Website link)
                </a>
              </span>
              .This check usually takes a few minutes but can be up to a few
              days in some cases.
            </li>
          </div>
          <div className="flex">
            <div className="font-bold text-white w-5 h-5 p-2 rounded-full bg-teal mr-3 mt-2 flex items-center justify-center text-xs">
              2
            </div>
            <li className="text-start mt-1">
              While your background check is running, you can log in and
              complete your profile here{" "}
              <span
                className="font-semibold text-black hover:text-[teal] cursor-pointer"
                onClick={openprofilepage}
              >
                Profile
              </span>
              .
            </li>
          </div>
          <div className="flex">
            <div className="font-bold text-white w-5 h-5 p-2 rounded-full bg-teal mr-3 mt-2 flex items-center justify-center text-xs">
              3
            </div>
            <li className="text-start mt-1 ">
              After your profile is complete, please connect a Stripe account to
              send and receive payments{" "}
              <span
                className="font-semibold text-black hover:text-[teal] cursor-pointer"
                onClick={openpayment}
              >
                Payment
              </span>
              .
            </li>
          </div>
          <div className="flex">
            <div className="font-bold text-white w-5 h-5 p-2 rounded-full bg-teal mr-3 mt-2 flex items-center justify-center text-xs">
              4
            </div>
            <li className="text-start mt-1 ">
              Once your background check has cleared (and you've completed the
              above steps), you can start posting{" "}
              <span
                className="font-semibold text-black hover:text-[teal] cursor-pointer"
                onClick={PostJobHandle}
              >
                {" "}
                Post job{" "}
              </span>{" "}
              or applying{" "}
              <span
                className="font-semibold text-black hover:text-[teal] cursor-pointer"
                onClick={() => navigate("/browse")}
              >
                {" "}
                Find Job
              </span>{" "}
              for gigs!
            </li>
          </div>
        </ol>
      </div>
    </div>
  );
}

export default VerificationForm;
