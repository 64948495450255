import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { User } from "../../API";
import { useUserContext } from "../../helpers/UserContext";
import { logEvent } from "../../helpers/Analytics";

interface QualificationsProps {
  user: User | null | undefined;
}

function Qualifications({ user }: QualificationsProps) {
  const { currentUser } = useUserContext();

  const languagesFormatted = user?.languages?.replace(
    new RegExp(",", "g"),
    ", "
  );
  const skillsFormatted = user?.skills?.split(",").map((skill, index) => {
    return (
      <p key={index} className="text-gray-600 flex items-center gap-2">
        <CheckCircleIcon className="w-6 h-6 text-green-600" />
        {skill}
      </p>
    );
  });

  return (
    <>
      <div className="bg-white text-left flex items-start p-6 lg:p-14 flex-col w-11/12 m-auto lg:w-full shadow-md rounded-xl h-auto">
        <div className="flex flex-col justify-center w-full">
          <div className="inline w-full relative">
            <div className="flex items-center relative w-full flex-row justify-between">
              <div className="flex items-center justify-start gap-4">
                <h2 className="text-gray-700 text-3xl font-semibold">
                  Qualifications
                </h2>
              </div>
              {currentUser?.userID === user?.userID && (
                <div className="flex gap-2 align-center">
                  <Link
                    to="/edit_account"
                    onClick={() => {
                      logEvent("profile_edit_button_clicked");
                    }}
                  >
                    <button className="text-gray-50 bg-orange-400 rounded-full w-auto GothamBold">
                      Edit
                    </button>
                  </Link>
                </div>
              )}
            </div>
          </div>

          <div className="flex-col flex md:flex-row w-full mt-8">
            <div className="w-full lg:w-1/2 flex flex-col gap-10">
              <div className="gap-2 flex flex-col">
                <h3 className="text-gray-700 font-bold">Languages</h3>
                {user?.languages ? (
                  <p className="text-gray-600">{languagesFormatted}</p>
                ) : (
                  <p className="text-gray-600">No languages provided.</p>
                )}
              </div>
            </div>
            <div className="w-full lg:w-1/2 flex flex-col gap-2 mt-10 lg:mt-0">
              <h3 className="text-gray-700 font-bold">Qualifications</h3>
              {user?.skills ? (
                skillsFormatted
              ) : (
                <p className="text-gray-600">No skills provided.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Qualifications;
