function VerticalCards({ header, body, imgSrc }) {
  return (
    <div className="max-w-sm w-10/12 md:w-full h-auto card-zoom-effect self-center rounded-xl overflow-hidden  bg-[#faebd7] shadow-lg">
      <img className="w-full" src={imgSrc} alt={header} />
      <div className="lg:h-[140px] lg:w-[300px] m-auto h-auto pb-8 pl-3 pr-3 self-center">
        <div className="font-bold pt-8 text-xl mb-2 text-gray-900 GothamBold">{header}</div>
        <p className="text-gray-700 text-base">{body}</p>
      </div>
    </div>
  );
}

export default VerticalCards;
