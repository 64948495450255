import {
  BanknotesIcon,
  MapPinIcon,
  UserGroupIcon,
  AcademicCapIcon,
  StarIcon
} from "@heroicons/react/20/solid";
import { JobRateType } from "../API";


interface ChipsProps {
  job?: any | null;
  // student?: any | null;
  // user?: any | null;
  showApplicants?: boolean;
  univ?: any | null;
}
// student, user
function Chips({ job, showApplicants, univ }: ChipsProps) {
  return (
    <>
      {/* this div code might be used don't remove this commented code */}
      <div className="flex xl:flex-row flex-col w-auto h-auto xl:items-center gap-4 items-start overflow-hidden">
        {!job?.rate && (
          <div className="flex bg-gray-100 text-gray-700 p-3 rounded-full border-radius">
            <div className="mr-2 flex">
              <div className="w-6 h-6 mr-2">
                <BanknotesIcon />
              </div>
              <p className="whitespace-nowrap">
                {job?.compensation || job?.avgHourlyRate
                  ? `$${job?.compensation || job?.avgHourlyRate} `
                  : "$"}
                {job?.rateType === JobRateType.HOURLY || job?.avgHourlyRate
                  ? "/hr"
                  : job?.rateType
                  ? "Flat rate"
                  : "0"}
              </p>
            </div>
          </div>
        )}
        <div className="flex bg-gray-100 text-gray-700 p-3 rounded-full border-radius pr-6 ">
          <span className="w-6 h-6 mr-2">
            <MapPinIcon />
          </span>
          {/* {user?.locationCity ? (
         <p className="whitespace-nowrap">
           {user?.locationCity + ", " + user?.locationState}
         </p> */}

          {job?.locationCity ? (
            // <p className="whitespace-nowrap truncate xxs:max-w-full max-w-[150px]">
            //   {job?.locationCity + ", " + job?.locationState}
            // </p>
            <p className="whitespace-nowrap truncate xxs:max-w-full max-w-[150px]">
              {job?.locationCity
                .split(" ")
                .map(
                  (word: string) => word.charAt(0).toUpperCase() + word.slice(1)
                )
                .join(" ") +
                ", " +
                job?.locationState.toUpperCase()}
            </p>
          ) : (
            <p className="whitespace-nowrap">Unknown</p>
          )}
        </div>
        {univ && (
          <div className="flex bg-gray-100 text-gray-700 p-3 rounded-full border-radius pr-6 ">
              <span className="w-6 h-6 mr-2">
                <AcademicCapIcon className="h-full m-auto"/>
              </span>
              <p className="whitespace-nowrap">{univ}</p>
          </div>
        )}
        {/* Display job category information */}
        {job?.category?.name && (
          <div className="flex bg-gray-100 text-gray-700 p-3 rounded-full border-radius pr-6 ">
            <span className="w-6 h-6 mr-2">
              <StarIcon className="h-full m-auto"></StarIcon>
            </span>
            <p className="whitespace-nowrap">{job?.category?.name}</p>
          </div>
        )}

        {showApplicants && (
          <div className="flex bg-gray-100 text-gray-700 p-3 rounded-full border-radius pr-6 ">
            <span className="w-6 h-6 mr-2">
              <UserGroupIcon />
            </span>
            <p className="whitespace-nowrap">{applicantFormatted()}</p>
          </div>
        )}
      </div>
    </>
  );

  /// Formats the number of applicants.
  function applicantFormatted() {
    if (job?.applicants?.items.length === 1) {
      return "1 Applicant";
    } else {
      return job?.applicants?.items.length + " Applicants";
    }
  }

  // Formats the number of applicants. for students
  // function applicantFormatted() {
  //   if (user?.applicants?.items.length === 1) {
  //     return "1 Applicant";
  //   } else {
  //     return user?.applicants?.items.length + " Applicants";
  //   }
  // }
}

export default Chips;
