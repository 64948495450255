import "react-toastify/dist/ReactToastify.css";
import "./index.css";

import Navbar from "./components/nav/navbar";
import Home from "./pages/home";
import Terms from "./pages/terms";
import About from "./pages/about";
import Browse from "./pages/browse";
import Signin from "./pages/signin";
import JobListing from "./pages/job_listing";
import Profile from "./pages/profile";
import CreateJob from "./pages/create_job";

import { Route, Routes } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { RequireAuth } from "./helpers/RequireAuth";
import { Authenticator } from "@aws-amplify/ui-react";

import Settings from "./pages/settings";
import ViewJobs from "./pages/view_jobs";
import EditProfile from "./pages/edit_profile";
// import ManageAccounts from "./pages/manage_accounts";
import ManageAccountsFE from "./pages/manage_accounts_fe";
import FlaggedContentView from "./pages/flagged_content";

import { UserProvider } from "./helpers/UserContext";
import { awsConfig } from "./aws-config";
import SocialLoginPrompt from "./components/modals/social_login_prompt";
import StudentHire from "./pages/student_hire";
import GoogleSheetJob from "./pages/googleSheetJob";
// import GoogleSheetUser from "./pages/googleSheetUser";
import SupportForm from "./pages/support_form";
import CouponForm from "./components/admin/coupon_form";
import CouponTable from "./components/admin/coupon_table";
import BrowseFE from "./pages/browse_fe";

Amplify.configure(awsConfig);

function AppRoutes() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/signup" element={<Signin />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/browse" element={<BrowseFE />} />
        <Route path="/student-hire" element={<StudentHire />} />
        <Route path="/support" element={<SupportForm />} />
        <Route path="/terms" element={<Terms />} />
        <Route
          path="/job_listing/:id"
          element={
            <RequireAuth>
              <JobListing />
            </RequireAuth>
          }
        >
          <Route
            path="/job_listing/:id/:status"
            element={
              <RequireAuth>
                <JobListing />
              </RequireAuth>
            }
          />
        </Route>
        <Route
          path="/profile/:id"
          element={
            <RequireAuth>
              <Profile />
            </RequireAuth>
          }
        >
          <Route
            path="/profile/:id/:status"
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            }
          />
        </Route>
        <Route
          path="/payment"
          element={
            <RequireAuth>
              <Settings />
            </RequireAuth>
          }
        />
        <Route
          path="/edit_account"
          element={
            <RequireAuth>
              <EditProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/view_jobs"
          element={
            <RequireAuth>
              <ViewJobs />
            </RequireAuth>
          }
        />
        <Route
          path="/create_job/"
          element={
            <RequireAuth>
              <CreateJob />
            </RequireAuth>
          }
        />
        <Route
          path="/create_job/:id"
          element={
            <RequireAuth>
              <CreateJob />
            </RequireAuth>
          }
        />
        <Route
          path="/manage_accounts"
          element={
            <RequireAuth>
              <ManageAccountsFE />
            </RequireAuth>
          }
        />
        <Route
          path="/flagged_content"
          element={
            <RequireAuth>
              <FlaggedContentView />
            </RequireAuth>
          }
        />
        <Route
          path="/goolesheet_job"
          element={
            <RequireAuth>
              <GoogleSheetJob />
            </RequireAuth>
          }
        />
        <Route
          path="/create_coupon"
          element={
            <RequireAuth>
              <CouponForm />
            </RequireAuth>
          }
        />
        <Route
          path="/edit_coupon/:id"
          element={
            <RequireAuth>
              <CouponForm />
            </RequireAuth>
          }
        />
        <Route
          path="/coupons"
          element={
            <RequireAuth>
              <CouponTable />
            </RequireAuth>
          }
        />
        {/* <Route
          path="/goolesheet_User"
          element={
            <RequireAuth>
              <GoogleSheetUser />
            </RequireAuth>
          }
        /> */}
      </Routes>
      <SocialLoginPrompt />
    </>
  );
}

function App() {
  return (
    <Authenticator.Provider>
      <UserProvider>
        <AppRoutes />
      </UserProvider>
    </Authenticator.Provider>
  );
}

export default App;
