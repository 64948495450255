import {
  AcademicCapIcon,
  ChatBubbleLeftRightIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronUpDownIcon,
  MapPinIcon,
  CalendarIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import { JobSpecilities, UpdateUserInput, UserType } from "../API";
import { Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { useUserContext } from "../helpers/UserContext";
import {
  CenterInPageSpinner,
  CustomSpinner,
  ProgressIndicator,
} from "../components/loading_indicator";
import * as dataProvider from "../graphql/DataProvider";
import { TagsInput } from "@henrytkirk/react-tag-input-component";
import SecondaryNav from "../components/nav/secondary_nav";
import { Loader } from "@googlemaps/js-api-loader";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../components/buttons/primary_button";
import { Combobox } from "@headlessui/react";
import { approvedSchooList } from "../helpers/school_list";
import { logEvent } from "../helpers/Analytics";
import DropdownSelector, {
  DropDownSelectorOption,
} from "../components/dropdown_selector";
import userProfileIcon from "../assets/userProfileIcon.svg";
import DeactivateAccountModal from "../components/modals/deactivate_account";
import SpecialitiesSelect, {
  CategoryOption,
} from "../components/profile/specilities_select";
import { GOOGLE_MAPS_API_KEY } from "../utils/constants";
import awsExports from "../aws-exports.js";
import { Amplify } from "aws-amplify";
import UserProfileImage from "../components/student/userProfileImage";

Amplify.configure(awsExports);
const currentYear = new Date().getFullYear();
const numberOfYears = 20;
const graduationYearsOptions: DropDownSelectorOption[] = Array.from(
  { length: numberOfYears },
  (_, index) => {
    const year = currentYear + index + 1;
    return { id: year.toString(), name: year.toString() };
  }
);
interface IFormErrors {
  locationState: string;
  locationCity: string;
  averageHourlyRate: string;
  jobSpecialities: string;
}

function EditProfile() {
  const { currentUser, updateUser, userGroup } = useUserContext();
  const navigate = useNavigate();
  const defaultValues = {
    firstName: "",
    lastName: "",
    biography: "",
    school: "",
    locationAddress: "",
    locationCity: "",
    locationState: "",
    locationZip: "",
    graduationYear: "",
    avgHourlyRate: 0,
    emailNotificationEnabled: currentUser?.emailNotificationEnabled,
  };
  const [formState, setFormState] = useState(defaultValues);
  const profileImageRef = useRef<HTMLInputElement | null>(null);
  const [formErrors, setFormErrors] = useState<IFormErrors>({
    locationState: "",
    locationCity: "",
    averageHourlyRate: "",
    jobSpecialities: "",
  });
  const [photoKeyValue, setPhotoKeyValue] = useState<string | null | undefined>(
    undefined
  );
  const [profileImageUrl, setProfileImageUrl] = useState<
    string | null | undefined
  >(undefined);
  const [uploadingProgress, setUploadingProgress] = useState<number>(0);
  const [languagesArray, setLanguagesArray] = useState<string[]>([]);
  const [skillsArray, setSkillsArray] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const locationCityStateRef = useRef<HTMLInputElement | null>(null);
  const locationStateRef = useRef<HTMLInputElement | null>(null);
  const autoCompleteRef = useRef<google.maps.places.Autocomplete | undefined>();
  const options = {
    componentRestrictions: { country: "us" }, // US Only.
    fields: ["address_components"],
    strictBounds: false,
    types: ["(cities)"], // Only search for cities.
  };

  const [schoolQuery, setSchoolQuery] = useState("");
  const [selectedGraduationYearsOption, setselectedGraduationYearsOption] =
    useState<DropDownSelectorOption>(graduationYearsOptions[0]);

  const [openDeactivateAccountModal, setOpenDeactivateAccountModal] =
    useState<boolean>(false);
  const [categories, setCategories] = useState<CategoryOption[]>([]);
  // selected Category
  const [selectedCategories, setSelectedCategory] = useState<CategoryOption[]>(
    []
  );
  useEffect(() => {
    dataProvider.getJobCategories().then((categories) => {
      const categoryOptions: CategoryOption[] = [];
      categories?.forEach((category) => {
        categoryOptions.push({
          id: category!.id,
          name: category!.name,
          unavailable: false,
        });
      });
      setCategories(categoryOptions);
    });
  }, []);

  const filteredSchools =
    schoolQuery === ""
      ? approvedSchooList.slice(0, 75)
      : approvedSchooList.filter(
          function (school: string) {
            const match = school.toLowerCase().includes(schoolQuery);
            if (this.count < 75 && match) {
              this.count++;
              return true;
            }
            return false;
          },
          { count: 0 }
        );

  /// Loads the Google Maps API.
  function loadMapsAPI() {
    new Loader({
      apiKey: GOOGLE_MAPS_API_KEY, // Colledge Key.
      version: "weekly",
      libraries: ["places"],
    })
      .importLibrary("places")
      .then((places) => {
        autoCompleteRef.current = new places.Autocomplete(
          locationCityStateRef.current!,
          options
        );
        autoCompleteRef.current.addListener("place_changed", updateCityState);
      })
      .catch((error) => {
        console.error("Error loading Maps API: ", error);
      });
  }

  useEffect(() => {
    if (!autoCompleteRef.current) {
      loadMapsAPI();
    }
  });

  /// Handles autocompletion for the City/State field.
  function updateCityState() {
    // Get the place details from the autocomplete object.
    const place = autoCompleteRef.current?.getPlace();
    for (const component of place?.address_components as google.maps.GeocoderAddressComponent[]) {
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0];

      switch (componentType) {
        case "locality":
          locationCityStateRef.current!.value = component.long_name;
          break;
        case "administrative_area_level_1": {
          locationStateRef.current!.value = component.short_name;
          break;
        }
      }
    }
  }

  /// Updates the form state when the user types in an input field.
  function setInput(key: any, value: String) {
    setFormState({ ...formState, [key]: value });
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [key as keyof IFormErrors]: "",
    }));
  }

  const imageKey = photoKeyValue ?? currentUser?.photoKey ;
  useEffect(() => {
    if (imageKey) {
        setProfileImageUrl(imageKey);
    } else {
      setProfileImageUrl(null);
    }
  }, [currentUser?.photoKey, photoKeyValue]);
  useEffect(() => {
    setSkillsArray(currentUser?.skills?.split(",").filter(Boolean) ?? []);
    setLanguagesArray(currentUser?.languages?.split(",").filter(Boolean) ?? []);
    setSchoolQuery(currentUser?.school?.toLowerCase() ?? "");
  }, [currentUser?.languages, currentUser?.skills, currentUser]);

  /// Uploads a user image to S3 and saves the URL to their profile.
  async function uploadUserImage(event: any) {
    setIsSaving(true);
    const file = event.target.files[0];
    try {
      if (!file || !currentUser) {
        setIsSaving(false);
        return;
      }
      // Create file name.
      const extension = file.name.split(".").pop();
      const fileName = uuidv4() + (extension ? "." + extension : "");
      await Storage.put(fileName, file, {
        metadata: { userID: currentUser?.userID! },
        progressCallback(progress) {
          setUploadingProgress(progress.loaded / progress.total);
        },
      });
      setUploadingProgress(0);
      setPhotoKeyValue(fileName);
      setProfileImageUrl(null);
      setIsSaving(false);
    } catch (error) {
      setUploadingProgress(0);
      setPhotoKeyValue(null);
      setProfileImageUrl(null);
      setIsSaving(false);
      console.error("Error uploading file: ", error);
      toast.error("Error uploading file.", { toastId: "updateUserImageError" });
    }
  }

  // validation for location , job Specilites and averageHourly Rate
  const validateForm = () => {
    let errors: IFormErrors = {
      locationState: "",
      locationCity: "",
      averageHourlyRate: "",
      jobSpecialities: "",
    };
    if (locationCityStateRef.current?.value.trim() === "") {
      errors.locationCity = "City is required!";
    }
    if (locationStateRef.current?.value?.trim() === "") {
      errors.locationState = "State is required!";
    }
    if (formState.avgHourlyRate <= 0) {
      errors.averageHourlyRate = "Average Hourly Rate is Required!";
    }
    if (selectedCategories.length <= 0) {
      errors.jobSpecialities = "Please select at least on Job Specialites!";
    }
    setFormErrors(errors);
    return errors;
  };
  async function saveUserInfo(event: { preventDefault: () => void }) {
    setIsSaving(true);
    event.preventDefault();
    if (!currentUser) {
      toast.error("No current user.", { toastId: "saveUserInfoError" });
      return;
    }
    if(currentUser?.type === UserType.STUDENT ){
    const errors = validateForm();
    const hasErrors = Object.values(errors).some((error) => error !== "");
    if (hasErrors) {
      setIsSaving(false);
      return;
    }
  }
    logEvent("profile_save_button_pressed");
    // Delete the existing photo first, if it exists and is different than existing.
    if (currentUser?.photoKey && currentUser?.photoKey !== photoKeyValue) {
      await Storage.remove(currentUser.photoKey);
    }

    // Update city/state from autocomplete.
    formState.locationState =
      locationStateRef.current?.value.toLowerCase() ?? "";
    formState.locationCity =
      locationCityStateRef.current?.value.toLowerCase() ?? "";
    formState.graduationYear = selectedGraduationYearsOption?.name ?? " ";
    formState.emailNotificationEnabled =
      formState.emailNotificationEnabled ?? false;
    const userData: UpdateUserInput = {
      userID: currentUser.userID,
      ...formState,
      fullName: `${currentUser.firstName.toLowerCase()} ${currentUser.lastName.toLowerCase()}`,
      languages: languagesArray.toString(),
      skills: skillsArray.toString(),
      avgHourlyRate: formState.avgHourlyRate as number,
    };

    if (photoKeyValue) {
      userData.photoKey = photoKeyValue;
    }
    const response = await dataProvider.updateUserProfile(userData);

    // Delete all job specilities if the user if there
    if ((currentUser?.jobSpecilities?.items?.length || 0) > 0) {
      // delete those jobs that are in the currentUser Object but not in the selectedCategories
      await dataProvider.deleteJobSpecilities(
        currentUser?.jobSpecilities?.items as JobSpecilities[]
      );
    }

    // create new job specilities that are selected
    if (selectedCategories.length > 0) {
      const jobSpecilities = selectedCategories.map((category) => ({
        categoryId: category.id,
        userId: currentUser.userID,
      }));
      await dataProvider.createJobSpecilities(jobSpecilities);
    }

    if (response) {
      toast.success("Successfully updated your profile.", {
        toastId: "saveUserInfo",
      });
    } else {
      toast.error("Could not update your profile!", {
        toastId: "saveUserInfoError",
      });
    }
    setIsSaving(false);
    updateUser();
    navigate(`/profile/${currentUser.userID}`);
  }

  /// Removes a user's current profile photo.
  async function removeCurrentPhoto() {
    if (!currentUser) {
      return;
    }

    if (currentUser?.photoKey) {
      setPhotoKeyValue(null);
      try {
        await Storage.remove(currentUser.photoKey);
      } catch (error) {
        console.log("Error removing photo:", error);
      }
      const userData: UpdateUserInput = {
        userID: currentUser.userID,
        photoKey: "",
      };
      const response = await dataProvider.updateUserProfile(userData);
      if (response) {
        toast.success("Successfully removed your photo.", {
          toastId: "removeCurrentPhoto",
        });
      } else {
        toast.error("Could not remove your photo!", {
          toastId: "removeCurrentPhotoError",
        });
      }
      updateUser();
    }
  }
  console.log( profileImageRef?.current?.value, "profileImageRef?.current?.value")
  useEffect(() => {
    if (currentUser) {
      setPhotoKeyValue(currentUser.photoKey);
      setFormState({
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        biography: currentUser.biography ?? "",
        school: currentUser.school ?? "",
        locationAddress: currentUser.locationAddress ?? "",
        locationCity: currentUser.locationCity ?? "",
        locationState: currentUser.locationState ?? "",
        locationZip: currentUser.locationZip ?? "",
        graduationYear: currentUser.graduationYear ?? "",
        avgHourlyRate: currentUser.avgHourlyRate ?? 0,
        emailNotificationEnabled: currentUser.emailNotificationEnabled || false,
      });
    }
    if (currentUser) {
      setselectedGraduationYearsOption({
        id: currentUser?.graduationYear ?? "",
        name: currentUser?.graduationYear ?? "", // Provide a default value (an empty string in this case)
      });
      const JobSpecialitiesCategory = currentUser.jobSpecilities?.items.map(
        (item) => {
          return {
            id: item?.category.id,
            name: item?.category.name,
            unavailable: false,
          };
        }
      ) as CategoryOption[];
      setSelectedCategory(JobSpecialitiesCategory);
    }
  }, [currentUser]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    logEvent("page_view_edit_profile", {
      queryParams: queryParams.entries(),
      referrer: document.referrer,
    });
  }, []);

  useEffect(() => {
    if (selectedCategories.length > 0) {
      setFormErrors((prevErrors) => ({ ...prevErrors, jobSpecialities: "" }));
    }
  }, [selectedCategories]);

  if (!currentUser) {
    return <CenterInPageSpinner />;
  }
  const handleSwitchToggle = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setFormState((prev) => ({
      ...prev,
      emailNotificationEnabled: isChecked,
    }));
  };


  return (
    <>
      <SecondaryNav
        title="Profile"
        toPage={`/profile/${currentUser?.userID}`}
      />
      <form
        className="space-y-6 w-11/12 lg:w-8/12 flex flex-col m-auto gap-4 mx-w-auto mt-4 mb-20"
        action="#"
        method="POST"
      >
        <div className="flex flex-col p-4 pb-10 lg:p-10 rounded-2xl w-full shadow-lg justify-center text-left m-auto gap-8">
          <div className="w-full m-auto">
            <h2 className="text-gray-900 text-4xl font-bold ml-8 lg:ml-10">
              Edit Profile
            </h2>
          </div>
          <div className="w-full m-auto">
            <label
              htmlFor="name"
              className="block text-sm font-medium leading-6 text-gray-900 ml-8 lg:ml-10"
            >
              Name
            </label>
            <div className="mt-2 ml-8 lg:ml-10 flex gap-2 items-center">
              <input
                onChange={(event) => setInput("firstName", event.target.value)}
                value={formState.firstName}
                id="firstName"
                type="text"
                placeholder="Firstname"
                required
                className="block w-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-12"
              />
              <input
                onChange={(event) => setInput("lastName", event.target.value)}
                value={formState.lastName}
                id="lastName"
                type="text"
                placeholder="Lastname"
                required
                className="block w-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-12"
              />
            </div>
          </div>
          <div className="w-full m-auto flex-row">
            <div>
              <label
                htmlFor="image"
                className="block text-sm font-medium leading-6 text-gray-900 ml-8 lg:ml-10"
              >
                Profile Image
              </label>
              <div className="flex gap-2 flex-col md:flex-row items-center">
                <div className="mt-2 ml-8 lg:ml-10 gap-2 items-center">
                  <input
                    key={photoKeyValue}
                    id="profileImage"
                    ref={profileImageRef}
                    title=""
                    type="file"
                    onChange={uploadUserImage}
                    className="block md:w-auto w-full bg-gray-50 rounded-md fileUpload border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 text-sm leading-6 focus:ring-2 focus:ring-gray-400"
                  />
                  {uploadingProgress > 0 && (
                    <ProgressIndicator progress={uploadingProgress} />
                  )}
                </div>
                <div className="mt-2 ml-8 lg:ml-10 gap-2 items-center">
                  {profileImageUrl ? (
                    <div className="flex gap-4 items-center">
                    
                  <UserProfileImage photoKey={profileImageUrl} className="object-cover !w-[65px] !h-[65px] rounded-[50%]"/>

                      <PrimaryButton
                        btnText="Remove"
                        disabled={
                          profileImageRef?.current?.value !== "" && 
                          !currentUser?.photoKey
                        }
                        onClick={(e: any) => {
                          e.preventDefault();
                          logEvent("profile_delete_photo_button_clicked");
                          // Remove uploaded photo.
                          if (profileImageRef.current) {
                            profileImageRef.current.value = "";
                          }
                          setPhotoKeyValue(currentUser?.photoKey);
                          removeCurrentPhoto();
                        }}
                      />
                    </div>
                  ) : (
                    // <UserCircleIcon className="w-[50px] h-[50px] rounded-full text-gray-500" />
                    <div className="w-[48px] h-[48px] p-1 bg-gray-100 relative hover:bg-gray-200 hover:cursor-pointer rounded-full text-gray-700">
                      <img
                        alt="user profile icon"
                        className="w-full h-full p-[7px]"
                        src={userProfileIcon}
                      />

                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full m-auto">
            <label
              htmlFor="biography"
              className="block text-sm font-medium leading-6 text-gray-900 ml-8 lg:ml-10"
            >
              Biography
            </label>
            <div className="mt-2 flex gap-2 ml-8 lg:ml-10">
              <textarea
                onChange={(event) => setInput("biography", event.target.value)}
                value={formState.biography}
                id="biography"
                placeholder="Enter your biography"
                required
                className="block w-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-40"
              ></textarea>
            </div>
          </div>
          <div className="flex lg:flex-row w-full m-auto">
            {/* Location city */}
            <div className="lg:w-1/2 ">
              <label
                htmlFor="location"
                className="text-sm font-medium leading-6 text-gray-900 flex items-center ml-10 lg:ml-10"
              >
                Location (City)
              </label>
              <div className="mt-2 flex items-center">
              <MapPinIcon className="w-6 h-6 text-gray-700 mr-2" />
                <input
                  defaultValue={formState.locationCity}
                  ref={locationCityStateRef}
                  id="locationCity"
                  type="text"
                  placeholder="City (e.g. New York)"
                  required
                  onChange={(event) => {
                    setFormErrors((prevErrors) => ({
                      ...prevErrors,
                      locationCity: "",
                    }));
                  }}
                  className="block w-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-12"
                />
              </div>
              <div className="text-sm font-medium leading-6 text-red-500 flex items-center ml-10 capitalize">
                {formErrors.locationCity}
              </div>
            </div>

            {/* Location State */}
            <div className="lg:w-1/2 mt-1">
              <div className="flex flex-col ml-5 lg:ml-5">
                <label
                  htmlFor="location"
                  className="text-sm font-medium leading-6 text-gray-900 flex items-center"
                >
                  Location (State)
                </label>
                <div className="flex items-center gap-2 mt-1">
                  <input
                    defaultValue={formState.locationState}
                    ref={locationStateRef}
                    type="text"
                    placeholder="State (e.g. NY)"
                    // maxLength={2}
                    // disabled={true}
                    onChange={(event) => {
                      setFormErrors((prevErrors) => ({
                        ...prevErrors,
                        locationState: "",
                      }));
                    }}
                    required
                    className="block w-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-12"
                  />
                </div>
                <div className="text-sm font-medium leading-6 text-red-500 flex items-center capitalize">
                  {formErrors.locationState}
                </div>
              </div>
            </div>
          </div>
        </div>
        {currentUser?.type === UserType.STUDENT && (
          <>
            <div className="flex flex-col p-4 pb-10 lg:p-10 rounded-2xl w-full shadow-lg justify-center text-left m-auto gap-8">
              <div className="w-full m-auto">
                <h2 className="text-gray-900 text-4xl font-bold ml-8 lg:ml-10">
                  Edit Qualifications
                </h2>
              </div>
              <div className="w-full m-auto">
                <label
                  htmlFor="school"
                  className="text-sm font-medium leading-6 text-gray-900 flex items-center ml-8 lg:ml-10"
                >
                  Enter your school
                </label>
                <div className="mt-2 flex items-center gap-2">
                  <AcademicCapIcon className="w-6 h-6 text-gray-700 mr-2" />
                  <Combobox
                    value={formState.school}
                    onChange={(value) => setInput("school", value)}
                  >
                    <div className="w-full relative mt-1">
                      <div className="w-full cursor-default rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 text-sm">
                        <Combobox.Input
                          className="block w-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-12 mr-3"
                          displayValue={(school: string) => school}
                          placeholder="Search for your school..."
                          onChange={(event) => {
                            setSchoolQuery(event.target.value.toLowerCase());
                          }}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-500"
                            aria-hidden="true"
                          />
                        </Combobox.Button>
                      </div>
                      <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md z-10 bg-white py-1 md:text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
                        {filteredSchools.map((school, index) => (
                          <Combobox.Option
                            key={index}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-900"
                              }`
                            }
                            value={school}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {school}
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-teal">
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Combobox.Option>
                        ))}
                      </Combobox.Options>
                    </div>
                  </Combobox>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row">
                {/* Languages */}
                <div className="lg:w-1/2 ">
                  <label
                    htmlFor="languages"
                    className="text-sm font-medium leading-6 text-gray-900 flex items-center mt-2 ml-8 lg:ml-10"
                  >
                    Languages
                  </label>
                  <div className="mt-2 flex items-center gap-2">
                    <ChatBubbleLeftRightIcon className="w-6 h-6 text-gray-700 mr-2" />
                    <TagsInput
                      classNames={{
                        container:
                          "flex items-center flex-wrap  focus:ring-gray-400 box-border rounded-md ring-1 ring-inset ring-gray-300",
                        tag: {
                          container:
                            "flex items-center m-1 bg-gray-50 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 h-10",
                          text: "pl-2 mr-0 text-sm font-medium text-gray-700",
                          removeButton: "w-8 -ml-2 text-gray-700",
                        },
                        input:
                          "block bg-gray-50 rounded-md border-0  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm h-12",
                      }}
                      value={languagesArray}
                      onChange={(tags: string[]) => setLanguagesArray(tags)}
                      name="languages"
                      placeHolder="Language"
                    />
                  </div>
                </div>

                {/* Graduation Years */}
                <div className="lg:w-1/2 text-sm font-medium leading-6 text-gray-900 flex items-center">
                  <div className="flex flex-col  lg:ml-10 mt-2">
                    <label
                      htmlFor="languages"
                      className="text-sm font-medium leading-6 text-gray-900  flex mb-1 items-center ml-10"
                    >
                      Graduation Years
                    </label>
                    <div className="flex items-center  gap-2">
                      <CalendarIcon
                        className="w-6 h-6 text-gray-700"
                        aria-hidden="true"
                      />
                      <DropdownSelector
                        options={graduationYearsOptions}
                        // comment class might be used in responsive
                        // ring-1 ring-inset ring-gray-300 placeholder:text-gray-50 focus:ring-2 focus:ring-inset focus:ring-gray-50
                        className=" !w-[200px] rounded-md  py-1.5  text-sm
               !bg-gray-300 !bg-opacity-20 !border-gray-200 !border-[2px] focus:!border-gray-400 focus:!border-[2px]
               
               text-gray-500
               
               "
                        //  !bg-gray-300 !bg-opacity-20 !border-gray-300 !border-[2px] focus:!border-gray-400 focus:!border-[2px]
                        selectedOption={selectedGraduationYearsOption}
                        onChange={(option) => {
                          setselectedGraduationYearsOption(option);
                        }}
                        customColor="gray"
                        placeholder="YEAR"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full m-auto">
                <label
                  htmlFor="title"
                  className="text-sm font-medium leading-6 text-gray-900 flex items-center ml-8 lg:ml-10"
                >
                  Skills and certifications
                </label>
                <div className="mt-2 flex items-center gap-2">
                  <CheckCircleIcon className="w-6 h-6 text-gray-700 mr-2" />
                  <TagsInput
                    classNames={{
                      container:
                        "flex items-center flex-wrap  focus:ring-gray-400 box-border rounded-md ring-1 ring-inset ring-gray-300",
                      tag: {
                        container:
                          "flex items-center m-1 bg-gray-50 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 h-10",
                        text: "pl-2 mr-0 text-sm font-medium text-gray-700",
                        removeButton: "w-8 -ml-2 text-gray-700",
                      },
                      input:
                        "block bg-gray-50 rounded-md border-0  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm h-12",
                    }}
                    value={skillsArray}
                    onChange={(tags: string[]) => setSkillsArray(tags)}
                    name="skills"
                    placeHolder="Skill"
                  />
                </div>
              </div>
            </div>
            <div className="md:flex grid pb-10 lg:p-10 rounded-2xl w-full shadow-lg md:justify-center justify-start text-left m-auto gap-8">
              <div className="w-full m-auto">
                <label
                  htmlFor="languages"
                  className="text-sm font-medium leading-6 text-gray-900  flex mb-1 items-center md:ml-11 ml-5"
                >
                  Choose your Specilities
                </label>
                <SpecialitiesSelect
                  count={3}
                  categories={categories}
                  selectedCategories={selectedCategories}
                  setSelectedCategory={setSelectedCategory}
                />
                <div className="text-sm font-medium leading-6 text-red-500 flex items-center ml-10 capitalize">
                  {formErrors.jobSpecialities}
                </div>
              </div>

              <div className="w-full m-auto md:mr-0 mr-7 md:pr-0 md:pl-0 pl-5 pr-4">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-6 text-gray-900 md:ml-8 lg:ml-4"
                >
                  Average Hourly Rate
                </label>
                <div className="mt-2 md:ml-2  lg:ml-4 flex gap-2 items-center">
                  <input
                    onChange={(event) => {
                      setInput("avgHourlyRate", event.target.value);
                      setFormErrors((prevErrors) => ({
                        ...prevErrors,
                        averageHourlyRate: "",
                      }));
                    }}
                    value={formState.avgHourlyRate}
                    id="avgHourlyRate"
                    type="number"
                    placeholder="Average Hourly Rate"
                    required
                    className="block w-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-12"
                  />
                </div>
                <div className="text-sm font-medium leading-6 text-red-500 flex items-center ml-10 capitalize">
                  {formErrors.averageHourlyRate}
                </div>
              </div>
            </div>
          </>
        )}
        {(currentUser?.type === UserType.STUDENT ||
          currentUser?.type === UserType.POSTER) && (
          <>
            <div className="flex pb-10 lg:p-10 rounded-2xl w-full shadow-lg justify-center text-left m-auto gap-8">
              <div className="w-full m-auto">
                <h4 className="text-gray-900 text-4lg font-bold ml-8 lg:ml-10">
                  Receive email updates
                  {/* Receive email notifications */}
                </h4>
              </div>
              <div className="flex max-w-md flex-col gap-4">
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    className="sr-only peer"
                    onChange={handleSwitchToggle}
                    checked={formState.emailNotificationEnabled || false}
                  />
                  <div className="w-11 h-6 bg-gray-300 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-200 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primaryTeal"></div>
                </label>
              </div>
            </div>
          </>
        )}
        <button
          className="w-full mt-8 flex justify-center items-center GothamBold bg-orange-500 font-bold text-white rounded-full disabled:opacity-50"
          onClick={saveUserInfo}
          disabled={isSaving}
        >
          {isSaving && <CustomSpinner className="pr-4" />} Save Profile
        </button>

        {/* Admins cannot deactivate their accounts */}
        {userGroup?.id !== "Admins" &&
          [UserType.STUDENT, UserType.POSTER].includes(currentUser?.type) && (
            <div className="w-full m-auto">
              <p className="text-gray-900 text-xs ml-8 lg:ml-10">
                To deactivate account click{" "}
                <span
                  className="underline cursor-pointer text-xs text-red-600"
                  onClick={() => setOpenDeactivateAccountModal(true)}
                >
                  here
                </span>
              </p>
            </div>
          )}
      </form>

      {openDeactivateAccountModal && (
        <DeactivateAccountModal
          open={openDeactivateAccountModal}
          setOpen={setOpenDeactivateAccountModal}
        />
      )}
    </>
  );
}

export default EditProfile;
