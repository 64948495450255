/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from "react";
import Footer from "../components/nav/footer";
import Search, { filters } from "../components/student/search";
import * as dataProvider from "../graphql/DataProvider";
import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  LightBulbIcon,
  MapPinIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import PrimaryButton from "../components/buttons/primary_button";
import { logEvent } from "../helpers/Analytics";
import SkeletonJobCards from "../components/Skeleton/SkeletonJobCards";
import Chips from "../components/chips";
import { Job, User, UserType, JobCategory, UpdateUserInput } from "../API";
import userProfileIcon from "../assets/userProfileIcon.svg";
import StudentHireModal from "../components/modals/student_hire_modal";
import StudentSelect from "../components/profile/student_select";
import { Auth } from "aws-amplify";
import {
  averageRatingForUser,
  sortArrayAlphabeticallyByProperty,
} from "../helpers/Utilities";
import Universities from "./universities.json";
// import Categories from "../assets/Categories.png"
import { StarIcon } from "@heroicons/react/20/solid";
import DropdownSelector, {
  DropDownSelectorOption,
} from "../components/dropdown_selector";
import { Loader } from "@googlemaps/js-api-loader";
import { GOOGLE_MAPS_API_KEY } from "../utils/constants";
import TooltipWrapper from "../helpers/TooltipWrapper";
import { HireStudentMessage } from "../helpers/Constants";
import AverageHourlySearch from "../components/student/averagerHourlySearch";
import ReviewRating from "../components/review_star";
import { parseLocation } from "../helpers/Utilities";
import { toast } from "react-toastify";
import JobCategoriesSelect from "../components/common/job_categories_select";
import { useDebounce } from "use-debounce";
import UserProfileImage from "../components/student/userProfileImage";
import { Button } from "flowbite-react";
import { HiOutlineArrowRight, HiOutlineArrowLeft } from "react-icons/hi";
interface StudentHireProps {
  showApplicants?: boolean;
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface JobSpecilities {
  id: string;
  name: string;
}

function StudentHire({ showApplicants }: StudentHireProps) {
  const navigate = useNavigate();
  const univObjects = JSON.parse(JSON.stringify(Universities));
  const filterOptions = useLocation().state as filters | null;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState<
    filters | null | undefined
  >(filterOptions);
  const [isInviteAllDisabled, setInviteAllDisabled] = useState(true);
  const [isSelectStudentModalOpen, setSelectStudentModalOpen] = useState(false);
  const [filteredStudents, setFilteredStudents] = useState<User[]>([]);
  const [geoCities, setGeoCities] = useState<User[]>([]);
  const [cityHeader, setCityHeader] = useState("");

  const categoryOption = useLocation().state as filters | null;
  const [jobs, setJobs] = useState<Job[]>([]);
  const [filteredJobs, setFilteredJobs] = useState<Job[]>([]);
  const [isLoadingJobs, setLoadingJobs] = useState<boolean>(true);
  const [selectedCategoryOption, setSelectedCategoryOption] = useState<
    filters | null | undefined
  >(categoryOption);
  const [signedInUser, setsignedInUser] = useState();

  /// The next token to use for pagination. If nil, then we can't move forward or
  /// are starting from the beginning.
  // const [nextToken, setNextToken] = useState<string | null | undefined>(null);
  /// City/State Autocomplete.
  const locationCityStateRef = useRef<HTMLInputElement | null>(null);
  const locationCoordinateLat = useRef<number | null>(null);
  const locationCoordinateLong = useRef<number | null>(null);
  const autoCompleteRef = useRef<google.maps.places.Autocomplete | undefined>();
  const [students, setStudents] = useState<User[]>([]);
  const [studentsModified, setStudentsModified] = useState<User[]>([]);
  const [isLoadingStudents, setLoadingStudents] = useState<boolean>(true);

  const [nextToken, setNextToken] = useState<string | null | undefined>(null);
  const [tokenStack, setTokenStack] = useState<string[]>([]);
  const [startIndex, setStartIndex] = useState<number>(0);
  const [nearbyAPICall, setnearbyAPICall] = useState(false);

  // selected user
  const [selectedStudent, setSelectedStudent] = useState<User[]>([]);
  // search student by average hourly hours
  const [minHourlyRate, setMinHourlyRate] = useState<number>(0);
  const [maxHourlyRate, setMaxHourlyRate] = useState<number>(200);
  const [showSlider, setShowSlider] = useState<boolean>(false);

  const [debouncedMinHourlyRate] = useDebounce(minHourlyRate, 1000);
  const [debouncedMaxHourlyRate] = useDebounce(maxHourlyRate, 1000);

  const [jobCategories, setJobCategories] = useState<JobCategory[]>([]);
  const [jobSpecilities, setJobSpecilities] = useState<JobSpecilities[]>([
    {
      id: "",
      name: "All Job Specilities",
    },
  ]);
  const [selectedJobSpecilities, setSelectedJobSpecilities] =
    useState<DropDownSelectorOption>(jobSpecilities[0]);
  const scrollTargetRef = useRef<HTMLDivElement | null>(null);

  const getAllCategories = () => {
    dataProvider.getJobCategories().then((categories) => {
      setJobCategories(categories);

      // Extract unique IDs from the current jobSpecilities state
      const existingIds = new Set(jobSpecilities.map((spec) => spec.id));

      // Filter out categories that already exist in jobSpecilities
      const newCategories = categories.filter(
        (category) => !existingIds.has(category.id)
      );

      // Create newJobSpecilities array by combining existing and new categories
      const newJobSpecilities = [...jobSpecilities, ...newCategories];

      // Update the state
      setJobSpecilities(newJobSpecilities);
    });
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  function getSearchResultsTitle(): string {
    // Default.
    // return `${isLoadingStudents ? "-" : students?.length} ${
    //   students.length === 1 ? "Student" : "Students"
    // }`;
    return `${isLoadingStudents ? "-" : studentsModified?.length} ${
      studentsModified.length === 1 ? "Student" : "Students"
    }`;
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    logEvent("page_view_browse", {
      queryParams: queryParams.entries(),
      referrer: document.referrer,
    });
  }, []);

  const onHandleMultipleStudentSelect = (students: User[]) => {
    setInviteAllDisabled(students.length < 0);
    setSelectedStudent(students);
  };

  const openSelectStudent = () => {
    // setSelectStudentModalOpen(true);
    if (signedInUser) {
      setSelectStudentModalOpen((prevOpen) => !prevOpen);
    }
  };
  const options = {
    componentRestrictions: { country: "us" }, // US Only.
    fields: ["geometry"],
    strictBounds: false,
    types: ["(cities)"], // Only search for cities.
  };

  /// Loads the Google Maps API.
  function loadMapsAPI() {
    new Loader({
      apiKey: GOOGLE_MAPS_API_KEY, // Colledge Key.
      version: "weekly",
      libraries: ["places"],
    })
      .importLibrary("places")
      .then((places) => {
        autoCompleteRef.current = new places.Autocomplete(
          locationCityStateRef.current!,
          options
        );
        // autoCompleteRef.current.addListener(
        //   "place_changed",
        //   getCoordinatesFromPlace
        // );
        autoCompleteRef.current.addListener("place_changed", () => {
          setTimeout(getCoordinatesFromPlace, 0);
        });
      })
      .catch((error) => {
        console.error("Error loading Maps API: ", error);
      });
  }

  useEffect(() => {
    if (!autoCompleteRef.current) {
      loadMapsAPI();
    }
  }, []);

  /// Handles autocompletion for the City/State field.
  async function getCoordinatesFromPlace() {
    // Get the place details from the autocomplete object.
    const place = autoCompleteRef.current?.getPlace();
    // Get Lat/Long coordinates.
    if (place?.geometry?.location) {
      const lat = place?.geometry?.location.lat();
      const lng = place?.geometry?.location.lng();
      locationCoordinateLat.current = lat;
      locationCoordinateLong.current = lng;
      setGeoCities([]);
      setLoadingStudents(true);
      try {
        await getCitiesAround(lat, lng);
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
      setLoadingStudents(false);
    }
  }

  async function getCitiesAround(lat: any, lng: any) {
    const url = `https://secure.geonames.org/findNearbyPlaceNameJSON?lat=${lat}&lng=${lng}&style=long&cities=cities5000&radius=20&maxRows=500&username=findica`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const cities = await response.json();
    setGeoCities((prev) => {
      return cities?.geonames;
    });
    return cities;
  }

  const categoryChanged = (selectedCategory: filters | undefined | null) => {
    setSelectedCategoryOption(selectedCategory);
  };

  /// Distance sorting options.
  const distanceSortOptions: DropDownSelectorOption[] = [
    { id: 3, name: "3 miles" },
    { id: 5, name: "5 miles" },
    { id: 10, name: "10 miles" },
    { id: 15, name: "15 miles" },
    { id: 20, name: "20 miles" },
    { id: 30, name: "30 miles" },
    { id: 40, name: "40 miles" },
  ];
  const [selectedDistanceSortOption, setSelectedDistanceSortOption] =
    useState<DropDownSelectorOption>(distanceSortOptions[2]);

  /// Load saved sort option from session storage. This is so the filters
  /// persist across Job navigations.
  useEffect(() => {
    const distanceOptionStored = sessionStorage.getItem("distanceOptionID");
    if (distanceOptionStored) {
      const distanceOption = distanceSortOptions.find(
        (option) => option.id === parseInt(distanceOptionStored)
      );
      if (distanceOption) {
        setSelectedDistanceSortOption(distanceOption);
      }
    }
  }, []);

  /// When user selects a new category or distance option, perform a new search.
  // useEffect(() => {
  //   searchJobs([]);
  // }, [selectedCategoryOption, selectedDistanceSortOption]);

  /// When the jobs array changes, process job filters.
  // useEffect(() => {
  //   processJobFilters();
  // }, [jobs]);

  /// Processes the job filters for sorting based on user selections.
  // function processJobFilters() {
  //   // setLoadingJobs(true);
  //   let sortedJobs = jobs;
  //   // Sort, if needed.
  //   setFilteredJobs([...sortedJobs]);
  //   // setLoadingJobs(false);
  // }
  /// Searches for jobs with selected category and existing jobs to add to (pagination).
  // const searchJobs = (jobsToUpdate?: Job[]) => {
  //   let token = nextToken;
  //   if (!jobsToUpdate?.length) {
  //     token = null;
  //     setNextToken(token);
  //   }
  //   setLoadingJobs(true);
  //   // Perform a search, using an X mile radius (if location is provided).
  //   // If categoryID is null, then it will search all categories.
  //   // If we're a fresh search, clear the stack.
  //   dataProvider
  //     .getAvailableJobsAroundLocation(
  //       selectedCategoryOption ? selectedCategoryOption.id : null,
  //       locationCoordinateLat.current,
  //       locationCoordinateLong.current,
  //       selectedDistanceSortOption.id as number, // Radius.
  //       token,
  //       30
  //     )
  //     .then((jobsQuery) => {
  //       setLoadingJobs(false);
  //       if (jobsQuery?.items) {
  //         const jobResults = jobsQuery.items;
  //         let existingJobs = jobsToUpdate ?? [];
  //         for (const job of jobResults) {
  //           if (
  //             existingJobs.filter((object) => object!.id === job!.id).length ===
  //             0
  //           ) {
  //             existingJobs.push(job as Job);
  //           }
  //         }
  //         setJobs(existingJobs);
  //       } else {
  //         setJobs(jobsToUpdate ?? []);
  //       }
  //       // setNextToken(jobsQuery?.nextToken);
  //       processJobFilters();
  //     });
  // };

  /// Resets the city/state field.
  function resetLocationField() {
    locationCityStateRef.current!.blur();
    locationCityStateRef.current!.value = "";
    locationCoordinateLat.current = null;
    locationCoordinateLong.current = null;
  }
  /// Searches for jobs using the city/state field.
  function setSearchCityState() {
    locationCityStateRef.current!.disabled = false;
    resetLocationField();
    // fetchFilterAlldata();
    setGeoCities([]);
  }
  useEffect(() => {
    Auth.currentUserInfo().then((res) => {
      setsignedInUser(res);
    });
  }, []);
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    logEvent("page_view_browse", {
      queryParams: queryParams.entries(),
      referrer: document.referrer,
    });
  }, []);

  const getCategoryName = (id: string) => {
    const category = jobCategories.find((category) => category.id === id);
    return category?.name;
  };

  const ShowSliderAction = () => {
    setShowSlider((prevShowSilder) => !prevShowSilder);
    setMaxHourlyRate(200);
    setMinHourlyRate(0);
  };

  // reset the average hourly slider
  const resetAverageHourlySlider = () => {
    setShowSlider(false);
    setMaxHourlyRate(200);
    setMinHourlyRate(0);
    setStartIndex(0);
    setSelectedPagination(updatePaginationOptions()[0]);
  };
  const SortjobSpecilities = sortArrayAlphabeticallyByProperty(
    jobSpecilities,
    "name"
  );
  const searchUsersWithFilters = (filters: any) => {
    setLoadingStudents(true);
    dataProvider
      .getAllUsers(nextToken, 999999, filters)
      .then((userQuery) => {
        setLoadingStudents(false);
        if (userQuery?.listUsers) {
          let userList = userQuery.listUsers.items as User[];
          //filter out the demo accounts
          let demoAcctDomains = ["studioinit.co", "colledge.us", "findica.io"];
          // console.log("Signed in user",signedInUser);
          // if (signedInUser && signedInUser["attributes"] && signedInUser["attributes"]["email"] ) {
          //   if (!(signedInUser["attributes"]["email"] as String).endsWith("colledge.us")) {
          //     demoAcctDomains.push("colledge.us");
          //     console.log("colledge.us included in demo acc domain...1");

          //   }
          // }else{
          //   demoAcctDomains.push("colledge.us");
          //   console.log("colledge.us included in demo acc domain...2");
          // }

          const hostnames = ["localhost", "dev.colledge.us"];
          if (!hostnames.includes(window.location.hostname)) {
            userList = userList.filter((element, index, array) => {
              let toReturn = true;
              toReturn = !demoAcctDomains.includes(
                element?.email.split("@")[1]
              );
              if (!toReturn) {
                console.log(
                  "filtering out demo acct :",
                  element.email,
                  element
                );
              }
              return toReturn;
            });
          }

          const sortedJobsWithIndex = userList.map((user, index) => ({
            ...user,
            indexKey: index + 1,
          }));

          setStudents(sortedJobsWithIndex);
          setStudentsModified(sortedJobsWithIndex);

          // setStudents(userList);
          setNextToken(userQuery?.listUsers?.nextToken);
        } else {
          setStudents([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
        setLoadingStudents(false);
        setStudents([]);
      });
  };
  // console.log(students, "504");
  // console.log(students, "students12");

  // const fetchFilterAlldata = () => {
  //   console.log("calling this function...");
  //   if (
  //     locationCityStateRef.current!?.value &&
  //     !selectedFilterOptions &&
  //     selectedJobSpecilities.id === ""
  //   ) {
  //     console.log("coming to if1")
  //     const address = parseLocation(locationCityStateRef.current!?.value);
  //     const filter = {
  //       type: UserType.STUDENT,
  //       location: {
  //         city: address?.city || locationCityStateRef.current!.value,
  //         state: address?.state || locationCityStateRef.current!.value,
  //       },
  //     };
  //     console.log(students, "students...............");

  //     searchUsersWithFilters(filter);
  //     console.log(filter, "filter");
  //   } else if (selectedJobSpecilities.id !== "" || selectedFilterOptions) {
  //     console.log("coming to else if1")
  //     setLoadingStudents(true);
  //     const jobSpecilitiesCategoryId =
  //       selectedJobSpecilities.id ||
  //       (selectedFilterOptions ? selectedFilterOptions.id : "");
  //     dataProvider
  //       .listJobSpecilitiesUsers({
  //         jobSpecilitiesCategoryId: jobSpecilitiesCategoryId as string,
  //       })
  //       .then((result) => {
  //         const userIds = result?.items
  //           .map((item) => item?.jobSpecilitiesUserUserID)
  //           .filter(Boolean);
  //         if (
  //           userIds &&
  //           userIds.length > 0 &&
  //           !locationCityStateRef.current!?.value
  //         ) {
  //           const uniqueUserIds = Array.from(new Set(userIds));
  //           const filter = {
  //             type: UserType.STUDENT,
  //             userID: uniqueUserIds,
  //             minimumAvgHourlyRate: debouncedMinHourlyRate,
  //             maximumAvgHourlyRate: debouncedMaxHourlyRate,
  //             // Add other filters as needed
  //           };
  //           searchUsersWithFilters(filter);
  //         } else if (
  //           (userIds &&
  //             userIds.length > 0 &&
  //             locationCityStateRef.current!?.value) ||
  //           minHourlyRate
  //         ) {
  //           const uniqueUserIds = Array.from(new Set(userIds));
  //           const address = parseLocation(locationCityStateRef.current!?.value);
  //           const filter = {
  //             type: UserType.STUDENT,
  //             userID: uniqueUserIds?.[0],
  //             minimumAvgHourlyRate: minHourlyRate,
  //             maximumAvgHourlyRate: maxHourlyRate,
  //             // Add other filters as needed
  //             location: {
  //               city: address?.city,
  //               state: address?.state,
  //             },
  //           };
  //           dataProvider
  //             .getFilterCatergeryAndlocation(null, 100, filter)
  //             .then((userQuery) => {
  //               setLoadingStudents(false);
  //               if (userQuery?.listUsers) {
  //                 const userList = userQuery.listUsers.items as User[];
  //                 console.log(userList, "userList");
  //                 // setStudents(userList);
  //                 // to get the list of students that are currectly matched with job catagory & location city
  //                 const filteredStudents = students.filter((student) => {
  //                   // Check if the student has job specialties
  //                   const hasJobSpecialties =
  //                     student.jobSpecilities &&
  //                     student.jobSpecilities.items &&
  //                     student.jobSpecilities.items.some(
  //                       (specialty) =>
  //                         specialty &&
  //                         specialty.jobSpecilitiesCategoryId ===
  //                           selectedFilterOptions?.id
  //                     );

  //                   // Check if the student's location matches any city in geoCities
  //                   const hasMatchingLocation = geoCities.some(
  //                     (city) =>
  //                       student.locationCity?.toLowerCase() ===
  //                         city?.name?.toLowerCase() &&
  //                       student.locationState?.toLowerCase() ===
  //                         city?.adminCode1?.toLowerCase()
  //                   );

  //                   // Include the student in the filtered result if both conditions are met
  //                   return hasJobSpecialties && hasMatchingLocation;
  //                 });
  //                 console.log(filteredStudents, "filteredStudents");
  //                 setStudents(filteredStudents);
  //                 setStudentsModified(filteredStudents);
  //               } else {
  //                 setStudents([]);
  //               }
  //             })
  //             .catch((error) => {
  //               console.error("Error fetching users:", error);
  //               setLoadingStudents(false);
  //               setStudents([]);
  //             });
  //         } else {
  //           setLoadingStudents(false);
  //           setStudents([]);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching job specialties users:", error);
  //         setLoadingStudents(false);
  //         setStudents([]);
  //       });
  //   } else {
  //     console.log("coming to else1")
  // const filter = {
  //   type: UserType.STUDENT,
  //   minimumAvgHourlyRate:
  //     maxHourlyRate === 200 && minHourlyRate === 0
  //       ? undefined
  //       : debouncedMinHourlyRate,
  //   maximumAvgHourlyRate:
  //     maxHourlyRate === 200 && minHourlyRate === 0
  //       ? undefined
  //       : debouncedMaxHourlyRate,
  // };
  // searchUsersWithFilters(filter);
  //   }
  // };

  const searchFilterChanged = (selectedFilter: filters | undefined | null) => {
    setSelectedFilterOptions(selectedFilter);
    setSelectedJobSpecilities(jobSpecilities[0]);
  };
  useEffect(() => {
    const filter = {
      type: UserType.STUDENT,
      minimumAvgHourlyRate:
        maxHourlyRate === 200 && minHourlyRate === 0
          ? undefined
          : debouncedMinHourlyRate,
      maximumAvgHourlyRate:
        maxHourlyRate === 200 && minHourlyRate === 0
          ? undefined
          : debouncedMaxHourlyRate,
    };
    searchUsersWithFilters(filter);
  }, []);

  // useEffect(
  //   () => {
  //     fetchFilterAlldata();
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   },
  //   [
  //     // selectedJobSpecilities,
  //     // debouncedMaxHourlyRate,
  //     // debouncedMinHourlyRate,
  //     // locationCityStateRef.current!?.value,
  //     // selectedFilterOptions,
  //     // geoCities,
  //   ]
  // );

  useEffect(() => {
    if (students.length <= 0) {
      return;
    }

    if (
      selectedFilterOptions &&
      Object.keys(selectedFilterOptions || {}).length > 0 &&
      locationCityStateRef?.current?.value &&
      locationCityStateRef.current.value.length > 0 &&
      geoCities.length > 0
    ) {
      const cityState = locationCityStateRef?.current?.value.split(",");
      const absoluteCity = cityState?.length>0?cityState[0].trim().toLocaleLowerCase():null;
      const absoluteState = cityState?.length>1?cityState[1].trim().toLocaleLowerCase():null;

      let result: any[] = [];
      for (let i = 0; i < students.length; i++) {
        const student = students[i];
        let { locationCity, locationState } = student;
        if (locationCity !== null && locationState !== null) {
          for (let j = 0; j < geoCities.length; j++) {
            const geocity = geoCities[j];
            let { adminCode1, name } = geocity;
            if (
              (locationCity?.toLowerCase() === name?.toLowerCase() &&
              locationState?.toLowerCase() === adminCode1?.toLowerCase())||
              (
                locationCity?.toLowerCase() === absoluteCity &&
                locationState?.toLowerCase() === absoluteState
              )
            ) {
              result.push(student);
              break;
            }
          }
        }
      }
      let resultWithSpeciality: any[] = [];
      for (let i = 0; i < result.length; i++) {
        const student = result[i];
        let services = student.jobSpecilities?.items;
        if (services?.length) {
          for (let j = 0; j < services.length; j++) {
            const service = services[j];
            if (
              service?.jobSpecilitiesCategoryId === selectedFilterOptions.id
            ) {
              resultWithSpeciality.push(student);
              break;
            }
          }
        }
      }

      if (minHourlyRate === 0 && maxHourlyRate === 200) {
        const sortedJobsWithIndex = resultWithSpeciality.map((user, index) => ({
          ...user,
          indexKey: index + 1,
        }));
        setStudentsModified(sortedJobsWithIndex);
      } else {
        let filtered: any[] = [];
        for (let i = 0; i < resultWithSpeciality.length; i++) {
          const student = resultWithSpeciality[i];
          if (student.avgHourlyRate != null) {
            if (
              student.avgHourlyRate >= minHourlyRate &&
              student.avgHourlyRate <= maxHourlyRate
            ) {
              filtered.push(student);
            }
          } else {
            continue;
          }
        }
        setStartIndex(0);
        setSelectedPagination(updatePaginationOptions()[0]);
        const sortedJobsWithIndex = filtered.map((user, index) => ({
          ...user,
          indexKey: index + 1,
        }));

        setStudentsModified(sortedJobsWithIndex);
      }
      // setStudentsModified(resultWithSpeciality);
    } else if (
      !selectedFilterOptions &&
      geoCities.length > 0 &&
      locationCityStateRef?.current?.value &&
      locationCityStateRef.current.value.length > 0
    ) {
      // let splittedLocation=locationCityStateRef.current.value.split(",");
      // let state=splittedLocation[1].toLowerCase().trim();
      // // filtering by state
      // let studentsFiltered=students.filter(student=>student.locationState&&student.locationState===state);
      // console.log(studentsFiltered,"studentsFiltered...")
      //
      let result: any[] = [];
      const cityState = locationCityStateRef?.current?.value.split(",");
      const absoluteCity = cityState?.length>0?cityState[0].trim().toLocaleLowerCase():null;
      const absoluteState = cityState?.length>1?cityState[1].trim().toLocaleLowerCase():null;

      for (let i = 0; i < students.length; i++) {
        const student = students[i];
        let { locationCity, locationState } = student;
        if (locationCity !== null && locationState !== null) {
          for (let j = 0; j < geoCities.length; j++) {
            const geocity = geoCities[j];
            let { adminCode1, name } = geocity;
            if (
              (
                locationCity?.toLowerCase() === name?.toLowerCase() &&
                locationState?.toLowerCase() === adminCode1?.toLowerCase())||
              (
                locationCity?.toLowerCase() === absoluteCity &&
                locationState?.toLowerCase() === absoluteState
              )
            ) {
              result.push(student);
              break;
            }
          }
        } else {
          continue;
        }
      }
      if (minHourlyRate === 0 && maxHourlyRate === 200) {
        const sortedJobsWithIndex = result.map((user, index) => ({
          ...user,
          indexKey: index + 1,
        }));
        setStudentsModified(sortedJobsWithIndex);
      } else {
        let filtered: any[] = [];
        for (let i = 0; i < result.length; i++) {
          const student = result[i];
          if (student.avgHourlyRate != null) {
            if (
              student.avgHourlyRate >= minHourlyRate &&
              student.avgHourlyRate <= maxHourlyRate
            ) {
              filtered.push(student);
            }
          } else {
            continue;
          }
        }

        setStartIndex(0);
        setSelectedPagination(updatePaginationOptions()[0]);
        const sortedJobsWithIndex = filtered.map((user, index) => ({
          ...user,
          indexKey: index + 1,
        }));
        setStudentsModified(sortedJobsWithIndex);
      }
      // setStudentsModified(result);
    } else if (
      selectedFilterOptions &&
      Object.keys(selectedFilterOptions || {}).length > 0 &&
      !locationCityStateRef?.current?.value
    ) {
      let resultWithSpeciality: any[] = [];
      for (let i = 0; i < students.length; i++) {
        const student = students[i];
        let services = student.jobSpecilities?.items;
        if (services?.length) {
          for (let j = 0; j < services.length; j++) {
            const service = services[j];
            if (
              service?.jobSpecilitiesCategoryId === selectedFilterOptions.id
            ) {
              resultWithSpeciality.push(student);
              break;
            }
          }
        }
      }
      if (minHourlyRate === 0 && maxHourlyRate === 200) {
        const sortedJobsWithIndex = resultWithSpeciality.map((user, index) => ({
          ...user,
          indexKey: index + 1,
        }));
        setStudentsModified(sortedJobsWithIndex);
      } else {
        let filtered: any[] = [];
        for (let i = 0; i < resultWithSpeciality.length; i++) {
          const student = resultWithSpeciality[i];
          if (student.avgHourlyRate != null) {
            if (
              student.avgHourlyRate >= minHourlyRate &&
              student.avgHourlyRate <= maxHourlyRate
            ) {
              filtered.push(student);
            }
          } else {
            continue;
          }
        }
        setStartIndex(0);
        setSelectedPagination(updatePaginationOptions()[0]);
        const sortedJobsWithIndex = filtered.map((user, index) => ({
          ...user,
          indexKey: index + 1,
        }));
        setStudentsModified(sortedJobsWithIndex);
      }
      // setStudentsModified(resultWithSpeciality);
    } else {
      if (minHourlyRate === 0 && maxHourlyRate === 200) {
        const sortedJobsWithIndex = students.map((user, index) => ({
          ...user,
          indexKey: index + 1,
        }));
        setStudentsModified(sortedJobsWithIndex);
      } else {
        let filtered: any[] = [];
        for (let i = 0; i < students.length; i++) {
          const student = students[i];
          if (student.avgHourlyRate != null) {
            if (
              student.avgHourlyRate >= minHourlyRate &&
              student.avgHourlyRate <= maxHourlyRate
            ) {
              filtered.push(student);
            }
          } else {
            continue;
          }
        }
        setStartIndex(0);
        setSelectedPagination(updatePaginationOptions()[0]);
        const sortedJobsWithIndex = filtered.map((user, index) => ({
          ...user,
          indexKey: index + 1,
        }));
        setStudentsModified(sortedJobsWithIndex);
      }
      // setStudentsModified(students);
    }
  }, [
    geoCities?.length,
    students?.length,
    selectedFilterOptions,
    minHourlyRate,
    maxHourlyRate,
  ]);

  // useEffect(() => {
  //   if(studentsModified?.length<0) return;
  //   if(minHourlyRate>=0&&maxHourlyRate<=200){
  //     let filtered:any[]=[];
  //     for (let i = 0; i < studentsModified.length; i++) {
  //       const student = studentsModified[i];
  //       if(student.avgHourlyRate!=null){
  //         if(student.avgHourlyRate>=minHourlyRate&&student.avgHourlyRate<=maxHourlyRate){
  //           filtered.push(student);
  //         }
  //       }

  //     }
  //     setStudentsModified(filtered);
  //   }else{
  //     setStudentsModified(students);
  //   }
  // }, [minHourlyRate,maxHourlyRate])

  const handleKeyCapture = (e: any) => {
    if (e.key === "Enter") {
      // fetchFilterAlldata();
    }
  };

  useEffect(() => {
    if (!autoCompleteRef.current) {
      loadMapsAPI();
    }
  }, []);

  // useEffect(() => {
  //   const tempStudents = [...students];

  //   const isLocalOrDev =
  //     window.location.hostname === "localhost" ||
  //     window.location.hostname === "dev.colledge.us";

  //   const updatedStudents = tempStudents.filter((student) => {
  //     const email = student?.email || "";

  //     // const signedInUserEmail = signedInUser?.attributes?.email||"";

  //     if (!signedInUser && !isLocalOrDev) {
  //       // Pre-Login: hide all students with colledge.us and findica.io email ids if domain is not localhost or dev.colledge.us
  //       return !email.includes("colledge.us") && !email.includes("findica.io");
  //     }
  //     if (signedInUser && !isLocalOrDev) {
  //       // Post-Login: if login users email is colledge.us, show all students except findica.io
  //       return !email.includes("findica.io");
  //     }
  //     // if (signedInUser && !isLocalOrDev) {
  //     //   // Post-Login: if login users email is findica.io, show students with findica.io and colledge.us emails along with all others
  //     //   return email.includes("findica.io") || email.includes("colledge.us");
  //     // }
  //     return true;
  //   });

  //   setStudents(updatedStudents);
  // }, [students?.length, signedInUser]);

  const getUniversity = (student: User) => {
    if (student.school) {
      return student.school;
    } else if (student.email) {
      let school_email_domain = student.email.split("@")[1];
      if (univObjects[school_email_domain]) {
        return univObjects[school_email_domain]["School Name"];
      }
    }
  };

  //** Function to calculate total number of pages
  const calculateTotalPages = () => {
    // return Math.ceil(students.length / ITEMS_PER_PAGE);
    return Math.ceil(studentsModified.length / ITEMS_PER_PAGE);
  };

  const totalItemsToShowOptions: DropDownSelectorOption[] = [
    { id: 1, name: "Show 10 per page" },
    { id: 2, name: "Show 20 per page" },
    { id: 3, name: "Show 30 per page" },
    { id: 4, name: "Show 40 per page" },
    { id: 5, name: "Show 50 per page" },
  ];
  const [totalItemToShow, setTotalItemToShow] =
    useState<DropDownSelectorOption>(totalItemsToShowOptions[0]);

  const paginationOptions: DropDownSelectorOption[] = [
    { id: 1, name: "Page 1 of 5" },
    { id: 2, name: "Page 2 of 5" },
    { id: 3, name: "Page 3 of 5" },
    { id: 4, name: "Page 4 of 5" },
    { id: 5, name: "Page 5 of 5" },
  ];
  const [selectedPagination, setSelectedPagination] =
    useState<DropDownSelectorOption>(paginationOptions[0]);

  const ITEMS_PER_PAGE_OPTIONS: {
    [key: string]: number;
  } = {
    "1": 10,
    "2": 20,
    "3": 30,
    "4": 40,
    "5": 50,
  };

  // Update pagination options on totalItemToShow change
  useEffect(() => {
    const newPaginationOptions = updatePaginationOptions();
    setSelectedPagination(newPaginationOptions[0]);
    // setTimeout(() => {
    //   setStartIndex(0);
    //   setSelectedPagination(updatePaginationOptions()[0]);
    // }, 2000);
  }, [
    totalItemToShow.id,
    // students,
    studentsModified,
    minHourlyRate,
    maxHourlyRate,
    setMinHourlyRate,
    setMaxHourlyRate,
  ]);

  const ITEMS_PER_PAGE = ITEMS_PER_PAGE_OPTIONS[totalItemToShow.id as string];
  // const startIndex = (Number(selectedPagination.id) - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;

  const displayedStudents = students.slice(startIndex, endIndex);

  //** Function to Update pagination options dynamically
  const updatePaginationOptions = useCallback(() => {
    const totalPages = calculateTotalPages();
    const newPaginationOptions = Array.from(
      { length: totalPages },
      (_, index) => ({
        id: index + 1,
        name: `Page ${index + 1} of ${totalPages}`,
      })
    );
    return newPaginationOptions;
  }, [calculateTotalPages]);

  // Handle page change
  const handlePageChangeTop = (option: DropDownSelectorOption) => {
    // window.scrollTo({ top: 0, behavior: "smooth" });
    setSelectedPagination(option);
    const startIndex = (Number(option.id) - 1) * ITEMS_PER_PAGE;
    setStartIndex(startIndex);
  };

  // Handle page change
  const handlePageChange = (option: DropDownSelectorOption) => {
    scrollTargetRef.current?.scrollIntoView({ behavior: "smooth" });
    setSelectedPagination(option);
    const startIndex = (Number(option.id) - 1) * ITEMS_PER_PAGE;
    setStartIndex(startIndex);
    logEvent("browse_load_more_button_pressed");
    // searchUsersWithFilters({
    //   type: UserType.STUDENT,
    //   minimumAvgHourlyRate: minHourlyRate,
    //   maximumAvgHourlyRate: maxHourlyRate,
    // });
  };

  const handleNextPage = () => {
    scrollTargetRef.current?.scrollIntoView({ behavior: "smooth" });

    const newStartIndex = startIndex + ITEMS_PER_PAGE;
    if (newStartIndex < students.length) {
      setStartIndex(newStartIndex);
      const newPageNumber = Math.ceil(newStartIndex / ITEMS_PER_PAGE) + 1;
      setSelectedPagination({
        id: newPageNumber,
        name: `Page ${newPageNumber} of ${calculateTotalPages()}`,
      });
    }
  };

  const handlePrevPage = () => {
    // window.scrollTo({ top: 0, behavior: "smooth" });
    scrollTargetRef.current?.scrollIntoView({ behavior: "smooth" });

    const newStartIndex = startIndex - ITEMS_PER_PAGE;
    if (newStartIndex >= 0) {
      setStartIndex(newStartIndex);
      const newPageNumber = Math.ceil(newStartIndex / ITEMS_PER_PAGE) + 1;
      setSelectedPagination({
        id: newPageNumber,
        name: `Page ${newPageNumber} of ${calculateTotalPages()}`,
      });
    }
  };

  const capitalizeEachWord = (str: string | any) => {
    let str1 = str
      .split(" ")
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    let splittedLocation = locationCityStateRef?.current?.value.split(",");
    if (splittedLocation) {
      let city = splittedLocation[0]?.toLowerCase()?.trim();
      if (city === str1?.toLowerCase()) {
        return str1;
      } else {
        return "Nearby";
      }
    } else {
      return str1;
    }
  };

  const searchedCityHeader = (str: string | any) => {
    let str1 = str
      .split(" ")
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    let splittedLocation = locationCityStateRef?.current?.value.split(",");
    if (splittedLocation) {
      let city = splittedLocation[0]?.toLowerCase()?.trim();

      if (city === str1?.toLowerCase()) {
        return "";
      } else {
        return `*No searched results found for ${city}. Showing near by to ${city}`;
      }
    }
  };

  const headerMessage = geoCities?.length && (
    <div className="">
      {studentsModified.every(
        (student) => searchedCityHeader(student?.locationCity ?? "") !== ""
      ) ? (
        <div>{`*No searched results found for ${locationCityStateRef?.current?.value
          .split(",")[0]
          ?.toLowerCase()
          ?.trim()
          .replace(/^\w/, (c) =>
            c.toUpperCase()
          )}! Showing nearby results.`}</div>
      ) : (
        ""
      )}
    </div>
  );

  return (
    <>
      <div className="flex-row container m-auto justify-center p-4 lg:pt-8 max-w-7xl">
        <div
          className="w-auto h-[600px] relative pt-10 py-10 lg:py-40 rounded-xl bg-[url('../src/assets/babysitting.avif')] bg-no-repeat bg-cover"
          style={{ backgroundPositionY: "top", backgroundPositionX: "center" }}
        >
          <div
            ref={scrollTargetRef}
            className="flex flex-col w-10/12 m-auto relative gap-4 mt-[280px]"
          >
            <div className="w-full lg:w-8/12 relative lg:absolute">
              <JobCategoriesSelect
                defaultOption={selectedFilterOptions}
                onSearch={searchFilterChanged}
              />
              {/* <Search
                onSearch={searchFilterChanged}
                optionSelected={selectedFilterOptions}
              /> */}
            </div>
            <div className="flex flex-col m-auto gap-4 w-full lg:w-full p-0 lg:pl-4 relative lg:absolute right-0">
              <div className="w-full lg:w-4/12 p-0 lg:pl-4 relative lg:absolute right-0">
                <div className="relative p-4 flex h-[64px] text-left w-full items-center bg-gray-50 rounded-lg ring-1 ring-black/5">
                  <MapPinIcon className="h-6 w-6 text-gray-500" />
                  <div className="flex flex-row w-full items-center">
                    <input
                      ref={locationCityStateRef}
                      id="cityState"
                      type="text"
                      placeholder="Enter City/State"
                      onKeyDownCapture={handleKeyCapture}
                      onKeyDown={(e) => {
                        e.stopPropagation();
                        // console.log(e.key,"e key")
                        // if (e.key === "Enter") {
                        //   getCoordinatesFromPlace();
                        // }
                      }} // Prevent conflicts with Headless UI keyboard navigation.
                      className="border-0 relative w-full text-gray-900 text-sm leading-6 bg-none GothamBook focus:border-transparent focus:ring-0 bg-gray-50 ring:none shadow:none"
                    />
                    {locationCityStateRef.current?.value &&
                      locationCityStateRef.current?.value.length > 0 && (
                        <XCircleIcon
                          className="h-6 w-6 text-gray-500"
                          onClick={() => setSearchCityState()}
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span>&nbsp;&nbsp;</span>
        <div></div>
      </div>
      <span>&nbsp;&nbsp;</span>
      <div></div>

      <div className="flex flex-col lg:flex-row gap-3 ml-4">
        <div className="w-full md:max-w-11/12 px-8 md:px-4 mx-auto my-0">
          <div className="lg:flex grid items-center lg:justify-between justify-start w-full">
            <h2 className="text-xl text-gray-800 font-bold lg:text-justify text-start md:mx-1/4 py-4">
              {getSearchResultsTitle()}
            </h2>

            <div className="lg:flex justify-between grid gap-4 lg:gap-0 items-center md:space-x-4">
              {/* {!isLoadingStudents && displayedStudents.length !== 0 && (
                <> */}
              {!isLoadingStudents && studentsModified.length !== 0 && (
                <>
                  <div className="sm:flex sm:flex-col md:flex md:flex-row md:ml-[16.5px] lg:flex lg:flex-row sm:mb-[-12px] lg:mb-0 md:mb-[-21px]">
                    <div className="md:flex md:items-center space-x-4 sm:flex sm:items-center mr-3">
                      <div className="mr-[-12px]">
                        <Button outline onClick={handlePrevPage}>
                          <HiOutlineArrowLeft
                            color="#0E7490"
                            className="h-6 w-6"
                          />
                        </Button>
                      </div>
                      <div>
                        <DropdownSelector
                          options={updatePaginationOptions()}
                          selectedOption={selectedPagination}
                          onChange={handlePageChangeTop}
                        />
                      </div>
                      <Button outline onClick={handleNextPage}>
                        <HiOutlineArrowRight
                          color="#0E7490"
                          className="h-6 w-6"
                        />
                      </Button>
                    </div>
                    <div className="sm:ml-[-12px]">
                      <DropdownSelector
                        options={totalItemsToShowOptions}
                        selectedOption={totalItemToShow}
                        onChange={(option) => {
                          setTotalItemToShow(option);
                          setStartIndex(0);
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="md:flex flex-row items-center">
                <span className="relative mr-2">
                  <PrimaryButton
                    btnText="Average Hourly Rate"
                    onClick={ShowSliderAction}
                    className={`!text-teal !whitespace-nowrap border-teal border-[1.7px] !rounded-md bg-[#138086] bg-opacity-10 flex items-center justify-center`}
                  />
                  {showSlider && (
                    <div className="bg-white pb-8 pt-7 px-4 max-w-[350px] rounded-md border border-primary-300  min-w-[280px] text-[#6C717E]  xs:text-sm text-xs mt-3 leading-normal right-[-30px] top-14 shadow-md absolute z-10">
                      <AverageHourlySearch
                        minHourlyRate={minHourlyRate}
                        maxHourlyRate={maxHourlyRate}
                        setMinHourlyRate={setMinHourlyRate}
                        setMaxHourlyRate={setMaxHourlyRate}
                      />
                      <XCircleIcon
                        onClick={resetAverageHourlySlider}
                        className=" absolute block cursor-pointer top-[5px] right-[5px] font-bold w-6 h-6 text-gray-900"
                      />
                    </div>
                  )}
                </span>
                <TooltipWrapper
                  message={HireStudentMessage}
                  showTooltip={!signedInUser}
                >
                  <PrimaryButton
                    btnText="Bulk Invite"
                    onClick={openSelectStudent}
                    className={`bg-teal ${
                      !signedInUser && " disabled opacity-70 mb-3"
                    } h-fit whitespace-nowrap`}
                  />
                </TooltipWrapper>
              </div>
            </div>
          </div>
          {isSelectStudentModalOpen && (
            <div className="rounded-2xl p-10 shadow-md pb-10 pt-10">
              <h4 className="text-base text-start text-gray-800 font-bold mx-1/4 py-2">
                Select Student:
              </h4>
              <div className="flex  flex-wrap w-full gap-0 justify-between items-center">
                <StudentSelect
                  selectedStudent={selectedStudent}
                  // students={displayedStudents}
                  students={studentsModified}
                  onSelectMultiple={onHandleMultipleStudentSelect}
                  setSelectStudent={setSelectedStudent}
                  isInviteAllDisabled={isInviteAllDisabled}
                />
                <div className="w-auto mt-4 ml-auto">
                  <PrimaryButton
                    btnText="Invite Selected"
                    onClick={() => {
                      setOpenModal(true);
                    }}
                    className={`bg-teal disabled:opacity-70  h-fit whitespace-nowrap ${
                      isInviteAllDisabled ? "opacity-70" : ""
                    }`}
                    disabled={selectedStudent.length === 0}
                  />
                </div>
              </div>
            </div>
          )}
          {geoCities?.length && studentsModified.length !== 0 && (
            <div className="text-red-500 text-start mb-3 mt-5">
              {headerMessage}
            </div>
          )}
          {isLoadingStudents ? (
            <div className="my-8">
              {Array(5)
                .fill(null)
                .map((_, index) => (
                  <SkeletonJobCards key={index} />
                ))}
            </div>
          ) : // ) : displayedStudents.length === 0 ? (
          studentsModified.length === 0 ? (
            <div className="text-gray-800 font-bold flex mx-1/4 mt-6 GothamBold text-center justify-center w-full">
              No Students Found.
            </div>
          ) : (
            // displayedStudents.map((student, index) => (
            studentsModified
              .slice(startIndex, endIndex)
              .map((student, index) => (
                // filteredStudents.map((student, index) => (
                <div
                  className="relative text-left flex mt-10 flex-col lg:flex-row gap-8 rounded-2xl p-10 shadow-md pb-10 pt-10 mb-8 "
                  key={index}
                  style={{ position: "relative" }}
                >
                  <h6 className="absolute bottom-0 left-0 pt-[2px] text-white bg-gray-700 text-center h-[28px] w-[52px] rounded-tl-none rounded-tr-[10px] rounded-bl-[10px] rounded-br-none">
                    {student?.indexKey}
                  </h6>

                  <div
                    className={`flex w-[112px] h-[100px] items-center bg-gray-100 relative hover:bg-gray-200 hover:cursor-pointer rounded-full text-gray-700 m-auto border-[3.2px] ${
                      student?.type === "POSTER"
                        ? "border-purple-500"
                        : student?.type === "STUDENT"
                        ? "border-green-500"
                        : "border-unknown"
                    }`}
                    onClick={() => navigate(`/profile/${student.userID}`)}
                  >
                    <UserProfileImage
                      photoKey={student?.photoKey}
                      poster={student}
                    />
                  </div>
                  <div className="w-full">
                    <div className="flex justify-between items-center">
                      <h5
                        className="mb-2 text-xl font-bold leading-tight text-gray-700 hover:cursor-pointer hover:text-[teal]"
                        onClick={() => navigate(`/profile/${student.userID}`)}
                      >
                        {`${student.firstName
                          .charAt(0)
                          .toUpperCase()}${student.firstName.slice(1)} ${
                          student.lastName &&
                          student.lastName.charAt(0).toUpperCase() + "."
                        }`}
                      </h5>
                      <div className="flex items-center gap-1">
                        <div className="w-5 h-5 text-yellow-400"></div>
                        <h3 className="text-gray-700 text-md font-bold pt-1 GothamBook">
                          {averageRatingForUser(student) > 0 ? (
                            <ReviewRating
                              rating={averageRatingForUser(student)}
                            />
                          ) : (
                            "No Reviews"
                          )}
                        </h3>
                      </div>
                    </div>

                    <div className="lg:flex md:gap-0 gap-5 grid justify-between mt-6">
                      {/* <Chips user={student}  showApplicants={showApplicants} /> */}
                      {/* {student["_univ"]=getUniversity(student)} */}
                      <Chips
                        job={student}
                        showApplicants={showApplicants}
                        univ={getUniversity(student)}
                      />
                    </div>
                    <div
                      className={`flex lg:flex-row flex-col mt-4 w-auto h-auto lg:items-center gap-4 items-start overflow-hidden`}
                    >
                      {student.jobSpecilities?.items?.map((item, index) => (
                        <div className="flex bg-gray-100 text-gray-700 p-3 rounded-full border-radius">
                          <div className="mr-2 flex">
                            <div className="w-6 h-6 mr-2">
                              <StarIcon className="h-full m-auto"></StarIcon>
                            </div>
                            <p className="whitespace-nowrap mt-[2px]">
                              {getCategoryName(item?.jobSpecilitiesCategoryId!)}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                    <TooltipWrapper
                      message={HireStudentMessage}
                      showTooltip={!signedInUser}
                      className="z-10"
                      parentClassName="!w-auto invite-button float-right"
                    >
                      <div className={`${!signedInUser && "h-[110px]"}`}>
                        <PrimaryButton
                          className={`${
                            !signedInUser && "disabled opacity-70 w-fit"
                          } bg-teal float-right `}
                          btnText="Invite"
                          onClick={() => {
                            if (signedInUser) {
                              setOpenModal(true);
                              setSelectedStudent([student]);
                              logEvent("student_job_invitation_button_clicked");
                            }
                          }}
                        />
                      </div>
                    </TooltipWrapper>
                    {geoCities?.length > 0 && (
                      <h6 className="absolute bottom-0 right-0 pt-[2px] text-white bg-gray-700 text-center h-[28px] w-[fit-content] px-3 rounded-tr-none rounded-tl-[10px] rounded-br-[10px] rounded-br-none">
                        {capitalizeEachWord(student?.locationCity ?? "")}
                      </h6>
                    )}
                  </div>
                </div>
              ))
          )}
          {openModal && (
            <StudentHireModal
              open={openModal}
              setOpen={setOpenModal}
              student={selectedStudent}
              onSelectMultiple={onHandleMultipleStudentSelect}
            />
          )}
        </div>
        <div className="hidden shadow-lg w-5/12 bg-gray-whie rounded-xl p-8 text-left h-auto self-start flex flex-col gap-2 mt-14">
          <LightBulbIcon className="w-14 h-14 p-4 bg-gray-50 text-gray-700 rounded-full" />
          <h2 className="text-gray-800 font-bold text-md">
            What do you mean by gig economy?{" "}
          </h2>
          <p className="text-gray-700">
            The gig economy is the fastest-growing segment of the labor market.
            It refers to a series of short-term engagements that provide more
            flexibility, choice, and control for both the student and the
            client.{" "}
          </p>
        </div>
      </div>
      <div className="flex justify-center items-center px-10 py-10">
        {/* {!isLoadingStudents && displayedStudents.length > 0 && (
          <> */}
        {!isLoadingStudents && studentsModified.length > 0 && (
          <>
            <Button outline onClick={handlePrevPage}>
              <HiOutlineArrowLeft color="#0E7490" className="h-6 w-6" />
            </Button>
            <DropdownSelector
              options={updatePaginationOptions()}
              selectedOption={selectedPagination}
              onChange={handlePageChange}
            />
            <Button outline className="mx-3" onClick={handleNextPage}>
              <HiOutlineArrowRight color="#0E7490" className="h-6 w-6" />
            </Button>
          </>
        )}
      </div>

      <span>&nbsp;&nbsp;</span>

      <Footer />
    </>
  );
}

export default StudentHire;
