import { Spinner, Progress } from "flowbite-react";

/// Spinner, centered on page.
export function CenterInPageSpinner() {
  return (
    <div className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2">
      <Spinner aria-label="Loading..." color="success" />
    </div>
  );
}

/// Centered in a div.
export function CenteredSpinner() {
  return (
    <div className="text-center">
      <Spinner aria-label="Loading..." color="success" />
    </div>
  );
}

export function CustomSpinner({ className = 'p-5' }: { className?: string }) {
  return (
    <div className={className}>
      <Spinner aria-label="Loading..." color="success" />
    </div>
  );
}

/// https://www.flowbite-react.com/docs/components/progress
export function ProgressIndicator({ progress }: { progress: number }) {
  return <Progress progress={progress} />;
}
