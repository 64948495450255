import SkeletonFooter from "./SkeletonFooter";

const SkeletonProfile = () => {
  const BADGES = Array.from({ length: 4 });

  return (
    <>
      <div className=" text-left flex items-start p-6 pr-0 lg:p-14 skeleton-pulse flex-col w-11/12 m-auto lg:w-full shadow-md !rounded-xl h-auto md:w-auto mb-10">
        <div className="flex-col lg:flex-row flex justify-center w-full gap-4">
          <div className="flex-none w-[100px] h-[100px] rounded-full self-start skeleton-block"></div>
          <div className="inline ml-0 lg:ml-10 w-full lg:w-11/12 relative flex-col">
            <div className="flex items-center relative w-full flex-row justify-between">
              <div className="text-gray-700 text-3xl rounded font-semibold mr-4 max-w-[300px] w-full skeleton-block overflow-clip whitespace-nowrap  h-8"></div>
              <div className="flex gap-2 align-center h-12 w-[100px] skeleton-block rounded-full  !ml-auto">
                <div className="text-gray-50 skeleton-block  !rounded-full !ml-auto h-8"></div>
              </div>
              <div className="flex gap-2  align-center pr-6 ">
                <div className="text-gray-50  rounded-full  w-20 h-8"></div>
              </div>
            </div>

            <div className="text-gray-700 text-md  lg:pr-40 !pr-40 rounded-md w-[90%] h-28 mt-4 skeleton-block"></div>

            <div className="flex flex-col md:flex-row items-center mt-8 md:gap-2 gap-4 whitespace-nowrap pb-4 md:pb-0">
              <div className="flex w-auto skeleton-block min-w-[140px] text-gray-700 p-4 rounded-full border-radius pr-6">
                <div className="text-gray-700 text-md   h-6"></div>
              </div>
              <div className="flex  skeleton-block text-gray-700 min-w-[140px] p-4 rounded-full border-radius pr-6">
                <div className="text-gray-700 text-md lg:w-auto  w-36 h-6"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white flex items-start mb-10 p-6 lg:p-14 flex-col w-11/12 m-auto lg:w-full skeleton-pulse  text-left shadow-md !rounded-xl h-auto">
        <div className="flex justify-center w-full">
          <div className="inline w-full relative">
            <div className="flex gap-4 items-center relative w-full flex-row justify-between">
              <div className="flex justify-start gap-4 items-center w-full">
                <div className="text-gray-700 text-3xl font-semibold items-center  max-w-[400px] w-full h-10 rounded skeleton-block"></div>
                <div className="flex gap-2 align-center w-[150px] h-12 rounded-full ml-auto justify-end skeleton-block"></div>
              </div>
            </div>
            <div className="flex items-center justify-center my-8 h-20 rounded skeleton-block"></div>
            <div className="text-gray-700 pt-2  w-[180px] h-6   rounded  skeleton-block"></div>
            <div className="text-gray-700 pt-2  w-[140px] h-6 mt-4  rounded  skeleton-block"></div>
          </div>
        </div>
      </div>

      <div className=" text-left flex skeleton-pulse items-start p-6 lg:p-14 flex-col w-11/12 m-auto lg:w-full mb-10 shadow-md !rounded-xl h-auto">
        <div className="text-gray-700 text-3xl font-semibold text-left mb-10  rounded-md w-[200px] h-12 skeleton-block"></div>
        <div className="flex-col md:flex-row flex justify-around w-full text-center whitespace-nowrap gap-10 pb-8">
          {BADGES.map((_, index) => (
            <div key={index} className="flex flex-col gap-2 justify-center">
              <div className="w-20 h-20 px-2 bg-gray-100 flex  justify-center text-gray-300 ju m-auto skeleton-block !rounded-lg  mb-4"></div>
              <p className="text-gray-700 text-md font-semibold  flex m-auto justify-center  w-32 rounded-md h-6 skeleton-block"></p>
              <p className="text-gray-700 text-sm  w-32 h-6  flex m-auto justify-center rounded-md skeleton-block"></p>
            </div>
          ))}
        </div>
      </div>

      <SkeletonFooter />
    </>
  );
};

export default SkeletonProfile;
