import { Dialog } from "@headlessui/react";

function CustomDialog({ open, onClose, title, description }) {
  if (!open) {
    return null;
  }

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="max-w-xl rounded-md bg-white p-8 lg:p-10 w-11/12 h-auto flex flex-col">
          <Dialog.Title className="text-gray-900 pb-4 text-2xl font-black">{title}</Dialog.Title>
          <Dialog.Description className="text-slate-600 pb-8 sm:h-[400px] lg:h-[600px] overflow-y-scroll">
            {description}
          </Dialog.Description>
          <button
            onClick={handleClose}
            className="bg-gray-100 text-gray-700 font-bold align-end mt-1"
          >
            Got it
          </button>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

export default CustomDialog;
