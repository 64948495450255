import { useEffect, useRef, useState } from "react";
import { Dialog } from "@headlessui/react";
import { User, UserReview } from "../../API";
import * as dataProvider from "../../graphql/DataProvider";
import StarRating from "./star_rating";
import { toast } from "react-toastify";
import { EmailTemplate, sendEmail } from "../../graphql/EmailHandler";
import { getColledgeURL } from "../../helpers/Utilities";
import PrimaryButton from "../buttons/primary_button";
import { logEvent } from "../../helpers/Analytics";
import { useUserContext } from '../../helpers/UserContext';

interface ReviewsDialogProps {
  /// User to write a review for.
  user: User;
  fetchData?: any;
  /// Whether the dialog is open or not.
  open: boolean;
  /// Function to call when the dialog is closed.
  onClose: (payload: { refetch: boolean }) => void;
}

function ReviewsDialog({ user, open, onClose }: ReviewsDialogProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<string>("");
  const {currentUser} = useUserContext();
  /// Current rating selected by user.
  const [rating, setRating] = useState(0);
  const [existingReviewId, setExistingReviewId] = useState<string | null>(null);
  const messageRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    dataProvider.getUserReviewForUser(user).then((review: UserReview | null) => {
      if (review) {
        setExistingReviewId(review.id);
        setMessage(review.message ?? "");
        setRating(review.rating);
      }
    });
  }, [user]);

  const handleClose = () => {
    onClose({ refetch: false });
  };

  const selectedRating = (selectedRating: number) => {
    setRating(selectedRating);
  };

  async function saveUserReview() {
    const message = messageRef.current?.value;
    if (!message) {
      toast.error("Please enter a message.", { toastId: "saveUserReview" });
      return;
    }
    setIsLoading(true);
    if (existingReviewId) {
      await dataProvider.updateUserReview(existingReviewId, rating, message);
    } else {
      await dataProvider.createUserReview(rating, message, user);
    }
    const reviewURL = getColledgeURL() + "profile/" + user.userID;
    const params: EmailTemplate = {
      toEmail: "dev@colledge.us",
      fromEmail: "hello@colledge.us",
      subject: "[Colledge] Review Received 🚨",
      message: `A user (${
        user.firstName + " " + user.lastName
      }) has received a review. Click on the button below to view the feedback received`,
      templateId:"d-5bc29311ecfe4c57a0cdad1b9e380fb9",
      actionURL:reviewURL,
    };
    const userEmailFeedbackParams: EmailTemplate = {
      toEmail: user.email,
      fromEmail: "hello@colledge.us",
      subject: "[Colledge] Review Received 🚨",
      message: `Hi ${user.firstName} ${user.lastName}, You have recieved a review from (${
        currentUser?.firstName + " " + currentUser?.lastName
      }). Click on the button below to view the feedback received`,
      templateId:"d-5bc29311ecfe4c57a0cdad1b9e380fb9",
      actionURL:reviewURL,
    };
    if (user.emailNotificationEnabled) {
      await sendEmail(userEmailFeedbackParams);
    }
    await sendEmail(params);
    setIsLoading(false);
    toast.success("Review saved!", { toastId: "saveUserReview" });
    onClose({ refetch: true });
  }

  return (
    <>
      <Dialog className="relative z-50" open={open} onClose={handleClose}>
        <div className="fixed inset-0 bg-black/30 h-auto" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-lg h-auto rounded-xl bg-white p-8 flex gap-2 flex-col">
            <Dialog.Title className="text-gray-800 text-3xl font-semibold">
              Write your review
            </Dialog.Title>
            <Dialog.Description className="block tracking-wide text-gray-700 text-md font-medium mb-4">
              Leave a review to help others
            </Dialog.Description>
            <div className="flex flex-row-reverse justify-end mb-4">
              <StarRating userSetRating={selectedRating} initialRating={rating} />
            </div>
            <form className="w-full relative flex ">
              <div className="justify-start w-full h-[300px]">
                <textarea
                  name="message"
                  ref={messageRef}
                  defaultValue={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  className="appearance-none block w-full h-full bg-gray-100 text-gray-600 rounded py-3 px-4 pt-4 mb-3 leading-tight focus:outline-none"
                  placeholder="Enter your review"
                />
              </div>
            </form>

            <div className="flex gap-2 justify-end mt-8">
              <button
                className="bg-gray-200 hover:bg-[hoverTeal] text-gray-800 font-bold py-3 px-8 rounded-full w-auto h-auto"
                onClick={() => {
                  handleClose();
                  logEvent("review_modal_close_button_clicked");
                }}
              >
                Cancel
              </button>
              <PrimaryButton
                btnText="Submit"
                showLoading={isLoading}
                disabled={message.length === 0}
                onClick={() => {
                  saveUserReview();
                  logEvent("review_modal_submit_button_clicked");
                }}
              />
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
}
export default ReviewsDialog;
