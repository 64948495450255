import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { useState, useRef, useEffect } from "react";
import { FlaggedContentType, FlaggedReason, User } from "../../API";
import * as dataProvider from "../../graphql/DataProvider";
import { toast } from "react-toastify";
import { useUserContext } from "../../helpers/UserContext";
import { logEvent } from "../../helpers/Analytics";
import { checkrVerificationMessage } from '../../helpers/Constants';
import TooltipWrapper from '../../helpers/TooltipWrapper';

export interface ReportButtonProps {
  /// The ID of the content being reported (e.g. a review, a job, etc.)
  contentId: string | null | undefined;
  /// The type of content, e.g. a review, a job, etc.
  contentType: FlaggedContentType | null | undefined;
  /// The user that created the content that's being reported.
  flaggedUser: User | null | undefined;
}

/// Displays a menu where a user can report content to be evaluated by an Admin.
function ReportContentButton({ contentId, contentType, flaggedUser }: ReportButtonProps) {
  const { currentUser, approvedUser } = useUserContext();
  const [open, setIsOpen] = useState(false);
  const [canFlagContent, setCanFlagContent] = useState(true);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const flaggedStorageName = "flagged_" + currentUser?.userID;

  useEffect(() => {
    updateCanFlagContent();
  }, [contentId]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  }, [menuRef]);

  /// Determines if a user can flag content or not.
  function updateCanFlagContent() {
    if (typeof Storage !== "undefined" && contentId) {
      const flaggedContentArray = localStorage.getItem(flaggedStorageName)?.split(",");
      if (flaggedContentArray !== null && flaggedContentArray?.includes(contentId)) {
        setCanFlagContent(false);
      }
    }
  }

  const toggleMenu = () => {
    logEvent("report_content_button_clicked");
    setIsOpen(!open);
  };
  const handleClickOutside = (event: any) => {
    if (!menuRef?.current?.contains(event.target)) {
      setIsOpen(false);
    }
  };

  async function createReport(reason: FlaggedReason) {
    toast.success("Thank you for your report!", { toastId: "reportSuccess" });
    dataProvider.setOrIncrementContentFlag(contentId, contentType, reason, flaggedUser);
    if (typeof Storage !== "undefined" && contentId) {
      const flaggedContentArray = localStorage.getItem(flaggedStorageName)?.split(",") ?? [];
      flaggedContentArray?.push(contentId);
      localStorage.setItem(flaggedStorageName, flaggedContentArray?.toString());
      setCanFlagContent(false);
    }
    setIsOpen(false);
  }
  console.log(approvedUser, 'approvedUser approvedUser')
 const togglemenu = approvedUser ? toggleMenu : undefined
  return (
    <>
      <div className="relative" ref={menuRef}>
        <TooltipWrapper
          showTooltip={!approvedUser}
          message={checkrVerificationMessage}
          className="w-[300px] right-[-150px]"
        >
          <div
            onClick={() => approvedUser && togglemenu?.()}
            className="w-10 bg-gray-100 p-2 hover:text-[teal] hover:cursor-pointer rounded-full text-gray-700 relative"
          >
            <EllipsisVerticalIcon />
          </div>
        </TooltipWrapper>
        

        {open && (
          <div className="bg-white shadow-md absolute mt-4 l-20 w-40 rounded-md right-[20px]">
            <ul className="text-gray-700">
              <li className="p-4 pb-2 pt-2 w-full text-sm border-b">
                Flagging content
              </li>
              {canFlagContent === true ? (
                <>
                  <li
                    className="p-4 w-full hover:bg-gray-100 hover:cursor-pointer text-sm GothamBold font-bold"
                    onClick={() => {
                      logEvent("report_content_button_clicked");
                      createReport(FlaggedReason.INAPPROPRIATE);
                    }}
                  >
                    Inappropriate
                  </li>
                  <li
                    className="p-4 w-full hover:bg-gray-100 hover:cursor-pointer text-sm GothamBold font-bold"
                    onClick={() => {
                      logEvent("report_content_button_clicked");
                      createReport(FlaggedReason.OTHER);
                    }}
                  >
                    Other
                  </li>
                </>
              ) : (
                <li className="p-4 w-full hover:bg-gray-100 hover:cursor-pointer text-sm  GothamBold font-bold">
                  Report Submitted
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    </>
  );
}

export default ReportContentButton;
