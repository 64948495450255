import { useEffect, useState, useMemo } from "react";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { useSearchParams } from "react-router-dom";
import { Pagination } from "@aws-amplify/ui-react";
import { User } from "../API";
import * as dataProvider from "../graphql/DataProvider";
// import AccountRow from "../components/admin/account_row";
import AccountRowFE from "../components/admin/account_row_fe";
import FilterAccountCardFE, {
  UserAccountFilters,
} from "../components/admin/filter_account_card_fe";
import { currentUserIsAdmin } from "../helpers/AuthStatus";
import { CenterInPageSpinner } from "../components/loading_indicator";
import { Tooltip } from "flowbite-react";
import * as adminDataProvider from "../graphql/AdminDataProvider";

interface PaginationProps {
  currentPage: number;
  perPage: number;
}

function paginateArray<T>(
  array: T[],
  { currentPage, perPage }: PaginationProps
): T[] {
  const startIndex = (currentPage - 1) * perPage;
  const endIndex = startIndex + perPage;

  return array.slice(startIndex, endIndex);
}

function calculateTotalPages(totalRecords: number, perPage: number): number {
  return Math.ceil(totalRecords / perPage);
}

function filterUsers(users: any, filters: UserAccountFilters): User[] {
  return users.filter((user: any) => {
    const fullNameMatches = filters.fullName
      ? user?.fullName?.toLowerCase().includes(filters.fullName.toLowerCase())
      : true;
    const emailMatches = filters.email
      ? user?.email?.toLowerCase().includes(filters.email.toLowerCase())
      : true;
    const typeMatches = filters.accountType
      ? filters.accountType === user?.type
      : true;
    const userIsStudent = user?.type === "STUDENT";
    let subscriptionMatches = true;
    if (!userIsStudent) {
      subscriptionMatches = filters.subscriptionType
        ? filters.subscriptionType === (user?.subscription || "INACTIVE")
        : true;
    } else {
      subscriptionMatches = filters.subscriptionType
        ? filters.subscriptionType !== (user?.subscription || "ACTIVE")
        : true;
    }
    const bgCheckMatches =
      filters.bgCheck === "" ||
      (user?.bgCheck &&
        ((filters.bgCheck === "pending" &&
          ((user.bgCheck.inviteStatus === "pending" &&
            user.bgCheck.reportStatus !== "complete") ||
            (user.bgCheck.reportStatus === "pending" &&
              user.bgCheck.reportResult !== "clear"))) ||
          (filters.bgCheck === "expired" &&
            user.bgCheck.inviteStatus === "expired" &&
            user.bgCheck.reportStatus === "" &&
            user.bgCheck.reportResult === "") ||
          (filters.bgCheck !== "expired" &&
            user.bgCheck.inviteStatus === filters.bgCheck) ||
          user.bgCheck.reportStatus === filters.bgCheck ||
          user.bgCheck.reportResult === filters.bgCheck));

    const accountTypeMatches =
      filters.userGroup === "" || user?.status === filters.userGroup;

    // Check if all filters are empty, then return all users
    const allFiltersEmpty =
      Object.values(filters).every((value) => value === "") ||
      Object.values(filters).every((value) => value === null);

    if (allFiltersEmpty) {
      return true; // Return all users when all filters are empty
    }

    if (filters.accountType === "STUDENT") {
      return (
        fullNameMatches &&
        emailMatches &&
        typeMatches &&
        bgCheckMatches &&
        accountTypeMatches && subscriptionMatches
      );
    }

    return (
      fullNameMatches &&
      emailMatches &&
      typeMatches &&
      subscriptionMatches &&
      bgCheckMatches &&
      accountTypeMatches
    );
  });
}


type TSortDirection = "asc" | "desc" | null | undefined;

function sortBy(
  users: User[],
  sortDirection: TSortDirection,
  columnName: string
) {
  const sortedUsers = [...users];

  sortedUsers.sort((a: any, b: any) => {
    const aValue = a[columnName];
    const bValue = b[columnName];

    if (aValue === bValue) {
      return 0;
    }

    return sortDirection === "asc"
      ? aValue > bValue
        ? 1
        : -1
      : aValue < bValue
      ? 1
      : -1;
  });
  return sortedUsers;
}

const perPage = 10;

/// Constructs the admin panel where admins can manage user accounts.
function ManageAccountsFE() {
  const [backupUserAccounts, setBackupUserAccounts] = useState<User[]>([]);
  // const rowTypeOptions: DropDownSelectorOption[] = [
  //   { id: 50, name: "50" },
  //   { id: 10, name: "10" },
  //   { id: 20, name: "20" },
  //   { id: 50, name: "50" },
  // ];
  // const [selectedRowTypeOption, setSeletedRowTypeOptions] = useState(
  //   rowTypeOptions[0]
  // );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [userAccounts, setUserAccounts] = useState<User[]>([]);
  const [nextToken, setNextToken] = useState<string | null | undefined>(null);
  const [localFilters, setLocalFilters] = useState<UserAccountFilters>({
    fullName: "",
    email: "",
    accountType: "",
    bgCheck: "",
    subscriptionType: "",
    userGroup:"",
  });
  // paginated records
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = searchParams.get("page")
    ? Number(searchParams.get("page"))
    : 1;
  const setCurrentPage = (newPage: number) => {
    setSearchParams({ page: newPage.toString() });
  };
  const [paginatedUsers, setPaginatedUsers] = useState<User[]>([]);

  // sorting filters
  const [sortDirection, setSortDirection] = useState<TSortDirection>(null);
  const [sortColumn, setSortColumn] = useState<string>("");

  // default state -> descending order -> ascending order -> descending order -> ascending order and so on
  // const handleSortChange = (fieldName: string) => {
  //   const newSortDirection =
  //     fieldName === sortColumn && sortDirection === "desc" ? "asc" : "desc";

  //   setSortDirection(newSortDirection);
  //   setSortColumn(fieldName);

  //   const sortedUsers = sortBy(userAccounts, newSortDirection, fieldName);
  //   setUserAccounts(sortedUsers);
  //   setPaginatedUsers(
  //     paginateArray<User>(sortedUsers, { currentPage, perPage })
  //   );
  // };

  // default state -> descending order -> ascending order -> default state (which is cleared sorting) -> descending order -> ascending order
  const handleSortChange = (fieldName: string) => {
    let newSortDirection: TSortDirection;

    if (fieldName === sortColumn) {
      // If the same column is clicked, toggle the sort direction
      newSortDirection =
        sortDirection === null
          ? "desc"
          : sortDirection === "desc"
          ? "asc"
          : null;
    } else {
      // If a new column is clicked, start with descending order
      newSortDirection = "desc";
    }

    setSortDirection(newSortDirection);
    setSortColumn(fieldName);

    const sortedUsers =
      newSortDirection !== null
        ? sortBy(userAccounts, newSortDirection, fieldName)
        : backupUserAccounts;

    setUserAccounts(sortedUsers);
    setPaginatedUsers(
      paginateArray<User>(sortedUsers, { currentPage, perPage })
    );
  };

  /// Load initial data.
  useEffect(() => {
    setIsLoading(true);

    // fetchData();
    fetchUserList();
  }, []);

  // async function fetchData() {
  //   setIsLoading(true);
  //   if ((await currentUserIsAdmin()) === false) {
  //     return;
  //   }
  //   // let filter = {
  //   //   fullName: filters.fullName,
  //   //   email: filters.email,
  //   //   type: filters.type as UserType,
  //   // };
  //   // fetch all the records at once
  //   const rowPerPage = null;
  //   dataProvider.getAllUsers(nextToken, rowPerPage, {}).then((results) => {
  //     const nextToken = results?.listUsers?.nextToken;
  //     setNextToken(nextToken);
  //     const users = results?.listUsers?.items as User[];
  //     setUserAccounts(users);
  //     setBackupUserAccounts(users);
  //     setPaginatedUsers(paginateArray<User>(users, { currentPage, perPage }));
  //     setIsLoading(false);
  //   });
  // }
  async function fetchUserList() {
    setIsLoading(true);
    if (!(await currentUserIsAdmin())) {
      setIsLoading(false); // Set loading to false as there's no data to fetch
      return;
    }
    dataProvider.getUserLists("text").then((result) => {
      if (result) {
        let users = JSON.parse(result)?.map((user: any) => ({
          firstName: user.firstName?.S,
          lastName: user.lastName?.S,
          fullName: user.fullName?.S,
          type: user.type?.S,
          createdAt: user.createdAt?.S,
          userID: user.userID?.S,
          email: user.email?.S,
          candidateID: user.candidateID?.S,
          bgCheck: {
            candidateID: user.bgCheck?.[0]?.candidateID?.S,
            invitationID: user.bgCheck?.[0]?.invitationID?.S,
            inviteStatus: user.bgCheck?.[0]?.inviteStatus?.S,
            reportResult: user.bgCheck?.[0]?.reportResult?.S,
            reportStatus: user.bgCheck?.[0]?.reportStatus?.S,
          },
          status: user.status?.S,
          subscription: user?.subscription?.S,
          name: user?.name?.S,
        })) as User[];
        setUserAccounts(users || []);
        setBackupUserAccounts(users || []);
        setPaginatedUsers(
          paginateArray<User>(users || [], { currentPage, perPage })
        );
        setIsLoading(false);
      }
    });
  }
  
  // whenever current page changes, just re-calculate the paginated records
  const filtersApplied = useMemo(
    () => Object.values(localFilters).some((fil) => Boolean(fil)),
    [localFilters]
  );
  useEffect(() => {
    setPaginatedUsers(
      paginateArray<User>(userAccounts, { currentPage, perPage })
    );
  }, [filtersApplied, userAccounts, currentPage]);

  const onApplyFilters = (filters: UserAccountFilters) => {
    setLocalFilters(filters);
    // always use the full records to filter from
    const filteredUsers = filterUsers(backupUserAccounts, filters);
    setUserAccounts(filteredUsers);
    const paginatedUsers = paginateArray(filteredUsers, {
      currentPage: 1,
      perPage,
    });
    setCurrentPage(1);
    setPaginatedUsers(paginatedUsers);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleOnChange = (
    newPageIndex?: number | undefined,
    prevPageIndex?: number | undefined
  ) => {
    console.log(newPageIndex, "newPageIndex newPageIndex");
    newPageIndex && setCurrentPage(newPageIndex);
  };
  //update all the user status (script)
  // useEffect(()=>{
  //   if(userAccounts.length > 0){
  //     for(let i=0; i<= paginatedUsers.length; i++){
  //       adminDataProvider.listGroupsForUser((userAccounts[i]?.userID)).then(async(group)=>{
  //         let groupName = group[0].GroupName.split("_")[0]
  //         const response = (await dataProvider.updateUserStatus(userAccounts[i]?.userID,groupName.toUpperCase()))
  //         console.log(response,'response')
  //       })
  //     }
  //   }
  // },[userAccounts])
  console.log(paginatedUsers,'paginatedUsers');

  if (isLoading) {
    return <CenterInPageSpinner />;
  }
  return (
    <>
      <div className="mx-auto w-11/12 lg:container md:px-4 px-0">
        <div className="py-8">
          <div className="my-2 relative flex flex-row md:flex-col justify-between items-center">
            <div className="flex items-center ">
              <h2 className="text-2xl text-left font-semibold leading-tight text-gray-700">
                Manage Accounts
              </h2>
              <FilterAccountCardFE
                defaultFilters={localFilters}
                onApplyFilters={onApplyFilters}
              />
            </div>
          </div>
          <div className="md:-mx-4 -mx-8 md:px-4 px-8 py-4 overflow-scroll lg:overflow-visible">
            <div className="inline-block min-w-full shadow rounded-lg">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="px-5 py-3 w-1/12 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Info
                    </th>
                    <th className="px-5 py-3 w-2/12 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      ACCOUNT TYPE
                    </th>
                    <th className="px-5 py-3 w-2/12 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      STATUS
                    </th>
                    <th className="px-5 py-3 w-1/12 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      SUBSCRIPTION
                    </th>
                    <th className="px-5 py-3 w-2/12 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      BG CHECK
                    </th>
                    <th
                      className="px-5 py-3 w-2/12 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider flex items-center cursor-pointer"
                      onClick={() => handleSortChange("createdAt")}
                    >
                      <Tooltip
                        content={
                          !sortDirection || sortDirection === "asc"
                            ? "Sort by descending order"
                            : "Sort by ascending order"
                        }
                        style="light"
                      >
                        Joined
                      </Tooltip>
                      <span className="flex flex-col">
                        <ChevronUpIcon
                          className={`h-5 w-5 mb-[-5px] ${
                            sortDirection === "asc" ? "text-orange-600" : ""
                          }`}
                        />
                        <ChevronDownIcon
                          className={`h-5 w-5 mt-[-5px] ${
                            sortDirection === "desc" ? "text-orange-600" : ""
                          }`}
                        />
                      </span>
                      {/* {sortDirection && (
                        <span>
                          {sortDirection === "asc" ? (
                            <ChevronUpIcon className="h-6 w-6" />
                          ) : (
                            <ChevronDownIcon className="cursor-pointer h-6 w-6" />
                          )}
                        </span>
                      )} */}
                    </th>
                    <th className="px-5 py-3 w-4/12 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      ACTIONS
                    </th>
                  </tr>
                </thead>
                {paginatedUsers.length > 0 ? (
                  paginatedUsers?.map((user: User, index) => {
                    return (
                      <tbody key={index}>
                        <AccountRowFE index={index} userToDisplay={user} />
                      </tbody>
                    );
                  })
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={12} className="text-center py-4 font-bold">
                        No User Found.
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
        {/* Show Pagination */}
        <div className="pb-12">
          <Pagination
            currentPage={currentPage}
            totalPages={calculateTotalPages(userAccounts.length, perPage)}
            onNext={handleNextPage}
            onPrevious={handlePreviousPage}
            onChange={handleOnChange}
          />
        </div>
      </div>
    </>
  );
}

export default ManageAccountsFE;
