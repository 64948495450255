import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { Modal } from "flowbite-react";
import { useEffect, useState } from "react";
import PrimaryButton from "../buttons/primary_button";
import SecondaryButton from "../buttons/secondary_button";
import * as dataProvider from "../../graphql/DataProvider";
import { Coupon } from "../../API";
import { CenterInPageSpinner } from "../loading_indicator";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const CouponTable = () => {
  const [couponData, setCouponData] = useState<Coupon[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteCouponId,setDeleteCouponId] = useState<string>("");
  const navigate = useNavigate();
  
  // delete Coupons
  const handleDelete = () => {
    dataProvider
      .deleteCoupon(deleteCouponId)
      .then((res) => {
        if (res) {
          toast.success("Coupon deleted Successfully", {
            toastId: "coupon_deleted",
          });
          setIsModalOpen(false);
          getCoupons();
        }
      })
      .catch((err) => {
        err &&
          toast.error("Error deleting Coupon", {
            toastId: "coupon_deleted_error",
          });
      });
    toast.dismiss();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setDeleteCouponId('');
  };

  // get Coupons
  const getCoupons = () => {
    setIsLoading(true);
    dataProvider.listCoupons().then((res) => {
      if (res) {
        let listCoupons = res?.listCoupons?.items as Coupon[];
        setCouponData(listCoupons);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    getCoupons();
  }, []);

  if (isLoading) {
    return <CenterInPageSpinner />;
  }
  return (
    <>
      <div className="mx-auto w-11/12 lg:container md:px-4 px-0">
        <div className="py-8">
          <div className="my-2 relative flex flex-row md:flex-col items-center ">
            <div className="flex  justify-center w-full items-center ">
              <h2 className="text-2xl text-center w-full font-semibold leading-tight text-gray-700">
                Coupon List
              </h2>
              <div className="ml-auto">
              <PrimaryButton
                className="bg-orange-400 whitespace-nowrap"
                btnText="Create Coupon"
                onClick={() => {
                  navigate("/create_coupon");
                }}
                />
                </div>
            </div>
          </div>
          <div className="md:-mx-4 -mx-8 md:px-4 px-8 py-4 overflow-scroll lg:overflow-visible">
            <div className="inline-block min-w-full shadow rounded-lg">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="px-5 pt-5 pb-5 py-3 w-2/12 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Coupon Code
                    </th>
                    <th className="px-5 pt-5 pb-5 py-3 w-2/12 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Active
                    </th>
                    <th className="px-5 pt-5 pb-5 py-3 w-2/12 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Type
                    </th>
                    <th className="px-5 pt-5 pb-5 py-3 w-1/12 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Amount/Percent
                    </th>
                    <th className="px-5 pt-5 pb-5 py-3 w-3/12 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Message
                    </th>
                    <th className="px-5 pt-5 pb-5 py-3 w-1/12 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Expiry Date
                    </th>
                    <th className="px-5 pt-5 pb-5 py-3 w-1/12 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                {couponData.length > 0 ? (
                  <tbody>
                    {couponData.map((coupons, index) => (
                      <tr key={index}>
                        <td className="px-5 py-5 border-b border-gray-200 text-left text-xs">
                          {coupons.couponCode}
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 text-center text-xs">
                          {coupons.isActive ? "Yes" : "No"}
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 text-center text-xs">
                          {coupons.type === "Percentage"
                            ? `Percentage`
                            : "Flat Off"}
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 text-center text-xs">
                          {coupons.type === "Percentage"
                            ? `${coupons.percentage}%`
                            : `$${Number(coupons.amount)/100}`}
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 text-center text-xs">
                          {coupons.description}
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 text-left text-xs">
                          {coupons.expiryDate}
                        </td>
                        <td className="px-2 md:px-5 py-3 md:py-5 border-b border-gray-200 text-left text-xs md:flex items-center md:space-x-2">
                          <Link to={`/edit_coupon/${coupons.id}`}>
                            <PencilSquareIcon className="w-4 h-4 md:w-5 md:h-5 text-blue-500 cursor-pointer hover:text-blue-700" />
                          </Link>
                          <TrashIcon
                            className="w-4 h-4 md:w-5 md:h-5 text-red-500 cursor-pointer hover:text-red-700"
                            onClick={() => {
                              setIsModalOpen(true);
                              setDeleteCouponId(coupons.id);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={12} className="text-center py-4 font-bold">
                        No Coupons Found.
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <Modal show={isModalOpen} onClose={handleCancel}>
          <Modal.Header>
            <p className="font-semibold text-red-600">Delete Coupon ?</p>
          </Modal.Header>
          <Modal.Body>
            <div className="space-y-6">
              <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                Are you sure you want to delete this Coupon?
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <PrimaryButton
              disabled={false}
              btnText="Delete"
              onClick={handleDelete}
            />
            <SecondaryButton
              className="!w-fit"
              btnText="Cancel"
              onClick={handleCancel}
            />
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default CouponTable;
