import { ReactNode } from 'react'
import { Tooltip } from 'flowbite-react'

interface IProps {
  children: ReactNode
  message: string
  showTooltip: boolean
}

function ButtonToolTip({ children, message, showTooltip }: IProps) {
  if (!showTooltip) {
    return <>{children}</>
  }
  return <Tooltip content={message}>{children}</Tooltip>
}

export default ButtonToolTip
