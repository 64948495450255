import { useEffect, useRef, useState } from "react";
import {
  CreateJobInput,
  Job,
  JobRateType,
  JobStatus,
  UpdateJobInput,
} from "../API";
import * as dataProvider from "../graphql/DataProvider";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@googlemaps/js-api-loader";
import { Listbox } from "@headlessui/react";
import geohash from "ngeohash";
import { toast } from "react-toastify";
import SecondaryNav from "../components/nav/secondary_nav";
import { CenterInPageSpinner } from "../components/loading_indicator";
import { Spinner } from "flowbite-react";
import { logEvent } from "../helpers/Analytics";
import BgCheckerWrapper from "../helpers/BgCheckerWrapper";
import { useUserContext } from "../helpers/UserContext";
import { sortArrayAlphabeticallyByProperty } from '../helpers/Utilities';
import { GOOGLE_MAPS_API_KEY } from '../utils/constants';
// import DropdownSelector, {
//   DropDownSelectorOption,
// } from "../components/dropdown_selector";

/// Initial state of our form.
const initialState = {
  title: "",
  locationAddress: "",
  locationCity: "",
  locationState: "",
  locationZip: "",
  description: "",
  compensation: 0,
  rateType: JobRateType.HOURLY,
};

interface CategoryOption {
  id: string;
  name: string;
  unavailable: boolean;
}

function CreateJob() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [jobToUpdate, setJobToUpdate] = useState<Job | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingJob, setIsLoadingJob] = useState<boolean>(false);
  const [formState, setFormState] = useState(initialState);
  const { approvedUser } = useUserContext();
  /// Available categories for a user to select from.
  const [jobCategories, setJobCategories] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] =
    useState<CategoryOption | null>(null);
  const formRef = useRef<HTMLFormElement | null>(null);
  const locationCityRef = useRef<HTMLInputElement | null>(null);
  const locationStateRef = useRef<HTMLInputElement | null>(null);
  const locationZipRef = useRef<HTMLInputElement | null>(null);
  const locationCoordinateLat = useRef<number | null>(null);
  const locationCoordinateLong = useRef<number | null>(null);
  useEffect(() => {
    if (id) {
      setIsLoadingJob(true);
      dataProvider.getJob(id).then((job) => {
        if (job) {
          setJobToUpdate(job);
          setSelectedCategory({
            id: job.category.id,
            name: job.category.name,
            unavailable: false,
          });
          setFormState({
            title: job.title ?? "",
            locationAddress: job.locationAddress,
            locationCity: job.locationCity,
            locationState: job.locationState,
            locationZip: job.locationZip,
            description: job.description ?? "",
            compensation: job.compensation ?? 0,
            rateType: job.rateType,
          });
        }
        setIsLoadingJob(false);
      });
    } else {
      setJobToUpdate(null);
    }
  }, [id]);

  /// Address Autocomplete.
  const autoCompleteRef = useRef<google.maps.places.Autocomplete | undefined>();
  const locationAddressRef = useRef<HTMLInputElement | null>(null);
  const options = {
    componentRestrictions: { country: "us" },
    fields: ["formatted_address", "address_components", "geometry"],
    strictBounds: false,
    types: ["address"],
  };

  /// Loads the Google Maps API.
  function loadMapsAPI() {
    new Loader({
      apiKey: GOOGLE_MAPS_API_KEY, // Colledge Key.
      version: "weekly",
      libraries: ["places"],
    })
      .importLibrary("places")
      .then((places) => {
        autoCompleteRef.current = new places.Autocomplete(
          locationAddressRef.current!,
          options
        );
        autoCompleteRef.current.addListener("place_changed", fillInAddress);
      })
      .catch((error) => {
        console.error("Error loading Maps API: ", error);
      });
  }

  useEffect(() => {
    if (!autoCompleteRef.current && !isLoadingJob) {
      loadMapsAPI();
    }
  });

  /// Handles address autocompletion for the Address field.
  function fillInAddress() {
    let locationAddress: HTMLInputElement;
    let locationZip: HTMLInputElement;
    locationAddress = locationAddressRef.current!;
    locationZip = locationZipRef.current!;

    // Get the place details from the autocomplete object.
    const place = autoCompleteRef.current?.getPlace();
    let address = "";
    let postcode = "";

    for (const component of place?.address_components as google.maps.GeocoderAddressComponent[]) {
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number": {
          address = `${component.long_name} ${address}`;
          break;
        }
        case "route": {
          address += component.short_name;
          break;
        }
        case "postal_code": {
          postcode = `${component.long_name}${postcode}`;
          break;
        }
        case "postal_code_suffix": {
          postcode = `${postcode}-${component.long_name}`;
          break;
        }
        case "locality":
          locationCityRef.current!.value = component.long_name;
          break;
        case "administrative_area_level_1": {
          locationStateRef.current!.value = component.short_name;
          break;
        }
      }
    }

    // Get Lat/Long coordinates.
    if (place?.geometry?.location) {
      const lat = place?.geometry?.location.lat();
      const lng = place?.geometry?.location.lng();
      locationCoordinateLat.current = lat;
      locationCoordinateLong.current = lng;
    }

    locationAddress.value = address;
    locationZip.value = postcode;
  }

  /// Updates the form state when the user types in an input field.
  function setInput(key: any, value: String) {
    setFormState({ ...formState, [key]: value });
  }

  /// Creates a new job that's attached to the current user.
  async function addOrUpdateJob(event: { preventDefault: () => void }) {
    try {
      setIsLoading(true);
      event.preventDefault();

      const locationAddress = locationAddressRef.current!;
      const locationCity = locationCityRef.current!;
      const locationState = locationStateRef.current!;
      const locationZip = locationZipRef.current!;
      const locationCountry = "US"; // Default to USA.
      const latitude = locationCoordinateLat.current!;
      const longitude = locationCoordinateLong.current!;
      const geoHash = geohash.encode(latitude, longitude, 8); // 8 is the precision.

      // Validate we have all the required fields.
      if (
        !formState.title ||
        !locationAddress.value ||
        formState.compensation === 0 ||
        !formState.description ||
        !selectedCategory
      ) {
        toast.warning("Missing required fields.", {
          toastId: "addOrUpdateJobWarning",
        });
        setIsLoading(false);
        return;
      }

      // Get the current user for the job and attach to job.
      let user = await dataProvider.getCurrentUser();

      if (!user) {
        setIsLoading(false);
        return;
      }

      let savedJob = null;
      if (jobToUpdate) {
        logEvent("job_update_button_clicked");
        let job: UpdateJobInput = {
          id: jobToUpdate.id,
          ...formState,
          locationAddress: locationAddress.value,
          locationCity: locationCity.value,
          locationState: locationState.value,
          locationZip: locationZip.value,
          locationCountry: locationCountry,
          jobStatus: jobToUpdate.jobStatus, // Keep it the same.
          jobCategoryId: selectedCategory?.id,
          jobPosterUserID: user?.userID,
          userPostedJobsUserID: user?.userID,
        };
        if (latitude && longitude) {
          job = {
            ...job,
            latitude: latitude,
            longitude: longitude,
            geoHash: geoHash,
          };
        }
        savedJob = await dataProvider.updateJob(job);
        if (savedJob) {
          toast.success("Job updated successfully!", {
            toastId: "addOrUpdateJobSuccess",
          });
        }
      } else {
        logEvent("job_create_button_clicked");
        const job: CreateJobInput = {
          ...formState,
          locationAddress: locationAddress.value,
          locationCity: locationCity.value,
          locationState: locationState.value,
          locationZip: locationZip.value,
          locationCountry: locationCountry,
          jobStatus: JobStatus.OPEN,
          jobCategoryId: selectedCategory?.id,
          jobPosterUserID: user?.userID,
          userPostedJobsUserID: user?.userID,
          latitude: latitude,
          longitude: longitude,
          geoHash: geoHash,
        };
        savedJob = await dataProvider.createJob(job);
        if (savedJob) {
          toast.success("Job created successfully!", {
            toastId: "addOrUpdateJobSuccess",
          });
        }
        // Set the form state back to the initial state.
        formRef.current?.reset();
        setFormState(initialState);
        // send the user to the job list page.
        if (savedJob) {
          navigate(`/job_listing/${savedJob.id}`);
        }
      }

      if (!savedJob) {
        toast.error("Error creating Job.", { toastId: "addOrUpdateJobError" });
        console.error("Error creating Job.");
      }
      setIsLoading(false);
    } catch (err) {
      toast.error("Error creating Job.", { toastId: "addOrUpdateJobError" });
      console.error("Error creating Job:", err);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    let categoryOptions: CategoryOption[] = [];
    dataProvider.getJobCategories().then((categories) => {
      categories?.forEach((category) => {
        categoryOptions.push({
          id: category!.id,
          name: category!.name,
          unavailable: false,
        });
      });
      setJobCategories(categoryOptions);
    });
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    logEvent("page_view_create_job", {
      queryParams: queryParams.entries(),
      referrer: document.referrer,
    });
  }, []);

  /// If we're loading a job, we're loading a job to edit.
  if (isLoadingJob) {
    return <CenterInPageSpinner />;
  }

  //important code We will use this to make some changes in Selector Dropdon for select changes in list dropdown.
  // const rateTypeOptions: DropDownSelectorOption[] = [
  //   { id: JobRateType.HOURLY, name: "Hourly" },
  //   { id: JobRateType.FLATRATE, name: "Fixed Fee (Flat rate)" },
  // ];

  // const handleRateTypeChange = (option: DropDownSelectorOption) => {
  //   setInput("rateType", option.id);
  // };

  // const selectedRateTypeOption: DropDownSelectorOption =
  //   rateTypeOptions.find(
  //     (option) => option.id === (jobToUpdate?.rateType ?? formState.rateType)
  //   ) || rateTypeOptions[0];
  const jobCategoriessorted = sortArrayAlphabeticallyByProperty(jobCategories, 'name');

  return (
    <>
      {jobToUpdate ? (
        <SecondaryNav
          title="Back to Job"
          toPage={"/job_listing/" + jobToUpdate.id}
        />
      ) : (
        <SecondaryNav title="My Jobs" toPage="/view_jobs" />
      )}
      <form
        className="space-y-6 w-full flex flex-col mx-w-auto mt-8 mb-8"
        action="#"
        method="POST"
        id="createJobForm"
        ref={formRef}
      >
        <div className="flex flex-col p-6 lg:p-10 rounded-2xl w-10/12 shadow-lg justify-center text-left m-auto gap-8">
          <div className="w-full m-auto">
            <h2 className="text-gray-900 text-4xl font-bold ">
              {jobToUpdate ? "Edit Job" : "Create a Job"}
            </h2>
          </div>
          <div className="w-full m-auto">
            <label
              htmlFor="title"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Job Title
            </label>
            <div className="mt-2">
              <input
                onChange={(event) => setInput("title", event.target.value)}
                defaultValue={jobToUpdate?.title ?? formState.title}
                id="title"
                type="text"
                placeholder="Job Title"
                required
                className="block w-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-12"
              />
            </div>
          </div>
          <div className="w-full m-auto">
            <label
              htmlFor="locationAddress"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Where is the job? (Address)
            </label>
            <div className="mt-2">
              <input
                ref={locationAddressRef}
                id="locationAddress"
                type="text"
                defaultValue={jobToUpdate?.locationAddress}
                placeholder="Enter Address of Job"
                required
                className="block w-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-12"
              />
            </div>
            <div className="w-full m-auto flex flex-col lg:flex-row gap-2">
              <div className="mt-4 md:mt-2 w-full md:w-1/3 lg:w-1/3">
                <input
                  ref={locationCityRef}
                  id="locationCity"
                  type="text"
                  placeholder="City"
                  defaultValue={jobToUpdate?.locationCity}
                  required
                  className="block w-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-12"
                />
              </div>
              <div className="mt-2 w-full lg:w-1/3">
                <input
                  ref={locationStateRef}
                  id="locationState"
                  type="text"
                  placeholder="State"
                  defaultValue={jobToUpdate?.locationState}
                  required
                  className="block w-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-12"
                />
              </div>
              <div className="mt-2 w-full lg:w-1/3">
                <input
                  ref={locationZipRef}
                  id="locationZip"
                  type="text"
                  placeholder="Zipcode"
                  defaultValue={jobToUpdate?.locationZip}
                  required
                  className="block w-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-12"
                />
              </div>
            </div>
          </div>

          <div className="w-full m-auto flex gap-4">
            <div className="w-full relative">
              <label className="block relative text-sm font-medium leading-6 text-gray-900">
                Category
              </label>
              <Listbox value={selectedCategory} onChange={setSelectedCategory}>
                <Listbox.Button className="block relative GothamBold w-full rounded-md font-semibold text-teal border-teal border-[1.7px]  bg-[#138086] bg-opacity-10   py-1.5  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-12">
                  {selectedCategory?.name ?? "Select a Category"}
                  <span className="pointer-events-none absolute inset-y-0 right-4 flex items-center pr-2">
                    <ChevronDownIcon
                      className="h-5 w-5 text-teal"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Listbox.Options className="absolute mt-2 max-h-80 w-full overflow-auto rounded-md bg-white  text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-md  z-10">
                  {jobCategoriessorted.map((category) => (
                    <Listbox.Option
                      key={category.id}
                      value={category}
                      disabled={category.unavailable}
                      className={({ active }) =>
                        `relative GothamBook cursor-default select-none py-4 pl-4  ${
                          active
                            ? "bg-gray-50 hover:bg-[#138086] hover:bg-opacity-10 text-[teal]"
                            : "!text-teal"
                        }`
                      }
                    >
                      {category.name}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Listbox>
            </div>
          </div>

          <div className="w-full m-auto flex lg:flex-row flex-col gap-4">
            <div className="w-full lg:w-6/12">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Payment amount ($)
              </label>
              <div className="mt-2">
                <input
                  id="compensation"
                  type="number"
                  onChange={(event) =>
                    setInput("compensation", event.target.value)
                  }
                  value={jobToUpdate?.compensation ?? formState.compensation}
                  placeholder="Enter $ amount (fixed or hourly)."
                  required
                  className="block w-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-12"
                />
              </div>
            </div>
            <div className="w-full lg:w-6/12">
              <label className="block text-sm font-medium leading-6 text-gray-900 GothamBook">
                Pay type
              </label>
              <div className="mt-2">
                <select
                    id="rateType"
                    onChange={(event) => setInput("rateType", event.target.value)}
                    value={jobToUpdate?.rateType ?? formState.rateType}
                    className="block w-full rounded-md font-semibold text-teal GothamBold border-teal border-[1.7px]  bg-[#138086] bg-opacity-10  py-1.5  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-12"
                  >
                    <option
                      id={JobRateType.HOURLY}
                      value={JobRateType.HOURLY}
                      className="!text-teal GothamBook"
                    >
                      Hourly
                    </option>
                    <option
                      id={JobRateType.FLATRATE}
                      value={JobRateType.FLATRATE}
                      className="!text-teal GothamBook"
                    >
                      Fixed Fee (Flat rate)
                    </option>
                  </select>
                {/* important code We will use this to make some changes in Selector Dropdon. */}
                {/* <DropdownSelector
                  options={rateTypeOptions}
                  selectedOption={selectedRateTypeOption}
                  onChange={handleRateTypeChange}
                  className="block w-full rounded-md font-semibold text-teal border-teal border-[1.7px] bg-[#138086] bg-opacity-10 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-12"
                  placeholder="Select rate type"
                /> */}
              </div>
            </div>
          </div>

          <div className="w-full m-auto">
            <label
              htmlFor="description"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Describe the job in detail.
            </label>
            <div className="mt-2 h-[300px]">
              <textarea
                id="description"
                onChange={(event) =>
                  setInput("description", event.target.value)
                }
                defaultValue={jobToUpdate?.description ?? formState.description}
                placeholder="Provide a job description with expectations. The more detail the better."
                required
                className="block w-full h-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6"
              />
            </div>
            <BgCheckerWrapper disabled={!approvedUser} loading={isLoading}>
              <button
                className="w-full mt-8 bg-orange-500 text-white font-bold rounded-full disabled:opacity-50"
                disabled={!approvedUser || isLoading}
                onClick={addOrUpdateJob}
              >
                {isLoading ? (
                  <>
                    <Spinner aria-label="Loading action..." color="failure" />
                    <span className="pl-3 GothamBold">
                      {jobToUpdate ? "Update Job" : "Create Job"}
                    </span>
                  </>
                ) : (
                  <span className="pl-3 GothamBold">
                    {jobToUpdate ? "Update Job" : "Create Job"}
                  </span>
                )}
              </button>
            </BgCheckerWrapper>
          </div>
        </div>
      </form>
    </>
  );
}

export default CreateJob;
