/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($userID: ID!) {
    getUser(userID: $userID) {
      userID
      firstName
      lastName
      email
      locationAddress
      locationCity
      locationState
      locationZip
      photoKey
      type
      school
      biography
      workLocation
      candidateID
      languages
      skills
      stripeAccountID
      subscription
      receivedReviews {
        items {
          id
          forUser {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          fromUser {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          rating
          message
          createdAt
          updatedAt
          userReceivedReviewsUserID
          userSentReviewsUserID
          userReviewForUserUserID
          userReviewFromUserUserID
          owner
        }
        nextToken
      }
      sentReviews {
        items {
          id
          forUser {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          fromUser {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          rating
          message
          createdAt
          updatedAt
          userReceivedReviewsUserID
          userSentReviewsUserID
          userReviewForUserUserID
          userReviewFromUserUserID
          owner
        }
        nextToken
      }
      jobApplications {
        items {
          id
          job {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          applicant {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          message
          status
          createdAt
          updatedAt
          userJobApplicationsUserID
          jobApplicantsId
          jobApplicationJobId
          jobApplicationApplicantUserID
          owner
        }
        nextToken
      }
      postedJobs {
        items {
          id
          poster {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          rateType
          compensation
          title
          description
          locationAddress
          locationCity
          locationState
          locationZip
          locationCountry
          category {
            id
            name
            createdAt
            updatedAt
          }
          applicants {
            nextToken
          }
          jobStatus
          latitude
          longitude
          geoHash
          createdAt
          updatedAt
          userPostedJobsUserID
          jobPosterUserID
          jobCategoryId
          owner
        }
        nextToken
      }
      owner
      name
      graduationYear
      emailNotificationEnabled
      fullName
      avgHourlyRate
      jobSpecilities {
        items {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          category {
            id
            name
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          userJobSpecilitiesUserID
          jobSpecilitiesUserUserID
          jobSpecilitiesCategoryId
          owner
        }
        nextToken
      }
      status
      geoHash
      stripeCreatedAt
      bgCheckPayment {
        id
        user {
          userID
          firstName
          lastName
          email
          locationAddress
          locationCity
          locationState
          locationZip
          photoKey
          type
          school
          biography
          workLocation
          candidateID
          languages
          skills
          stripeAccountID
          subscription
          receivedReviews {
            nextToken
          }
          sentReviews {
            nextToken
          }
          jobApplications {
            nextToken
          }
          postedJobs {
            nextToken
          }
          owner
          name
          graduationYear
          emailNotificationEnabled
          fullName
          avgHourlyRate
          jobSpecilities {
            nextToken
          }
          status
          geoHash
          stripeCreatedAt
          bgCheckPayment {
            id
            payment
            createdAt
            updatedAt
            backgroundCheckPaymentStatusUserUserID
          }
          createdAt
          updatedAt
          userBgCheckPaymentId
        }
        payment
        createdAt
        updatedAt
        backgroundCheckPaymentStatusUserUserID
      }
      createdAt
      updatedAt
      userBgCheckPaymentId
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $userID: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      userID: $userID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userID
        firstName
        lastName
        email
        locationAddress
        locationCity
        locationState
        locationZip
        photoKey
        type
        school
        biography
        workLocation
        candidateID
        languages
        skills
        stripeAccountID
        subscription
        receivedReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        sentReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        jobApplications {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        postedJobs {
          items {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          nextToken
        }
        owner
        name
        graduationYear
        emailNotificationEnabled
        fullName
        avgHourlyRate
        jobSpecilities {
          items {
            id
            createdAt
            updatedAt
            userJobSpecilitiesUserID
            jobSpecilitiesUserUserID
            jobSpecilitiesCategoryId
            owner
          }
          nextToken
        }
        status
        geoHash
        stripeCreatedAt
        bgCheckPayment {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          payment
          createdAt
          updatedAt
          backgroundCheckPaymentStatusUserUserID
        }
        createdAt
        updatedAt
        userBgCheckPaymentId
      }
      nextToken
    }
  }
`;
export const getJobSpecilities = /* GraphQL */ `
  query GetJobSpecilities($id: ID!) {
    getJobSpecilities(id: $id) {
      id
      user {
        userID
        firstName
        lastName
        email
        locationAddress
        locationCity
        locationState
        locationZip
        photoKey
        type
        school
        biography
        workLocation
        candidateID
        languages
        skills
        stripeAccountID
        subscription
        receivedReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        sentReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        jobApplications {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        postedJobs {
          items {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          nextToken
        }
        owner
        name
        graduationYear
        emailNotificationEnabled
        fullName
        avgHourlyRate
        jobSpecilities {
          items {
            id
            createdAt
            updatedAt
            userJobSpecilitiesUserID
            jobSpecilitiesUserUserID
            jobSpecilitiesCategoryId
            owner
          }
          nextToken
        }
        status
        geoHash
        stripeCreatedAt
        bgCheckPayment {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          payment
          createdAt
          updatedAt
          backgroundCheckPaymentStatusUserUserID
        }
        createdAt
        updatedAt
        userBgCheckPaymentId
      }
      category {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      userJobSpecilitiesUserID
      jobSpecilitiesUserUserID
      jobSpecilitiesCategoryId
      owner
    }
  }
`;
export const listJobSpecilities = /* GraphQL */ `
  query ListJobSpecilities(
    $filter: ModelJobSpecilitiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobSpecilities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user {
          userID
          firstName
          lastName
          email
          locationAddress
          locationCity
          locationState
          locationZip
          photoKey
          type
          school
          biography
          workLocation
          candidateID
          languages
          skills
          stripeAccountID
          subscription
          receivedReviews {
            nextToken
          }
          sentReviews {
            nextToken
          }
          jobApplications {
            nextToken
          }
          postedJobs {
            nextToken
          }
          owner
          name
          graduationYear
          emailNotificationEnabled
          fullName
          avgHourlyRate
          jobSpecilities {
            nextToken
          }
          status
          geoHash
          stripeCreatedAt
          bgCheckPayment {
            id
            payment
            createdAt
            updatedAt
            backgroundCheckPaymentStatusUserUserID
          }
          createdAt
          updatedAt
          userBgCheckPaymentId
        }
        category {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        userJobSpecilitiesUserID
        jobSpecilitiesUserUserID
        jobSpecilitiesCategoryId
        owner
      }
      nextToken
    }
  }
`;
export const getJob = /* GraphQL */ `
  query GetJob($id: ID!) {
    getJob(id: $id) {
      id
      poster {
        userID
        firstName
        lastName
        email
        locationAddress
        locationCity
        locationState
        locationZip
        photoKey
        type
        school
        biography
        workLocation
        candidateID
        languages
        skills
        stripeAccountID
        subscription
        receivedReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        sentReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        jobApplications {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        postedJobs {
          items {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          nextToken
        }
        owner
        name
        graduationYear
        emailNotificationEnabled
        fullName
        avgHourlyRate
        jobSpecilities {
          items {
            id
            createdAt
            updatedAt
            userJobSpecilitiesUserID
            jobSpecilitiesUserUserID
            jobSpecilitiesCategoryId
            owner
          }
          nextToken
        }
        status
        geoHash
        stripeCreatedAt
        bgCheckPayment {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          payment
          createdAt
          updatedAt
          backgroundCheckPaymentStatusUserUserID
        }
        createdAt
        updatedAt
        userBgCheckPaymentId
      }
      rateType
      compensation
      title
      description
      locationAddress
      locationCity
      locationState
      locationZip
      locationCountry
      category {
        id
        name
        createdAt
        updatedAt
      }
      applicants {
        items {
          id
          job {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          applicant {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          message
          status
          createdAt
          updatedAt
          userJobApplicationsUserID
          jobApplicantsId
          jobApplicationJobId
          jobApplicationApplicantUserID
          owner
        }
        nextToken
      }
      jobStatus
      latitude
      longitude
      geoHash
      createdAt
      updatedAt
      userPostedJobsUserID
      jobPosterUserID
      jobCategoryId
      owner
    }
  }
`;
export const listJobs = /* GraphQL */ `
  query ListJobs(
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        poster {
          userID
          firstName
          lastName
          email
          locationAddress
          locationCity
          locationState
          locationZip
          photoKey
          type
          school
          biography
          workLocation
          candidateID
          languages
          skills
          stripeAccountID
          subscription
          receivedReviews {
            nextToken
          }
          sentReviews {
            nextToken
          }
          jobApplications {
            nextToken
          }
          postedJobs {
            nextToken
          }
          owner
          name
          graduationYear
          emailNotificationEnabled
          fullName
          avgHourlyRate
          jobSpecilities {
            nextToken
          }
          status
          geoHash
          stripeCreatedAt
          bgCheckPayment {
            id
            payment
            createdAt
            updatedAt
            backgroundCheckPaymentStatusUserUserID
          }
          createdAt
          updatedAt
          userBgCheckPaymentId
        }
        rateType
        compensation
        title
        description
        locationAddress
        locationCity
        locationState
        locationZip
        locationCountry
        category {
          id
          name
          createdAt
          updatedAt
        }
        applicants {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        jobStatus
        latitude
        longitude
        geoHash
        createdAt
        updatedAt
        userPostedJobsUserID
        jobPosterUserID
        jobCategoryId
        owner
      }
      nextToken
    }
  }
`;
export const jobsByLocationCountryAndGeoHash = /* GraphQL */ `
  query JobsByLocationCountryAndGeoHash(
    $locationCountry: String!
    $geoHash: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobsByLocationCountryAndGeoHash(
      locationCountry: $locationCountry
      geoHash: $geoHash
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        poster {
          userID
          firstName
          lastName
          email
          locationAddress
          locationCity
          locationState
          locationZip
          photoKey
          type
          school
          biography
          workLocation
          candidateID
          languages
          skills
          stripeAccountID
          subscription
          receivedReviews {
            nextToken
          }
          sentReviews {
            nextToken
          }
          jobApplications {
            nextToken
          }
          postedJobs {
            nextToken
          }
          owner
          name
          graduationYear
          emailNotificationEnabled
          fullName
          avgHourlyRate
          jobSpecilities {
            nextToken
          }
          status
          geoHash
          stripeCreatedAt
          bgCheckPayment {
            id
            payment
            createdAt
            updatedAt
            backgroundCheckPaymentStatusUserUserID
          }
          createdAt
          updatedAt
          userBgCheckPaymentId
        }
        rateType
        compensation
        title
        description
        locationAddress
        locationCity
        locationState
        locationZip
        locationCountry
        category {
          id
          name
          createdAt
          updatedAt
        }
        applicants {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        jobStatus
        latitude
        longitude
        geoHash
        createdAt
        updatedAt
        userPostedJobsUserID
        jobPosterUserID
        jobCategoryId
        owner
      }
      nextToken
    }
  }
`;
export const getJobCategory = /* GraphQL */ `
  query GetJobCategory($id: ID!) {
    getJobCategory(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listJobCategories = /* GraphQL */ `
  query ListJobCategories(
    $filter: ModelJobCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const jobCategoriesByName = /* GraphQL */ `
  query JobCategoriesByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelJobCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobCategoriesByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJobApplication = /* GraphQL */ `
  query GetJobApplication($id: ID!) {
    getJobApplication(id: $id) {
      id
      job {
        id
        poster {
          userID
          firstName
          lastName
          email
          locationAddress
          locationCity
          locationState
          locationZip
          photoKey
          type
          school
          biography
          workLocation
          candidateID
          languages
          skills
          stripeAccountID
          subscription
          receivedReviews {
            nextToken
          }
          sentReviews {
            nextToken
          }
          jobApplications {
            nextToken
          }
          postedJobs {
            nextToken
          }
          owner
          name
          graduationYear
          emailNotificationEnabled
          fullName
          avgHourlyRate
          jobSpecilities {
            nextToken
          }
          status
          geoHash
          stripeCreatedAt
          bgCheckPayment {
            id
            payment
            createdAt
            updatedAt
            backgroundCheckPaymentStatusUserUserID
          }
          createdAt
          updatedAt
          userBgCheckPaymentId
        }
        rateType
        compensation
        title
        description
        locationAddress
        locationCity
        locationState
        locationZip
        locationCountry
        category {
          id
          name
          createdAt
          updatedAt
        }
        applicants {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        jobStatus
        latitude
        longitude
        geoHash
        createdAt
        updatedAt
        userPostedJobsUserID
        jobPosterUserID
        jobCategoryId
        owner
      }
      applicant {
        userID
        firstName
        lastName
        email
        locationAddress
        locationCity
        locationState
        locationZip
        photoKey
        type
        school
        biography
        workLocation
        candidateID
        languages
        skills
        stripeAccountID
        subscription
        receivedReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        sentReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        jobApplications {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        postedJobs {
          items {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          nextToken
        }
        owner
        name
        graduationYear
        emailNotificationEnabled
        fullName
        avgHourlyRate
        jobSpecilities {
          items {
            id
            createdAt
            updatedAt
            userJobSpecilitiesUserID
            jobSpecilitiesUserUserID
            jobSpecilitiesCategoryId
            owner
          }
          nextToken
        }
        status
        geoHash
        stripeCreatedAt
        bgCheckPayment {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          payment
          createdAt
          updatedAt
          backgroundCheckPaymentStatusUserUserID
        }
        createdAt
        updatedAt
        userBgCheckPaymentId
      }
      message
      status
      createdAt
      updatedAt
      userJobApplicationsUserID
      jobApplicantsId
      jobApplicationJobId
      jobApplicationApplicantUserID
      owner
    }
  }
`;
export const listJobApplications = /* GraphQL */ `
  query ListJobApplications(
    $filter: ModelJobApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobApplications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        job {
          id
          poster {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          rateType
          compensation
          title
          description
          locationAddress
          locationCity
          locationState
          locationZip
          locationCountry
          category {
            id
            name
            createdAt
            updatedAt
          }
          applicants {
            nextToken
          }
          jobStatus
          latitude
          longitude
          geoHash
          createdAt
          updatedAt
          userPostedJobsUserID
          jobPosterUserID
          jobCategoryId
          owner
        }
        applicant {
          userID
          firstName
          lastName
          email
          locationAddress
          locationCity
          locationState
          locationZip
          photoKey
          type
          school
          biography
          workLocation
          candidateID
          languages
          skills
          stripeAccountID
          subscription
          receivedReviews {
            nextToken
          }
          sentReviews {
            nextToken
          }
          jobApplications {
            nextToken
          }
          postedJobs {
            nextToken
          }
          owner
          name
          graduationYear
          emailNotificationEnabled
          fullName
          avgHourlyRate
          jobSpecilities {
            nextToken
          }
          status
          geoHash
          stripeCreatedAt
          bgCheckPayment {
            id
            payment
            createdAt
            updatedAt
            backgroundCheckPaymentStatusUserUserID
          }
          createdAt
          updatedAt
          userBgCheckPaymentId
        }
        message
        status
        createdAt
        updatedAt
        userJobApplicationsUserID
        jobApplicantsId
        jobApplicationJobId
        jobApplicationApplicantUserID
        owner
      }
      nextToken
    }
  }
`;
export const getCoupon = /* GraphQL */ `
  query GetCoupon($id: ID!) {
    getCoupon(id: $id) {
      id
      couponCode
      isActive
      amount
      percentage
      type
      description
      expiryDate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCoupons = /* GraphQL */ `
  query ListCoupons(
    $filter: ModelCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoupons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        couponCode
        isActive
        amount
        percentage
        type
        description
        expiryDate
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getUserReview = /* GraphQL */ `
  query GetUserReview($id: ID!) {
    getUserReview(id: $id) {
      id
      forUser {
        userID
        firstName
        lastName
        email
        locationAddress
        locationCity
        locationState
        locationZip
        photoKey
        type
        school
        biography
        workLocation
        candidateID
        languages
        skills
        stripeAccountID
        subscription
        receivedReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        sentReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        jobApplications {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        postedJobs {
          items {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          nextToken
        }
        owner
        name
        graduationYear
        emailNotificationEnabled
        fullName
        avgHourlyRate
        jobSpecilities {
          items {
            id
            createdAt
            updatedAt
            userJobSpecilitiesUserID
            jobSpecilitiesUserUserID
            jobSpecilitiesCategoryId
            owner
          }
          nextToken
        }
        status
        geoHash
        stripeCreatedAt
        bgCheckPayment {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          payment
          createdAt
          updatedAt
          backgroundCheckPaymentStatusUserUserID
        }
        createdAt
        updatedAt
        userBgCheckPaymentId
      }
      fromUser {
        userID
        firstName
        lastName
        email
        locationAddress
        locationCity
        locationState
        locationZip
        photoKey
        type
        school
        biography
        workLocation
        candidateID
        languages
        skills
        stripeAccountID
        subscription
        receivedReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        sentReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        jobApplications {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        postedJobs {
          items {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          nextToken
        }
        owner
        name
        graduationYear
        emailNotificationEnabled
        fullName
        avgHourlyRate
        jobSpecilities {
          items {
            id
            createdAt
            updatedAt
            userJobSpecilitiesUserID
            jobSpecilitiesUserUserID
            jobSpecilitiesCategoryId
            owner
          }
          nextToken
        }
        status
        geoHash
        stripeCreatedAt
        bgCheckPayment {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          payment
          createdAt
          updatedAt
          backgroundCheckPaymentStatusUserUserID
        }
        createdAt
        updatedAt
        userBgCheckPaymentId
      }
      rating
      message
      createdAt
      updatedAt
      userReceivedReviewsUserID
      userSentReviewsUserID
      userReviewForUserUserID
      userReviewFromUserUserID
      owner
    }
  }
`;
export const listUserReviews = /* GraphQL */ `
  query ListUserReviews(
    $filter: ModelUserReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        forUser {
          userID
          firstName
          lastName
          email
          locationAddress
          locationCity
          locationState
          locationZip
          photoKey
          type
          school
          biography
          workLocation
          candidateID
          languages
          skills
          stripeAccountID
          subscription
          receivedReviews {
            nextToken
          }
          sentReviews {
            nextToken
          }
          jobApplications {
            nextToken
          }
          postedJobs {
            nextToken
          }
          owner
          name
          graduationYear
          emailNotificationEnabled
          fullName
          avgHourlyRate
          jobSpecilities {
            nextToken
          }
          status
          geoHash
          stripeCreatedAt
          bgCheckPayment {
            id
            payment
            createdAt
            updatedAt
            backgroundCheckPaymentStatusUserUserID
          }
          createdAt
          updatedAt
          userBgCheckPaymentId
        }
        fromUser {
          userID
          firstName
          lastName
          email
          locationAddress
          locationCity
          locationState
          locationZip
          photoKey
          type
          school
          biography
          workLocation
          candidateID
          languages
          skills
          stripeAccountID
          subscription
          receivedReviews {
            nextToken
          }
          sentReviews {
            nextToken
          }
          jobApplications {
            nextToken
          }
          postedJobs {
            nextToken
          }
          owner
          name
          graduationYear
          emailNotificationEnabled
          fullName
          avgHourlyRate
          jobSpecilities {
            nextToken
          }
          status
          geoHash
          stripeCreatedAt
          bgCheckPayment {
            id
            payment
            createdAt
            updatedAt
            backgroundCheckPaymentStatusUserUserID
          }
          createdAt
          updatedAt
          userBgCheckPaymentId
        }
        rating
        message
        createdAt
        updatedAt
        userReceivedReviewsUserID
        userSentReviewsUserID
        userReviewForUserUserID
        userReviewFromUserUserID
        owner
      }
      nextToken
    }
  }
`;
export const getJobMessageLog = /* GraphQL */ `
  query GetJobMessageLog($logId: ID!) {
    getJobMessageLog(logId: $logId) {
      logId
      job {
        id
        poster {
          userID
          firstName
          lastName
          email
          locationAddress
          locationCity
          locationState
          locationZip
          photoKey
          type
          school
          biography
          workLocation
          candidateID
          languages
          skills
          stripeAccountID
          subscription
          receivedReviews {
            nextToken
          }
          sentReviews {
            nextToken
          }
          jobApplications {
            nextToken
          }
          postedJobs {
            nextToken
          }
          owner
          name
          graduationYear
          emailNotificationEnabled
          fullName
          avgHourlyRate
          jobSpecilities {
            nextToken
          }
          status
          geoHash
          stripeCreatedAt
          bgCheckPayment {
            id
            payment
            createdAt
            updatedAt
            backgroundCheckPaymentStatusUserUserID
          }
          createdAt
          updatedAt
          userBgCheckPaymentId
        }
        rateType
        compensation
        title
        description
        locationAddress
        locationCity
        locationState
        locationZip
        locationCountry
        category {
          id
          name
          createdAt
          updatedAt
        }
        applicants {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        jobStatus
        latitude
        longitude
        geoHash
        createdAt
        updatedAt
        userPostedJobsUserID
        jobPosterUserID
        jobCategoryId
        owner
      }
      applicant {
        id
        job {
          id
          poster {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          rateType
          compensation
          title
          description
          locationAddress
          locationCity
          locationState
          locationZip
          locationCountry
          category {
            id
            name
            createdAt
            updatedAt
          }
          applicants {
            nextToken
          }
          jobStatus
          latitude
          longitude
          geoHash
          createdAt
          updatedAt
          userPostedJobsUserID
          jobPosterUserID
          jobCategoryId
          owner
        }
        applicant {
          userID
          firstName
          lastName
          email
          locationAddress
          locationCity
          locationState
          locationZip
          photoKey
          type
          school
          biography
          workLocation
          candidateID
          languages
          skills
          stripeAccountID
          subscription
          receivedReviews {
            nextToken
          }
          sentReviews {
            nextToken
          }
          jobApplications {
            nextToken
          }
          postedJobs {
            nextToken
          }
          owner
          name
          graduationYear
          emailNotificationEnabled
          fullName
          avgHourlyRate
          jobSpecilities {
            nextToken
          }
          status
          geoHash
          stripeCreatedAt
          bgCheckPayment {
            id
            payment
            createdAt
            updatedAt
            backgroundCheckPaymentStatusUserUserID
          }
          createdAt
          updatedAt
          userBgCheckPaymentId
        }
        message
        status
        createdAt
        updatedAt
        userJobApplicationsUserID
        jobApplicantsId
        jobApplicationJobId
        jobApplicationApplicantUserID
        owner
      }
      posterHash
      applicantHash
      messages {
        items {
          id
          to
          from
          subject
          body
          messageLog {
            logId
            posterHash
            applicantHash
            createdAt
            updatedAt
            jobMessageLogJobId
            jobMessageLogApplicantId
            owner
          }
          createdAt
          updatedAt
          jobMessageLogMessagesLogId
          jobMessageMessageLogLogId
        }
        nextToken
      }
      createdAt
      updatedAt
      jobMessageLogJobId
      jobMessageLogApplicantId
      owner
    }
  }
`;
export const listJobMessageLogs = /* GraphQL */ `
  query ListJobMessageLogs(
    $logId: ID
    $filter: ModelJobMessageLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listJobMessageLogs(
      logId: $logId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        logId
        job {
          id
          poster {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          rateType
          compensation
          title
          description
          locationAddress
          locationCity
          locationState
          locationZip
          locationCountry
          category {
            id
            name
            createdAt
            updatedAt
          }
          applicants {
            nextToken
          }
          jobStatus
          latitude
          longitude
          geoHash
          createdAt
          updatedAt
          userPostedJobsUserID
          jobPosterUserID
          jobCategoryId
          owner
        }
        applicant {
          id
          job {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          applicant {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          message
          status
          createdAt
          updatedAt
          userJobApplicationsUserID
          jobApplicantsId
          jobApplicationJobId
          jobApplicationApplicantUserID
          owner
        }
        posterHash
        applicantHash
        messages {
          items {
            id
            to
            from
            subject
            body
            createdAt
            updatedAt
            jobMessageLogMessagesLogId
            jobMessageMessageLogLogId
          }
          nextToken
        }
        createdAt
        updatedAt
        jobMessageLogJobId
        jobMessageLogApplicantId
        owner
      }
      nextToken
    }
  }
`;
export const getJobMessage = /* GraphQL */ `
  query GetJobMessage($id: ID!) {
    getJobMessage(id: $id) {
      id
      to
      from
      subject
      body
      messageLog {
        logId
        job {
          id
          poster {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          rateType
          compensation
          title
          description
          locationAddress
          locationCity
          locationState
          locationZip
          locationCountry
          category {
            id
            name
            createdAt
            updatedAt
          }
          applicants {
            nextToken
          }
          jobStatus
          latitude
          longitude
          geoHash
          createdAt
          updatedAt
          userPostedJobsUserID
          jobPosterUserID
          jobCategoryId
          owner
        }
        applicant {
          id
          job {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          applicant {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          message
          status
          createdAt
          updatedAt
          userJobApplicationsUserID
          jobApplicantsId
          jobApplicationJobId
          jobApplicationApplicantUserID
          owner
        }
        posterHash
        applicantHash
        messages {
          items {
            id
            to
            from
            subject
            body
            createdAt
            updatedAt
            jobMessageLogMessagesLogId
            jobMessageMessageLogLogId
          }
          nextToken
        }
        createdAt
        updatedAt
        jobMessageLogJobId
        jobMessageLogApplicantId
        owner
      }
      createdAt
      updatedAt
      jobMessageLogMessagesLogId
      jobMessageMessageLogLogId
    }
  }
`;
export const listJobMessages = /* GraphQL */ `
  query ListJobMessages(
    $filter: ModelJobMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        to
        from
        subject
        body
        messageLog {
          logId
          job {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          applicant {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          posterHash
          applicantHash
          messages {
            nextToken
          }
          createdAt
          updatedAt
          jobMessageLogJobId
          jobMessageLogApplicantId
          owner
        }
        createdAt
        updatedAt
        jobMessageLogMessagesLogId
        jobMessageMessageLogLogId
      }
      nextToken
    }
  }
`;
export const getFlaggedContent = /* GraphQL */ `
  query GetFlaggedContent($contentId: String!, $flagReason: FlaggedReason!) {
    getFlaggedContent(contentId: $contentId, flagReason: $flagReason) {
      contentId
      flaggedUser {
        userID
        firstName
        lastName
        email
        locationAddress
        locationCity
        locationState
        locationZip
        photoKey
        type
        school
        biography
        workLocation
        candidateID
        languages
        skills
        stripeAccountID
        subscription
        receivedReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        sentReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        jobApplications {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        postedJobs {
          items {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          nextToken
        }
        owner
        name
        graduationYear
        emailNotificationEnabled
        fullName
        avgHourlyRate
        jobSpecilities {
          items {
            id
            createdAt
            updatedAt
            userJobSpecilitiesUserID
            jobSpecilitiesUserUserID
            jobSpecilitiesCategoryId
            owner
          }
          nextToken
        }
        status
        geoHash
        stripeCreatedAt
        bgCheckPayment {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          payment
          createdAt
          updatedAt
          backgroundCheckPaymentStatusUserUserID
        }
        createdAt
        updatedAt
        userBgCheckPaymentId
      }
      contentType
      flagReason
      reportCount
      createdAt
      updatedAt
      flaggedContentFlaggedUserUserID
    }
  }
`;
export const listFlaggedContents = /* GraphQL */ `
  query ListFlaggedContents(
    $contentId: String
    $flagReason: ModelStringKeyConditionInput
    $filter: ModelFlaggedContentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFlaggedContents(
      contentId: $contentId
      flagReason: $flagReason
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        contentId
        flaggedUser {
          userID
          firstName
          lastName
          email
          locationAddress
          locationCity
          locationState
          locationZip
          photoKey
          type
          school
          biography
          workLocation
          candidateID
          languages
          skills
          stripeAccountID
          subscription
          receivedReviews {
            nextToken
          }
          sentReviews {
            nextToken
          }
          jobApplications {
            nextToken
          }
          postedJobs {
            nextToken
          }
          owner
          name
          graduationYear
          emailNotificationEnabled
          fullName
          avgHourlyRate
          jobSpecilities {
            nextToken
          }
          status
          geoHash
          stripeCreatedAt
          bgCheckPayment {
            id
            payment
            createdAt
            updatedAt
            backgroundCheckPaymentStatusUserUserID
          }
          createdAt
          updatedAt
          userBgCheckPaymentId
        }
        contentType
        flagReason
        reportCount
        createdAt
        updatedAt
        flaggedContentFlaggedUserUserID
      }
      nextToken
    }
  }
`;
export const flaggedContentsByContentType = /* GraphQL */ `
  query FlaggedContentsByContentType(
    $contentType: FlaggedContentType!
    $sortDirection: ModelSortDirection
    $filter: ModelFlaggedContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    flaggedContentsByContentType(
      contentType: $contentType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        contentId
        flaggedUser {
          userID
          firstName
          lastName
          email
          locationAddress
          locationCity
          locationState
          locationZip
          photoKey
          type
          school
          biography
          workLocation
          candidateID
          languages
          skills
          stripeAccountID
          subscription
          receivedReviews {
            nextToken
          }
          sentReviews {
            nextToken
          }
          jobApplications {
            nextToken
          }
          postedJobs {
            nextToken
          }
          owner
          name
          graduationYear
          emailNotificationEnabled
          fullName
          avgHourlyRate
          jobSpecilities {
            nextToken
          }
          status
          geoHash
          stripeCreatedAt
          bgCheckPayment {
            id
            payment
            createdAt
            updatedAt
            backgroundCheckPaymentStatusUserUserID
          }
          createdAt
          updatedAt
          userBgCheckPaymentId
        }
        contentType
        flagReason
        reportCount
        createdAt
        updatedAt
        flaggedContentFlaggedUserUserID
      }
      nextToken
    }
  }
`;
export const getSampleJob = /* GraphQL */ `
  query GetSampleJob($id: ID!) {
    getSampleJob(id: $id) {
      id
      rateType
      compensation
      title
      description
      locationCity
      locationState
      createdAt
      updatedAt
    }
  }
`;
export const listSampleJobs = /* GraphQL */ `
  query ListSampleJobs(
    $filter: ModelSampleJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSampleJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        rateType
        compensation
        title
        description
        locationCity
        locationState
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFAQ = /* GraphQL */ `
  query GetFAQ($id: ID!) {
    getFAQ(id: $id) {
      id
      question
      answer
      order
      createdAt
      updatedAt
    }
  }
`;
export const listFAQS = /* GraphQL */ `
  query ListFAQS(
    $filter: ModelFAQFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFAQS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        question
        answer
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBackgroundCheckPaymentStatus = /* GraphQL */ `
  query GetBackgroundCheckPaymentStatus($id: ID!) {
    getBackgroundCheckPaymentStatus(id: $id) {
      id
      user {
        userID
        firstName
        lastName
        email
        locationAddress
        locationCity
        locationState
        locationZip
        photoKey
        type
        school
        biography
        workLocation
        candidateID
        languages
        skills
        stripeAccountID
        subscription
        receivedReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        sentReviews {
          items {
            id
            rating
            message
            createdAt
            updatedAt
            userReceivedReviewsUserID
            userSentReviewsUserID
            userReviewForUserUserID
            userReviewFromUserUserID
            owner
          }
          nextToken
        }
        jobApplications {
          items {
            id
            message
            status
            createdAt
            updatedAt
            userJobApplicationsUserID
            jobApplicantsId
            jobApplicationJobId
            jobApplicationApplicantUserID
            owner
          }
          nextToken
        }
        postedJobs {
          items {
            id
            rateType
            compensation
            title
            description
            locationAddress
            locationCity
            locationState
            locationZip
            locationCountry
            jobStatus
            latitude
            longitude
            geoHash
            createdAt
            updatedAt
            userPostedJobsUserID
            jobPosterUserID
            jobCategoryId
            owner
          }
          nextToken
        }
        owner
        name
        graduationYear
        emailNotificationEnabled
        fullName
        avgHourlyRate
        jobSpecilities {
          items {
            id
            createdAt
            updatedAt
            userJobSpecilitiesUserID
            jobSpecilitiesUserUserID
            jobSpecilitiesCategoryId
            owner
          }
          nextToken
        }
        status
        geoHash
        stripeCreatedAt
        bgCheckPayment {
          id
          user {
            userID
            firstName
            lastName
            email
            locationAddress
            locationCity
            locationState
            locationZip
            photoKey
            type
            school
            biography
            workLocation
            candidateID
            languages
            skills
            stripeAccountID
            subscription
            owner
            name
            graduationYear
            emailNotificationEnabled
            fullName
            avgHourlyRate
            status
            geoHash
            stripeCreatedAt
            createdAt
            updatedAt
            userBgCheckPaymentId
          }
          payment
          createdAt
          updatedAt
          backgroundCheckPaymentStatusUserUserID
        }
        createdAt
        updatedAt
        userBgCheckPaymentId
      }
      payment
      createdAt
      updatedAt
      backgroundCheckPaymentStatusUserUserID
    }
  }
`;
export const listBackgroundCheckPaymentStatuses = /* GraphQL */ `
  query ListBackgroundCheckPaymentStatuses(
    $filter: ModelBackgroundCheckPaymentStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBackgroundCheckPaymentStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user {
          userID
          firstName
          lastName
          email
          locationAddress
          locationCity
          locationState
          locationZip
          photoKey
          type
          school
          biography
          workLocation
          candidateID
          languages
          skills
          stripeAccountID
          subscription
          receivedReviews {
            nextToken
          }
          sentReviews {
            nextToken
          }
          jobApplications {
            nextToken
          }
          postedJobs {
            nextToken
          }
          owner
          name
          graduationYear
          emailNotificationEnabled
          fullName
          avgHourlyRate
          jobSpecilities {
            nextToken
          }
          status
          geoHash
          stripeCreatedAt
          bgCheckPayment {
            id
            payment
            createdAt
            updatedAt
            backgroundCheckPaymentStatusUserUserID
          }
          createdAt
          updatedAt
          userBgCheckPaymentId
        }
        payment
        createdAt
        updatedAt
        backgroundCheckPaymentStatusUserUserID
      }
      nextToken
    }
  }
`;
export const getBackgroundCheckLog = /* GraphQL */ `
  query GetBackgroundCheckLog($candidateID: ID!) {
    getBackgroundCheckLog(candidateID: $candidateID) {
      candidateID
      userID
      invitationID
      reportID
      inviteStatus
      reportStatus
      reportResult
      reportAdjudication
      backgroundCheckCompletedAt
      createdAt
      updatedAt
    }
  }
`;
export const listBackgroundCheckLogs = /* GraphQL */ `
  query ListBackgroundCheckLogs(
    $candidateID: ID
    $filter: ModelBackgroundCheckLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBackgroundCheckLogs(
      candidateID: $candidateID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        candidateID
        userID
        invitationID
        reportID
        inviteStatus
        reportStatus
        reportResult
        reportAdjudication
        backgroundCheckCompletedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSetting = /* GraphQL */ `
  query GetSetting($id: ID!) {
    getSetting(id: $id) {
      id
      feature
      active
      createdAt
      updatedAt
    }
  }
`;
export const listSettings = /* GraphQL */ `
  query ListSettings(
    $filter: ModelSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        feature
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserBadges = /* GraphQL */ `
  query GetUserBadges($userId: String!) {
    getUserBadges(userId: $userId) {
      id
      name
      description
      icon
    }
  }
`;
export const checkStripeExpressAccountStatus = /* GraphQL */ `
  query CheckStripeExpressAccountStatus($stripeAccountID: String!) {
    checkStripeExpressAccountStatus(stripeAccountID: $stripeAccountID)
  }
`;
export const getStripeLoginLink = /* GraphQL */ `
  query GetStripeLoginLink($accountId: String!) {
    getStripeLoginLink(accountId: $accountId)
  }
`;
export const logEvent = /* GraphQL */ `
  query LogEvent($name: String, $data: EventLogData) {
    logEvent(name: $name, data: $data)
  }
`;
export const getUserList = /* GraphQL */ `
  query GetUserList($msg: String) {
    getUserList(msg: $msg)
  }
`;
