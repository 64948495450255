import { useState } from "react";
import { StarIcon } from "@heroicons/react/20/solid";

interface StarRatingProps {
  /// Current rating selected by user.
  initialRating: number;
  /// Function to call when the rating is changed.
  userSetRating: (rating: number) => void;
}

/// Function that shows 5 stars for a user to click to rate with.
export default function StarRating({ initialRating, userSetRating }: StarRatingProps) {
  const [rating, setRating] = useState(initialRating);
  const [hover, setHover] = useState(0);

  return (
    <div>
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <button
            type="button"
            key={index}
            className={"mx- w-10 h-10 rounded full p-1"}
            onClick={() => {
              userSetRating(index);
              setRating(index);
            }}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(rating)}
          >
            <StarIcon
              className={
                index <= (hover || rating)
                  ? "text-yellow-500 bg-yellow-300"
                  : "bg-gray-200 text-gray-300"
              }
            />
          </button>
        );
      })}
    </div>
  );
}
