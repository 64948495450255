import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

/// E.g. "Job Listing Page", "/browse"
function SecondaryNav({ title, toPage }: { title: string; toPage: string }) {
  return (
    <>
      <Link
        to={toPage}
        className="w-3/12 flex gap-4 items-center pl-6 md:pl-8 mt-8 mb-8 "
      >
        <div className="flex items-center whitespace-nowrap">
          <div className="w-8 h-8 bg-gray-100 rounded-full text-gray-600 self-center">
            <ChevronLeftIcon className="w-6 h-full m-auto hover:text-[teal]" />
          </div>
          <h3 className="text-lg font-semibold text-gray-700 ml-4 hover:text-[teal]">{title}</h3>
        </div>
      </Link>
    </>
  );
}

export default SecondaryNav;
