import ProfileCard from "../components/profile/profile_card";
import ReviewsCard from "../components/reviews/reviews_card";
import Footer from "../components/nav/footer";
import BadgesCard from "../components/profile/badges_card";
import Qualifications from "../components/profile/qualifications";
import { useEffect, useState } from "react";
import { JobMessageLog, User, UserBadge, UserType } from "../API";
import { useLocation, useParams } from "react-router-dom";
import * as dataProvider from "../graphql/DataProvider";
import { toast } from "react-toastify";
import { useUserContext } from "../helpers/UserContext";
import SecondaryNav from "../components/nav/secondary_nav";
import { getUserBadges } from "../graphql/BadgeHandler";
import { logEvent } from "../helpers/Analytics";
import SkeletonProfile from "../components/Skeleton/SkeletonProfile";
import Banner from "../components/banner/banner";
import { studentProfileBannerMessage } from "../helpers/Constants";

function Profile() {
  const location = useLocation();
  const { currentUser, updateUser } = useUserContext();
  const { id, status } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [profileToView, setProfileToView] = useState<User | null>();
  const [messageLog, setMessageLog] = useState<JobMessageLog | null>(null);
  const [canLeaveReview, setCanLeaveReview] = useState<boolean>(false);
  const [userBadges, setUserBadges] = useState<UserBadge[]>([]);

  useEffect(() => {
    loadData();
  }, [id]);
  // Handles response from Stripe
  useEffect(() => {
    switch (status) {
      case "subscriptionSuccess":
        toast.success("Subscription successful!", {
          toastId: "subscriptionSuccess",
        });
        break;
      case "subscriptionCancel":
        toast.error("Subscription not completed.", {
          toastId: "subscriptionCancel",
        });
        break;
      case "accountSuccess":
        toast.success("Stripe account updated.", { toastId: "accountSuccess" });
        break;
      case "accountRefresh":
        toast.error("Stripe account not updated.", {
          toastId: "accountRefresh",
        });
        break;
    }
    if (status) {
      updateUser();
    }
  }, []);

  async function loadData() {
    if (!id) return;
    setIsLoading(true);
    const user = await dataProvider.getUser(id);
    if (user) {
      setProfileToView(user);
      await showContactButtonIfNeeded(user);
      const results = await dataProvider.hasActiveJobOrApplicationWithUser(
        user
      );
      const hasActiveJobOrApplication = results?.length > 0;
      setCanLeaveReview(hasActiveJobOrApplication);
      const badges = await getUserBadges(user!.userID);
      if (badges) {
        setUserBadges(badges);
      }
    }
    setIsLoading(false);
  }
  /// Determine if we need to show the contact button.
  async function showContactButtonIfNeeded(user: User | null) {
    if (currentUser?.userID === user?.userID) {
      // No need to contact ourselves.
      return;
    }
    const [application, job] = await dataProvider.openJobOrApplicationWithUser(
      user
    );
    const messageLog = await dataProvider.getMessageLogForJob(job, application);
    setMessageLog(messageLog);
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    logEvent("page_view_profile", {
      queryParams: queryParams.entries(),
      referrer: document.referrer,
      attributes: { profile_id: id },
    });
  }, []);

  if (isLoading) {
    // return <CenterInPageSpinner />;
    return (
      <div className="my-5">
        {/* <CustomSpinner /> */}
        {Array(1)
          .fill(null)
          .map((_, index) => (
            <SkeletonProfile key={index} />
          ))}
      </div>
    );
  } else {
    const currentUserHasIncompleteInfo =
      (!currentUser?.locationCity ||
        !currentUser?.locationState ||
        !currentUser?.jobSpecilities ||
        !currentUser?.avgHourlyRate) &&
      currentUser?.type === UserType.STUDENT;
    return (
      <>
        {profileToView?.userID !== currentUser?.userID && location.state && (
          <SecondaryNav title={"Back to Job"} toPage={location.state} />
        )}
        <div className="container max-w-6xl flex flex-col m-auto gap-10">
            {currentUserHasIncompleteInfo && <Banner bannerMsg={studentProfileBannerMessage}/>}
          <ProfileCard user={profileToView} messageLog={messageLog} />
          <ReviewsCard
            user={profileToView}
            canLeaveReview={canLeaveReview}
            onReload={loadData}
          />
          {profileToView?.type === UserType.STUDENT && (
            <Qualifications user={profileToView} />
          )}
          <BadgesCard user={profileToView} userBadges={userBadges ?? []} />
          <Footer />
        </div>
      </>
    );
  }
}

export default Profile;
