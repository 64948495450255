import {
  CheckStripeExpressAccountStatusQuery,
  CreateStripeCheckoutSessionMutation,
  CreateStripeCheckoutSessionMutationVariables,
  CreateStripeExpressOnboardingLinkMutation,
  CreateStripeExpressUserAccountMutation,
  CreateStripeSubscriptionCheckoutMutation,
  GetStripeLoginLinkQuery,
  JobRateType,
  RemoveStripeAccountMutation,
  User,
  UserType,
} from "../API";
import GraphQLAPI from "@aws-amplify/api-graphql";
import { mutations, queries } from ".";
import { Auth } from "aws-amplify";

/// Data object for use with Checkout.
export interface StripeData {
  rows: StripeDataRow[];
  /// Final details, such as company name and commission.
  finalDetails: {
    organization: string;
    /// Commission percent, as a number, e.g. 15.0.
    commissionPercent: number;
  };
}
export interface StripeDataRow {
  jobTitle: string;
  jobDescription: string;
  rateType: JobRateType;
  jobCompensation: number;
}

/// Gets the Stripe Express Dashboard login link for a user.
/// This only applies to Students.
export async function getStripeLoginLink(user: User | null): Promise<string | null> {
  if (!user || user.type !== UserType.STUDENT || !user.stripeAccountID) {
    return null;
  }

  const currentUser = await Auth.currentUserInfo();
  if (!currentUser) {
    return null;
  }

  try {
    const response = (await GraphQLAPI.graphql({
      query: queries.getStripeLoginLink,
      variables: { accountId: user.stripeAccountID },
    })) as { data: GetStripeLoginLinkQuery };
    if (response) {
      const data = JSON.parse(response.data?.getStripeLoginLink ?? "{}");
      if (data.status === false) {
        return null;
      }
      return data?.url;
    }
    return null;
  } catch (err) {
    console.error("Error fetching stripe login link:", err);
    return null;
  }
}

/// Creates a Stripe Subscription Checkout Session for a Job Poster (No Students).
/// @param user: The Job Poster sending the payment.
/// @param subTerm: The subscription term: "MONTHLY" or "YEARLY".
export async function createStripeSubscriptionCheckout(
  user: User | null,
  subTerm: string
): Promise<string | null> {
  if (!user || user.type !== UserType.POSTER) {
    return null;
  }

  const currentUser = await Auth.currentUserInfo();
  if (!currentUser) {
    return null;
  }

  try {
    const response = (await GraphQLAPI.graphql({
      query: mutations.createStripeSubscriptionCheckout,
      variables: { userId: user.userID, subTerm: subTerm },
    })) as { data: CreateStripeSubscriptionCheckoutMutation };
    if (response) {
      const data = JSON.parse(response.data?.createStripeSubscriptionCheckout ?? "{}");
      if (data.status === false) {
        return null;
      }
      return data?.url;
    }
    return null;
  } catch (err) {
    console.error("Error Subscription Checkout: ", err);
    return null;
  }
}

/// Creates a Stripe Checkout Session for a Job Poster, so they can pay a Student.
/// @param userId The ID of the Job Poster sending the payment.
/// @param amountDueStudent The amount the Student will receive. This must be in pennies.
/// @param studentStripeAccountId The Stripe Account ID of the Student receiving the payment.
/// @param jobId The ID of the Job the payment is for.
/// Note: The Colledge fee is calculated on the server. Even though the fee is displayed and
/// calculated on the client to present to the user, to avoid issues with client side manipulation,
/// the fee is calculated on the server again and sent to Stripe.
export async function createStripeCheckoutSession(
  userId: string,
  amountDueStudent: number, // As pennies!, e.g. 12.00 =  1200.
  studentStripeAccountId: string,
  jobId: string,
  couponCode:string,
): Promise<string | null> {
  if (!studentStripeAccountId || !jobId) {
    return null;
  }

  const currentUser = await Auth.currentUserInfo();
  if (!currentUser) {
    return null;
  }

  try {
    const input = {
      userID: userId,
      amountDueStudent: amountDueStudent,
      studentStripeAccountId: studentStripeAccountId,
      jobId: jobId,
      couponCode:couponCode,
    } as CreateStripeCheckoutSessionMutationVariables;

    const response = (await GraphQLAPI.graphql({
      query: mutations.createStripeCheckoutSession,
      variables: input,
    })) as { data: CreateStripeCheckoutSessionMutation };
    if (response) {
      const data = JSON.parse(response.data?.createStripeCheckoutSession ?? "{}");
      if (data.status === false) {
        return null;
      }
      return data?.url;
    }
    return null;
  } catch (err) {
    console.error("Error Payout Checkout: ", err);
    return null;
  }
}

/// Creates a Stripe Express Account for the Student (No Poster).
export async function createStripeExpressUserAccount(user: User | null): Promise<any | null> {
  if (!user || user.type !== UserType.STUDENT) {
    return null;
  }

  const currentUser = await Auth.currentUserInfo();
  if (!currentUser) {
    return null;
  }

  try {
    const response = (await GraphQLAPI.graphql({
      query: mutations.createStripeExpressUserAccount,
      variables: { userId: user.userID, userEmail: user.email },
    })) as { data: CreateStripeExpressUserAccountMutation };
    if (response) {
      const data = JSON.parse(response.data?.createStripeExpressUserAccount ?? "{}");
      if (data.status === false) {
        return null;
      }
      return data;
    }
    return null;
  } catch (err) {
    console.error("Error Express Account: ", err);
    return null;
  }
}

/// Creates a Stripe Express Onboarding Link for the Student (No Poster).
export async function createStripeExpressOnboardingLink(user: User | null): Promise<any | null> {
  if (!user || user.type !== UserType.STUDENT) {
    return null;
  }

  const currentUser = await Auth.currentUserInfo();
  if (!currentUser) {
    return null;
  }

  try {
    const response = (await GraphQLAPI.graphql({
      query: mutations.createStripeExpressOnboardingLink,
      variables: { userId: user.userID, stripeAccountID: user.stripeAccountID },
    })) as { data: CreateStripeExpressOnboardingLinkMutation };
    if (response) {
      const data = JSON.parse(response.data?.createStripeExpressOnboardingLink ?? "{}");
      if (data.status === false) {
        return null;
      }
      return data.url;
    }
    return null;
  } catch (err) {
    console.error("Error Express Onboarding: ", err);
    return null;
  }
}

/// Creates a Stripe Express Account for the Student (No Poster).
export async function checkStripeExpressAccountStatus(user: User | null): Promise<boolean> {
  if (!user?.stripeAccountID || user.type !== UserType.STUDENT) {
    return false;
  }

  const currentUser = await Auth.currentUserInfo();
  if (!currentUser) {
    return false;
  }

  try {
    const response = (await GraphQLAPI.graphql({
      query: queries.checkStripeExpressAccountStatus,
      variables: { stripeAccountID: user.stripeAccountID },
    })) as { data: CheckStripeExpressAccountStatusQuery };
    console.log("response: ", response);
    if (response) {
      const data = JSON.parse(response.data?.checkStripeExpressAccountStatus ?? "{}");
      if (data.status === false) {
        return false;
      }
      // So long as there's nothing due, the account is able to receive payments.
      return data.currently_due.length === 0;
    }
    return false;
  } catch (err) {
    console.error("Error Checking Express Account: ", err);
    return false;
  }
}

/// removeStripeExpressAccount a Stripe Express Account for the Student (No Poster).
export async function removeStripeExpressAccount(user: User | null): Promise<boolean> {
  if (!user?.stripeAccountID || user.type !== UserType.STUDENT) {
    return false;
  }

  const currentUser = await Auth.currentUserInfo();
  if (!currentUser) {
    return false;
  }

  try {
    const response = (await GraphQLAPI.graphql({
      query: mutations.removeStripeAccount,
      variables: { stripeAccountID: user.stripeAccountID },
    })) as { data: RemoveStripeAccountMutation };
    if (response) {
      const data = JSON.parse(response.data?.removeStripeAccount ?? "{}");
      if (data.status === false) {
        return false;
      }
      // So long as there's nothing due, the account is able to receive payments.
      return data.currently_due.length === 0;
    }
    return false;
  } catch (err) {
    console.error("Error Checking Express Account: ", err);
    return false;
  }
}