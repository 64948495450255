export const checkrVerificationMessage =
  "Please complete your background check to apply for jobs. Look for an email from Checkr";
  // "This action is disabled due to Checkr verification has not been completed!";

export const stripeSetupAccountMessage = 
"Please setup your Stripe account before applying to jobs. So you can receive payment";
export const HireStudentMessage = 
"You must login with Poster account for Hire a student";

export const PosterCheckerMessage = "Please complete your background check before posting a job";

// banner msg for student when is profile is not completed
export const studentProfileBannerMessage =
  "Please set the location, specialties and average hourly rate for be able to list your account in the search results";