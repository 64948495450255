import React from "react";
import { SelectField, Button } from "@aws-amplify/ui-react";
import { Dialog } from "@headlessui/react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as dataProvider from "../../graphql/DataProvider";
import { UpdateUserInput, UserType } from "../../API";
import { useUserContext } from "../../helpers/UserContext";
import { currentUserIsAdmin } from "../../helpers/AuthStatus";

function SocialLoginPrompt() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [userType, setUserType] = React.useState(UserType.STUDENT);
  const [userLocation, setUserLocation] = React.useState("");
  const { loading: userLoading, currentUser, updateUser } = useUserContext();

  React.useEffect(() => {
    const showSocialLoginPrompt = async () => {
      if (!userLoading) {
        let isCurrentUserAdmin;
        try {
          isCurrentUserAdmin = await currentUserIsAdmin();
        } catch (error) {
          console.log("Do not break the application");
        }
        // show the Social Login prompt, if
        // - user is not an admin
        // - user is approved (currentUser will be available only then)
        // - user type is not set or it is equal to UNKNOWN
        // - workLocation is not set
        if (!isCurrentUserAdmin) {
          if (
            currentUser &&
            (!currentUser?.type ||
              currentUser?.type === "UNKNOWN" ||
              !currentUser?.workLocation)
          ) {
            setOpen(true);
          } else {
            setOpen(false);
          }
        } else {
          setOpen(false);
        }
      }
    };
    showSocialLoginPrompt();
  }, [userLoading, currentUser]);

  const onSubmit = async (event: any) => {
    event?.preventDefault();
    const userData: UpdateUserInput = {
      userID: currentUser?.userID!,
      type: userType,
      workLocation: userLocation,
    };
    setLoading(true);
    try {
      const response = await dataProvider.updateUserProfile(userData);
      if (response) {
        toast.success("Successfully updated your profile.", {
          toastId: "saveUserInfo",
        });
      } else {
        toast.error("Could not update your profile!", {
          toastId: "saveUserInfoError",
        });
      }
      updateUser();
      setOpen(false);
      navigate(`/profile/${currentUser?.userID!}`);
    } catch (error) {
      console.log(error, "ERROR while updating");
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {};

  return (
    <Dialog open={open} onClose={handleClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <form onSubmit={onSubmit}>
          <Dialog.Panel className="max-w-xl rounded-md bg-white p-8 lg:p-10 w-11/12 h-auto flex flex-col">
            <Dialog.Title className="text-gray-900 pb-4 text-2xl font-black">
              Provide details
            </Dialog.Title>
            <Dialog.Description className="text-slate-600 pb-8 sm:h-[400px] lg:h-[600px] overflow-y-scroll">
              As you have been logged in using social account, we need you to
              provide your user type and location.
              <div className="mt-8">
                <div className="mb-4">
                  <SelectField
                    name="userType"
                    label={
                      <span className="font-semibold">
                        How would you describe yourself
                      </span>
                    }
                    errorMessage="Please select an option."
                    labelHidden={false}
                    value={userType}
                    onChange={(event) => {
                      setUserType(event.target.value as UserType);
                    }}
                  >
                    {/* Values must match UserType.STUDENT / UserType.POSTER */}
                    <option value="STUDENT">
                      I'm a Student looking for a job.
                    </option>
                    <option value="POSTER">I want to hire a Student</option>
                  </SelectField>
                </div>
                <div>
                  <SelectField
                    name="userLocation"
                    label={
                      <span className="font-semibold">
                        Where do you plan to work/post jobs?
                      </span>
                    }
                    errorMessage="Please select an option."
                    hasError={false}
                    labelHidden={false}
                    value={userLocation}
                    onChange={(event) => {
                      setUserLocation(event.target.value);
                    }}
                  >
                    <option value="">Select your location...</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </SelectField>
                </div>
              </div>
            </Dialog.Description>
            <Button type="submit" variation="primary" isLoading={loading}>
              Submit
            </Button>
          </Dialog.Panel>
        </form>
      </div>
    </Dialog>
  );
}

export default SocialLoginPrompt;
