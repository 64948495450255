/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://glgfmbyd45.execute-api.us-east-2.amazonaws.com/main",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://olezeaulxffrloxfftkvkn2pki.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-vzm3wo432vcb3nft2dpzl4vvgu",
    "aws_cognito_identity_pool_id": "us-east-2:fa585e34-853f-41a0-92f2-7aeb693bd55d",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_sNL5RaSKR",
    "aws_user_pools_web_client_id": "7hitb4h40v3oc2e9q3rbtas6tc",
    "oauth": {
        "domain": "colledge862ecf3c-862ecf3c-main.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://dev.colledge.us/",
        "redirectSignOut": "http://localhost:3000/,https://dev.colledge.us/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "colledgeuserimages121631-main",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
