import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

/// An option in the menu.
export interface MenuButtonOption {
  /// Name of the option, e.g. "Edit".
  name: string;
  /// Action to take when user clicks.
  onClick: (value?: any) => void;
}

export interface MenuButtonProps {
  /// Name, e.g. "Options"
  menuName: string;
  /// Array of options to display.
  options: MenuButtonOption[];
}

/// Provides a menu with options a user can choose from.
/// Currently used in the Admin dashboard.
export default function MenuButton({ menuName, options }: MenuButtonProps) {
  return (
    <Menu as="div" className="relative inline-block text-left overflow-visible">
      <div>
        <Menu.Button className="flex w-full text-left justify-center rounded-md bg-gray-100 rounded-full px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
          {menuName}
          <ChevronDownIcon className="ml-2 -mr-1 h-5 w-5 text-gray-700" aria-hidden="true" />
        </Menu.Button>
      </div>
      <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none z-50">
        {options.map((option, index) => (
          <div key={index} className="px-1 py-1 ">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-gray-100 text-black" : "bg-white text-gray-900"
                  } group flex w-full items-center text-sm`}
                  onClick={option.onClick}
                >
                  {option.name}
                </button>
              )}
            </Menu.Item>
          </div>
        ))}
      </Menu.Items>
    </Menu>
  );
}
