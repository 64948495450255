import { API, Auth } from "aws-amplify";
import * as dataProvider from "../graphql/DataProvider";
import {
  BackgroundCheckLog,
  DeleteUserInput,
  DeleteUserReviewMutation,
  GetBackgroundCheckLogQuery,
  StartBackgroundCheckMutation,
  User,
} from "../API";
import GraphQLAPI from "@aws-amplify/api-graphql";
import { queries, mutations } from ".";

/**
 * Functions for Admin users only.
 * These should be ACL'd, so if errors are thrown, check that your user is
 * added to the "Admins" group in the UserPool within the AWS Cognito console.
 * AWS Cognito console:
 * https://us-east-2.console.aws.amazon.com/cognito/v2/home?region=us-east-2
 */

const ADMIN_API_NAME = "AdminQueries";

/// Get a list of Cognito groups that the Cognito User belongs to.
/// @param userID The ID of the Cognito User. This is the same ID
/// from the DB User object userID field.
/// Current groups are: Admins, Approved_Users, Pending_Users.
export async function listGroupsForUser(userID: string): Promise<any> {
  const apiFunction = "/listGroupsForUser";
  const params = {
    queryStringParameters: {
      username: userID, // DB User's UserID.
    },
    headers: await headers(),
  };
  const response = await API.get(ADMIN_API_NAME, apiFunction, params);
  return response.Groups;
}

/// Adds a user to a Cognito group specificed.
/// @param userID The userID of the DB User object.
export async function addUserToGroup(userID: string, groupname: string): Promise<any> {
  const apiFunction = "/addUserToGroup";
  const params = {
    body: {
      username: userID, // DB User's UserID.
      groupname: groupname,
    },
    headers: await headers(),
  };
  const response = await API.post(ADMIN_API_NAME, apiFunction, params);
  return response;
}

/// Removes a user from the group specified.
export async function removeUserFromGroup(userID: string, groupname: string): Promise<any> {
  const apiFunction = "/removeUserFromGroup";
  const params = {
    body: {
      username: userID, // DB User's UserID.
      groupname: groupname,
    },
    headers: await headers(),
  };
  const response = await API.post(ADMIN_API_NAME, apiFunction, params);
  return response;
}

/// Creates the headers for AdminQueries.
async function headers() {
  return {
    "Content-Type": "application/json",
    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
  };
}

/// Begins the background check process for a user. This will re-trigger a new background
/// check even if the user had one previously. This is most useful when an invite expired or
/// there was an issue with the first report.
export async function startBackgroundCheckForUser(user: User | null): Promise<any | null> {
  if (!user) {
    return null;
  }

  try {
    const response = (await GraphQLAPI.graphql({
      query: mutations.startBackgroundCheck,
      variables: { userId: user.userID },
    })) as { data: StartBackgroundCheckMutation };
    if (response) {
      const data = JSON.parse(response.data?.startBackgroundCheck ?? "{}");
      if (data.statusCode !== 200) {
        return null;
      }
      return data;
    }
    return null;
  } catch (err) {
    console.error("startBackgroundCheckForUser Error: ", err);
    return null;
  }
}

/// Fetches a user's background check log.
export async function getUserBackgroundCheckLog(
  user: User | null
): Promise<BackgroundCheckLog | null> {
  if (!user) return null;
  try {
    const result = (await GraphQLAPI.graphql({
      query: queries.getBackgroundCheckLog,
      variables: { userID: user.userID, candidateID: user.candidateID },
    })) as { data: GetBackgroundCheckLogQuery };
    return result.data?.getBackgroundCheckLog as BackgroundCheckLog;
  } catch (err) {
    return null;
  }
}

/// Creates job categories from the JSON array provided.
/// Note: id is optional. It will be auto-created if not provided.
/// Also note: This should be in alphabetical order.
export async function createJobCategories() {
  const categories = [
    {
      id: "199e2592-90c7-4b09-b1d7-9cfaa0c31123",
      name: "Babysitting",
    },
    {
      id: "0519bf96-de60-4a72-8623-c6f30a8a4573",
      name: "Culinary",
    },
    {
      id: "da73c4df-91b9-4091-a762-434c72df2dd6",
      name: "Elder Care",
    },
    {
      id: "880f78bf-23f3-4438-bdbb-2fbbb4e194b2",
      name: "Fashion",
    },
    {
      id: "a2db7854-b1e6-484c-b3d8-357d2fa0ff71",
      name: "Pet Care",
    },
    {
      id: "3e3c3199-fdf3-40f7-be29-88e195392969",
      name: "Tutor",
    },
    {
      id: "449e5e21-e5e7-4199-9509-f1a0318c0ca8",
      name: "Yard services",
    },
    {
      id: "7c4196af-2544-436c-a83b-8777180a5e17",
      name: "Other",
    },
  ];
  await dataProvider.createJobCategories(categories);
}


export async function disableUser(
  user: User | null,
) {
  if(!user) return null;
  const apiFunction = "/disableLoggedInUser";
  const params = {
    body: {
      username: user.userID, // DB User's UserID.
    },
    headers: await headers(),
  };
  const response = await API.post(ADMIN_API_NAME, apiFunction, params);
  return response;
}
export async function deactivateUsereview(user: User) {
  if (!user) return;
  const input: DeleteUserInput = {
    userID: user?.userID,
  }
  
  try {
    // Always delete the user's review
    
      await GraphQLAPI.graphql({
      query: mutations.removeUserDetails,
      variables:  input ,
    }) as { data: DeleteUserReviewMutation };

  
  } catch (err) {
    console.error("Error deleting user:", err);
  }
}
export async function deleteCognitoUser(
  user: User | null,
) {
  if(!user) return null;
  const apiFunction = "/deleteLoggedInUser";
  const params = {
    body: {
      username: user.userID, // DB User's UserID.
    },
    headers: await headers(),
  };
  const response = await API.post(ADMIN_API_NAME, apiFunction, params);
  return response;
}