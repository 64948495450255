import Footer from "../components/nav/footer";
import Search, { CategoryOption } from "../components/jobs/search";
import JobCards from "../components/jobs/job_cards";
import * as dataProvider from "../graphql/DataProvider";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Loader } from "@googlemaps/js-api-loader";
import { CenteredSpinner } from "../components/loading_indicator";
import {
  LightBulbIcon,
  MapPinIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import PrimaryButton from "../components/buttons/primary_button";
import { Job, JobRateType } from "../API";
import DropdownSelector, {
  DropDownSelectorOption,
} from "../components/dropdown_selector";
import { logEvent } from "../helpers/Analytics";
import SkeletonJobCards from "../components/Skeleton/SkeletonJobCards";
import { GOOGLE_MAPS_API_KEY } from "../utils/constants";
import JobCategoriesSelect from "../components/common/job_categories_select";
import { parseLocation } from "../helpers/Utilities";
import { useDebounce } from "use-debounce";
import { Button } from "flowbite-react";
import { HiOutlineArrowRight, HiOutlineArrowLeft } from "react-icons/hi";
import { Auth } from "aws-amplify";
// const ITEMS_PER_PAGE = 10;

function BrowseFE() {
  const scrollTargetRef = useRef<HTMLDivElement | null>(null);
  const categoryOption = useLocation().state as CategoryOption | null;
  const [jobs, setJobs] = useState<Job[]>([]);
  const [showjobs, setshowJobs] = useState<Job[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [filteredJobs, setFilteredJobs] = useState<Job[]>([]);
  const [isLoadingJobs, setLoadingJobs] = useState<boolean>(true);
  const [startIndex, setStartIndex] = useState<number>(0);
  const [selectedCategoryOption, setSelectedCategoryOption] = useState<
    CategoryOption | null | undefined
  >(categoryOption);
  /// The next token to use for pagination. If nil, then we can't move forward or
  /// are starting from the beginning.
  const [nextToken, setNextToken] = useState<string | null | undefined>(null);
  const [locatinInputValue, setLocationInputValue] = useState<string>("");

  /// City/State Autocomplete.
  const locationCityStateRef = useRef<HTMLInputElement | null>(null);
  const locationCoordinateLat = useRef<number | null>(null);
  const locationCoordinateLong = useRef<number | null>(null);
  const autoCompleteRef = useRef<google.maps.places.Autocomplete | undefined>();
  const [signedInUser, setsignedInUser] = useState();

  useEffect(() => {
    Auth.currentUserInfo().then((res) => {
      setsignedInUser(res);
    });
  }, []);

  console.log(signedInUser, "signedInUser");
  const options = {
    componentRestrictions: { country: "us" }, // US Only.
    fields: ["geometry"],
    strictBounds: false,
    types: ["(cities)"], // Only search for cities.
  };

  /// Loads the Google Maps API.
  function loadMapsAPI() {
    new Loader({
      apiKey: GOOGLE_MAPS_API_KEY, // Colledge Key.
      version: "weekly",
      libraries: ["places"],
    })
      .importLibrary("places")
      .then((places) => {
        autoCompleteRef.current = new places.Autocomplete(
          locationCityStateRef.current!,
          options
        );
        autoCompleteRef.current.addListener(
          "place_changed",
          getCoordinatesFromPlace
        );
      })
      .catch((error) => {
        console.error("Error loading Maps API: ", error);
      });
  }

  useEffect(() => {
    if (!autoCompleteRef.current) {
      loadMapsAPI();
    }
  });

  /// Handles autocompletion for the City/State field.
  function getCoordinatesFromPlace() {
    // Get the place details from the autocomplete object.
    const place = autoCompleteRef.current?.getPlace();
    // Get Lat/Long coordinates.
    if (place?.geometry?.location) {
      const lat = place?.geometry?.location.lat();
      const lng = place?.geometry?.location.lng();
      locationCoordinateLat.current = lat;
      locationCoordinateLong.current = lng;
      searchJobs();
    }
  }

  const categoryChanged = (
    selectedCategory: CategoryOption | undefined | null
  ) => {
    setSelectedCategoryOption(selectedCategory);
  };

  //** Function to calculate total number of pages
  const calculateTotalPages = () => {
    return Math.ceil(filteredJobs.length / ITEMS_PER_PAGE);
  };
  const totalItemsToShowOptions: DropDownSelectorOption[] = [
    { id: 1, name: "Show 10 per page" },
    { id: 2, name: "Show 20 per page" },
    { id: 3, name: "Show 30 per page" },
    { id: 4, name: "Show 40 per page" },
    { id: 5, name: "Show 50 per page" },
  ];
  const [totalItemToShow, setTotalItemToShow] =
    useState<DropDownSelectorOption>(totalItemsToShowOptions[0]);

  const paginationOptions: DropDownSelectorOption[] = [
    { id: 1, name: "Page 1 of 5" },
    { id: 2, name: "Page 2 of 5" },
    { id: 3, name: "Page 3 of 5" },
    { id: 4, name: "Page 4 of 5" },
    { id: 5, name: "Page 5 of 5" },
  ];
  const [selectedPagination, setSelectedPagination] =
    useState<DropDownSelectorOption>(paginationOptions[0]);

  //** Function to Update pagination options dynamically
  const updatePaginationOptions = useCallback(() => {
    const totalPages = calculateTotalPages();
    const newPaginationOptions = Array.from(
      { length: totalPages },
      (_, index) => ({
        id: index + 1,
        name: `Page ${index + 1} of ${totalPages}`,
      })
    );
    return newPaginationOptions;
  }, [calculateTotalPages]);

  // Update pagination options on totalItemToShow change
  useEffect(() => {
    const newPaginationOptions = updatePaginationOptions();
    setSelectedPagination(newPaginationOptions[0]);
  }, [totalItemToShow.id, filteredJobs]);

  // Handle page change
  const handlePageChangeTop = (option: DropDownSelectorOption) => {
    // window.scrollTo({ top: 0, behavior: "smooth" });
    setSelectedPagination(option);
    const startIndex = (Number(option.id) - 1) * ITEMS_PER_PAGE;
    setStartIndex(startIndex);
  };
  // Handle page change
  const handlePageChange = (option: DropDownSelectorOption) => {
    scrollTargetRef.current?.scrollIntoView({ behavior: "smooth" });
    setSelectedPagination(option);
    const startIndex = (Number(option.id) - 1) * ITEMS_PER_PAGE;
    setStartIndex(startIndex);
  };

  // Get saved data from sessionStorage
  const sortOptions: DropDownSelectorOption[] = [
    { id: "ANY", name: "Featured Jobs" },
    { id: "Price_DESC", name: "Price: High to Low" },
    { id: "Price_ASC", name: "Price: Low to High" },
    { id: "Date_ASC", name: "Newest First" },
    { id: "Date_DESC", name: "Oldest First" },
  ];
  const [selectedSortOption, setSelectedSortOption] =
    useState<DropDownSelectorOption>(sortOptions[3]);

  /// Rate sorting options.
  const rateSortOptions: DropDownSelectorOption[] = [
    { id: "", name: "All Rates" },
    { id: JobRateType.HOURLY, name: "Hourly" },
    { id: JobRateType.FLATRATE, name: "Fixed Price" },
  ];
  const [selectedRateSortOption, setSelectedRateSortOption] =
    useState<DropDownSelectorOption>(rateSortOptions[0]);

  /// Distance sorting options.
  const distanceSortOptions: DropDownSelectorOption[] = [
    { id: 3, name: "3 miles" },
    { id: 5, name: "5 miles" },
    { id: 10, name: "10 miles" },
    { id: 15, name: "15 miles" },
    { id: 20, name: "20 miles" },
    { id: 30, name: "30 miles" },
    { id: 40, name: "40 miles" },
  ];
  const [selectedDistanceSortOption, setSelectedDistanceSortOption] =
    useState<DropDownSelectorOption>(distanceSortOptions[2]);

  /// Load saved sort option from session storage. This is so the filters
  /// persist across Job navigations.
  useEffect(() => {
    const sortOptionStored = sessionStorage.getItem("sortOptionID");
    if (sortOptionStored) {
      const sortOption = sortOptions.find(
        (option) => option.id === sortOptionStored
      );
      if (sortOption) {
        setSelectedSortOption(sortOption);
      }
    }

    const rateOptionStored = sessionStorage.getItem("rateOptionID");
    if (rateOptionStored) {
      const rateOption = rateSortOptions.find(
        (option) => option.id === rateOptionStored
      );
      if (rateOption) {
        setSelectedRateSortOption(rateOption);
      }
    }

    const distanceOptionStored = sessionStorage.getItem("distanceOptionID");
    if (distanceOptionStored) {
      const distanceOption = distanceSortOptions.find(
        (option) => option.id === parseInt(distanceOptionStored)
      );
      if (distanceOption) {
        setSelectedDistanceSortOption(distanceOption);
      }
    }
  }, []);

  const handleNextButtonClick = () => {
    if (startIndex < jobs.length) {
    } else {
      // No more jobs to display, handle accordingly (e.g., disable the next button)
    }
  };
  /// When user selects a new category or distance option, perform a new search.
  useEffect(() => {
    searchJobs();
  }, [selectedCategoryOption, selectedDistanceSortOption]);
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    logEvent("page_view_browse", {
      queryParams: queryParams.entries(),
      referrer: document.referrer,
    });
  }, [selectedSortOption, selectedRateSortOption]);
  /// When the jobs array changes, process job filters.
  useEffect(() => {
    processJobFilters();
  }, [jobs, selectedSortOption, selectedRateSortOption]);

  /// Processes the job filters for sorting based on user selections.
  function processJobFilters() {
    // setLoadingJobs(true);
    let sortedJobs = jobs;
    if (selectedRateSortOption.id !== "") {
      sortedJobs = sortedJobs.filter(
        (job: Job) => job.rateType === selectedRateSortOption.id
      );
    }

    // Sort, if needed.
    if (selectedSortOption.id !== "ANY") {
      // Price.
      if (selectedSortOption.id === "Price_ASC") {
        sortedJobs = sortedJobs
          .sort((jobA: Job, jobB: Job) => {
            return jobA.compensation! > jobB.compensation! ? -1 : 1;
          })
          .reverse();
      } else if (selectedSortOption.id === "Price_DESC") {
        sortedJobs = sortedJobs.sort((jobA: Job, jobB: Job) => {
          return jobA.compensation! > jobB.compensation! ? -1 : 1;
        });
      }

      // Date.
      if (selectedSortOption.id === "Date_ASC") {
        sortedJobs = sortedJobs.sort((jobA: Job, jobB: Job) => {
          return jobA.createdAt! > jobB.createdAt! ? -1 : 1;
        });
      } else if (selectedSortOption.id === "Date_DESC") {
        sortedJobs = sortedJobs
          .sort((jobA: Job, jobB: Job) => {
            return jobA.createdAt! > jobB.createdAt! ? -1 : 1;
          })
          .reverse();
      }
    }
    // setFilteredJobs([...sortedJobs]);
    const sortedJobsWithIndex = sortedJobs.map((job, index) => ({
      ...job,
      indexKey: index + 1,
    }));
    // setFilteredJobs(sortedJobsWithIndex);
    if (
      sortedJobsWithIndex &&
      sortedJobsWithIndex.length > 0 &&
      selectedCategoryOption &&
      selectedCategoryOption.id
    ) {
      const tempFilteredJobs = sortedJobsWithIndex.filter(
        (job) => job.category.id === selectedCategoryOption.id
      );
      setFilteredJobs(tempFilteredJobs);
    } else {
      setFilteredJobs(sortedJobsWithIndex);
    }

    setStartIndex(0);
    setSelectedPagination(updatePaginationOptions()[0]);
  }

  /// Resets the city/state field.
  function resetLocationField() {
    locationCityStateRef.current!.blur();
    locationCityStateRef.current!.value = "";
    locationCoordinateLat.current = null;
    locationCoordinateLong.current = null;
  }

  /// Searches for jobs using the city/state field.
  function setSearchCityState() {
    locationCityStateRef.current!.disabled = false;
    resetLocationField();
    searchJobs();
  }
  const searchJobs = async () => {
    let token = nextToken;
    setStartIndex(0);
    setLoadingJobs(true);
    let address = parseLocation(locationCityStateRef.current!?.value);
    let location = {
      city: address?.city || locationCityStateRef.current!.value,
      state: address?.state || locationCityStateRef.current!.value,
    };

    // let geoHashJobs = await dataProvider.getAvailableJobsAroundLocation(
    //   selectedCategoryOption?.id,
    //   locationCoordinateLat.current,
    //   locationCoordinateLong.current,
    //   selectedDistanceSortOption.id as number, // Radius.
    //   token,
    //   1000000,
    //   location
    // );
    let geoHashJobs;
    if (!geoHashJobs) {
      geoHashJobs = await dataProvider.getAvailableJobsAroundLocation(
        selectedCategoryOption?.id,
        locationCoordinateLat.current,
        locationCoordinateLong.current,
        selectedDistanceSortOption.id as number, // Radius.
        token,
        1000000,
        location
      );
    }

    if (!geoHashJobs || !geoHashJobs.items || geoHashJobs.items.length === 0) {
      // If geoHash search returns empty, try location-based search
      let locationJobs = await dataProvider.getAvailableJobsAroundLocation(
        selectedCategoryOption?.id,
        locationCoordinateLat.current,
        locationCoordinateLong.current,
        selectedDistanceSortOption.id as number, // Radius.
        token,
        30,
        location
      );
      if (locationJobs && locationJobs.items) {
        const jobResults = locationJobs.items as Job[];
        setJobs(jobResults);
        setLoadingJobs(false);
        setNextToken(locationJobs.nextToken);
        processJobFilters();
        return;
      }
    }
    if (geoHashJobs?.items) {
      const jobResults = geoHashJobs.items as Job[];
      setJobs(jobResults);
    } else {
      setJobs([]);
    }
    setLoadingJobs(false);
    setNextToken(geoHashJobs?.nextToken);
    processJobFilters();
  };
  console.log(filteredJobs, "filteredJobs");
  useEffect(() => {
    const tempJobs = [...filteredJobs];
    const tempPosters = [];
    for (let i = 0; i < tempJobs?.length; i++) {
      const element = tempJobs[i]?.poster;
      tempPosters.push(element);
    }

    const isLocalOrDev =
      window.location.hostname === "localhost" ||
      window.location.hostname === "dev.colledge.us";

    const updatedStudents = tempPosters.filter((poster) => {
      const email = poster?.email || "";

      // const signedInUserEmail = signedInUser?.attributes?.email||"";

      if (!signedInUser) {
        // Pre-Login: hide all job postings with findica.io email ids if domain is not localhost or dev.colledge.us
        return !email.includes("findica.io");
      }
      // if (signedInUser && !isLocalOrDev) {
      //   // Post-Login: if login users email is colledge.us, show all students except findica.io
      //   return !email.includes("findica.io");
      // }
      // if (signedInUser && !isLocalOrDev) {
      //   // Post-Login: if login users email is findica.io, show students with findica.io and colledge.us emails along with all others
      //   return email.includes("findica.io") || email.includes("colledge.us");
      // }
      return true;
    });
    console.log(updatedStudents, "updatedStudents...");
    // setStudents(updatedStudents);

    console.log(tempPosters, "tempPosters");
  }, [filteredJobs?.length, signedInUser]);

  console.log(filteredJobs, "filteredJobs");

  function getSearchResultsTitle(): string {
    if (
      !selectedCategoryOption &&
      !locationCoordinateLong.current &&
      !locationCoordinateLat.current
    ) {
      return `${isLoadingJobs ? "-" : filteredJobs.length} ${
        filteredJobs.length === 1 ? "Job" : "Jobs"
      } Postings`;
    }
    if (selectedCategoryOption?.id === "") {
      return `${isLoadingJobs ? "-" : filteredJobs.length} ${
        filteredJobs.length === 1 ? "Job" : "Jobs"
      } in all categories`;
    }
    if (selectedCategoryOption?.id?.length) {
      return `${isLoadingJobs ? "-" : filteredJobs.length}
        ${selectedCategoryOption?.name} ${
        filteredJobs.length === 1 ? "Job" : "Jobs"
      }`;
    }
    // Default.
    return `${isLoadingJobs ? "-" : filteredJobs.length} ${
      filteredJobs.length === 1 ? "Job" : "Jobs"
    } Postings`;
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    logEvent("page_view_browse", {
      queryParams: queryParams.entries(),
      referrer: document.referrer,
    });
  }, []);

  const ITEMS_PER_PAGE_OPTIONS: {
    [key: string]: number;
  } = {
    "1": 10,
    "2": 20,
    "3": 30,
    "4": 40,
    "5": 50,
  };
  const ITEMS_PER_PAGE = ITEMS_PER_PAGE_OPTIONS[totalItemToShow.id as string];

  const endIndex = startIndex + ITEMS_PER_PAGE;

  const jobsToDisplay = filteredJobs.slice(startIndex, endIndex);

  const handleNextPage = () => {
    scrollTargetRef.current?.scrollIntoView({ behavior: "smooth" });

    const newStartIndex = startIndex + ITEMS_PER_PAGE;
    if (newStartIndex < filteredJobs.length) {
      setStartIndex(newStartIndex);
      const newPageNumber = Math.ceil(newStartIndex / ITEMS_PER_PAGE) + 1;
      setSelectedPagination({
        id: newPageNumber,
        name: `Page ${newPageNumber} of ${calculateTotalPages()}`,
      });
    }
  };

  const handlePrevPage = () => {
    scrollTargetRef.current?.scrollIntoView({ behavior: "smooth" });

    const newStartIndex = startIndex - ITEMS_PER_PAGE;
    if (newStartIndex >= 0) {
      setStartIndex(newStartIndex);
      const newPageNumber = Math.ceil(newStartIndex / ITEMS_PER_PAGE) + 1;
      setSelectedPagination({
        id: newPageNumber,
        name: `Page ${newPageNumber} of ${calculateTotalPages()}`,
      });
    }
  };

  const handleKeyCapture = (e: any) => {
    if (e.key === "Enter") {
      searchJobs();
    }
  };
  const loadmoreDisbale = endIndex >= filteredJobs.length ? true : false;

  return (
    <>
      <div className="flex-row container m-auto justify-center p-4 lg:pt-8 max-w-7xl">
        <div className="w-auto h-100 pt-10 py-10 lg:py-40 rounded-xl bg-[url('../src/assets/Hand.webp')] bg-no-repeat bg-cover">
          <h1 className="colledge mt-10 mb-10 text-white white">
            FIND YOUR NEXT GIG
          </h1>
          <div
            ref={scrollTargetRef}
            className="flex flex-col w-10/12 m-auto relative gap-4"
          >
            <div className="w-full lg:w-8/12 relative lg:absolute">
              <JobCategoriesSelect
                defaultOption={categoryOption}
                onSearch={categoryChanged}
              />
            </div>
            <div className="w-full lg:w-4/12 p-0 lg:pl-4 relative lg:absolute right-0">
              <div className="relative p-4 flex h-[64px] text-left w-full items-center bg-gray-50 rounded-lg ring-1 ring-black/5">
                <MapPinIcon className="h-6 w-6 text-gray-500" />
                <div className="flex flex-row w-full items-center">
                  <input
                    ref={locationCityStateRef}
                    id="cityState"
                    type="text"
                    placeholder="Enter City/State"
                    onKeyDownCapture={handleKeyCapture}
                    onKeyDown={(e) => e.stopPropagation()} // Prevent conflicts with Headless UI keyboard navigation.
                    className="border-0 relative w-full text-gray-900 text-sm leading-6 bg-none GothamBook focus:border-transparent focus:ring-0 bg-gray-50 ring:none shadow:none"
                  />
                  {locationCityStateRef.current?.value &&
                    locationCityStateRef.current?.value.length > 0 && (
                      <XCircleIcon
                        className="h-6 w-6 text-gray-500"
                        onClick={() => setSearchCityState()}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span>&nbsp;&nbsp;</span>
      <div></div>
      <div className="flex flex-col lg:flex-row gap-3 ml-4">
        <div className="w-full md:max-w-11/12 px-8 md:px-4 mx-auto my-0">
          <div className="flex flex-row flex-wrap w-full justify-between">
            <div className="flex">
              <h2 className="text-xl text-gray-800 font-bold mx-1/4 py-4 ">
                {getSearchResultsTitle()}
              </h2>
            </div>
            {!isLoadingJobs && jobsToDisplay?.length !== 0 && (
              <div className="flex flex-row flex-wrap mr-5 sm:ml-[-12px]">
                <div className="flex justify-center items-center sm:ml-3">
                  <Button outline onClick={handlePrevPage}>
                    <HiOutlineArrowLeft color="#0E7490" className="h-6 w-6" />
                  </Button>
                  <DropdownSelector
                    options={updatePaginationOptions()}
                    selectedOption={selectedPagination}
                    onChange={handlePageChangeTop}
                  />
                  <Button outline className="mx-3" onClick={handleNextPage}>
                    <HiOutlineArrowRight color="#0E7490" className="h-6 w-6" />
                  </Button>
                </div>

                <DropdownSelector
                  options={totalItemsToShowOptions}
                  selectedOption={totalItemToShow}
                  onChange={(option) => {
                    setTotalItemToShow(option);
                    setStartIndex(0);
                  }}
                />

                <DropdownSelector
                  options={sortOptions}
                  selectedOption={selectedSortOption}
                  onChange={(option) => {
                    sessionStorage.setItem("sortOptionID", option.id as string);
                    setSelectedSortOption(option);
                  }}
                />
                <DropdownSelector
                  options={rateSortOptions}
                  selectedOption={selectedRateSortOption}
                  onChange={(option) => {
                    sessionStorage.setItem("rateOptionID", option.id as string);
                    setSelectedRateSortOption(option);
                  }}
                />

                {locationCoordinateLat.current &&
                  locationCoordinateLong.current && (
                    <DropdownSelector
                      options={distanceSortOptions}
                      selectedOption={selectedDistanceSortOption}
                      onChange={(option) => {
                        if (option.id === 0) {
                          resetLocationField();
                        }
                        sessionStorage.setItem(
                          "distanceOptionID",
                          option.id as string
                        );
                        setSelectedDistanceSortOption(option);
                      }}
                    />
                  )}
              </div>
            )}
          </div>

          <div className="mt-5">
            {isLoadingJobs ? (
              <div className="my-5">
                {Array(5)
                  .fill(null)
                  .map((_, index) => (
                    <SkeletonJobCards key={index} />
                  ))}
              </div>
            ) : jobsToDisplay.length === 0 ? (
              <div className="text-gray-800 font-bold flex mx-1/4 mt-10 GothamBold">
                No Jobs Found.
              </div>
            ) : (
              jobsToDisplay.map((job, index) => (
                <>
                  <JobCards key={index} job={job} />
                </>
              ))
            )}
          </div>
        </div>
        <div className="hidden shadow-lg w-5/12 bg-gray-whie rounded-xl p-8 text-left h-auto self-start flex flex-col gap-2 mt-14">
          <LightBulbIcon className="w-14 h-14 p-4 bg-gray-50 text-gray-700 rounded-full" />
          <h2 className="text-gray-800 font-bold text-md">
            What do you mean by gig economy?{" "}
          </h2>
          <p className="text-gray-700">
            The gig economy is the fastest-growing segment of the labor market.
            It refers to a series of short-term engagements that provide more
            flexibility, choice, and control for both the student and the
            client.{" "}
          </p>
        </div>
      </div>
      <div className="flex justify-center items-center px-10 py-10">
        {/* {!isLoadingJobs && jobs.length > 0 && (
          <PrimaryButton
            btnText="Load More"
            disabled={isLoadingJobs || loadmoreDisbale}
            showLoading={isLoadingJobs}
            className="!bg-[teal] !hover:bg-[hoverTeal] flex flex-row justify-center text-white font-bold py-3 px-8 rounded-full w-auto h-auto disabled:opacity-50"
            onClick={handleNextPage}
          />
        )} */}
        {!isLoadingJobs && jobsToDisplay?.length !== 0 && (
          <>
            <Button outline onClick={handlePrevPage}>
              <HiOutlineArrowLeft color="#0E7490" className="h-6 w-6" />
            </Button>
            <DropdownSelector
              options={updatePaginationOptions()}
              selectedOption={selectedPagination}
              onChange={handlePageChange}
            />
            <Button outline className="mx-3" onClick={handleNextPage}>
              <HiOutlineArrowRight color="#0E7490" className="h-6 w-6" />
            </Button>
          </>
        )}
      </div>
      <span>&nbsp;&nbsp;</span>
      <Footer />
    </>
  );
}

export default BrowseFE;
