import {
  ChevronRightIcon,
  StarIcon,
  // UserCircleIcon,
} from "@heroicons/react/20/solid";
import { Job, JobApplication, JobStatus, User } from "../../API";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import * as dataProvider from "../../graphql/DataProvider";
import { Storage } from "aws-amplify";
import {
  averageRatingForUser,
  formattedName,
  formattedReviewText,
} from "../../helpers/Utilities";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { CustomSpinner } from "../loading_indicator";
import UserProfileImage from "../student/userProfileImage";
import JobCommunicationModal from "./job_communication";

interface ApplicantDetailsProps {
  application: JobApplication | null;
  showChevron: boolean;
  selectedApplication?: (application: JobApplication | null) => void | null;
  isHired?: boolean;
  /// State for managing breadcrumbs.
  state?: string;
  loading: boolean;
  job?: Job;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

/// When the "showChevron" is true, it will link to the user's profile page.
/// When false, it will show a radio button and not link anywhere.
function ApplicantDetails({
  application,
  showChevron,
  selectedApplication,
  isHired = false,
  state,
  job,
  loading,
  setLoading,
}: ApplicantDetailsProps) {
  const [applicant, setApplicant] = useState<User | null>(null);
  const [showContactModal, setShowContactModal] = useState<boolean>(false);
  const [userAvgRating, setUserAvgRating] = useState(0);
  useEffect(() => {
    setLoading(true);
    dataProvider
      .getUser(application?.userJobApplicationsUserID)
      .then((user) => {
        if (user) {
          setApplicant(user);
          setLoading(false);
          setUserAvgRating(averageRatingForUser(user));
        }
      });
  }, [applicant?.userID]);
  /// Function that returns the main body of the layout. This is so
  /// we can conditionally render the chevron and other attributes.
  function mainLayout(applicant: User | null, showChevron: boolean) {
    return (
      <div className="flex pr-6 justify-start justify-around w-full">
        <div className="flex flex-row items-center gap-4 w-full py-4">
          {showChevron === false && isHired === false && (
            <input
              type="radio"
              name="hiredApplicant"
              className="border-gray-400"
              value={applicant?.userID ?? ""}
              id="hiredApplicant"
              onClick={() => {
                if (selectedApplication) {
                  selectedApplication(application);
                }
              }}
            />
          )}
          <div
            className={`w-12 h-12  rounded-full overflow-hidden flex items-center justify-center border-[3.2px] ${
              applicant?.type === "POSTER"
                ? "border-purple-500"
                : applicant?.type === "STUDENT"
                ? "border-green-500"
                : "border-unknown"
            }`}
          >
            <div>
              <UserProfileImage
                photoKey={applicant?.photoKey}
                poster={applicant}
              />
            </div>
          </div>
          <div className="flex text-left flex-col gap-1 mr-4">
            <h3 className="text-gray-700 text-lg font-semibold hover:text-[teal]">
              {formattedName(applicant)}
            </h3>
            <div className="flex items-center gap-1">
              <div className="w-5 h-5 text-yellow-400">
                <StarIcon className="h-full m-auto"></StarIcon>
              </div>
              <h3 className="text-gray-700 text-md font-bold pt-1 GothamBook">
                {userAvgRating > 0 ? userAvgRating : ""}
              </h3>
              <h3 className="text-gray-700 text-md pt-1 GothamBook">
                {!loading && formattedReviewText(applicant)}
              </h3>
            </div>
            {isHired === true && (
              <span className="text-[teal] text-sm font-semibold flex gap-1 items-center -ml-2">
                <CheckCircleIcon className="w-10 h-10 p-2 text-[teal] -mr-1" />
                Hired
              </span>
            )}

            {isHired === true && job?.jobStatus !== JobStatus.CLOSED && (
              <button
                className="text-gray-50 bg-[teal] p-3 pl-6 pr-6 rounded-full"
                onClick={(e) => {
                  e.preventDefault();
                  setShowContactModal(true);
                }}
              >
                Message
              </button>
            )}
          </div>
        </div>
        {showChevron && (
          <div className="flex flex-row justify-evenly">
            <div className="w-10 h-10 bg-gray-100 rounded-full text-gray-600 self-center">
              <ChevronRightIcon className="w-6 h-full m-auto hover:text-[teal]"></ChevronRightIcon>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      {loading ? (
        <div className="my-5 text-center">
          {/* <CustomSpinner /> */}
          <div className="flex justify-start justify-around w-full">
            <div className="flex flex-row items-center gap-4 w-full ">
              <div className="w-16 h-16 rounded-full self-center skeleton-block "></div>
              <div className="flex text-left flex-col gap-1 mr-4">
                <h3 className="text-gray-700 text-lg font-semibold rounded w-36 h-6 skeleton-block"></h3>
                <div className="flex items-center gap-1">
                  <div className="w-5 h-5 text-yellow-400 skeleton-block rounded-full"></div>
                  <h3 className="text-gray-700 text-md rounded font-bold pt-1 skeleton-block  w-12 h-6"></h3>
                </div>
              </div>
            </div>
            <div className="pr-2 flex flex-row justify-evenly">
              <div className="w-10 h-10 skeleton-block rounded-full text-gray-600 self-center"></div>
            </div>
          </div>
        </div>
      ) : showChevron === true ? (
        <Link
          key={applicant?.userID}
          state={state}
          to={`/profile/${applicant?.userID}`}
          className="w-full self-start"
        >
          {mainLayout(applicant, showChevron)}
        </Link>
      ) : (
        <>{mainLayout(applicant, showChevron)}</>
      )}
      <JobCommunicationModal
        // messageLog={isHired}
        job={job}
        toUser={application?.applicant}
        isOpen={showContactModal}
        onClose={() => setShowContactModal(false)}
      />
    </>
  );
}

export default ApplicantDetails;
