interface IBanner {
  bannerMsg: string;
}
const Banner = ({ bannerMsg }: IBanner) => {
  return (
    <>
      <div className="w-auto h-[50px] bg-teal text-white flex items-center justify-center GothamBold">
        {bannerMsg}
      </div>
    </>
  );
};
export default Banner;
