function SkeletonHireModal() {
    return (
      <>
        <div className="text-left skeleton-pulse skeleton-loader flex flex-col lg:flex-row gap-8 !rounded-2xl p-10  pb-10 pt-10 mb-8 overflow-clip">
          <div className="w-full   md:w-10/12">
            <div className="mb-2 skeleton-block  h-10 w-full rounded max-w-[500px] text-xl font-bold leading-tight text-gray-700"></div>
            <p className="mb-4 mt-4 skeleton-block  h-20 w-full rounded-md font-med text-gray-600 pb-4"></p>
  
            <div className="flex  flex-row  w-auto h-auto md:items-center gap-4 items-start overflow-hidden">
              <div className="flex  min-w-[150px] skeleton-block  text-gray-700 p-3 rounded-full border-radius">
                <div className="mr-2    flex ">
                  <div className="w-6 h-6   mr-2"></div>
                  <p className="whitespace-nowrap  "></p>
                </div>
              </div>
              <div className="flex   min-w-[150px] skeleton-block    text-gray-700 p-3 rounded-full border-radius pr-6">
                <span className="w-6 h-6 mr-2  "></span>
                <p className="whitespace-nowrap  "></p>
              </div>
            </div>
          </div>
        </div>
        {/* bg-[#f8f8f8] */}
      </>
    );
  }
  
  export default SkeletonHireModal;
  