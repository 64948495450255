import SignUpForm from "../components/sign_up_form";

import SplashImage from "../assets/signin-splash.webp";
import SplashImageFallback from "../assets/signin-splash.png";
import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  AuthenticatedStatus,
  currentUserIsApproved,
} from "../helpers/AuthStatus";
import VerificationForm from "../components/verification_form";
import { useUserContext } from "../helpers/UserContext";
import { ImageWithFallback } from "../helpers/ImageWithFallback";
import { CenterInPageSpinner } from "../components/loading_indicator";
import { logEvent } from "../helpers/Analytics";

function Signin() {
  const { currentUser } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();
  const fromLocation = location.state?.from?.pathname || "/profile";
  const authenticated = AuthenticatedStatus();
  const [approved, setApproved] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    setIsLoading(true);
    // See if the user has been approved
    currentUserIsApproved().then((result) => {
      setApproved(result);
      setIsLoading(false);
      continueNavigation();
    });
  }, [authenticated]);

  useEffect(() => {
    continueNavigation();
  }, [approved, authenticated, currentUser?.userID]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    logEvent("page_view_signin", {
      queryParams: queryParams.entries(),
      referrer: document.referrer,
    });
  }, []);

  function continueNavigation() {
    if (authenticated && approved && currentUser?.userID) {
      if (fromLocation === "/profile") {
        const userId = currentUser?.userID;
        navigate(fromLocation + "/" + userId, { replace: true });
        return;
      }
      navigate(fromLocation, { replace: true });
    }
  }

  if (isLoading) {
    return <CenterInPageSpinner />;
  }

  return (
    <>
      <div className="flex max-h-screen h-full overflow-hidden">
        <div className="hidden md:block md:w-1/2 h-full">
          <ImageWithFallback
            src={SplashImage}
            fallback={SplashImageFallback}
            alt="Splash"
            className="object-cover h-full"
          />
        </div>
        <div className="flex-row h-auto sm-10/12 m-auto lg:w-1/2 self-center auth">
          {!authenticated && (
            <SignUpForm
              state={location.pathname === "/signin" ? "signIn" : "signUp"}
            />
          )}
          {!approved && authenticated && <VerificationForm />}
        </div>
      </div>
    </>
  );
}

export default Signin;
