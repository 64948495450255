import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { UserType, SubscriptionStatus } from "../API";
import { useUserContext } from "../helpers/UserContext";
import PrimaryButton from "../components/buttons/primary_button";
import * as stripeHandler from "../graphql/StripeHandler";
import { toast } from "react-toastify";
import { logEvent } from "../helpers/Analytics";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Spinner } from "flowbite-react";
import { CustomSpinner } from "../components/loading_indicator";
import * as dataProvider from "../graphql/DataProvider";

function Settings() {
  const { currentUser, loading } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);

  /// Onboard or show user their dashboard.
  async function handleStripeEvent(event: any) {
    event.preventDefault();
    if (!currentUser) return;
    setIsLoading(true);

    if (!currentUser?.stripeAccountID) {
      const Striperesponse = await stripeHandler.createStripeExpressUserAccount(
        currentUser
      );
      currentUser.stripeAccountID = Striperesponse?.id;
      await dataProvider.updateUserStripeAccountId(
        currentUser,
        Striperesponse.id
      );
    }
    const loginLink = await createExpressLoginLink();
    if (loginLink) {
      setIsLoading(false);
      window.location.href = loginLink;
    } else {
      // Forward to onboarding.
      const onboardingLink = await createExpressOnboardingLink();
      if (onboardingLink) {
        setIsLoading(false);
        window.location.href = onboardingLink;
      } else {
        setIsLoading(false);
        toast.error("There was an error loading your Stripe account.");
      }
    }
  }

  /// Gets the link to the user's onboarding link.
  async function createExpressOnboardingLink(): Promise<string | undefined> {
    if (currentUser?.type !== UserType.STUDENT) return;
    // if (!currentUser?.stripeAccountID) return;
    const response = await stripeHandler.createStripeExpressOnboardingLink(
      currentUser
    );
    return response;
  }

  /// Gets the link to the user's stripe express dashboard.
  /// This is only for students. Will return null if error or
  /// user has not completed onboarding.
  async function createExpressLoginLink() {
    if (currentUser?.type !== UserType.STUDENT) return;
    // if (!currentUser?.stripeAccountID) return;

    const respone = await stripeHandler.getStripeLoginLink(currentUser);
    return respone;
  }

  /// Set up a subscription for the user
  /// @param subTerm: The subscription term (MONTHLY or YEARLY).
  async function handleSubscription(subTerm: string) {
    if (isLoading) return;
    setIsLoading(true);
    const url = await stripeHandler.createStripeSubscriptionCheckout(
      currentUser,
      subTerm
    );
    if (url) {
      window.location.href = url;
    } else {
      toast.error("There was an error creating your subscription.");
    }
    setIsLoading(false);
  }

  /// Returns the customer portal URL.
  /// https://stripe.com/docs/customer-management/activate-no-code-customer-portal
  function getCustomerPortalURL(): string {
    switch (process.env.REACT_APP_ENV) {
      case "development":
        return "https://billing.stripe.com/p/login/test_14k7wa2WL5bf0YE8ww";
      case "production":
        return "https://billing.stripe.com/p/login/eVa4jLaIR6hLgSY000";
    }

    return "https://billing.stripe.com/p/login/eVa4jLaIR6hLgSY000";
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    logEvent("page_view_settings", {
      queryParams: queryParams.entries(),
      referrer: document.referrer,
    });
  }, []);

  /// The button that's presented to the user to subscribe monthly or annually.
  /// https://dashboard.stripe.com/products/prod_Oll2ZDUvjRlj4q
  function subscribeButton() {
    return (
      <Menu>
        <div>
          <Menu.Button className="bg-[teal] hover:bg-[hoverTeal] flex flex-row justify-center text-white font-bold py-3 px-8 rounded-full w-auto h-auto">
            {isLoading ? (
              <>
                <Spinner aria-label="Loading action..." />
                <span className="pl-3"> {"Subscribe"}</span>
              </>
            ) : (
              <span className=""> {"Subscribe"}</span>
            )}
            <ChevronDownIcon
              className="ml-2 -mr-1 h-5 w-5 text-white"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Menu.Items className="absolute mt-2 w-40 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none z-50">
          <div className="px-1 py-1 ">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`GothamBook ${
                    active ? "bg-gray-100 text-black" : "bg-white text-gray-900"
                  } group flex w-full items-center text-sm`}
                  onClick={() => {
                    handleSubscription("MONTHLY");
                    logEvent("settings_subscribe_button_clicked");
                  }}
                >
                  {"Monthly: $30"}
                </button>
              )}
            </Menu.Item>
          </div>
          <div className="px-1 py-1 ">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`GothamBook ${
                    active ? "bg-gray-100 text-black" : "bg-white text-gray-900"
                  } group flex w-full items-center text-sm`}
                  onClick={() => {
                    handleSubscription("YEARLY");
                    logEvent("settings_subscribe_button_clicked");
                  }}
                >
                  {"Yearly: $150"}
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Menu>
    );
  }
  return (
    <>
      <div className="bg-white text-left flex items-start p-6 lg:p-14 flex-col w-11/12 m-auto lg:w-full shadow-md rounded-xl h-auto">
        <h2 className="text-gray-700 text-3xl font-semibold mb-8">Payment</h2>
        {loading ? (
          <div className="flex items-center justify-center w-full my-5">
            <CustomSpinner />
          </div>
        ) : (
          <div className="flex w-full flex-col gap-8">
            {currentUser?.type === UserType.POSTER && (
              <div className="w-full justify-between items-center flex gap-2">
                <div className="gap-2 flex flex-col">
                  <h3 className="text-gray-700 text-md font-bold">
                    Colledge Subscription
                  </h3>
                  <p className="text-gray-700 text-md">
                    {currentUser?.subscription === SubscriptionStatus.ACTIVE
                      ? `Your subscription is ${currentUser?.subscription}.`
                      : "Subscribe and post unlimited jobs."}
                  </p>
                </div>
                <div className="w-[150px] ">
                  {currentUser?.subscription !== SubscriptionStatus.ACTIVE ? (
                    subscribeButton()
                  ) : (
                    <Link to={getCustomerPortalURL()}>
                      <PrimaryButton
                        btnText="Manage"
                        onClick={() => {
                          logEvent(
                            "settings_manage_subscription_button_clicked"
                          );
                        }}
                      />
                    </Link>
                  )}
                </div>
              </div>
            )}

            {currentUser?.type === UserType.STUDENT && (
              <div className="w-full justify-between items-center flex gap-2">
                <div className="gap-2 flex flex-col">
                  <h3 className="text-gray-700 text-md font-bold">
                    Payout Details
                  </h3>
                  <p className="text-gray-700 text-md">
                    Manage your stripe payout account.
                  </p>
                </div>
                <div className="w-[130px] ">
                  <PrimaryButton
                    btnText="Manage"
                    onClick={(e: any) => {
                      handleStripeEvent(e);
                      logEvent("settings_manage_payout_button_clicked");
                    }}
                    showLoading={isLoading}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default Settings;
