export function formatDate(
  date: Date | string,
  showTime?: boolean,
  hours12?: boolean
) {
  const options: Partial<Intl.DateTimeFormatOptions> = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  if (showTime) {
    options["hour"] = "2-digit";
    options["minute"] = "2-digit";
  }

  if (hours12) {
    options["hour12"] = true;
  }
  return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
}
