import { useEffect, useState } from "react";
import * as dataProvider from "../../graphql/DataProvider";
import { ApplicationStatus, Job, JobApplication, UserType } from "../../API";
import JobCards from "../jobs/job_cards";
import { useUserContext } from "../../helpers/UserContext";
import { CenterInPageSpinner } from "../loading_indicator";
import SkeletonMyJob from "../Skeleton/SkeletonMyJob";

/// Shows a student's job applications and their statuses.
export default function StudentJobsView() {
  const { currentUser } = useUserContext();
  const [openJobs, setOpenJobs] = useState<Job[]>([]);
  const [inProgressJobs, setInProgressJobs] = useState<Job[]>([]);
  const [completedJobs, setCompletedJobs] = useState<Job[]>([]);
  const [rejectedJobs, setRejectedJobs] = useState<Job[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (currentUser?.type !== UserType.STUDENT) {
      setIsLoading(false);
      return;
    }
    fetchData();
  }, [currentUser]);

  async function fetchData() {
    setIsLoading(true);
    const [, applications] = await dataProvider.getJobsAndApplicationsForUser(
      currentUser
    );
    if (!applications) {
      return;
    }
    const applied: JobApplication[] = applications.filter(
      (application: JobApplication) =>
        application.status === ApplicationStatus.PENDING
    );
    const inProgress: JobApplication[] = applications.filter(
      (application: JobApplication) =>
        application.status === ApplicationStatus.ACCEPTED
    );
    const completed: JobApplication[] = applications.filter(
      (application: JobApplication) =>
        application.status === ApplicationStatus.COMPLETE
    );
    const rejected: JobApplication[] = applications.filter(
      (application: JobApplication) =>
        application.status === ApplicationStatus.DENIED
    );

    setIsLoading(false);
    setOpenJobs(applied.map((application) => application?.job));
    setInProgressJobs(inProgress.map((application) => application?.job));
    setCompletedJobs(completed.map((application) => application?.job));
    setRejectedJobs(rejected.map((application) => application?.job));
  }

  if (isLoading) {
    // return <CenterInPageSpinner />;
    return (
      <div className="my-5">
        {/* <CustomSpinner /> */}
        {Array(1)
          .fill(null)
          .map((_, index) => (
            <SkeletonMyJob key={index} />
          ))}
      </div>
    );
  } else {
    return (
      <div className="bg-white flex gap-4 items-start p-6 lg:p-14 flex-col w-11/12 m-auto lg:w-full text-left rounded-xl h-auto">
        {openJobs.length > 0 ||
        inProgressJobs.length > 0 ||
        completedJobs.length > 0 ||
        rejectedJobs.length > 0 ? (
          <h2 className="text-gray-700 text-3xl font-semibold">My jobs</h2>
        ) : (
          <div className="flex flex-col gap-2 items-center pb-2">
            <p className="text-gray-400 text-md font-md">
              When you apply or complete a job. They will appear here.
            </p>
          </div>
        )}

        {openJobs.length > 0 && (
          <>
            <div className="flex gap-2 items-center pb-2 w-full">
              <div className="bg-green-600 w-3 h-3 rounded-full"></div>
              <p className="text-gray-700 text-sm font-bold GothamBold">Applied</p>
            </div>
            {openJobs?.map((job: Job, index: number) => (
              <div key={index} className="w-full">
                <JobCards key={index} job={job} />
              </div>
            ))}
          </>
        )}

        {inProgressJobs.length > 0 && (
          <>
            <div className="flex gap-2 items-center pb-2">
              <div className="bg-orange-500 w-3 h-3 rounded-full"></div>
              <p className="text-gray-700 text-sm font-bold GothamBold">In Progress</p>
            </div>
            {inProgressJobs?.map((job: Job, index: number) => (
              <div key={index} className="w-full">
                <JobCards key={index} job={job} />
              </div>
            ))}
          </>
        )}

        {completedJobs.length > 0 && (
          <>
            <div className="flex gap-2 items-center pb-2">
              <div className="bg-gray-600 w-3 h-3 rounded-full"></div>
              <p className="text-gray-700 text-lg text-sm font-bold GothamBold">
                Completed
              </p>
            </div>
            {completedJobs?.map((job: Job, index: number) => (
              <div key={index} className="w-full">
                <JobCards key={index} job={job} />
              </div>
            ))}
          </>
        )}

        {rejectedJobs.length > 0 && (
          <>
            <div className="flex gap-2 items-center pb-2">
              <div className="bg-gray-600 w-3 h-3 rounded-full"></div>
              <p className="text-gray-700 text-lg">Rejected</p>
            </div>
            {rejectedJobs?.map((job: Job, index: number) => (
              <div key={index} className="w-full">
                <JobCards key={index} job={job} />
              </div>
            ))}
          </>
        )}
      </div>
    );
  }
}
