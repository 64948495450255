import React from "react";
import { Modal } from "flowbite-react";
import PrimaryButton from "../buttons/primary_button";
import SecondaryButton from "../buttons/secondary_button";
import * as dataProvider from "../../graphql/DataProvider";
import { Coupon, ModelCouponFilterInput, couponCodeType } from "../../API";

interface ApplyCouponModalProps {
  open: boolean;
  appliedCoupon: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCouponCode: React.Dispatch<React.SetStateAction<string>>;
  setFlatOffAmount: React.Dispatch<React.SetStateAction<number>>;
  setPercentageDiscount: React.Dispatch<React.SetStateAction<number>>;
  setAppliedCoupon: React.Dispatch<React.SetStateAction<string>>;
}

const ApplyCouponModal = ({
  open,
  appliedCoupon,
  setOpen,
  setCouponCode,
  setFlatOffAmount,
  setPercentageDiscount,
  setAppliedCoupon,
}: ApplyCouponModalProps) => {
  const [coupon, setCoupon] = React.useState<string>(appliedCoupon);
  const [couponError, setCouponError] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  // apply the coupon code
  const handleApplyCoupon = () => {
    if (!coupon) return setCouponError("Coupon Code is required!");
    if(coupon === appliedCoupon) return setCouponError("Coupon Already Applied!")
    let filters = {
      couponCode: coupon as string,
    } as ModelCouponFilterInput;
    setIsLoading(true);
    dataProvider.listCoupons(null, null, filters).then((res) => {
      let couponData = res?.listCoupons?.items as Coupon[];
      if (!couponData || couponData.length === 0) {
        setCouponError("Coupon does not exits");
      } else {
        let code = couponData?.[0]?.couponCode as string;
        setFlatOffAmount(0);
        setPercentageDiscount(0);
        setCouponCode(code);
        setAppliedCoupon(code);
        if (couponData?.[0]?.type === couponCodeType.Flat_Off) {
          setFlatOffAmount(Number(couponData?.[0]?.amount) / 100);
        } else {
          setPercentageDiscount(Number(couponData?.[0]?.percentage) / 100);
        }
        setIsLoading(false);
        setOpen(false);
      }
    });
  };
  return (
    <Modal show={open} onClose={() => setOpen(false)}>
      <Modal.Header>
        <p className="font-semibold">Apply Coupon</p>
      </Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          <div className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            <p className="text-base font-semibold mb-1">Type Coupon Code:</p>
            <input
              autoFocus
              className="block w-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-12"
              type="text"
              value={coupon}
              onChange={(e) => setCoupon(e.target.value)}
            />
            <div className="text-red-500 text-sm text-start capitalize ">
              {couponError}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton
          btnText="Apply Coupon"
          onClick={handleApplyCoupon}
          showLoading={isLoading}
        />
        <SecondaryButton
          className="!w-fit"
          btnText="Cancel"
          onClick={() => setOpen(false)}
        />
      </Modal.Footer>
    </Modal>
  );
};
export default ApplyCouponModal;
