interface ImageWithFallbackProps {
  src: string;
  fallback: string;
  type?: string;
  alt?: string;
  [x: string]: any;
}

/// Provides an interface for displaying an webp image with fallback.
export const ImageWithFallback = ({
  src,
  fallback,
  type = "image/webp",
  alt,
  ...delegated
}: ImageWithFallbackProps) => {
  return (
    <picture>
      <source srcSet={src} type={type} />
      <img src={fallback} alt={alt} {...delegated} />
    </picture>
  );
};
