import React from "react";
import { JobStatus } from '../../API';
import ColorChips from '../color_chips';

interface DisplayJobStatusProps {
  status: JobStatus;
}

const DisplayJobStatus = ({ status } : DisplayJobStatusProps) => {

  const { value, bgClass }  = React.useMemo(() => {
    switch(status) {
      case JobStatus.OPEN:
        return {
          value: "Open",
          bgClass: "bg-green-500"
        };
      case JobStatus.CLOSED:
        return {
          value: "Closed",
          bgClass: "bg-red-500"
        };
      case JobStatus.IN_PROGRESS:
        return {
          value: "In Progress",
          bgClass: "bg-blue-500"
        };
      default:
        return {
          value: "",
          bgClass: ""
        }
    }
  }, [status]);

  return (
    <ColorChips value={value} bgStyle={bgClass} />
  )

}

export default DisplayJobStatus;