import React, { useState } from "react";
import { Modal } from "flowbite-react";
import PrimaryButton from "../buttons/primary_button";
import SecondaryButton from "../buttons/secondary_button";
import { useUserContext } from "../../helpers/UserContext";
import * as dataProvider from "../../graphql/DataProvider";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthenticator } from "@aws-amplify/ui-react";

interface DeactivateAccountModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const DeactivateAccountModal = ({
  open,
  setOpen,
}: DeactivateAccountModalProps) => {
  const { currentUser } = useUserContext();
  const [email, setEmail] = React.useState("");
  const { signOut } = useAuthenticator();
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const isValidEmail = email === currentUser?.email;
  const deactivateAccountHandle = async () => {
    if (currentUser !== null) {
      setloading(true);
      try {
        const deactivateAccount = await dataProvider.deactivateUserAccount(
          currentUser
        );
        if (deactivateAccount) {
          signOut();
          toast.success("Account successfully deactivated", {
            toastId: "deactivated",
          });
          navigate("/sigin");
          setloading(false);
        }
      } catch (error) {
        setloading(false);

        toast.error("Error deactivating account:");
      }
    } else {
      // Handle the case where currentUser is null
      toast.error("currentUser is null");
    }
  };
  return (
    <Modal show={open} onClose={() => setOpen(false)}>
      <Modal.Header>
        <p className="font-semibold text-red-600">Deactivate Account?</p>
      </Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            Are you sure you want to deactivate your account? All of your data
            will be permanently removed. This action cannot be undone.
          </p>
          <div className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            <p className="text-base font-semibold mb-1">
              Type your email to confirm:
            </p>
            <input
              autoFocus
              className="block w-full bg-gray-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 text-sm leading-6 h-12"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton
          disabled={!isValidEmail}
          showLoading={loading}
          btnText="Confirm"
          onClick={deactivateAccountHandle}
        />
        <SecondaryButton
          className="!w-fit"
          btnText="Decline"
          onClick={() => setOpen(false)}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default DeactivateAccountModal;
