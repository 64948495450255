import PrimaryButton from "../components/buttons/primary_button";
import { useUserContext } from "../helpers/UserContext";
import * as dataProvider from "../graphql/DataProvider";
import { useEffect, useState } from "react";
import { CategoryOption } from '../components/jobs/search';
const GoogleSheetJob = () => {
  const { currentUser } = useUserContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [jobCategories, setJobCategories] = useState<any[]>([]);

  
 
  useEffect(() => {
    let categoryOptions: CategoryOption[] = [];
    dataProvider.getJobCategories().then((categories) => {
      categories?.forEach((category) => {
        categoryOptions.push({
          id: category!.id,
          name: category!.name,
          unavailable: false,
        });
      });
      setJobCategories(categoryOptions);
    });
  }, []);
  const googlesheetjobscreate = async () => {
    if(currentUser ){
      setIsLoading(true)
    dataProvider.createjobGooglesheet(currentUser).then((res) => {
     
      dataProvider.createMultipleJobs(res,jobCategories,setIsLoading);
    });
  }
  };

  return (
    <div className="bg-white flex items-start p-6 lg:p-14 flex-col w-11/12 m-auto lg:w-full text-left shadow-md rounded-xl h-auto mt-10">
      <div className="flex justify-center w-full">
        {/* <div className="grid gap-5"> */}
        <div className="inline w-full relative">
          <div className="flex gap-4 items-center relative w-full flex-row justify-between">
            <div className="flex justify-start gap-4 items-center w-full">
              <h2 className="text-gray-700 md:text-3xl text-2xl font-semibold items-center w-full">
                Create GoogleSheet Jobs
              </h2>
            </div>
          </div>
          <section className="mt-2">
            This will create jobs given in the sheet - <br />
            <a
              href="https://docs.google.com/spreadsheets/d/1jKq0uHtYvo3TKqbv4JcatWiIv_W43DD_3oBCd9ln8BI/edit#gid=0"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-[teal]"
            >
              https://docs.google.com/spreadsheets/d/1jKq0uHtYvo3TKqbv4JcatWiIv_W43DD_3oBCd9ln8BI/edit#gid=0
            </a>
          </section>
        </div>
        <PrimaryButton
          className="bg-orange-400 h-fit"
          btnText="Create"
          showLoading={isLoading}
          onClick={googlesheetjobscreate}
        />
      </div>
    </div>
  );
};

export default GoogleSheetJob;
