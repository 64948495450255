interface ColorChipProps {
  /// The value to show.
  value: any;
  /// The bg style, such as "bg-red-200"
  bgStyle: string;
}

/// A colored chip / bubble - whatever you want to call it with a value inside.
export default function ColorChips({ value, bgStyle }: ColorChipProps) {
  return (
    <span
      className={`relative flex gap-2 items-center px-3 py-1 font-semibold leading-tight text-gray-700`}
    >
      <span className={`${bgStyle} h-2 w-2 rounded-full relative`}></span>
      <span className="relative GothamBold">{value}</span>
    </span>
  );
}

interface ChipProps {
  /// The value to show.
  value: any;
}

/// Gray bubble chip thing.
export function GrayColorChip({ value }: ChipProps) {
  return ColorChips({ value: value, bgStyle: "bg-gray-400" });
}

/// Red bubble chip thing.
export function RedColorChip({ value }: ChipProps) {
  return ColorChips({ value: value, bgStyle: "bg-red-500" });
}

/// Green bubble chip thing.
export function GreenColorChip({ value }: ChipProps) {
  return ColorChips({ value: value, bgStyle: "bg-green-500" });
}
