import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { StarIcon } from "@heroicons/react/20/solid";
import * as dataProvider from "../../graphql/DataProvider";
import { Job, UserReview } from "../../API";
import Chips from "../chips";

export enum ModalContentType {
  JOB = "JOB",
  USER_REVIEW = "USER_REVIEW",
}

export interface ContentModalProps {
  isOpen: boolean;
  closeModal: () => void;
  contentId: string;
  contentType: ModalContentType;
}

/// Displays a modal that shows the content for the ID and Type provided.
export default function ContentModal({
  isOpen,
  closeModal,
  contentId,
  contentType,
}: ContentModalProps) {
  const [content, setContent] = useState<any | null>(null);

  useEffect(() => {
    switch (contentType) {
      case ModalContentType.JOB:
        dataProvider.getJob(contentId).then((job) => {
          setContent(job);
        });
        break;
      case ModalContentType.USER_REVIEW:
        dataProvider.getReview(contentId).then((review) => {
          setContent(review);
        });
        break;
    }
  }, []);

  function formattedTitle() {
    switch (contentType) {
      case ModalContentType.JOB:
        return content?.title;
      case ModalContentType.USER_REVIEW:
        return "User Review";
    }
  }

  function formattedDescription() {
    switch (contentType) {
      case ModalContentType.JOB:
        return content?.description;
      case ModalContentType.USER_REVIEW:
        return content?.message;
    }
  }

  function jobDetailsIfNeeded() {
    if (contentType !== ModalContentType.JOB) {
      return;
    }
    const job = content as Job;
    if (!job) {
      return;
    }
    return (
      <div className="flex items-center gap-2 rounded-full pr-6">
        <Chips job={job} />
      </div>
    );
  }
  function reviewRatingIfNeeded() {
    if (contentType !== ModalContentType.USER_REVIEW) {
      return;
    }
    const userReview = content as UserReview;
    if (!userReview) {
      return;
    }
    return (
      <div className="flex items-center gap-2 rounded-full pr-6">
        {[...Array(5)].map((star, index) => {
          index += 1;
          if (index <= userReview.rating) {
            return (
              <div className="w-6 text-yellow-400">
                <StarIcon className="h-full m-auto"></StarIcon>
              </div>
            );
          } else {
            return (
              <div className="w-6 text-gray-200">
                <StarIcon className="h-full m-auto"></StarIcon>
              </div>
            );
          }
        })}
        <h3 className="text-gray-700 text-md font-bold pt-1">{userReview.rating}</h3>
      </div>
    );
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    {formattedTitle()}
                  </Dialog.Title>
                  <div className="mt-2">
                    {reviewRatingIfNeeded()}
                    <p className="text-sm text-black">{formattedDescription()}</p>
                    {jobDetailsIfNeeded()}
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
