import { ReactElement } from "react";
import { ImageWithFallback } from "../../helpers/ImageWithFallback";

interface TwoColProps {
  header?: string | undefined;
  body: string;
  imgSrc: string;
  imgSrcFallback: string;
  imgSrcMobile: string;
  imgSrcMobileFallback: string;
  style: any;
  subTitle?: string | undefined;
  secondarySubBody?: ReactElement | undefined;
  subBody?: ReactElement | undefined;
  direction?: string | undefined;
}

function TwoCol({
  header,
  body,
  imgSrc,
  imgSrcFallback,
  imgSrcMobile,
  imgSrcMobileFallback,
  style,
  subTitle,
  secondarySubBody,
  subBody,
  direction,
}: TwoColProps) {
  return (
    <>
      <div
        className={`Hero flex ${
          direction ? "flex-row-reverse" : "flex-row"
        } h-auto lg:h-[800px] w-full mx-auto px-4 lg:px-10 pt-8 lg:pt-0`}
        style={style}
      >
        <div className="basis-full relative lg:basis-6/12 h-auto flex-row justify-center">
          <div className="text-left p-2 lg:p-12">
            <div className="basis-full lg:basis-6/12">
              {header !== undefined && <h1 className="py-4 colledge !text-teal !capitalize">{header}</h1>}
              {body !== undefined && <p className="text-slate-600 text-[17px] pb-12 lg:pb-16">{body}</p>}
              {subTitle !== undefined && (
                <h2 className="text-gray-900 pb-4 text-lg lg:text-2xl font-black">{subTitle}</h2>
              )}
              {subBody !== undefined && <p className="text-slate-600 pb-4 lg:pb-8">{subBody}</p>}
              {secondarySubBody !== undefined && (
                <p className="text-slate-600 pb-4 lg:pb-8">{secondarySubBody}</p>
              )}
            </div>
            <ImageWithFallback
              src={imgSrcMobile}
              fallback={imgSrcMobileFallback}
              alt=""
              className="object-cover items-center block lg:hidden mt-4"
            />
          </div>
        </div>
        <div className="basis-full lg:basis-6/12 w-full self-center hidden lg:block lg:h-auto">
          <ImageWithFallback
            src={imgSrc}
            fallback={imgSrcFallback}
            alt=""
            className="object-cover ml-auto hover:cursor-pointer"
          />
        </div>
      </div>
    </>
  );
}

export default TwoCol;
