import { useCallback, useState } from 'react';
// import { useResizeObserver } from '@wojtekmaj/react-hooks';
import { pdfjs, Document, Page } from 'react-pdf';
import Footer from "../components/nav/footer";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useEffect, useRef } from "react";
import SecondaryButton from "../components/buttons/secondary_button";
import './terms.css';

import type { PDFDocumentProxy } from 'pdfjs-dist';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url,
// ).toString();

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

const resizeObserverOptions = {};

const maxWidth = 800;

type PDFFile = string | File | null;

export default function Terms() {
  const faqRef = useRef<HTMLDivElement | null>(null);
  const whyColledgeRef = useRef<HTMLDivElement | null>(null);
  const aboutUsRef = useRef<HTMLDivElement | null>(null);
  const [file, setFile] = useState<PDFFile>('/colledge_tos.pdf');
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>();


  const onResize = useCallback<ResizeObserverCallback>((entries) => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  // useResizeObserver(containerRef, resizeObserverOptions, onResize);

  function onFileChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { files } = event.target;

    if (files && files[0]) {
      setFile(files[0] || null);
    }
  }

  function onDocumentLoadSuccess({ numPages: nextNumPages }: PDFDocumentProxy): void {
    setNumPages(nextNumPages);
    setPageNumber(1);
    // setNumPages(nextNumPages);
  }
  function changePage(offset: number) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <>
    <div className="terms">
      <div className="terms__container">
        <div className="terms__container__document" ref={setContainerRef}>
          <Document file={file} onLoadSuccess={onDocumentLoadSuccess} options={options}>
              <Page
                pageNumber={pageNumber}
                width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
              />
          </Document>
          <div className="text-gray-900 text-lg font-medium p-2 mt-8">
            <SecondaryButton
              className="!w-fit"
              btnText="Prev"
              onClick={previousPage}
              disabled={pageNumber <= 1}
            />   
            <span> Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'} </span>         

            <SecondaryButton
              className="!w-fit"
              btnText="Next"
              onClick={nextPage}
              disabled={pageNumber >= numPages}
            />             
          </div>          
        </div>
      </div>
    </div>
    <Footer
          faqRef={faqRef}
          aboutUsRef={aboutUsRef}
          whyColledgeRef={whyColledgeRef}
        />
    </>        
  );
}