import * as dataProvider from "../../graphql/DataProvider";
import ApplicantDetails from "./applicant_details";
import { ApplicationStatus, Job, JobApplication, JobStatus } from "../../API";
import { useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import SecondaryButton from "../buttons/secondary_button";
import { EmailTemplate, sendEmail } from "../../graphql/EmailHandler";
import { getColledgeURL } from "../../helpers/Utilities";
import PrimaryButton from "../buttons/primary_button";
import { logEvent } from "../../helpers/Analytics";

interface ModalProps {
  job: Job;
  isOpen: boolean;
  onClose: (refetch?: boolean) => void;
}

/// Modal where a poster selects a student to hire for their job.
export default function HireModal({ job, isOpen, onClose }: ModalProps) {
  const [selectedApplication, setSelectedApplication] = useState<JobApplication | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fetchedJob, setFetchedJob] = useState<Job | null>(null);
  const [applicantLoading, setApplicantLoading] = useState(false);

  useEffect(() => {
    if (job?.id) {
      dataProvider.getJob(job.id).then((response) => {
        setFetchedJob(response);
      });
    }
  }, [job]);

  /// Callback for the applicant that was selected.
  const applicationSelected = (application: JobApplication | null) => {
    setSelectedApplication(application);
  };

  /// Sets who the poster hired for the job. It could have
  /// been a student or nobody. If nobody, then it will set
  /// all applicants as denied.
  async function markApplicantHired() {
    setIsLoading(true);
    for (const application of fetchedJob?.applicants?.items as JobApplication[]) {
      // If the applicant was selected, mark them as accepted,
      // otherwise mark as denied.
      const status =
        application.id === selectedApplication?.id
          ? ApplicationStatus.ACCEPTED
          : ApplicationStatus.DENIED;
      await dataProvider.updateJobApplicationStatus(status, application);
      // Send email to the applicants.
      let templateId = "";
      let actionURL = getColledgeURL();
      let fromEmail = "hello@colledge.us";
      switch (status) {
        case ApplicationStatus.ACCEPTED:
          const messageLog = await dataProvider.getMessageLogForJob(fetchedJob!, application);
          const posterEmail = dataProvider.userContactEmailForMessageLog(
            fetchedJob?.poster,
            messageLog
          );
          if (posterEmail) {
            fromEmail = posterEmail;
          }
          templateId = "d-98d1c75e793e4aa0a2831ded4c6bbeb4";
          actionURL = actionURL + "job_listing/" + fetchedJob?.id;
          break;
        case ApplicationStatus.DENIED:
          templateId = "d-f7977dba5c8a4d86beab91ec265a676d";
          actionURL = actionURL + "browse";
          break;
      }
      const params: EmailTemplate = {
        toEmail: application.applicant.email,
        fromEmail: fromEmail,
        templateId: templateId,
        actionURL: actionURL,
      };
      await sendEmail(params);
    }
    // Mark the job as IN PROGRESS or CLOSED, depending on who was hired.
    const jobStatus = selectedApplication ? JobStatus.IN_PROGRESS : JobStatus.CLOSED;
    await dataProvider.updateJobStatus(jobStatus, fetchedJob);
    setIsLoading(false);
    // pass the flag true to submit
    onClose(true);
  }

  const onCloseHireModal = () =>{
    onClose()
    setSelectedApplication(null)
  }

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 overflow-auto ">
          <div className="fixed inset-0 bg-gray-900 opacity-75"></div>
          <div
            className="bg-white rounded-lg p-8 z-10 w-10/12 lg:w-5/12"
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxHeight: "90vh",
              overflowY: "auto",
            }}
          >
            <div className="w-full text-left justify-between relative m-auto gap-4 flex items-center mb-6">
              <h2 className="text-gray-900 text-3xl font-bold">Select a Student for the Job</h2>
              <XMarkIcon
                onClick={() => {
                  onCloseHireModal()
                  logEvent("hire_modal_close_button_clicked");
                }}
                className="cursor-pointer w-12 h-12 p-3 hover:pointer rounded-full text-gray-700 bg-gray-50"
              />
            </div>
            {fetchedJob?.applicants?.items.map((applicant: any) => {
              return (
                <ApplicantDetails
                  showChevron={false}
                  application={applicant}
                  selectedApplication={applicationSelected}
                  isHired={applicant.status === ApplicationStatus.ACCEPTED}
                  setLoading={setApplicantLoading}
                  loading={applicantLoading}
                />
              );
            })}
            {!applicantLoading && (
              <div className="w-full m-auto flex flex-col gap-3">
                <PrimaryButton
                  btnText="Hire"
                  primaryColor="bg-teal"
                  onClick={() => {
                    logEvent("hire_modal_hire_button_clicked");
                    markApplicantHired();
                  }}
                  disabled={isLoading || !selectedApplication}
                  showLoading={isLoading}
                />

                <SecondaryButton
                  btnText="Cancel"
                  onClick={() => {
                    logEvent("hire_modal_nohire_button_clicked");
                    onCloseHireModal()
                  }}
                  disabled={isLoading}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
