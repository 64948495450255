import { useEffect, useState } from "react";
import { Job, JobStatus, UserType } from "../../API";
import JobCards from "../jobs/job_cards";
import { useUserContext } from "../../helpers/UserContext";
import { CenterInPageSpinner } from "../loading_indicator";
import * as dataProvider from "../../graphql/DataProvider";
import SkeletonMyJob from "../Skeleton/SkeletonMyJob";

/// Shows a poster's jobs and their statuses.
export default function PosterJobsView() {
  const { currentUser } = useUserContext();
  const [openJobs, setOpenJobs] = useState<Job[]>([]);
  const [inProgressJobs, setInProgressJobs] = useState<Job[]>([]);
  const [completedJobs, setCompletedJobs] = useState<Job[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (currentUser?.type !== UserType.POSTER) {
      setIsLoading(false);
      return;
    }
    fetchData();
  }, [currentUser]);

  async function fetchData() {
    setIsLoading(true);
    const [jobs] = await dataProvider.getJobsAndApplicationsForUser(
      currentUser
    );
    if (!jobs) {
      return;
    }
    const open: Job[] = jobs.filter(
      (job: Job) => job.jobStatus === JobStatus.OPEN
    );
    const inProgress: Job[] = jobs.filter(
      (job: Job) => job.jobStatus === JobStatus.IN_PROGRESS
    );
    const completed: Job[] = jobs.filter(
      (job: Job) => job.jobStatus === JobStatus.CLOSED
    );

    setIsLoading(false);
    setOpenJobs(open ?? []);
    setInProgressJobs(inProgress ?? []);
    setCompletedJobs(completed ?? []);
  }

  if (isLoading) {
    // return <CenterInPageSpinner />;
    return (
      <div className="my-5">
        {/* <CustomSpinner /> */}
        {Array(1)
          .fill(null)
          .map((_, index) => (
            <SkeletonMyJob key={index} />
          ))}
      </div>
    );
  } else {
    return (
      <div className="bg-white flex items-start p-6 lg:p-14 flex-col w-11/12 m-auto lg:w-full text-left rounded-xl h-auto gap-4">
        {openJobs.length > 1 ||
        inProgressJobs.length > 1 ||
        completedJobs.length > 1 ? (
          <h2 className="text-gray-700 text-3xl font-semibold GothamBold">
            My jobs
          </h2>
        ) : (
          <div className="flex flex-col gap-2 items-center pb-2">
            <p className="text-gray-400 text-md font-md GothamBook">
              When you create a job. They will appear here.
            </p>
          </div>
        )}

        {openJobs.length > 0 && (
          <>
            <div className="flex gap-2 items-center pb-2 w-full">
              <div className="bg-green-600 w-3 h-3 rounded-full"></div>
              <p className="text-gray-700 text-md font-bold GothamBold">Open</p>
            </div>
            {openJobs?.map((job: Job) => (
              <div className="w-full" key={job.id}>
                <JobCards job={job} showApplicants={true} />
              </div>
            ))}
          </>
        )}

        {inProgressJobs.length > 0 && (
          <>
            <div className="flex gap-2 items-center pb-2">
              <div className="bg-orange-500 w-3 h-3 text-md font-bold rounded-full"></div>
              <p className="text-gray-700 text-lg GothamBold">In Progress</p>
            </div>
            {inProgressJobs.map((job: Job, index: number) => (
              <div className="w-full" key={index}>
                <JobCards job={job} showApplicants={true} />
              </div>
            ))}
          </>
        )}

        {completedJobs.length > 0 && (
          <>
            <div className="flex gap-2 items-center pb-2">
              <div className="bg-gray-600 w-3 h-3 text-md font-bold rounded-full"></div>
              <p className="text-gray-700 text-lg GothamBold">Completed</p>
            </div>
            {completedJobs.map((job: Job) => (
              <div className="w-full" key={job.id}>
                <JobCards job={job} showApplicants={true} />
              </div>
            ))}
          </>
        )}
      </div>
    );
  }
}
