import { Listbox } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { set } from "immer/dist/internal";
import { useEffect, useState } from "react";

/// An option in the dropdown selector.
export interface DropDownSelectorOption {
  id: string | number;
  // id: string | number | any;
  name: string;
}

interface DropdownSelectorProps {
  /// The options to show in the dropdown.
  options: DropDownSelectorOption[];
  /// The selected option.
  selectedOption?: DropDownSelectorOption | undefined;
  /// The function to call when the selected option changes.
  onChange: (value: DropDownSelectorOption) => void;
  className?: string;
  placeholder?: string;
  customColor?: string;
  // maxWidthDropDown?: string;
}

/// Dropdown button that allows a user to select an option.
export default function DropdownSelector({
  options,
  selectedOption,
  onChange,
  className = "",
  placeholder = "",
  customColor,
  // maxWidthDropDown,
}: DropdownSelectorProps) {
  const [selected, setSelected] = useState<DropDownSelectorOption | undefined>(
    selectedOption ?? options[0]
  );

  useEffect(() => {
    if (selectedOption) {
      setSelected(selectedOption);
    }
  }, [selectedOption]);

  return (
    <Listbox
      value={selected}
      onChange={(option) => {
        onChange(option);
        setSelected(option);
      }}
      by="id"
    >
      <div className="flex relative flex-row w-auto py-1 selector-main-div ">
        <Listbox.Button
          className={`relative items-center GothamBold w-full cursor-default  rounded-md font-semibold !text-${customColor}-500 !border-${customColor}-500 !border-[1.7px] !bg-${customColor}-100 py-3 pr-10 text-left focus:outline-none focus-visible:border-gray-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 text-sm
          text-[#138086] border-[#138086] border-[1.7px] bg-[#138086] bg-opacity-10
          ${className}`}
          // className={`relative items-center GothamBold w-full cursor-default rounded-md font-semibold text-red-500 border-red-500 border-[1.7px] bg-red-100  py-3 pr-10 text-left focus:outline-none focus-visible:border-gray-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 text-sm ${className}`}
        >
          <span className="block truncate pl-1">
            {selected?.name || placeholder}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ChevronDownIcon
              // className=" text-red-500 h-4 w-4 "
              className={`h-4 w-4 text-${customColor}-900 
            text-[#138086] 
            `}
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Listbox.Options
        className="absolute max-h-60 text-left overflow-auto rounded-md top-[60px] bg-white py-1  text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm z-40 md:max-w-[384px] max-w-[280px]"
        //  className={` absolute max-h-60 text-left overflow-auto rounded-md top-[60px] bg-white py-1  text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm z-40 ${maxWidthDropDown}`}
         >
          {options.map((option: DropDownSelectorOption, index) => (
            <Listbox.Option
              key={index}
              className={({ active }) =>
                `relative cursor-default hover:bg-gray-300 hover:!bg-${customColor}-500 hover:bg-opacity-25  select-none py-2 pl-10 pr-4 ${
                  active ? " text-gray-900" : "text-gray-800"
                }`
              }
              value={option}
            >
              {({ selected }) => (
                <>
                  <span
                    className={`flex text-${customColor}-800 
            text-[#138086] truncate GothamBook  md:!whitespace-nowrap !whitespace-normal  ${
              selected ? "font-medium" : "font-normal"
            }`}
                  >
                    {option.name}
                  </span>
                  {selected ? (
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600">
                      <CheckIcon
                        // className="h-5 w-5 text-red-500"
                        className={`h-4 w-4 text-${customColor}-800 
            text-[#138086]
            `}
                        aria-hidden="true"
                      />
                    </span>
                  ) : null}
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </div>
    </Listbox>
  );
}
