const SkeletonFooter = () => {
  return (
    <>
      <div className="w-full skeleton-pulse h-[300px] !rounded-xl">
        <div className="mx-auto max-w-screen-xl space-y-8 px-4 py-16 lg:space-y-16 lg:px-8 text-left">
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
            <div>
              <div className="text-teal-600">
                <div className="font-bold font-gotham text-3xl tracking-tight  skeleton-block rounded-md w-[236px] h-[60px] "></div>
              </div>
            </div>
            <div className="grid md:grid-cols-3 w-full gap-8 grid-cols-2 lg:col-span-2 lg:grid-cols-3 md:flex md:justify-between">
              <div>
                <p className="font-semibold text-gray-900 skeleton-block  w-36 h-8 rounded-md"></p>
                <ul className="mt-6 space-y-4 text-sm">
                  <li>
                    <div className="text-gray-600  skeleton-block  transition font-bold hover:text-[teal] md:w-44 w-36 h-5 "></div>
                  </li>
                  <li>
                    <div className="text-gray-600  skeleton-block transition rounded font-bold hover:text-[teal] md:w-44 w-36 h-5 "></div>
                  </li>
                </ul>
              </div>
              <div>
                <p className="font-semibold text-gray-900 skeleton-block  w-36 h-8 rounded-md"></p>
                <ul className="mt-6 space-y-4 text-sm">
                  <li>
                    <div className="text-gray-600  skeleton-block transition rounded font-bold hover:text-[teal] md:w-44 w-36 h-5 "></div>
                  </li>
                  <li>
                    <div className="text-gray-600  skeleton-block transition rounded font-bold hover:text-[teal] md:w-44 w-36 h-5 "></div>
                  </li>
                </ul>
              </div>
              <div>
                <p className="font-semibold text-gray-900 skeleton-block  w-36 h-8 rounded-md"></p>
                <ul className="mt-6 space-y-4 text-sm">
                  <li>
                    <div className="text-gray-600  skeleton-block transition rounded font-bold hover:text-[teal] md:w-44 w-36 h-5 "></div>
                  </li>
                  <li>
                    <div className="text-gray-600  skeleton-block transition rounded font-bold hover-text-[teal] md:w-44 w-36 h-5 "></div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <p className="text-xs text-gray-500 font-bold max-w-[500px] w-full  h-8 rounded skeleton-block"></p>
        </div>
      </div>
    </>
  );
};

export default SkeletonFooter;
